const value: Record<string, Record<string, [number, number][]>> = {
    'world': {
        'overall': [
            [
                162593,
                1,
            ],
            [
                162572,
                1,
            ],
            [
                162584,
                1,
            ],
            [
                163755,
                26,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                187474,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164089,
                3,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164089,
                3,
            ],
            [
                187474,
                1,
            ],
            [
                164112,
                6,
            ],
            [
                6698,
                5,
            ],
            [
                164030,
                4,
            ],
            [
                6372,
                1,
            ],
            [
                164030,
                1,
            ],
            [
                6372,
                1,
            ],
            [
                164030,
                1,
            ],
            [
                6372,
                1,
            ],
            [
                163999,
                3,
            ],
            [
                164072,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                17212,
                5,
            ],
            [
                187474,
                2,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                163707,
                6,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163740,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163740,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163740,
                1,
            ],
            [
                164109,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                164058,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                164058,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                164058,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                164074,
                3,
            ],
            [
                163707,
                1,
            ],
            [
                164089,
                3,
            ],
            [
                163755,
                4,
            ],
            [
                5054,
                1,
            ],
            [
                164041,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164041,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164041,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                164112,
                12,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164023,
                25,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                4,
            ],
            [
                6698,
                1,
            ],
            [
                164112,
                2,
            ],
            [
                164065,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                139134,
                3,
            ],
            [
                156418,
                3,
            ],
            [
                162527,
                3,
            ],
            [
                163707,
                1,
            ],
            [
                164112,
                3,
            ],
            [
                6698,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                157980,
                1,
            ],
            [
                160180,
                1,
            ],
            [
                162940,
                1,
            ],
            [
                157606,
                1,
            ],
            [
                159736,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                164072,
                5,
            ],
            [
                164112,
                1,
            ],
            [
                162650,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162650,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                164025,
                5,
            ],
            [
                164006,
                1,
            ],
            [
                162864,
                1,
            ],
            [
                163549,
                1,
            ],
            [
                163740,
                3,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                5,
            ],
            [
                163930,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                2,
                54,
            ],
            [
                82,
                1,
            ],
            [
                2,
                12,
            ],
            [
                82,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                27,
                54,
            ],
            [
                820,
                1,
            ],
            [
                27,
                12,
            ],
            [
                820,
                1,
            ],
            [
                27,
                6,
            ],
            [
                1,
                5,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                820,
                7,
            ],
            [
                27,
                18,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                2,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                11,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                46,
            ],
            [
                1,
                1,
            ],
            [
                27,
                17,
            ],
            [
                1,
                1,
            ],
            [
                27,
                21,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                40,
                1,
            ],
            [
                0,
                12,
            ],
            [
                40,
                1,
            ],
            [
                0,
                35,
            ],
            [
                40,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                13,
                54,
            ],
            [
                410,
                1,
            ],
            [
                13,
                12,
            ],
            [
                410,
                1,
            ],
            [
                13,
                6,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                7,
            ],
            [
                13,
                18,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                4,
                54,
            ],
            [
                164,
                1,
            ],
            [
                4,
                12,
            ],
            [
                164,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                61,
                54,
            ],
            [
                1646,
                1,
            ],
            [
                61,
                12,
            ],
            [
                1646,
                1,
            ],
            [
                61,
                6,
            ],
            [
                3,
                5,
            ],
            [
                61,
                4,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                4,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1646,
                7,
            ],
            [
                61,
                18,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                4,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                2,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                11,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                46,
            ],
            [
                3,
                1,
            ],
            [
                61,
                17,
            ],
            [
                3,
                1,
            ],
            [
                61,
                21,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                155,
                1,
            ],
            [
                0,
                12,
            ],
            [
                155,
                1,
            ],
            [
                0,
                6,
            ],
            [
                155,
                5,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                5,
            ],
            [
                155,
                2,
            ],
            [
                0,
                18,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                2,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                11,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                46,
            ],
            [
                155,
                1,
            ],
            [
                0,
                17,
            ],
            [
                155,
                1,
            ],
            [
                0,
                21,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                293,
                1,
            ],
            [
                0,
                12,
            ],
            [
                293,
                1,
            ],
            [
                0,
                6,
            ],
            [
                293,
                5,
            ],
            [
                0,
                4,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                4,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                5,
            ],
            [
                293,
                2,
            ],
            [
                0,
                18,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                4,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                2,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                11,
            ],
            [
                282,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                46,
            ],
            [
                293,
                1,
            ],
            [
                0,
                17,
            ],
            [
                293,
                1,
            ],
            [
                0,
                21,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4823,
                5,
            ],
            [
                0,
                4,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                5,
            ],
            [
                4823,
                2,
            ],
            [
                0,
                18,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                4,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                2,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                11,
            ],
            [
                3233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                46,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                17,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                21,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1025,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1025,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                11,
            ],
            [
                990,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                338,
                1,
            ],
            [
                0,
                12,
            ],
            [
                338,
                1,
            ],
            [
                0,
                6,
            ],
            [
                338,
                5,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                5,
            ],
            [
                338,
                2,
            ],
            [
                0,
                18,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                2,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                11,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                46,
            ],
            [
                338,
                1,
            ],
            [
                0,
                17,
            ],
            [
                338,
                1,
            ],
            [
                0,
                21,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CCD': [
            [
                35380,
                1,
            ],
            [
                35371,
                1,
            ],
            [
                35373,
                1,
            ],
            [
                36276,
                26,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                2,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36293,
                3,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36293,
                3,
            ],
            [
                36301,
                7,
            ],
            [
                0,
                5,
            ],
            [
                36274,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36269,
                3,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36301,
                3,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36240,
                6,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36242,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36242,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36242,
                1,
            ],
            [
                36300,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36279,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36279,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36279,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36292,
                3,
            ],
            [
                36240,
                1,
            ],
            [
                36293,
                3,
            ],
            [
                36276,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36301,
                12,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36275,
                25,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36301,
                2,
            ],
            [
                36293,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                28807,
                3,
            ],
            [
                34452,
                3,
            ],
            [
                35989,
                3,
            ],
            [
                36240,
                1,
            ],
            [
                36301,
                3,
            ],
            [
                0,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                33767,
                1,
            ],
            [
                34329,
                1,
            ],
            [
                35529,
                1,
            ],
            [
                33543,
                1,
            ],
            [
                34057,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36290,
                5,
            ],
            [
                36301,
                1,
            ],
            [
                35405,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                35405,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                5,
            ],
            [
                36300,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36242,
                3,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                5,
            ],
            [
                36295,
                6,
            ],
        ],
        'CSA': [
            [
                171,
                3,
            ],
            [
                174,
                71,
            ],
            [
                0,
                5,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                6,
            ],
            [
                174,
                20,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                2,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                11,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                46,
            ],
            [
                0,
                1,
            ],
            [
                174,
                17,
            ],
            [
                0,
                1,
            ],
            [
                174,
                17,
            ],
            [
                171,
                1,
            ],
            [
                174,
                1,
            ],
            [
                171,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                25,
            ],
        ],
        'City': [
            [
                31828,
                1,
            ],
            [
                31822,
                1,
            ],
            [
                31827,
                1,
            ],
            [
                32002,
                26,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                2,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                4,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                10,
            ],
            [
                0,
                5,
            ],
            [
                32129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32124,
                3,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32132,
                3,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32003,
                6,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32009,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                3,
            ],
            [
                32003,
                1,
            ],
            [
                32132,
                3,
            ],
            [
                32002,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                12,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32128,
                25,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                2,
            ],
            [
                32128,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                24055,
                3,
            ],
            [
                29583,
                3,
            ],
            [
                31581,
                3,
            ],
            [
                32003,
                1,
            ],
            [
                32132,
                3,
            ],
            [
                0,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                30155,
                1,
            ],
            [
                30861,
                1,
            ],
            [
                31878,
                1,
            ],
            [
                30064,
                1,
            ],
            [
                30765,
                1,
            ],
            [
                32132,
                7,
            ],
            [
                31840,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31840,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                5,
            ],
            [
                32091,
                1,
            ],
            [
                31765,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                32009,
                3,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                5,
            ],
            [
                32082,
                6,
            ],
        ],
        'Congressional District': [
            [
                435,
                74,
            ],
            [
                0,
                5,
            ],
            [
                435,
                4,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                4,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                6,
            ],
            [
                435,
                20,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                4,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                2,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                11,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                46,
            ],
            [
                0,
                1,
            ],
            [
                435,
                17,
            ],
            [
                0,
                1,
            ],
            [
                435,
                21,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                25,
            ],
        ],
        'Continent': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                1,
                54,
            ],
            [
                217,
                1,
            ],
            [
                1,
                12,
            ],
            [
                217,
                1,
            ],
            [
                1,
                35,
            ],
            [
                217,
                7,
            ],
            [
                1,
                209,
            ],
        ],
        'County': [
            [
                3144,
                3,
            ],
            [
                3222,
                71,
            ],
            [
                0,
                5,
            ],
            [
                3222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3222,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                4,
            ],
            [
                3180,
                3,
            ],
            [
                3215,
                3,
            ],
            [
                3222,
                7,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                17,
            ],
            [
                3144,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                3144,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                25,
            ],
        ],
        'County Cross CD': [
            [
                3721,
                3,
            ],
            [
                3720,
                26,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                2,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                4,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                10,
            ],
            [
                0,
                5,
            ],
            [
                3721,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3721,
                3,
            ],
            [
                3720,
                8,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                3,
            ],
            [
                3720,
                1,
            ],
            [
                3721,
                3,
            ],
            [
                3720,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                12,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                26,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                4,
            ],
            [
                3663,
                3,
            ],
            [
                3708,
                3,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                3,
            ],
            [
                3719,
                3,
            ],
            [
                3718,
                1,
            ],
            [
                3721,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                6,
            ],
            [
                3720,
                5,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                11,
            ],
        ],
        'Historical Congressional District': [
            [
                9365,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9365,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                306,
                74,
            ],
            [
                0,
                5,
            ],
            [
                306,
                4,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                4,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                6,
            ],
            [
                306,
                20,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                4,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                2,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                11,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                46,
            ],
            [
                0,
                1,
            ],
            [
                306,
                17,
            ],
            [
                0,
                1,
            ],
            [
                306,
                21,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                3436,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3436,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3436,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                4,
            ],
            [
                3427,
                3,
            ],
            [
                3436,
                10,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Judicial District': [
            [
                90,
                3,
            ],
            [
                91,
                71,
            ],
            [
                0,
                5,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                6,
            ],
            [
                91,
                20,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                2,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                11,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                46,
            ],
            [
                0,
                1,
            ],
            [
                91,
                17,
            ],
            [
                0,
                1,
            ],
            [
                91,
                17,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                25,
            ],
        ],
        'MSA': [
            [
                933,
                3,
            ],
            [
                945,
                71,
            ],
            [
                0,
                5,
            ],
            [
                945,
                4,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                4,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                6,
            ],
            [
                945,
                20,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                4,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                2,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                11,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                46,
            ],
            [
                0,
                1,
            ],
            [
                945,
                17,
            ],
            [
                0,
                1,
            ],
            [
                945,
                17,
            ],
            [
                933,
                1,
            ],
            [
                945,
                1,
            ],
            [
                933,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                25,
            ],
        ],
        'Media Market': [
            [
                210,
                74,
            ],
            [
                0,
                5,
            ],
            [
                210,
                4,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                4,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                6,
            ],
            [
                210,
                20,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                4,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                2,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                11,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                46,
            ],
            [
                0,
                1,
            ],
            [
                210,
                17,
            ],
            [
                0,
                1,
            ],
            [
                210,
                21,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                25,
            ],
        ],
        'Native Area': [
            [
                371,
                2,
            ],
            [
                372,
                1,
            ],
            [
                357,
                26,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                2,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                4,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                10,
            ],
            [
                0,
                5,
            ],
            [
                369,
                4,
            ],
            [
                0,
                1,
            ],
            [
                369,
                1,
            ],
            [
                0,
                1,
            ],
            [
                369,
                1,
            ],
            [
                0,
                1,
            ],
            [
                367,
                3,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                6,
            ],
            [
                373,
                3,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                362,
                6,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                372,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                2,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                3,
            ],
            [
                362,
                1,
            ],
            [
                373,
                3,
            ],
            [
                357,
                4,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                12,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                370,
                25,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                4,
            ],
            [
                0,
                1,
            ],
            [
                373,
                4,
            ],
            [
                270,
                3,
            ],
            [
                340,
                3,
            ],
            [
                356,
                3,
            ],
            [
                362,
                1,
            ],
            [
                373,
                3,
            ],
            [
                0,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                338,
                1,
            ],
            [
                351,
                1,
            ],
            [
                366,
                1,
            ],
            [
                336,
                1,
            ],
            [
                347,
                1,
            ],
            [
                373,
                10,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                6,
            ],
            [
                346,
                1,
            ],
            [
                354,
                1,
            ],
            [
                362,
                3,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                5,
            ],
            [
                370,
                6,
            ],
        ],
        'Native Statistical Area': [
            [
                288,
                1,
            ],
            [
                285,
                2,
            ],
            [
                278,
                26,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                2,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                4,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                10,
            ],
            [
                0,
                5,
            ],
            [
                286,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                3,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                6,
            ],
            [
                288,
                3,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                281,
                6,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                2,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                3,
            ],
            [
                281,
                1,
            ],
            [
                288,
                3,
            ],
            [
                278,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                12,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                286,
                25,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                4,
            ],
            [
                0,
                1,
            ],
            [
                288,
                4,
            ],
            [
                265,
                3,
            ],
            [
                272,
                3,
            ],
            [
                281,
                4,
            ],
            [
                288,
                3,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                269,
                1,
            ],
            [
                272,
                1,
            ],
            [
                278,
                1,
            ],
            [
                267,
                1,
            ],
            [
                271,
                1,
            ],
            [
                288,
                10,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                5,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                281,
                3,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                11,
            ],
        ],
        'Native Subdivision': [
            [
                459,
                3,
            ],
            [
                455,
                26,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                2,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                4,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                10,
            ],
            [
                0,
                5,
            ],
            [
                457,
                4,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                3,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                6,
            ],
            [
                459,
                3,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                450,
                6,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                2,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                3,
            ],
            [
                450,
                1,
            ],
            [
                459,
                3,
            ],
            [
                455,
                4,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                12,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                457,
                25,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                4,
            ],
            [
                0,
                1,
            ],
            [
                459,
                4,
            ],
            [
                305,
                3,
            ],
            [
                415,
                3,
            ],
            [
                444,
                3,
            ],
            [
                450,
                1,
            ],
            [
                459,
                3,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                433,
                1,
            ],
            [
                435,
                1,
            ],
            [
                447,
                1,
            ],
            [
                429,
                1,
            ],
            [
                433,
                1,
            ],
            [
                459,
                10,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                5,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                450,
                3,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                11,
            ],
        ],
        'Neighborhood': [
            [
                16413,
                1,
            ],
            [
                16412,
                1,
            ],
            [
                16415,
                1,
            ],
            [
                16322,
                26,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                2,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16428,
                3,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16428,
                3,
            ],
            [
                16440,
                7,
            ],
            [
                0,
                5,
            ],
            [
                16415,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16415,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16415,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16407,
                3,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                6,
            ],
            [
                16440,
                3,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16386,
                6,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16390,
                1,
            ],
            [
                16439,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16424,
                3,
            ],
            [
                16386,
                1,
            ],
            [
                16428,
                3,
            ],
            [
                16322,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16417,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16417,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16417,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16440,
                12,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16410,
                25,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16440,
                2,
            ],
            [
                16424,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                13111,
                3,
            ],
            [
                14731,
                3,
            ],
            [
                16120,
                3,
            ],
            [
                16386,
                1,
            ],
            [
                16440,
                3,
            ],
            [
                0,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15710,
                1,
            ],
            [
                16261,
                1,
            ],
            [
                16426,
                1,
            ],
            [
                15705,
                1,
            ],
            [
                16253,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                16423,
                5,
            ],
            [
                16440,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                5,
            ],
            [
                16424,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16302,
                1,
            ],
            [
                16390,
                3,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                5,
            ],
            [
                16403,
                6,
            ],
        ],
        'School District': [
            [
                13323,
                1,
            ],
            [
                13322,
                2,
            ],
            [
                13326,
                26,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                2,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                4,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                10,
            ],
            [
                0,
                5,
            ],
            [
                13328,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                3,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13331,
                3,
            ],
            [
                13326,
                2,
            ],
            [
                13322,
                6,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13322,
                1,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13331,
                3,
            ],
            [
                13322,
                1,
            ],
            [
                13331,
                3,
            ],
            [
                13326,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13331,
                12,
            ],
            [
                13326,
                2,
            ],
            [
                13329,
                25,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13331,
                4,
            ],
            [
                12744,
                3,
            ],
            [
                13228,
                3,
            ],
            [
                13310,
                3,
            ],
            [
                13322,
                1,
            ],
            [
                13331,
                3,
            ],
            [
                0,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13272,
                1,
            ],
            [
                13290,
                1,
            ],
            [
                13318,
                1,
            ],
            [
                13269,
                1,
            ],
            [
                13286,
                1,
            ],
            [
                13331,
                1,
            ],
            [
                13330,
                5,
            ],
            [
                13331,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13331,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                5,
            ],
            [
                13328,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13322,
                3,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                5,
            ],
            [
                13328,
                6,
            ],
        ],
        'State House District': [
            [
                4834,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4834,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4834,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                4,
            ],
            [
                4825,
                3,
            ],
            [
                4832,
                3,
            ],
            [
                4834,
                7,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                25,
            ],
        ],
        'State Senate District': [
            [
                1942,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1942,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1942,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                25,
            ],
        ],
        'Subnational Region': [
            [
                51,
                3,
            ],
            [
                52,
                51,
            ],
            [
                3645,
                1,
            ],
            [
                52,
                12,
            ],
            [
                3645,
                1,
            ],
            [
                52,
                6,
            ],
            [
                13,
                5,
            ],
            [
                52,
                4,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                4,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                3645,
                7,
            ],
            [
                52,
                18,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                4,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                2,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                11,
            ],
            [
                10,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                46,
            ],
            [
                13,
                1,
            ],
            [
                52,
                17,
            ],
            [
                13,
                1,
            ],
            [
                52,
                17,
            ],
            [
                51,
                1,
            ],
            [
                52,
                1,
            ],
            [
                51,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                1,
            ],
            [
                13,
                1,
            ],
            [
                52,
                25,
            ],
        ],
        'USDA County Type': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Urban Area': [
            [
                2612,
                3,
            ],
            [
                2638,
                27,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                3,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                5,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                11,
            ],
            [
                0,
                5,
            ],
            [
                2638,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2638,
                3,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                8,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                12,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                27,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                5,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                4,
            ],
            [
                2594,
                3,
            ],
            [
                2631,
                3,
            ],
            [
                2638,
                7,
            ],
            [
                0,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                8,
            ],
            [
                2612,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2612,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                6,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                5,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                12,
            ],
        ],
        'Urban Center': [
            [
                321,
                3,
            ],
            [
                324,
                51,
            ],
            [
                10158,
                1,
            ],
            [
                324,
                12,
            ],
            [
                10158,
                1,
            ],
            [
                324,
                6,
            ],
            [
                46,
                5,
            ],
            [
                324,
                4,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                4,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                10158,
                7,
            ],
            [
                324,
                18,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                4,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                2,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                11,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                46,
            ],
            [
                46,
                1,
            ],
            [
                324,
                4,
            ],
            [
                323,
                3,
            ],
            [
                324,
                10,
            ],
            [
                46,
                1,
            ],
            [
                324,
                17,
            ],
            [
                321,
                1,
            ],
            [
                324,
                1,
            ],
            [
                321,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                1,
            ],
            [
                46,
                1,
            ],
            [
                324,
                25,
            ],
        ],
        'ZIP': [
            [
                32834,
                1,
            ],
            [
                32833,
                1,
            ],
            [
                32834,
                1,
            ],
            [
                32919,
                26,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                2,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32964,
                3,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32964,
                3,
            ],
            [
                32967,
                7,
            ],
            [
                0,
                5,
            ],
            [
                32951,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32951,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32951,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32940,
                3,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32967,
                3,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32843,
                6,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32864,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32864,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32864,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32963,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32957,
                3,
            ],
            [
                32843,
                1,
            ],
            [
                32964,
                3,
            ],
            [
                32919,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32967,
                12,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32947,
                25,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32967,
                2,
            ],
            [
                32948,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                27919,
                3,
            ],
            [
                31605,
                3,
            ],
            [
                32626,
                3,
            ],
            [
                32843,
                1,
            ],
            [
                32967,
                3,
            ],
            [
                0,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32218,
                1,
            ],
            [
                32562,
                1,
            ],
            [
                32879,
                1,
            ],
            [
                32176,
                1,
            ],
            [
                32506,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32956,
                5,
            ],
            [
                32967,
                1,
            ],
            [
                32836,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32836,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32949,
                5,
            ],
            [
                32925,
                1,
            ],
            [
                32760,
                1,
            ],
            [
                32773,
                1,
            ],
            [
                32864,
                3,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                5,
            ],
            [
                32884,
                6,
            ],
        ],
    },
    'Africa': {
        'overall': [
            [
                0,
                54,
            ],
            [
                3582,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3582,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3582,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                147,
                1,
            ],
            [
                0,
                12,
            ],
            [
                147,
                1,
            ],
            [
                0,
                35,
            ],
            [
                147,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                75,
                1,
            ],
            [
                0,
                12,
            ],
            [
                75,
                1,
            ],
            [
                0,
                35,
            ],
            [
                75,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                33,
                1,
            ],
            [
                0,
                12,
            ],
            [
                33,
                1,
            ],
            [
                0,
                35,
            ],
            [
                33,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                290,
                1,
            ],
            [
                0,
                12,
            ],
            [
                290,
                1,
            ],
            [
                0,
                35,
            ],
            [
                290,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Continent': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                55,
                1,
            ],
            [
                0,
                12,
            ],
            [
                55,
                1,
            ],
            [
                0,
                35,
            ],
            [
                55,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                939,
                1,
            ],
            [
                0,
                12,
            ],
            [
                939,
                1,
            ],
            [
                0,
                35,
            ],
            [
                939,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2012,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2012,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2012,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Antarctica': {
        'overall': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Asia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                8670,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8670,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8670,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                51,
                1,
            ],
            [
                0,
                12,
            ],
            [
                51,
                1,
            ],
            [
                0,
                35,
            ],
            [
                51,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                493,
                1,
            ],
            [
                0,
                12,
            ],
            [
                493,
                1,
            ],
            [
                0,
                35,
            ],
            [
                493,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                25,
                1,
            ],
            [
                0,
                12,
            ],
            [
                25,
                1,
            ],
            [
                0,
                35,
            ],
            [
                25,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                248,
                1,
            ],
            [
                0,
                12,
            ],
            [
                248,
                1,
            ],
            [
                0,
                35,
            ],
            [
                248,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                103,
                1,
            ],
            [
                0,
                12,
            ],
            [
                103,
                1,
            ],
            [
                0,
                35,
            ],
            [
                103,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                986,
                1,
            ],
            [
                0,
                12,
            ],
            [
                986,
                1,
            ],
            [
                0,
                35,
            ],
            [
                986,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Continent': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                52,
                1,
            ],
            [
                0,
                12,
            ],
            [
                52,
                1,
            ],
            [
                0,
                35,
            ],
            [
                52,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1003,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1003,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1003,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                5692,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5692,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5692,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Europe': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2283,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2283,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2283,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                78,
                1,
            ],
            [
                0,
                12,
            ],
            [
                78,
                1,
            ],
            [
                0,
                35,
            ],
            [
                78,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                41,
                1,
            ],
            [
                0,
                12,
            ],
            [
                41,
                1,
            ],
            [
                0,
                35,
            ],
            [
                41,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                151,
                1,
            ],
            [
                0,
                12,
            ],
            [
                151,
                1,
            ],
            [
                0,
                35,
            ],
            [
                151,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Continent': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                49,
                1,
            ],
            [
                0,
                12,
            ],
            [
                49,
                1,
            ],
            [
                0,
                35,
            ],
            [
                49,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                951,
                1,
            ],
            [
                0,
                12,
            ],
            [
                951,
                1,
            ],
            [
                0,
                35,
            ],
            [
                951,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                977,
                1,
            ],
            [
                0,
                12,
            ],
            [
                977,
                1,
            ],
            [
                0,
                35,
            ],
            [
                977,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'North America': {
        'overall': [
            [
                162079,
                1,
            ],
            [
                162058,
                1,
            ],
            [
                162070,
                1,
            ],
            [
                163253,
                26,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                171060,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163576,
                3,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163576,
                3,
            ],
            [
                171060,
                1,
            ],
            [
                163598,
                6,
            ],
            [
                6698,
                5,
            ],
            [
                163519,
                4,
            ],
            [
                6372,
                1,
            ],
            [
                163519,
                1,
            ],
            [
                6372,
                1,
            ],
            [
                163519,
                1,
            ],
            [
                6372,
                1,
            ],
            [
                163488,
                3,
            ],
            [
                163559,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163559,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163559,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163559,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163559,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163559,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                1310,
                5,
            ],
            [
                171060,
                2,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163205,
                6,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163237,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163237,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163237,
                1,
            ],
            [
                163596,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163547,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163547,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                163547,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163512,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                163562,
                3,
            ],
            [
                163205,
                1,
            ],
            [
                163576,
                3,
            ],
            [
                163253,
                4,
            ],
            [
                5054,
                1,
            ],
            [
                163530,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163530,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163530,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                163598,
                12,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163512,
                25,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                4,
            ],
            [
                6698,
                1,
            ],
            [
                163598,
                2,
            ],
            [
                163552,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                138677,
                3,
            ],
            [
                155929,
                3,
            ],
            [
                162027,
                3,
            ],
            [
                163205,
                1,
            ],
            [
                163598,
                3,
            ],
            [
                6698,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                157491,
                1,
            ],
            [
                159680,
                1,
            ],
            [
                162429,
                1,
            ],
            [
                157118,
                1,
            ],
            [
                159238,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                163559,
                5,
            ],
            [
                163598,
                1,
            ],
            [
                162136,
                1,
            ],
            [
                163598,
                1,
            ],
            [
                162136,
                1,
            ],
            [
                163508,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                163508,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                163508,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                163508,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                163514,
                5,
            ],
            [
                163497,
                1,
            ],
            [
                162375,
                1,
            ],
            [
                163053,
                1,
            ],
            [
                163237,
                3,
            ],
            [
                163598,
                1,
            ],
            [
                162465,
                1,
            ],
            [
                163253,
                1,
            ],
            [
                163598,
                5,
            ],
            [
                163423,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                2,
                54,
            ],
            [
                7,
                1,
            ],
            [
                2,
                12,
            ],
            [
                7,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                27,
                54,
            ],
            [
                60,
                1,
            ],
            [
                27,
                12,
            ],
            [
                60,
                1,
            ],
            [
                27,
                6,
            ],
            [
                1,
                5,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                60,
                7,
            ],
            [
                27,
                18,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                2,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                11,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                46,
            ],
            [
                1,
                1,
            ],
            [
                27,
                17,
            ],
            [
                1,
                1,
            ],
            [
                27,
                21,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                13,
                54,
            ],
            [
                31,
                1,
            ],
            [
                13,
                12,
            ],
            [
                31,
                1,
            ],
            [
                13,
                6,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                7,
            ],
            [
                13,
                18,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                4,
                54,
            ],
            [
                13,
                1,
            ],
            [
                4,
                12,
            ],
            [
                13,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                61,
                54,
            ],
            [
                123,
                1,
            ],
            [
                61,
                12,
            ],
            [
                123,
                1,
            ],
            [
                61,
                6,
            ],
            [
                3,
                5,
            ],
            [
                61,
                4,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                4,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                123,
                7,
            ],
            [
                61,
                18,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                4,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                2,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                11,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                46,
            ],
            [
                3,
                1,
            ],
            [
                61,
                17,
            ],
            [
                3,
                1,
            ],
            [
                61,
                21,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                1,
            ],
            [
                3,
                1,
            ],
            [
                61,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                155,
                1,
            ],
            [
                0,
                12,
            ],
            [
                155,
                1,
            ],
            [
                0,
                6,
            ],
            [
                155,
                5,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                5,
            ],
            [
                155,
                2,
            ],
            [
                0,
                18,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                2,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                11,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                46,
            ],
            [
                155,
                1,
            ],
            [
                0,
                17,
            ],
            [
                155,
                1,
            ],
            [
                0,
                21,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                293,
                1,
            ],
            [
                0,
                12,
            ],
            [
                293,
                1,
            ],
            [
                0,
                6,
            ],
            [
                293,
                5,
            ],
            [
                0,
                4,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                4,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                5,
            ],
            [
                293,
                2,
            ],
            [
                0,
                18,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                4,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                2,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                11,
            ],
            [
                282,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                46,
            ],
            [
                293,
                1,
            ],
            [
                0,
                17,
            ],
            [
                293,
                1,
            ],
            [
                0,
                21,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4823,
                5,
            ],
            [
                0,
                4,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                5,
            ],
            [
                4823,
                2,
            ],
            [
                0,
                18,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                4,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                2,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                11,
            ],
            [
                3233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                46,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                17,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                21,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1025,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1025,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                11,
            ],
            [
                990,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                338,
                1,
            ],
            [
                0,
                12,
            ],
            [
                338,
                1,
            ],
            [
                0,
                6,
            ],
            [
                338,
                5,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                5,
            ],
            [
                338,
                2,
            ],
            [
                0,
                18,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                2,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                11,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                46,
            ],
            [
                338,
                1,
            ],
            [
                0,
                17,
            ],
            [
                338,
                1,
            ],
            [
                0,
                21,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CCD': [
            [
                35336,
                1,
            ],
            [
                35327,
                1,
            ],
            [
                35329,
                1,
            ],
            [
                36232,
                26,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                2,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36250,
                3,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36250,
                3,
            ],
            [
                36257,
                7,
            ],
            [
                0,
                5,
            ],
            [
                36231,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36226,
                3,
            ],
            [
                36247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36247,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36257,
                3,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36197,
                6,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36199,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36199,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36199,
                1,
            ],
            [
                36256,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36236,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36249,
                3,
            ],
            [
                36197,
                1,
            ],
            [
                36250,
                3,
            ],
            [
                36232,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36257,
                12,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                26,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36257,
                2,
            ],
            [
                36250,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                28765,
                3,
            ],
            [
                34409,
                3,
            ],
            [
                35946,
                3,
            ],
            [
                36197,
                1,
            ],
            [
                36257,
                3,
            ],
            [
                0,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                33723,
                1,
            ],
            [
                34286,
                1,
            ],
            [
                35485,
                1,
            ],
            [
                33499,
                1,
            ],
            [
                34014,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                36247,
                5,
            ],
            [
                36257,
                1,
            ],
            [
                35361,
                1,
            ],
            [
                36257,
                1,
            ],
            [
                35361,
                1,
            ],
            [
                36231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36232,
                5,
            ],
            [
                36256,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36199,
                3,
            ],
            [
                36257,
                1,
            ],
            [
                36226,
                1,
            ],
            [
                36232,
                1,
            ],
            [
                36257,
                5,
            ],
            [
                36252,
                6,
            ],
        ],
        'CSA': [
            [
                171,
                3,
            ],
            [
                174,
                71,
            ],
            [
                0,
                5,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                6,
            ],
            [
                174,
                20,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                2,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                11,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                46,
            ],
            [
                0,
                1,
            ],
            [
                174,
                17,
            ],
            [
                0,
                1,
            ],
            [
                174,
                17,
            ],
            [
                171,
                1,
            ],
            [
                174,
                1,
            ],
            [
                171,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                25,
            ],
        ],
        'City': [
            [
                31665,
                1,
            ],
            [
                31659,
                1,
            ],
            [
                31664,
                1,
            ],
            [
                31839,
                26,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                2,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                4,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                10,
            ],
            [
                0,
                5,
            ],
            [
                31966,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31966,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31966,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31961,
                3,
            ],
            [
                31969,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                0,
                6,
            ],
            [
                31969,
                3,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31840,
                6,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31846,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31846,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31846,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31967,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31967,
                3,
            ],
            [
                31840,
                1,
            ],
            [
                31969,
                3,
            ],
            [
                31839,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31969,
                12,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31965,
                25,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31969,
                2,
            ],
            [
                31965,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                23909,
                3,
            ],
            [
                29427,
                3,
            ],
            [
                31419,
                3,
            ],
            [
                31840,
                1,
            ],
            [
                31969,
                3,
            ],
            [
                0,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                29996,
                1,
            ],
            [
                30698,
                1,
            ],
            [
                31715,
                1,
            ],
            [
                29905,
                1,
            ],
            [
                30602,
                1,
            ],
            [
                31969,
                7,
            ],
            [
                31677,
                1,
            ],
            [
                31969,
                1,
            ],
            [
                31677,
                1,
            ],
            [
                31963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31965,
                5,
            ],
            [
                31928,
                1,
            ],
            [
                31606,
                1,
            ],
            [
                31802,
                1,
            ],
            [
                31846,
                3,
            ],
            [
                31969,
                1,
            ],
            [
                31613,
                1,
            ],
            [
                31839,
                1,
            ],
            [
                31969,
                5,
            ],
            [
                31919,
                6,
            ],
        ],
        'Congressional District': [
            [
                433,
                74,
            ],
            [
                0,
                5,
            ],
            [
                433,
                4,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                4,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                6,
            ],
            [
                433,
                20,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                4,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                2,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                11,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                46,
            ],
            [
                0,
                1,
            ],
            [
                433,
                17,
            ],
            [
                0,
                1,
            ],
            [
                433,
                21,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                433,
                25,
            ],
        ],
        'Continent': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                1,
                54,
            ],
            [
                29,
                1,
            ],
            [
                1,
                12,
            ],
            [
                29,
                1,
            ],
            [
                1,
                35,
            ],
            [
                29,
                7,
            ],
            [
                1,
                209,
            ],
        ],
        'County': [
            [
                3139,
                3,
            ],
            [
                3217,
                71,
            ],
            [
                0,
                5,
            ],
            [
                3217,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3217,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                4,
            ],
            [
                3175,
                3,
            ],
            [
                3210,
                3,
            ],
            [
                3217,
                7,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                17,
            ],
            [
                3139,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                3139,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3217,
                25,
            ],
        ],
        'County Cross CD': [
            [
                3715,
                3,
            ],
            [
                3714,
                26,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                4,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                4,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                4,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                4,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                4,
            ],
            [
                3715,
                2,
            ],
            [
                3714,
                2,
            ],
            [
                3715,
                4,
            ],
            [
                3714,
                2,
            ],
            [
                3715,
                10,
            ],
            [
                0,
                5,
            ],
            [
                3715,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3715,
                3,
            ],
            [
                3714,
                8,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                4,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                2,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3714,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3714,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3714,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                3,
            ],
            [
                3714,
                1,
            ],
            [
                3715,
                3,
            ],
            [
                3714,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                12,
            ],
            [
                3714,
                2,
            ],
            [
                3715,
                26,
            ],
            [
                3714,
                2,
            ],
            [
                3715,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                4,
            ],
            [
                3657,
                3,
            ],
            [
                3702,
                3,
            ],
            [
                3714,
                4,
            ],
            [
                3715,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3714,
                2,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                3,
            ],
            [
                3713,
                3,
            ],
            [
                3712,
                1,
            ],
            [
                3715,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3715,
                6,
            ],
            [
                3714,
                5,
            ],
            [
                3715,
                1,
            ],
            [
                3714,
                2,
            ],
            [
                3715,
                11,
            ],
        ],
        'Historical Congressional District': [
            [
                9353,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9353,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9353,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9353,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                305,
                74,
            ],
            [
                0,
                5,
            ],
            [
                305,
                4,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                4,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                6,
            ],
            [
                305,
                20,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                4,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                2,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                11,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                46,
            ],
            [
                0,
                1,
            ],
            [
                305,
                17,
            ],
            [
                0,
                1,
            ],
            [
                305,
                21,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                3427,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3427,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                4,
            ],
            [
                3418,
                3,
            ],
            [
                3427,
                10,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3427,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Judicial District': [
            [
                89,
                3,
            ],
            [
                90,
                71,
            ],
            [
                0,
                5,
            ],
            [
                90,
                4,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                4,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                6,
            ],
            [
                90,
                20,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                4,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                2,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                11,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                46,
            ],
            [
                0,
                1,
            ],
            [
                90,
                17,
            ],
            [
                0,
                1,
            ],
            [
                90,
                17,
            ],
            [
                89,
                1,
            ],
            [
                90,
                1,
            ],
            [
                89,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                25,
            ],
        ],
        'MSA': [
            [
                929,
                3,
            ],
            [
                941,
                71,
            ],
            [
                0,
                5,
            ],
            [
                941,
                4,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                4,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                6,
            ],
            [
                941,
                20,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                4,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                2,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                11,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                46,
            ],
            [
                0,
                1,
            ],
            [
                941,
                17,
            ],
            [
                0,
                1,
            ],
            [
                941,
                17,
            ],
            [
                929,
                1,
            ],
            [
                941,
                1,
            ],
            [
                929,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                1,
            ],
            [
                0,
                1,
            ],
            [
                941,
                25,
            ],
        ],
        'Media Market': [
            [
                209,
                74,
            ],
            [
                0,
                5,
            ],
            [
                209,
                4,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                4,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                6,
            ],
            [
                209,
                20,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                4,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                2,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                11,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                46,
            ],
            [
                0,
                1,
            ],
            [
                209,
                17,
            ],
            [
                0,
                1,
            ],
            [
                209,
                21,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                25,
            ],
        ],
        'Native Area': [
            [
                311,
                2,
            ],
            [
                312,
                1,
            ],
            [
                308,
                26,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                2,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                4,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                10,
            ],
            [
                0,
                5,
            ],
            [
                311,
                4,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                309,
                3,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                6,
            ],
            [
                313,
                3,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                310,
                6,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                2,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                3,
            ],
            [
                310,
                1,
            ],
            [
                313,
                3,
            ],
            [
                308,
                4,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                12,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                312,
                25,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                4,
            ],
            [
                0,
                1,
            ],
            [
                313,
                4,
            ],
            [
                239,
                3,
            ],
            [
                294,
                3,
            ],
            [
                305,
                3,
            ],
            [
                310,
                1,
            ],
            [
                313,
                3,
            ],
            [
                0,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                297,
                1,
            ],
            [
                303,
                1,
            ],
            [
                309,
                1,
            ],
            [
                296,
                1,
            ],
            [
                301,
                1,
            ],
            [
                313,
                10,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                5,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                310,
                3,
            ],
            [
                313,
                1,
            ],
            [
                304,
                1,
            ],
            [
                308,
                1,
            ],
            [
                313,
                11,
            ],
        ],
        'Native Statistical Area': [
            [
                288,
                1,
            ],
            [
                285,
                2,
            ],
            [
                278,
                26,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                2,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                4,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                10,
            ],
            [
                0,
                5,
            ],
            [
                286,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                3,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                6,
            ],
            [
                288,
                3,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                281,
                6,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                2,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                3,
            ],
            [
                281,
                1,
            ],
            [
                288,
                3,
            ],
            [
                278,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                12,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                286,
                25,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                4,
            ],
            [
                0,
                1,
            ],
            [
                288,
                4,
            ],
            [
                265,
                3,
            ],
            [
                272,
                3,
            ],
            [
                281,
                4,
            ],
            [
                288,
                3,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                269,
                1,
            ],
            [
                272,
                1,
            ],
            [
                278,
                1,
            ],
            [
                267,
                1,
            ],
            [
                271,
                1,
            ],
            [
                288,
                10,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                5,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                281,
                3,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                11,
            ],
        ],
        'Native Subdivision': [
            [
                459,
                3,
            ],
            [
                455,
                26,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                2,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                4,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                10,
            ],
            [
                0,
                5,
            ],
            [
                457,
                4,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                3,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                6,
            ],
            [
                459,
                3,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                450,
                6,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                2,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                3,
            ],
            [
                450,
                1,
            ],
            [
                459,
                3,
            ],
            [
                455,
                4,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                12,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                457,
                25,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                4,
            ],
            [
                0,
                1,
            ],
            [
                459,
                4,
            ],
            [
                305,
                3,
            ],
            [
                415,
                3,
            ],
            [
                444,
                3,
            ],
            [
                450,
                1,
            ],
            [
                459,
                3,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                433,
                1,
            ],
            [
                435,
                1,
            ],
            [
                447,
                1,
            ],
            [
                429,
                1,
            ],
            [
                433,
                1,
            ],
            [
                459,
                10,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                5,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                450,
                3,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                11,
            ],
        ],
        'Neighborhood': [
            [
                16394,
                1,
            ],
            [
                16393,
                1,
            ],
            [
                16396,
                1,
            ],
            [
                16303,
                26,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                2,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16409,
                3,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16409,
                3,
            ],
            [
                16421,
                7,
            ],
            [
                0,
                5,
            ],
            [
                16396,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16396,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16396,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16388,
                3,
            ],
            [
                16404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16404,
                1,
            ],
            [
                0,
                6,
            ],
            [
                16421,
                3,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16367,
                6,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16371,
                1,
            ],
            [
                16420,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16403,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16403,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16403,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16405,
                3,
            ],
            [
                16367,
                1,
            ],
            [
                16409,
                3,
            ],
            [
                16303,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16398,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16398,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16398,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16421,
                12,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16391,
                25,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16421,
                2,
            ],
            [
                16405,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                13093,
                3,
            ],
            [
                14712,
                3,
            ],
            [
                16101,
                3,
            ],
            [
                16367,
                1,
            ],
            [
                16421,
                3,
            ],
            [
                0,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                15691,
                1,
            ],
            [
                16242,
                1,
            ],
            [
                16407,
                1,
            ],
            [
                15686,
                1,
            ],
            [
                16234,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                16404,
                5,
            ],
            [
                16421,
                1,
            ],
            [
                16403,
                1,
            ],
            [
                16421,
                1,
            ],
            [
                16403,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16391,
                5,
            ],
            [
                16405,
                1,
            ],
            [
                15832,
                1,
            ],
            [
                16283,
                1,
            ],
            [
                16371,
                3,
            ],
            [
                16421,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16303,
                1,
            ],
            [
                16421,
                5,
            ],
            [
                16384,
                6,
            ],
        ],
        'School District': [
            [
                13322,
                1,
            ],
            [
                13321,
                2,
            ],
            [
                13325,
                26,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                4,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                4,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                4,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                4,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                4,
            ],
            [
                13330,
                2,
            ],
            [
                13325,
                2,
            ],
            [
                13330,
                4,
            ],
            [
                13325,
                2,
            ],
            [
                13330,
                10,
            ],
            [
                0,
                5,
            ],
            [
                13327,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13327,
                3,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13330,
                3,
            ],
            [
                13325,
                2,
            ],
            [
                13321,
                6,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                4,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                2,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13321,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                3,
            ],
            [
                13321,
                1,
            ],
            [
                13330,
                3,
            ],
            [
                13325,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                12,
            ],
            [
                13325,
                2,
            ],
            [
                13328,
                25,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                2,
            ],
            [
                13330,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                4,
            ],
            [
                12743,
                3,
            ],
            [
                13227,
                3,
            ],
            [
                13309,
                3,
            ],
            [
                13321,
                1,
            ],
            [
                13330,
                3,
            ],
            [
                0,
                1,
            ],
            [
                13325,
                2,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                2,
            ],
            [
                13271,
                1,
            ],
            [
                13289,
                1,
            ],
            [
                13317,
                1,
            ],
            [
                13268,
                1,
            ],
            [
                13285,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13329,
                5,
            ],
            [
                13330,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                5,
            ],
            [
                13327,
                1,
            ],
            [
                13325,
                2,
            ],
            [
                13321,
                3,
            ],
            [
                13330,
                1,
            ],
            [
                13325,
                2,
            ],
            [
                13330,
                5,
            ],
            [
                13327,
                6,
            ],
        ],
        'State House District': [
            [
                4783,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4783,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4783,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                4,
            ],
            [
                4774,
                3,
            ],
            [
                4781,
                3,
            ],
            [
                4783,
                7,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4783,
                25,
            ],
        ],
        'State Senate District': [
            [
                1917,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1917,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1917,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1917,
                25,
            ],
        ],
        'Subnational Region': [
            [
                50,
                3,
            ],
            [
                51,
                51,
            ],
            [
                349,
                1,
            ],
            [
                51,
                12,
            ],
            [
                349,
                1,
            ],
            [
                51,
                6,
            ],
            [
                13,
                5,
            ],
            [
                51,
                4,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                4,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                349,
                7,
            ],
            [
                51,
                18,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                4,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                2,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                11,
            ],
            [
                10,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                46,
            ],
            [
                13,
                1,
            ],
            [
                51,
                17,
            ],
            [
                13,
                1,
            ],
            [
                51,
                17,
            ],
            [
                50,
                1,
            ],
            [
                51,
                1,
            ],
            [
                50,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                1,
            ],
            [
                13,
                1,
            ],
            [
                51,
                25,
            ],
        ],
        'USDA County Type': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Urban Area': [
            [
                2597,
                3,
            ],
            [
                2623,
                27,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                3,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                5,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                11,
            ],
            [
                0,
                5,
            ],
            [
                2623,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2623,
                3,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                8,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                12,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                27,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                5,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                4,
            ],
            [
                2579,
                3,
            ],
            [
                2616,
                3,
            ],
            [
                2623,
                7,
            ],
            [
                0,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                2,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                8,
            ],
            [
                2597,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                2597,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2623,
                6,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                5,
            ],
            [
                2621,
                1,
            ],
            [
                2623,
                12,
            ],
        ],
        'Urban Center': [
            [
                320,
                3,
            ],
            [
                323,
                51,
            ],
            [
                691,
                1,
            ],
            [
                323,
                12,
            ],
            [
                691,
                1,
            ],
            [
                323,
                6,
            ],
            [
                46,
                5,
            ],
            [
                323,
                4,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                4,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                691,
                7,
            ],
            [
                323,
                18,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                4,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                2,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                11,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                46,
            ],
            [
                46,
                1,
            ],
            [
                323,
                4,
            ],
            [
                322,
                3,
            ],
            [
                323,
                10,
            ],
            [
                46,
                1,
            ],
            [
                323,
                17,
            ],
            [
                320,
                1,
            ],
            [
                323,
                1,
            ],
            [
                320,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                1,
            ],
            [
                46,
                1,
            ],
            [
                323,
                25,
            ],
        ],
        'ZIP': [
            [
                32741,
                1,
            ],
            [
                32740,
                1,
            ],
            [
                32741,
                1,
            ],
            [
                32827,
                26,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                2,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32871,
                3,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32871,
                3,
            ],
            [
                32874,
                7,
            ],
            [
                0,
                5,
            ],
            [
                32858,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32858,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32858,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32847,
                3,
            ],
            [
                32863,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32863,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32863,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32863,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32863,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32863,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32874,
                3,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32753,
                6,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32773,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32773,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32773,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32870,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32870,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32870,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32854,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32864,
                3,
            ],
            [
                32753,
                1,
            ],
            [
                32871,
                3,
            ],
            [
                32827,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32874,
                12,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32854,
                25,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32874,
                2,
            ],
            [
                32855,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                27834,
                3,
            ],
            [
                31515,
                3,
            ],
            [
                32536,
                3,
            ],
            [
                32753,
                1,
            ],
            [
                32874,
                3,
            ],
            [
                0,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32127,
                1,
            ],
            [
                32470,
                1,
            ],
            [
                32786,
                1,
            ],
            [
                32085,
                1,
            ],
            [
                32414,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32863,
                5,
            ],
            [
                32874,
                1,
            ],
            [
                32743,
                1,
            ],
            [
                32874,
                1,
            ],
            [
                32743,
                1,
            ],
            [
                32853,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32853,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32853,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32853,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32856,
                5,
            ],
            [
                32834,
                1,
            ],
            [
                32670,
                1,
            ],
            [
                32684,
                1,
            ],
            [
                32773,
                3,
            ],
            [
                32874,
                1,
            ],
            [
                32734,
                1,
            ],
            [
                32827,
                1,
            ],
            [
                32874,
                5,
            ],
            [
                32794,
                6,
            ],
        ],
    },
    'Oceania': {
        'overall': [
            [
                514,
                3,
            ],
            [
                502,
                26,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                758,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                513,
                3,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                513,
                3,
            ],
            [
                758,
                1,
            ],
            [
                514,
                6,
            ],
            [
                0,
                5,
            ],
            [
                511,
                4,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                3,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                246,
                5,
            ],
            [
                758,
                2,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                7,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                0,
                1,
            ],
            [
                503,
                1,
            ],
            [
                0,
                1,
            ],
            [
                503,
                1,
            ],
            [
                0,
                1,
            ],
            [
                503,
                1,
            ],
            [
                513,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                2,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                3,
            ],
            [
                502,
                1,
            ],
            [
                513,
                3,
            ],
            [
                502,
                4,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                514,
                12,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                511,
                25,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                4,
            ],
            [
                0,
                1,
            ],
            [
                514,
                2,
            ],
            [
                513,
                1,
            ],
            [
                514,
                1,
            ],
            [
                457,
                3,
            ],
            [
                489,
                3,
            ],
            [
                500,
                3,
            ],
            [
                502,
                1,
            ],
            [
                514,
                3,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                489,
                1,
            ],
            [
                500,
                1,
            ],
            [
                511,
                1,
            ],
            [
                488,
                1,
            ],
            [
                498,
                1,
            ],
            [
                514,
                1,
            ],
            [
                513,
                5,
            ],
            [
                514,
                4,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                5,
            ],
            [
                509,
                1,
            ],
            [
                489,
                1,
            ],
            [
                496,
                1,
            ],
            [
                503,
                3,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                5,
            ],
            [
                507,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'CCD': [
            [
                44,
                58,
            ],
            [
                43,
                3,
            ],
            [
                44,
                3,
            ],
            [
                43,
                3,
            ],
            [
                44,
                7,
            ],
            [
                0,
                5,
            ],
            [
                43,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                6,
            ],
            [
                44,
                5,
            ],
            [
                43,
                6,
            ],
            [
                44,
                9,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                44,
                3,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                2,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                7,
            ],
            [
                44,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                14,
            ],
            [
                43,
                25,
            ],
            [
                44,
                7,
            ],
            [
                0,
                1,
            ],
            [
                44,
                2,
            ],
            [
                43,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                3,
            ],
            [
                43,
                7,
            ],
            [
                44,
                3,
            ],
            [
                0,
                1,
            ],
            [
                44,
                6,
            ],
            [
                43,
                1,
            ],
            [
                44,
                2,
            ],
            [
                43,
                1,
            ],
            [
                44,
                1,
            ],
            [
                43,
                5,
            ],
            [
                44,
                4,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                5,
            ],
            [
                44,
                3,
            ],
            [
                43,
                3,
            ],
            [
                44,
                8,
            ],
            [
                43,
                6,
            ],
        ],
        'City': [
            [
                163,
                30,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                3,
            ],
            [
                159,
                1,
            ],
            [
                163,
                5,
            ],
            [
                159,
                1,
            ],
            [
                163,
                11,
            ],
            [
                0,
                5,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                6,
            ],
            [
                163,
                3,
            ],
            [
                159,
                1,
            ],
            [
                163,
                8,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                2,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                11,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                12,
            ],
            [
                159,
                1,
            ],
            [
                163,
                27,
            ],
            [
                159,
                1,
            ],
            [
                163,
                5,
            ],
            [
                0,
                1,
            ],
            [
                163,
                4,
            ],
            [
                146,
                3,
            ],
            [
                156,
                3,
            ],
            [
                162,
                3,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                12,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                6,
            ],
            [
                159,
                1,
            ],
            [
                163,
                5,
            ],
            [
                159,
                1,
            ],
            [
                163,
                12,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Continent': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'County': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'County Cross CD': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Media Market': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Area': [
            [
                60,
                3,
            ],
            [
                49,
                26,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                2,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                4,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                10,
            ],
            [
                0,
                5,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                3,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                6,
            ],
            [
                60,
                3,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                52,
                6,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                59,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                2,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                3,
            ],
            [
                52,
                1,
            ],
            [
                60,
                3,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                12,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                58,
                25,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                4,
            ],
            [
                31,
                3,
            ],
            [
                46,
                3,
            ],
            [
                51,
                3,
            ],
            [
                52,
                1,
            ],
            [
                60,
                3,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                41,
                1,
            ],
            [
                48,
                1,
            ],
            [
                57,
                1,
            ],
            [
                40,
                1,
            ],
            [
                46,
                1,
            ],
            [
                60,
                10,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                5,
            ],
            [
                57,
                1,
            ],
            [
                42,
                1,
            ],
            [
                46,
                1,
            ],
            [
                52,
                3,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                5,
            ],
            [
                57,
                6,
            ],
        ],
        'Neighborhood': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                18,
                3,
            ],
            [
                19,
                10,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'School District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'State House District': [
            [
                51,
                74,
            ],
            [
                0,
                5,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                6,
            ],
            [
                51,
                20,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                2,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                11,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                46,
            ],
            [
                0,
                1,
            ],
            [
                51,
                17,
            ],
            [
                0,
                1,
            ],
            [
                51,
                21,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                25,
            ],
        ],
        'State Senate District': [
            [
                25,
                74,
            ],
            [
                0,
                5,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                6,
            ],
            [
                25,
                20,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                2,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                11,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                46,
            ],
            [
                0,
                1,
            ],
            [
                25,
                17,
            ],
            [
                0,
                1,
            ],
            [
                25,
                21,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                54,
            ],
            [
                142,
                1,
            ],
            [
                1,
                12,
            ],
            [
                142,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                142,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                54,
            ],
            [
                61,
                1,
            ],
            [
                1,
                12,
            ],
            [
                61,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                93,
                3,
            ],
            [
                92,
                26,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                2,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                4,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                10,
            ],
            [
                0,
                5,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                6,
            ],
            [
                93,
                3,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                90,
                6,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                2,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                3,
            ],
            [
                90,
                1,
            ],
            [
                93,
                3,
            ],
            [
                92,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                12,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                26,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                85,
                3,
            ],
            [
                90,
                7,
            ],
            [
                93,
                3,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                11,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                5,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                89,
                1,
            ],
            [
                91,
                3,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                5,
            ],
            [
                90,
                6,
            ],
        ],
    },
    'South America': {
        'overall': [
            [
                0,
                54,
            ],
            [
                1182,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1182,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1182,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                45,
                1,
            ],
            [
                0,
                12,
            ],
            [
                45,
                1,
            ],
            [
                0,
                35,
            ],
            [
                45,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                35,
            ],
            [
                23,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                92,
                1,
            ],
            [
                0,
                12,
            ],
            [
                92,
                1,
            ],
            [
                0,
                35,
            ],
            [
                92,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Continent': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                261,
                1,
            ],
            [
                0,
                12,
            ],
            [
                261,
                1,
            ],
            [
                0,
                35,
            ],
            [
                261,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                725,
                1,
            ],
            [
                0,
                12,
            ],
            [
                725,
                1,
            ],
            [
                0,
                35,
            ],
            [
                725,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Andorra': {
        'overall': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'United Arab Emirates': {
        'overall': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Afghanistan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                100,
                1,
            ],
            [
                0,
                12,
            ],
            [
                100,
                1,
            ],
            [
                0,
                35,
            ],
            [
                100,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                29,
                1,
            ],
            [
                0,
                12,
            ],
            [
                29,
                1,
            ],
            [
                0,
                35,
            ],
            [
                29,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Antigua and Barbuda': {
        'overall': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Anguilla': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Albania': {
        'overall': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Armenia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Angola': {
        'overall': [
            [
                0,
                54,
            ],
            [
                90,
                1,
            ],
            [
                0,
                12,
            ],
            [
                90,
                1,
            ],
            [
                0,
                35,
            ],
            [
                90,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                46,
                1,
            ],
            [
                0,
                12,
            ],
            [
                46,
                1,
            ],
            [
                0,
                35,
            ],
            [
                46,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Argentina': {
        'overall': [
            [
                0,
                54,
            ],
            [
                126,
                1,
            ],
            [
                0,
                12,
            ],
            [
                126,
                1,
            ],
            [
                0,
                35,
            ],
            [
                126,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                72,
                1,
            ],
            [
                0,
                12,
            ],
            [
                72,
                1,
            ],
            [
                0,
                35,
            ],
            [
                72,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Austria': {
        'overall': [
            [
                0,
                54,
            ],
            [
                32,
                1,
            ],
            [
                0,
                12,
            ],
            [
                32,
                1,
            ],
            [
                0,
                35,
            ],
            [
                32,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Australia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                50,
                1,
            ],
            [
                0,
                12,
            ],
            [
                50,
                1,
            ],
            [
                0,
                35,
            ],
            [
                50,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Aruba': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Azerbaijan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                32,
                1,
            ],
            [
                0,
                12,
            ],
            [
                32,
                1,
            ],
            [
                0,
                35,
            ],
            [
                32,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bosnia and Herzegovina': {
        'overall': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Barbados': {
        'overall': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bangladesh': {
        'overall': [
            [
                0,
                54,
            ],
            [
                183,
                1,
            ],
            [
                0,
                12,
            ],
            [
                183,
                1,
            ],
            [
                0,
                35,
            ],
            [
                183,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                83,
                1,
            ],
            [
                0,
                12,
            ],
            [
                83,
                1,
            ],
            [
                0,
                35,
            ],
            [
                83,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Belgium': {
        'overall': [
            [
                0,
                54,
            ],
            [
                29,
                1,
            ],
            [
                0,
                12,
            ],
            [
                29,
                1,
            ],
            [
                0,
                35,
            ],
            [
                29,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Burkina Faso': {
        'overall': [
            [
                0,
                54,
            ],
            [
                65,
                1,
            ],
            [
                0,
                12,
            ],
            [
                65,
                1,
            ],
            [
                0,
                35,
            ],
            [
                65,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                32,
                1,
            ],
            [
                0,
                12,
            ],
            [
                32,
                1,
            ],
            [
                0,
                35,
            ],
            [
                32,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bulgaria': {
        'overall': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                35,
            ],
            [
                28,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bahrain': {
        'overall': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Burundi': {
        'overall': [
            [
                0,
                54,
            ],
            [
                45,
                1,
            ],
            [
                0,
                12,
            ],
            [
                45,
                1,
            ],
            [
                0,
                35,
            ],
            [
                45,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Benin': {
        'overall': [
            [
                0,
                54,
            ],
            [
                55,
                1,
            ],
            [
                0,
                12,
            ],
            [
                55,
                1,
            ],
            [
                0,
                35,
            ],
            [
                55,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bermuda': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Brunei': {
        'overall': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bolivia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                38,
                1,
            ],
            [
                0,
                12,
            ],
            [
                38,
                1,
            ],
            [
                0,
                35,
            ],
            [
                38,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Brazil': {
        'overall': [
            [
                0,
                54,
            ],
            [
                482,
                1,
            ],
            [
                0,
                12,
            ],
            [
                482,
                1,
            ],
            [
                0,
                35,
            ],
            [
                482,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                48,
                1,
            ],
            [
                0,
                12,
            ],
            [
                48,
                1,
            ],
            [
                0,
                35,
            ],
            [
                48,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                352,
                1,
            ],
            [
                0,
                12,
            ],
            [
                352,
                1,
            ],
            [
                0,
                35,
            ],
            [
                352,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'The Bahamas': {
        'overall': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                32,
                1,
            ],
            [
                0,
                12,
            ],
            [
                32,
                1,
            ],
            [
                0,
                35,
            ],
            [
                32,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bhutan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                35,
            ],
            [
                31,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Bouvet Island': {},
    'Botswana': {
        'overall': [
            [
                0,
                54,
            ],
            [
                30,
                1,
            ],
            [
                0,
                12,
            ],
            [
                30,
                1,
            ],
            [
                0,
                35,
            ],
            [
                30,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Belarus': {
        'overall': [
            [
                0,
                54,
            ],
            [
                36,
                1,
            ],
            [
                0,
                12,
            ],
            [
                36,
                1,
            ],
            [
                0,
                35,
            ],
            [
                36,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Belize': {
        'overall': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Canada': {
        'overall': [
            [
                2,
                54,
            ],
            [
                6737,
                1,
            ],
            [
                2,
                12,
            ],
            [
                6737,
                1,
            ],
            [
                2,
                6,
            ],
            [
                6698,
                5,
            ],
            [
                2,
                4,
            ],
            [
                6372,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6372,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6372,
                1,
            ],
            [
                2,
                4,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                103,
                5,
            ],
            [
                6737,
                2,
            ],
            [
                2,
                18,
            ],
            [
                5517,
                1,
            ],
            [
                2,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                2,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                2,
                4,
            ],
            [
                5517,
                1,
            ],
            [
                2,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                2,
                1,
            ],
            [
                5517,
                1,
            ],
            [
                2,
                2,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6306,
                1,
            ],
            [
                2,
                11,
            ],
            [
                5054,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6419,
                1,
            ],
            [
                2,
                46,
            ],
            [
                6698,
                1,
            ],
            [
                2,
                17,
            ],
            [
                6698,
                1,
            ],
            [
                2,
                21,
            ],
            [
                6331,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                2,
                1,
            ],
            [
                6331,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                54,
            ],
            [
                3,
                1,
            ],
            [
                1,
                12,
            ],
            [
                3,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                9,
                7,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                15,
                1,
            ],
            [
                1,
                12,
            ],
            [
                15,
                1,
            ],
            [
                1,
                6,
            ],
            [
                3,
                5,
            ],
            [
                1,
                4,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                4,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                15,
                7,
            ],
            [
                1,
                18,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                4,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                2,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                11,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                46,
            ],
            [
                3,
                1,
            ],
            [
                1,
                17,
            ],
            [
                3,
                1,
            ],
            [
                1,
                21,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                155,
                1,
            ],
            [
                0,
                12,
            ],
            [
                155,
                1,
            ],
            [
                0,
                6,
            ],
            [
                155,
                5,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                5,
            ],
            [
                155,
                2,
            ],
            [
                0,
                18,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                4,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                2,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                11,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                46,
            ],
            [
                155,
                1,
            ],
            [
                0,
                17,
            ],
            [
                155,
                1,
            ],
            [
                0,
                21,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                293,
                1,
            ],
            [
                0,
                12,
            ],
            [
                293,
                1,
            ],
            [
                0,
                6,
            ],
            [
                293,
                5,
            ],
            [
                0,
                4,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                4,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                5,
            ],
            [
                293,
                2,
            ],
            [
                0,
                18,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                4,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                2,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                11,
            ],
            [
                282,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                46,
            ],
            [
                293,
                1,
            ],
            [
                0,
                17,
            ],
            [
                293,
                1,
            ],
            [
                0,
                21,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4823,
                5,
            ],
            [
                0,
                4,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4497,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                5,
            ],
            [
                4823,
                2,
            ],
            [
                0,
                18,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                4,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3643,
                1,
            ],
            [
                0,
                2,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4431,
                1,
            ],
            [
                0,
                11,
            ],
            [
                3233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4544,
                1,
            ],
            [
                0,
                46,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                17,
            ],
            [
                4823,
                1,
            ],
            [
                0,
                21,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4456,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1025,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1025,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                11,
            ],
            [
                990,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                338,
                1,
            ],
            [
                0,
                12,
            ],
            [
                338,
                1,
            ],
            [
                0,
                6,
            ],
            [
                338,
                5,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                5,
            ],
            [
                338,
                2,
            ],
            [
                0,
                18,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                4,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                2,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                11,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                46,
            ],
            [
                338,
                1,
            ],
            [
                0,
                17,
            ],
            [
                338,
                1,
            ],
            [
                0,
                21,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13,
                5,
            ],
            [
                0,
                4,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                4,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                8,
            ],
            [
                0,
                18,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                4,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                2,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                11,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                46,
            ],
            [
                13,
                1,
            ],
            [
                0,
                17,
            ],
            [
                13,
                1,
            ],
            [
                0,
                21,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                50,
                1,
            ],
            [
                0,
                12,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                46,
                5,
            ],
            [
                0,
                4,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                4,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                50,
                7,
            ],
            [
                0,
                18,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                4,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                2,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                11,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                46,
            ],
            [
                46,
                1,
            ],
            [
                0,
                17,
            ],
            [
                46,
                1,
            ],
            [
                0,
                21,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Cocos (Keeling) Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Democratic Republic of the Congo': {
        'overall': [
            [
                0,
                54,
            ],
            [
                231,
                1,
            ],
            [
                0,
                12,
            ],
            [
                231,
                1,
            ],
            [
                0,
                35,
            ],
            [
                231,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                33,
                1,
            ],
            [
                0,
                12,
            ],
            [
                33,
                1,
            ],
            [
                0,
                35,
            ],
            [
                33,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                127,
                1,
            ],
            [
                0,
                12,
            ],
            [
                127,
                1,
            ],
            [
                0,
                35,
            ],
            [
                127,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Central African Republic': {
        'overall': [
            [
                0,
                54,
            ],
            [
                39,
                1,
            ],
            [
                0,
                12,
            ],
            [
                39,
                1,
            ],
            [
                0,
                35,
            ],
            [
                39,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Congo': {
        'overall': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                35,
            ],
            [
                37,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Switzerland': {
        'overall': [
            [
                0,
                54,
            ],
            [
                53,
                1,
            ],
            [
                0,
                12,
            ],
            [
                53,
                1,
            ],
            [
                0,
                35,
            ],
            [
                53,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Ivory Coast': {
        'overall': [
            [
                0,
                54,
            ],
            [
                73,
                1,
            ],
            [
                0,
                12,
            ],
            [
                73,
                1,
            ],
            [
                0,
                35,
            ],
            [
                73,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                35,
                1,
            ],
            [
                0,
                12,
            ],
            [
                35,
                1,
            ],
            [
                0,
                35,
            ],
            [
                35,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Cook Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Chile': {
        'overall': [
            [
                0,
                54,
            ],
            [
                62,
                1,
            ],
            [
                0,
                12,
            ],
            [
                62,
                1,
            ],
            [
                0,
                35,
            ],
            [
                62,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                33,
                1,
            ],
            [
                0,
                12,
            ],
            [
                33,
                1,
            ],
            [
                0,
                35,
            ],
            [
                33,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Cameroon': {
        'overall': [
            [
                0,
                54,
            ],
            [
                88,
                1,
            ],
            [
                0,
                12,
            ],
            [
                88,
                1,
            ],
            [
                0,
                35,
            ],
            [
                88,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'China': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2488,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                163,
                1,
            ],
            [
                0,
                12,
            ],
            [
                163,
                1,
            ],
            [
                0,
                35,
            ],
            [
                163,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                82,
                1,
            ],
            [
                0,
                12,
            ],
            [
                82,
                1,
            ],
            [
                0,
                35,
            ],
            [
                82,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                35,
                1,
            ],
            [
                0,
                12,
            ],
            [
                35,
                1,
            ],
            [
                0,
                35,
            ],
            [
                35,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                312,
                1,
            ],
            [
                0,
                12,
            ],
            [
                312,
                1,
            ],
            [
                0,
                35,
            ],
            [
                312,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1825,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1825,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1825,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Colombia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                154,
                1,
            ],
            [
                0,
                12,
            ],
            [
                154,
                1,
            ],
            [
                0,
                35,
            ],
            [
                154,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                33,
                1,
            ],
            [
                0,
                12,
            ],
            [
                33,
                1,
            ],
            [
                0,
                35,
            ],
            [
                33,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                90,
                1,
            ],
            [
                0,
                12,
            ],
            [
                90,
                1,
            ],
            [
                0,
                35,
            ],
            [
                90,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Costa Rica': {
        'overall': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Cuba': {
        'overall': [
            [
                0,
                54,
            ],
            [
                43,
                1,
            ],
            [
                0,
                12,
            ],
            [
                43,
                1,
            ],
            [
                0,
                35,
            ],
            [
                43,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Cape Verde': {
        'overall': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Christmas Island': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Cyprus': {
        'overall': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Czech Republic': {
        'overall': [
            [
                0,
                54,
            ],
            [
                42,
                1,
            ],
            [
                0,
                12,
            ],
            [
                42,
                1,
            ],
            [
                0,
                35,
            ],
            [
                42,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Germany': {
        'overall': [
            [
                0,
                54,
            ],
            [
                162,
                1,
            ],
            [
                0,
                12,
            ],
            [
                162,
                1,
            ],
            [
                0,
                35,
            ],
            [
                162,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                25,
                1,
            ],
            [
                0,
                12,
            ],
            [
                25,
                1,
            ],
            [
                0,
                35,
            ],
            [
                25,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                90,
                1,
            ],
            [
                0,
                12,
            ],
            [
                90,
                1,
            ],
            [
                0,
                35,
            ],
            [
                90,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Djibouti': {
        'overall': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Denmark': {
        'overall': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Dominica': {
        'overall': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Dominican Republic': {
        'overall': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Algeria': {
        'overall': [
            [
                0,
                54,
            ],
            [
                179,
                1,
            ],
            [
                0,
                12,
            ],
            [
                179,
                1,
            ],
            [
                0,
                35,
            ],
            [
                179,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                48,
                1,
            ],
            [
                0,
                12,
            ],
            [
                48,
                1,
            ],
            [
                0,
                35,
            ],
            [
                48,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                95,
                1,
            ],
            [
                0,
                12,
            ],
            [
                95,
                1,
            ],
            [
                0,
                35,
            ],
            [
                95,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Ecuador': {
        'overall': [
            [
                0,
                54,
            ],
            [
                65,
                1,
            ],
            [
                0,
                12,
            ],
            [
                65,
                1,
            ],
            [
                0,
                35,
            ],
            [
                65,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                35,
            ],
            [
                31,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Estonia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Egypt': {
        'overall': [
            [
                0,
                54,
            ],
            [
                266,
                1,
            ],
            [
                0,
                12,
            ],
            [
                266,
                1,
            ],
            [
                0,
                35,
            ],
            [
                266,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                185,
                1,
            ],
            [
                0,
                12,
            ],
            [
                185,
                1,
            ],
            [
                0,
                35,
            ],
            [
                185,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Eritrea': {
        'overall': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Spain': {
        'overall': [
            [
                0,
                54,
            ],
            [
                126,
                1,
            ],
            [
                0,
                12,
            ],
            [
                126,
                1,
            ],
            [
                0,
                35,
            ],
            [
                126,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                35,
            ],
            [
                23,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                73,
                1,
            ],
            [
                0,
                12,
            ],
            [
                73,
                1,
            ],
            [
                0,
                35,
            ],
            [
                73,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Ethiopia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                312,
                1,
            ],
            [
                0,
                12,
            ],
            [
                312,
                1,
            ],
            [
                0,
                35,
            ],
            [
                312,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                30,
                1,
            ],
            [
                0,
                12,
            ],
            [
                30,
                1,
            ],
            [
                0,
                35,
            ],
            [
                30,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                235,
                1,
            ],
            [
                0,
                12,
            ],
            [
                235,
                1,
            ],
            [
                0,
                35,
            ],
            [
                235,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Finland': {
        'overall': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Fiji': {
        'overall': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Falkland Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Micronesia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Faroe Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'France': {
        'overall': [
            [
                0,
                54,
            ],
            [
                151,
                1,
            ],
            [
                0,
                12,
            ],
            [
                151,
                1,
            ],
            [
                0,
                35,
            ],
            [
                151,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                80,
                1,
            ],
            [
                0,
                12,
            ],
            [
                80,
                1,
            ],
            [
                0,
                35,
            ],
            [
                80,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Gabon': {
        'overall': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'United Kingdom': {
        'overall': [
            [
                0,
                54,
            ],
            [
                173,
                1,
            ],
            [
                0,
                12,
            ],
            [
                173,
                1,
            ],
            [
                0,
                35,
            ],
            [
                173,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                138,
                1,
            ],
            [
                0,
                12,
            ],
            [
                138,
                1,
            ],
            [
                0,
                35,
            ],
            [
                138,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Grenada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Georgia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Guernsey': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Ghana': {
        'overall': [
            [
                0,
                54,
            ],
            [
                89,
                1,
            ],
            [
                0,
                12,
            ],
            [
                89,
                1,
            ],
            [
                0,
                35,
            ],
            [
                89,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                51,
                1,
            ],
            [
                0,
                12,
            ],
            [
                51,
                1,
            ],
            [
                0,
                35,
            ],
            [
                51,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Gibraltar': {
        'overall': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Greenland': {
        'overall': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'The Gambia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Guinea': {
        'overall': [
            [
                0,
                54,
            ],
            [
                42,
                1,
            ],
            [
                0,
                12,
            ],
            [
                42,
                1,
            ],
            [
                0,
                35,
            ],
            [
                42,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Equatorial Guinea': {
        'overall': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Greece': {
        'overall': [
            [
                0,
                54,
            ],
            [
                36,
                1,
            ],
            [
                0,
                12,
            ],
            [
                36,
                1,
            ],
            [
                0,
                35,
            ],
            [
                36,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'South Georgia and the South Sandwich Islands': {},
    'Guatemala': {
        'overall': [
            [
                0,
                54,
            ],
            [
                73,
                1,
            ],
            [
                0,
                12,
            ],
            [
                73,
                1,
            ],
            [
                0,
                35,
            ],
            [
                73,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                39,
                1,
            ],
            [
                0,
                12,
            ],
            [
                39,
                1,
            ],
            [
                0,
                35,
            ],
            [
                39,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Guinea-Bissau': {
        'overall': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Guyana': {
        'overall': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Heard Island and McDonald Islands': {},
    'Honduras': {
        'overall': [
            [
                0,
                54,
            ],
            [
                42,
                1,
            ],
            [
                0,
                12,
            ],
            [
                42,
                1,
            ],
            [
                0,
                35,
            ],
            [
                42,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Croatia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                38,
                1,
            ],
            [
                0,
                12,
            ],
            [
                38,
                1,
            ],
            [
                0,
                35,
            ],
            [
                38,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Haiti': {
        'overall': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                35,
            ],
            [
                37,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Hungary': {
        'overall': [
            [
                0,
                54,
            ],
            [
                49,
                1,
            ],
            [
                0,
                12,
            ],
            [
                49,
                1,
            ],
            [
                0,
                35,
            ],
            [
                49,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Indonesia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                473,
                1,
            ],
            [
                0,
                12,
            ],
            [
                473,
                1,
            ],
            [
                0,
                35,
            ],
            [
                473,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                35,
            ],
            [
                31,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                59,
                1,
            ],
            [
                0,
                12,
            ],
            [
                59,
                1,
            ],
            [
                0,
                35,
            ],
            [
                59,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                317,
                1,
            ],
            [
                0,
                12,
            ],
            [
                317,
                1,
            ],
            [
                0,
                35,
            ],
            [
                317,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Ireland': {
        'overall': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Israel': {
        'overall': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                35,
            ],
            [
                28,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Isle of Man': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'India': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2509,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2509,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2509,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                162,
                1,
            ],
            [
                0,
                12,
            ],
            [
                162,
                1,
            ],
            [
                0,
                35,
            ],
            [
                162,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                84,
                1,
            ],
            [
                0,
                12,
            ],
            [
                84,
                1,
            ],
            [
                0,
                35,
            ],
            [
                84,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                36,
                1,
            ],
            [
                0,
                12,
            ],
            [
                36,
                1,
            ],
            [
                0,
                35,
            ],
            [
                36,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                313,
                1,
            ],
            [
                0,
                12,
            ],
            [
                313,
                1,
            ],
            [
                0,
                35,
            ],
            [
                313,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                35,
            ],
            [
                37,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1841,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1841,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1841,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'British Indian Ocean Territory': {},
    'Iraq': {
        'overall': [
            [
                0,
                54,
            ],
            [
                124,
                1,
            ],
            [
                0,
                12,
            ],
            [
                124,
                1,
            ],
            [
                0,
                35,
            ],
            [
                124,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                71,
                1,
            ],
            [
                0,
                12,
            ],
            [
                71,
                1,
            ],
            [
                0,
                35,
            ],
            [
                71,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Iran': {
        'overall': [
            [
                0,
                54,
            ],
            [
                275,
                1,
            ],
            [
                0,
                12,
            ],
            [
                275,
                1,
            ],
            [
                0,
                35,
            ],
            [
                275,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                29,
                1,
            ],
            [
                0,
                12,
            ],
            [
                29,
                1,
            ],
            [
                0,
                35,
            ],
            [
                29,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                35,
            ],
            [
                31,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                176,
                1,
            ],
            [
                0,
                12,
            ],
            [
                176,
                1,
            ],
            [
                0,
                35,
            ],
            [
                176,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Iceland': {
        'overall': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Italy': {
        'overall': [
            [
                0,
                54,
            ],
            [
                145,
                1,
            ],
            [
                0,
                12,
            ],
            [
                145,
                1,
            ],
            [
                0,
                35,
            ],
            [
                145,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                90,
                1,
            ],
            [
                0,
                12,
            ],
            [
                90,
                1,
            ],
            [
                0,
                35,
            ],
            [
                90,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Jersey': {
        'overall': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Jamaica': {
        'overall': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Jordan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                32,
                1,
            ],
            [
                0,
                12,
            ],
            [
                32,
                1,
            ],
            [
                0,
                35,
            ],
            [
                32,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Japan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                208,
                1,
            ],
            [
                0,
                12,
            ],
            [
                208,
                1,
            ],
            [
                0,
                35,
            ],
            [
                208,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                109,
                1,
            ],
            [
                0,
                12,
            ],
            [
                109,
                1,
            ],
            [
                0,
                35,
            ],
            [
                109,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Kenya': {
        'overall': [
            [
                0,
                54,
            ],
            [
                124,
                1,
            ],
            [
                0,
                12,
            ],
            [
                124,
                1,
            ],
            [
                0,
                35,
            ],
            [
                124,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                41,
                1,
            ],
            [
                0,
                12,
            ],
            [
                41,
                1,
            ],
            [
                0,
                35,
            ],
            [
                41,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Kyrgyzstan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Cambodia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                55,
                1,
            ],
            [
                0,
                12,
            ],
            [
                55,
                1,
            ],
            [
                0,
                35,
            ],
            [
                55,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                25,
                1,
            ],
            [
                0,
                12,
            ],
            [
                25,
                1,
            ],
            [
                0,
                35,
            ],
            [
                25,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Kiribati': {
        'overall': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Comoros': {
        'overall': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Saint Kitts and Nevis': {
        'overall': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'North Korea': {
        'overall': [
            [
                0,
                54,
            ],
            [
                114,
                1,
            ],
            [
                0,
                12,
            ],
            [
                114,
                1,
            ],
            [
                0,
                35,
            ],
            [
                114,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                80,
                1,
            ],
            [
                0,
                12,
            ],
            [
                80,
                1,
            ],
            [
                0,
                35,
            ],
            [
                80,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'South Korea': {
        'overall': [
            [
                0,
                54,
            ],
            [
                78,
                1,
            ],
            [
                0,
                12,
            ],
            [
                78,
                1,
            ],
            [
                0,
                35,
            ],
            [
                78,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                39,
                1,
            ],
            [
                0,
                12,
            ],
            [
                39,
                1,
            ],
            [
                0,
                35,
            ],
            [
                39,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Kuwait': {
        'overall': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Cayman Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Kazakhstan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                83,
                1,
            ],
            [
                0,
                12,
            ],
            [
                83,
                1,
            ],
            [
                0,
                35,
            ],
            [
                83,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Laos': {
        'overall': [
            [
                0,
                54,
            ],
            [
                40,
                1,
            ],
            [
                0,
                12,
            ],
            [
                40,
                1,
            ],
            [
                0,
                35,
            ],
            [
                40,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Lebanon': {
        'overall': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Saint Lucia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Liechtenstein': {
        'overall': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Sri Lanka': {
        'overall': [
            [
                0,
                54,
            ],
            [
                39,
                1,
            ],
            [
                0,
                12,
            ],
            [
                39,
                1,
            ],
            [
                0,
                35,
            ],
            [
                39,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Liberia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Lesotho': {
        'overall': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Lithuania': {
        'overall': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Luxembourg': {
        'overall': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Latvia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                53,
                1,
            ],
            [
                0,
                12,
            ],
            [
                53,
                1,
            ],
            [
                0,
                35,
            ],
            [
                53,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                43,
                1,
            ],
            [
                0,
                12,
            ],
            [
                43,
                1,
            ],
            [
                0,
                35,
            ],
            [
                43,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Libya': {
        'overall': [
            [
                0,
                54,
            ],
            [
                51,
                1,
            ],
            [
                0,
                12,
            ],
            [
                51,
                1,
            ],
            [
                0,
                35,
            ],
            [
                51,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Morocco': {
        'overall': [
            [
                0,
                54,
            ],
            [
                97,
                1,
            ],
            [
                0,
                12,
            ],
            [
                97,
                1,
            ],
            [
                0,
                35,
            ],
            [
                97,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                65,
                1,
            ],
            [
                0,
                12,
            ],
            [
                65,
                1,
            ],
            [
                0,
                35,
            ],
            [
                65,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Monaco': {
        'overall': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Moldova': {
        'overall': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                35,
            ],
            [
                37,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Montenegro': {
        'overall': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                35,
            ],
            [
                28,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Madagascar': {
        'overall': [
            [
                0,
                54,
            ],
            [
                45,
                1,
            ],
            [
                0,
                12,
            ],
            [
                45,
                1,
            ],
            [
                0,
                35,
            ],
            [
                45,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Marshall Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'North Macedonia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                84,
                1,
            ],
            [
                0,
                12,
            ],
            [
                84,
                1,
            ],
            [
                0,
                35,
            ],
            [
                84,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                71,
                1,
            ],
            [
                0,
                12,
            ],
            [
                71,
                1,
            ],
            [
                0,
                35,
            ],
            [
                71,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Mali': {
        'overall': [
            [
                0,
                54,
            ],
            [
                52,
                1,
            ],
            [
                0,
                12,
            ],
            [
                52,
                1,
            ],
            [
                0,
                35,
            ],
            [
                52,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Myanmar': {
        'overall': [
            [
                0,
                54,
            ],
            [
                155,
                1,
            ],
            [
                0,
                12,
            ],
            [
                155,
                1,
            ],
            [
                0,
                35,
            ],
            [
                155,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                96,
                1,
            ],
            [
                0,
                12,
            ],
            [
                96,
                1,
            ],
            [
                0,
                35,
            ],
            [
                96,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Mongolia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                35,
            ],
            [
                37,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Mauritania': {
        'overall': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Montserrat': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Malta': {
        'overall': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Mauritius': {
        'overall': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Maldives': {
        'overall': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                35,
            ],
            [
                23,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Malawi': {
        'overall': [
            [
                0,
                54,
            ],
            [
                49,
                1,
            ],
            [
                0,
                12,
            ],
            [
                49,
                1,
            ],
            [
                0,
                35,
            ],
            [
                49,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                35,
            ],
            [
                28,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Mexico': {
        'overall': [
            [
                3,
                54,
            ],
            [
                264,
                1,
            ],
            [
                3,
                12,
            ],
            [
                264,
                1,
            ],
            [
                3,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                7,
            ],
            [
                3,
                18,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                54,
            ],
            [
                16,
                1,
            ],
            [
                2,
                12,
            ],
            [
                16,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                8,
                1,
            ],
            [
                1,
                12,
            ],
            [
                8,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                35,
            ],
            [
                31,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                32,
                1,
            ],
            [
                0,
                12,
            ],
            [
                32,
                1,
            ],
            [
                0,
                35,
            ],
            [
                32,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                169,
                1,
            ],
            [
                0,
                12,
            ],
            [
                169,
                1,
            ],
            [
                0,
                35,
            ],
            [
                169,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Malaysia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                81,
                1,
            ],
            [
                0,
                12,
            ],
            [
                81,
                1,
            ],
            [
                0,
                35,
            ],
            [
                81,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                38,
                1,
            ],
            [
                0,
                12,
            ],
            [
                38,
                1,
            ],
            [
                0,
                35,
            ],
            [
                38,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Mozambique': {
        'overall': [
            [
                0,
                54,
            ],
            [
                89,
                1,
            ],
            [
                0,
                12,
            ],
            [
                89,
                1,
            ],
            [
                0,
                35,
            ],
            [
                89,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Namibia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                25,
                1,
            ],
            [
                0,
                12,
            ],
            [
                25,
                1,
            ],
            [
                0,
                35,
            ],
            [
                25,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Niger': {
        'overall': [
            [
                0,
                54,
            ],
            [
                72,
                1,
            ],
            [
                0,
                12,
            ],
            [
                72,
                1,
            ],
            [
                0,
                35,
            ],
            [
                72,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                29,
                1,
            ],
            [
                0,
                12,
            ],
            [
                29,
                1,
            ],
            [
                0,
                35,
            ],
            [
                29,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Norfolk Island': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Nigeria': {
        'overall': [
            [
                0,
                54,
            ],
            [
                530,
                1,
            ],
            [
                0,
                12,
            ],
            [
                530,
                1,
            ],
            [
                0,
                35,
            ],
            [
                530,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                50,
                1,
            ],
            [
                0,
                12,
            ],
            [
                50,
                1,
            ],
            [
                0,
                35,
            ],
            [
                50,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                35,
            ],
            [
                37,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                391,
                1,
            ],
            [
                0,
                12,
            ],
            [
                391,
                1,
            ],
            [
                0,
                35,
            ],
            [
                391,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Nicaragua': {
        'overall': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                35,
            ],
            [
                37,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Netherlands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                70,
                1,
            ],
            [
                0,
                12,
            ],
            [
                70,
                1,
            ],
            [
                0,
                35,
            ],
            [
                70,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                38,
                1,
            ],
            [
                0,
                12,
            ],
            [
                38,
                1,
            ],
            [
                0,
                35,
            ],
            [
                38,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Norway': {
        'overall': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Nepal': {
        'overall': [
            [
                0,
                54,
            ],
            [
                72,
                1,
            ],
            [
                0,
                12,
            ],
            [
                72,
                1,
            ],
            [
                0,
                35,
            ],
            [
                72,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Nauru': {
        'overall': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Niue': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'New Zealand': {
        'overall': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Oman': {
        'overall': [
            [
                0,
                54,
            ],
            [
                30,
                1,
            ],
            [
                0,
                12,
            ],
            [
                30,
                1,
            ],
            [
                0,
                35,
            ],
            [
                30,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Panama': {
        'overall': [
            [
                0,
                54,
            ],
            [
                25,
                1,
            ],
            [
                0,
                12,
            ],
            [
                25,
                1,
            ],
            [
                0,
                35,
            ],
            [
                25,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Peru': {
        'overall': [
            [
                0,
                54,
            ],
            [
                94,
                1,
            ],
            [
                0,
                12,
            ],
            [
                94,
                1,
            ],
            [
                0,
                35,
            ],
            [
                94,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                45,
                1,
            ],
            [
                0,
                12,
            ],
            [
                45,
                1,
            ],
            [
                0,
                35,
            ],
            [
                45,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Papua New Guinea': {
        'overall': [
            [
                0,
                54,
            ],
            [
                35,
                1,
            ],
            [
                0,
                12,
            ],
            [
                35,
                1,
            ],
            [
                0,
                35,
            ],
            [
                35,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Philippines': {
        'overall': [
            [
                0,
                54,
            ],
            [
                219,
                1,
            ],
            [
                0,
                12,
            ],
            [
                219,
                1,
            ],
            [
                0,
                35,
            ],
            [
                219,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                35,
            ],
            [
                23,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                84,
                1,
            ],
            [
                0,
                12,
            ],
            [
                84,
                1,
            ],
            [
                0,
                35,
            ],
            [
                84,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                91,
                1,
            ],
            [
                0,
                12,
            ],
            [
                91,
                1,
            ],
            [
                0,
                35,
            ],
            [
                91,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Pakistan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                339,
                1,
            ],
            [
                0,
                12,
            ],
            [
                339,
                1,
            ],
            [
                0,
                35,
            ],
            [
                339,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                30,
                1,
            ],
            [
                0,
                12,
            ],
            [
                30,
                1,
            ],
            [
                0,
                35,
            ],
            [
                30,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                55,
                1,
            ],
            [
                0,
                12,
            ],
            [
                55,
                1,
            ],
            [
                0,
                35,
            ],
            [
                55,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                211,
                1,
            ],
            [
                0,
                12,
            ],
            [
                211,
                1,
            ],
            [
                0,
                35,
            ],
            [
                211,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Poland': {
        'overall': [
            [
                0,
                54,
            ],
            [
                92,
                1,
            ],
            [
                0,
                12,
            ],
            [
                92,
                1,
            ],
            [
                0,
                35,
            ],
            [
                92,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Pitcairn Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'State of Palestine': {
        'overall': [
            [
                0,
                54,
            ],
            [
                33,
                1,
            ],
            [
                0,
                12,
            ],
            [
                33,
                1,
            ],
            [
                0,
                35,
            ],
            [
                33,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Portugal': {
        'overall': [
            [
                0,
                54,
            ],
            [
                38,
                1,
            ],
            [
                0,
                12,
            ],
            [
                38,
                1,
            ],
            [
                0,
                35,
            ],
            [
                38,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Palau': {
        'overall': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Paraguay': {
        'overall': [
            [
                0,
                54,
            ],
            [
                41,
                1,
            ],
            [
                0,
                12,
            ],
            [
                41,
                1,
            ],
            [
                0,
                35,
            ],
            [
                41,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Qatar': {
        'overall': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Romania': {
        'overall': [
            [
                0,
                54,
            ],
            [
                92,
                1,
            ],
            [
                0,
                12,
            ],
            [
                92,
                1,
            ],
            [
                0,
                35,
            ],
            [
                92,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                42,
                1,
            ],
            [
                0,
                12,
            ],
            [
                42,
                1,
            ],
            [
                0,
                35,
            ],
            [
                42,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                30,
                1,
            ],
            [
                0,
                12,
            ],
            [
                30,
                1,
            ],
            [
                0,
                35,
            ],
            [
                30,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Serbia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                53,
                1,
            ],
            [
                0,
                12,
            ],
            [
                53,
                1,
            ],
            [
                0,
                35,
            ],
            [
                53,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Russia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                393,
                1,
            ],
            [
                0,
                12,
            ],
            [
                393,
                1,
            ],
            [
                0,
                35,
            ],
            [
                393,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                25,
                1,
            ],
            [
                0,
                12,
            ],
            [
                25,
                1,
            ],
            [
                0,
                35,
            ],
            [
                25,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                43,
                1,
            ],
            [
                0,
                12,
            ],
            [
                43,
                1,
            ],
            [
                0,
                35,
            ],
            [
                43,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                83,
                1,
            ],
            [
                0,
                12,
            ],
            [
                83,
                1,
            ],
            [
                0,
                35,
            ],
            [
                83,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                204,
                1,
            ],
            [
                0,
                12,
            ],
            [
                204,
                1,
            ],
            [
                0,
                35,
            ],
            [
                204,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Rwanda': {
        'overall': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                35,
            ],
            [
                28,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Saudi Arabia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                91,
                1,
            ],
            [
                0,
                12,
            ],
            [
                91,
                1,
            ],
            [
                0,
                35,
            ],
            [
                91,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Solomon Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Seychelles': {
        'overall': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                35,
            ],
            [
                28,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Sudan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                128,
                1,
            ],
            [
                0,
                12,
            ],
            [
                128,
                1,
            ],
            [
                0,
                35,
            ],
            [
                128,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                71,
                1,
            ],
            [
                0,
                12,
            ],
            [
                71,
                1,
            ],
            [
                0,
                35,
            ],
            [
                71,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Sweden': {
        'overall': [
            [
                0,
                54,
            ],
            [
                47,
                1,
            ],
            [
                0,
                12,
            ],
            [
                47,
                1,
            ],
            [
                0,
                35,
            ],
            [
                47,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Singapore': {
        'overall': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Saint Helena, Ascension and Tristan da Cunha': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Slovenia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                218,
                1,
            ],
            [
                0,
                12,
            ],
            [
                218,
                1,
            ],
            [
                0,
                35,
            ],
            [
                218,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                211,
                1,
            ],
            [
                0,
                12,
            ],
            [
                211,
                1,
            ],
            [
                0,
                35,
            ],
            [
                211,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Slovakia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Sierra Leone': {
        'overall': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                35,
            ],
            [
                23,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'San Marino': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Senegal': {
        'overall': [
            [
                0,
                54,
            ],
            [
                58,
                1,
            ],
            [
                0,
                12,
            ],
            [
                58,
                1,
            ],
            [
                0,
                35,
            ],
            [
                58,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                35,
            ],
            [
                31,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Somalia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                57,
                1,
            ],
            [
                0,
                12,
            ],
            [
                57,
                1,
            ],
            [
                0,
                35,
            ],
            [
                57,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                35,
            ],
            [
                18,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                22,
                1,
            ],
            [
                0,
                12,
            ],
            [
                22,
                1,
            ],
            [
                0,
                35,
            ],
            [
                22,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Suriname': {
        'overall': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'South Sudan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                53,
                1,
            ],
            [
                0,
                12,
            ],
            [
                53,
                1,
            ],
            [
                0,
                35,
            ],
            [
                53,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'S\u00e3o Tom\u00e9 and Pr\u00edncipe': {
        'overall': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'El Salvador': {
        'overall': [
            [
                0,
                54,
            ],
            [
                29,
                1,
            ],
            [
                0,
                12,
            ],
            [
                29,
                1,
            ],
            [
                0,
                35,
            ],
            [
                29,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Syria': {
        'overall': [
            [
                0,
                54,
            ],
            [
                63,
                1,
            ],
            [
                0,
                12,
            ],
            [
                63,
                1,
            ],
            [
                0,
                35,
            ],
            [
                63,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Eswatini': {
        'overall': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Turks and Caicos Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Chad': {
        'overall': [
            [
                0,
                54,
            ],
            [
                73,
                1,
            ],
            [
                0,
                12,
            ],
            [
                73,
                1,
            ],
            [
                0,
                35,
            ],
            [
                73,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                35,
            ],
            [
                23,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                29,
                1,
            ],
            [
                0,
                12,
            ],
            [
                29,
                1,
            ],
            [
                0,
                35,
            ],
            [
                29,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Togo': {
        'overall': [
            [
                0,
                54,
            ],
            [
                33,
                1,
            ],
            [
                0,
                12,
            ],
            [
                33,
                1,
            ],
            [
                0,
                35,
            ],
            [
                33,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                16,
                1,
            ],
            [
                0,
                12,
            ],
            [
                16,
                1,
            ],
            [
                0,
                35,
            ],
            [
                16,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Thailand': {
        'overall': [
            [
                0,
                54,
            ],
            [
                170,
                1,
            ],
            [
                0,
                12,
            ],
            [
                170,
                1,
            ],
            [
                0,
                35,
            ],
            [
                170,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                78,
                1,
            ],
            [
                0,
                12,
            ],
            [
                78,
                1,
            ],
            [
                0,
                35,
            ],
            [
                78,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                46,
                1,
            ],
            [
                0,
                12,
            ],
            [
                46,
                1,
            ],
            [
                0,
                35,
            ],
            [
                46,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Tajikistan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                35,
            ],
            [
                34,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Tokelau': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'East Timor': {
        'overall': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Turkmenistan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                38,
                1,
            ],
            [
                0,
                12,
            ],
            [
                38,
                1,
            ],
            [
                0,
                35,
            ],
            [
                38,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Tunisia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                59,
                1,
            ],
            [
                0,
                12,
            ],
            [
                59,
                1,
            ],
            [
                0,
                35,
            ],
            [
                59,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                24,
                1,
            ],
            [
                0,
                12,
            ],
            [
                24,
                1,
            ],
            [
                0,
                35,
            ],
            [
                24,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Tonga': {
        'overall': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Turkey': {
        'overall': [
            [
                0,
                54,
            ],
            [
                262,
                1,
            ],
            [
                0,
                12,
            ],
            [
                262,
                1,
            ],
            [
                0,
                35,
            ],
            [
                262,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                13,
                1,
            ],
            [
                0,
                12,
            ],
            [
                13,
                1,
            ],
            [
                0,
                35,
            ],
            [
                13,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                21,
                1,
            ],
            [
                0,
                12,
            ],
            [
                21,
                1,
            ],
            [
                0,
                35,
            ],
            [
                21,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                81,
                1,
            ],
            [
                0,
                12,
            ],
            [
                81,
                1,
            ],
            [
                0,
                35,
            ],
            [
                81,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                132,
                1,
            ],
            [
                0,
                12,
            ],
            [
                132,
                1,
            ],
            [
                0,
                35,
            ],
            [
                132,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Trinidad and Tobago': {
        'overall': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Tuvalu': {
        'overall': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Tanzania': {
        'overall': [
            [
                0,
                54,
            ],
            [
                120,
                1,
            ],
            [
                0,
                12,
            ],
            [
                120,
                1,
            ],
            [
                0,
                35,
            ],
            [
                120,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                35,
            ],
            [
                31,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                42,
                1,
            ],
            [
                0,
                12,
            ],
            [
                42,
                1,
            ],
            [
                0,
                35,
            ],
            [
                42,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Ukraine': {
        'overall': [
            [
                0,
                54,
            ],
            [
                142,
                1,
            ],
            [
                0,
                12,
            ],
            [
                142,
                1,
            ],
            [
                0,
                35,
            ],
            [
                142,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                35,
            ],
            [
                17,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                78,
                1,
            ],
            [
                0,
                12,
            ],
            [
                78,
                1,
            ],
            [
                0,
                35,
            ],
            [
                78,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Uganda': {
        'overall': [
            [
                0,
                54,
            ],
            [
                181,
                1,
            ],
            [
                0,
                12,
            ],
            [
                181,
                1,
            ],
            [
                0,
                35,
            ],
            [
                181,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                122,
                1,
            ],
            [
                0,
                12,
            ],
            [
                122,
                1,
            ],
            [
                0,
                35,
            ],
            [
                122,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                35,
            ],
            [
                26,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'United States Minor Outlying Islands': {},
    'USA': {
        'overall': [
            [
                162593,
                1,
            ],
            [
                162572,
                1,
            ],
            [
                162584,
                1,
            ],
            [
                163755,
                26,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164175,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164089,
                3,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164089,
                3,
            ],
            [
                164175,
                1,
            ],
            [
                164112,
                6,
            ],
            [
                1,
                5,
            ],
            [
                164030,
                4,
            ],
            [
                1,
                1,
            ],
            [
                164030,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164030,
                1,
            ],
            [
                1,
                1,
            ],
            [
                163999,
                3,
            ],
            [
                164072,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164072,
                1,
            ],
            [
                1,
                1,
            ],
            [
                547,
                5,
            ],
            [
                164175,
                2,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                163707,
                6,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                1,
                1,
            ],
            [
                163740,
                1,
            ],
            [
                1,
                1,
            ],
            [
                163740,
                1,
            ],
            [
                1,
                1,
            ],
            [
                163740,
                1,
            ],
            [
                164109,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164058,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164058,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164058,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164023,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164074,
                3,
            ],
            [
                163707,
                1,
            ],
            [
                164089,
                3,
            ],
            [
                163755,
                4,
            ],
            [
                1,
                1,
            ],
            [
                164041,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164041,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164041,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164112,
                12,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164023,
                25,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                4,
            ],
            [
                1,
                1,
            ],
            [
                164112,
                2,
            ],
            [
                164065,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                139134,
                3,
            ],
            [
                156418,
                3,
            ],
            [
                162527,
                3,
            ],
            [
                163707,
                1,
            ],
            [
                164112,
                3,
            ],
            [
                1,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                157980,
                1,
            ],
            [
                160180,
                1,
            ],
            [
                162940,
                1,
            ],
            [
                157606,
                1,
            ],
            [
                159736,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                164072,
                5,
            ],
            [
                164112,
                1,
            ],
            [
                162650,
                1,
            ],
            [
                164112,
                1,
            ],
            [
                162650,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164019,
                1,
            ],
            [
                1,
                1,
            ],
            [
                164025,
                5,
            ],
            [
                164006,
                1,
            ],
            [
                162864,
                1,
            ],
            [
                163549,
                1,
            ],
            [
                163740,
                3,
            ],
            [
                164112,
                1,
            ],
            [
                162955,
                1,
            ],
            [
                163755,
                1,
            ],
            [
                164112,
                5,
            ],
            [
                163930,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                2,
                54,
            ],
            [
                4,
                1,
            ],
            [
                2,
                12,
            ],
            [
                4,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                27,
                54,
            ],
            [
                41,
                1,
            ],
            [
                27,
                12,
            ],
            [
                41,
                1,
            ],
            [
                27,
                6,
            ],
            [
                1,
                5,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                41,
                7,
            ],
            [
                27,
                18,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                4,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                2,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                11,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                46,
            ],
            [
                1,
                1,
            ],
            [
                27,
                17,
            ],
            [
                1,
                1,
            ],
            [
                27,
                21,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                1,
            ],
            [
                1,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        '1B Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                13,
                54,
            ],
            [
                19,
                1,
            ],
            [
                13,
                12,
            ],
            [
                19,
                1,
            ],
            [
                13,
                6,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                7,
            ],
            [
                13,
                18,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                4,
                54,
            ],
            [
                7,
                1,
            ],
            [
                4,
                12,
            ],
            [
                7,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                61,
                54,
            ],
            [
                77,
                1,
            ],
            [
                61,
                12,
            ],
            [
                77,
                1,
            ],
            [
                61,
                6,
            ],
            [
                0,
                5,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                7,
            ],
            [
                61,
                18,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                2,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                11,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                46,
            ],
            [
                0,
                1,
            ],
            [
                61,
                17,
            ],
            [
                0,
                1,
            ],
            [
                61,
                21,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                25,
            ],
        ],
        'CCD': [
            [
                35380,
                1,
            ],
            [
                35371,
                1,
            ],
            [
                35373,
                1,
            ],
            [
                36276,
                26,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                2,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36293,
                3,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36293,
                3,
            ],
            [
                36301,
                7,
            ],
            [
                0,
                5,
            ],
            [
                36274,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36269,
                3,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36290,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36301,
                3,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36240,
                6,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36242,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36242,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36242,
                1,
            ],
            [
                36300,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36279,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36279,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36279,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36292,
                3,
            ],
            [
                36240,
                1,
            ],
            [
                36293,
                3,
            ],
            [
                36276,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36301,
                12,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36275,
                25,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36301,
                2,
            ],
            [
                36293,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                28807,
                3,
            ],
            [
                34452,
                3,
            ],
            [
                35989,
                3,
            ],
            [
                36240,
                1,
            ],
            [
                36301,
                3,
            ],
            [
                0,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                33767,
                1,
            ],
            [
                34329,
                1,
            ],
            [
                35529,
                1,
            ],
            [
                33543,
                1,
            ],
            [
                34057,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                36290,
                5,
            ],
            [
                36301,
                1,
            ],
            [
                35405,
                1,
            ],
            [
                36301,
                1,
            ],
            [
                35405,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36275,
                5,
            ],
            [
                36300,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36242,
                3,
            ],
            [
                36301,
                1,
            ],
            [
                36270,
                1,
            ],
            [
                36276,
                1,
            ],
            [
                36301,
                5,
            ],
            [
                36295,
                6,
            ],
        ],
        'CSA': [
            [
                171,
                3,
            ],
            [
                174,
                71,
            ],
            [
                0,
                5,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                6,
            ],
            [
                174,
                20,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                2,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                11,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                46,
            ],
            [
                0,
                1,
            ],
            [
                174,
                17,
            ],
            [
                0,
                1,
            ],
            [
                174,
                17,
            ],
            [
                171,
                1,
            ],
            [
                174,
                1,
            ],
            [
                171,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                25,
            ],
        ],
        'City': [
            [
                31828,
                1,
            ],
            [
                31822,
                1,
            ],
            [
                31827,
                1,
            ],
            [
                32002,
                26,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                2,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                4,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                10,
            ],
            [
                0,
                5,
            ],
            [
                32129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32124,
                3,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32132,
                3,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32003,
                6,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32009,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                3,
            ],
            [
                32003,
                1,
            ],
            [
                32132,
                3,
            ],
            [
                32002,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                12,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32128,
                25,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32132,
                2,
            ],
            [
                32128,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                24055,
                3,
            ],
            [
                29583,
                3,
            ],
            [
                31581,
                3,
            ],
            [
                32003,
                1,
            ],
            [
                32132,
                3,
            ],
            [
                0,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                30155,
                1,
            ],
            [
                30861,
                1,
            ],
            [
                31878,
                1,
            ],
            [
                30064,
                1,
            ],
            [
                30765,
                1,
            ],
            [
                32132,
                7,
            ],
            [
                31840,
                1,
            ],
            [
                32132,
                1,
            ],
            [
                31840,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32128,
                5,
            ],
            [
                32091,
                1,
            ],
            [
                31765,
                1,
            ],
            [
                31965,
                1,
            ],
            [
                32009,
                3,
            ],
            [
                32132,
                1,
            ],
            [
                31772,
                1,
            ],
            [
                32002,
                1,
            ],
            [
                32132,
                5,
            ],
            [
                32082,
                6,
            ],
        ],
        'Congressional District': [
            [
                435,
                74,
            ],
            [
                0,
                5,
            ],
            [
                435,
                4,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                4,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                6,
            ],
            [
                435,
                20,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                4,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                2,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                11,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                46,
            ],
            [
                0,
                1,
            ],
            [
                435,
                17,
            ],
            [
                0,
                1,
            ],
            [
                435,
                21,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                1,
            ],
            [
                0,
                1,
            ],
            [
                435,
                25,
            ],
        ],
        'Country': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                3144,
                3,
            ],
            [
                3222,
                71,
            ],
            [
                0,
                5,
            ],
            [
                3222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3222,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                4,
            ],
            [
                3180,
                3,
            ],
            [
                3215,
                3,
            ],
            [
                3222,
                7,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                17,
            ],
            [
                3144,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                3144,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3222,
                25,
            ],
        ],
        'County Cross CD': [
            [
                3721,
                3,
            ],
            [
                3720,
                26,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                2,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                4,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                10,
            ],
            [
                0,
                5,
            ],
            [
                3721,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3721,
                3,
            ],
            [
                3720,
                8,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                3,
            ],
            [
                3720,
                1,
            ],
            [
                3721,
                3,
            ],
            [
                3720,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                12,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                26,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                4,
            ],
            [
                3663,
                3,
            ],
            [
                3708,
                3,
            ],
            [
                3720,
                4,
            ],
            [
                3721,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                3,
            ],
            [
                3719,
                3,
            ],
            [
                3718,
                1,
            ],
            [
                3721,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3721,
                6,
            ],
            [
                3720,
                5,
            ],
            [
                3721,
                1,
            ],
            [
                3720,
                2,
            ],
            [
                3721,
                11,
            ],
        ],
        'Historical Congressional District': [
            [
                9365,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9365,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9365,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                306,
                74,
            ],
            [
                0,
                5,
            ],
            [
                306,
                4,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                4,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                6,
            ],
            [
                306,
                20,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                4,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                2,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                11,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                46,
            ],
            [
                0,
                1,
            ],
            [
                306,
                17,
            ],
            [
                0,
                1,
            ],
            [
                306,
                21,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                3436,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3436,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3436,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                4,
            ],
            [
                3427,
                3,
            ],
            [
                3436,
                10,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3436,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Judicial District': [
            [
                90,
                3,
            ],
            [
                91,
                71,
            ],
            [
                0,
                5,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                6,
            ],
            [
                91,
                20,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                2,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                11,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                46,
            ],
            [
                0,
                1,
            ],
            [
                91,
                17,
            ],
            [
                0,
                1,
            ],
            [
                91,
                17,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                25,
            ],
        ],
        'MSA': [
            [
                933,
                3,
            ],
            [
                945,
                71,
            ],
            [
                0,
                5,
            ],
            [
                945,
                4,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                4,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                6,
            ],
            [
                945,
                20,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                4,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                2,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                11,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                46,
            ],
            [
                0,
                1,
            ],
            [
                945,
                17,
            ],
            [
                0,
                1,
            ],
            [
                945,
                17,
            ],
            [
                933,
                1,
            ],
            [
                945,
                1,
            ],
            [
                933,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                1,
            ],
            [
                0,
                1,
            ],
            [
                945,
                25,
            ],
        ],
        'Media Market': [
            [
                210,
                74,
            ],
            [
                0,
                5,
            ],
            [
                210,
                4,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                4,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                6,
            ],
            [
                210,
                20,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                4,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                2,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                11,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                46,
            ],
            [
                0,
                1,
            ],
            [
                210,
                17,
            ],
            [
                0,
                1,
            ],
            [
                210,
                21,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                25,
            ],
        ],
        'Native Area': [
            [
                371,
                2,
            ],
            [
                372,
                1,
            ],
            [
                357,
                26,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                2,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                4,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                10,
            ],
            [
                0,
                5,
            ],
            [
                369,
                4,
            ],
            [
                0,
                1,
            ],
            [
                369,
                1,
            ],
            [
                0,
                1,
            ],
            [
                369,
                1,
            ],
            [
                0,
                1,
            ],
            [
                367,
                3,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                6,
            ],
            [
                373,
                3,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                362,
                6,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                372,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                2,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                3,
            ],
            [
                362,
                1,
            ],
            [
                373,
                3,
            ],
            [
                357,
                4,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                12,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                370,
                25,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                4,
            ],
            [
                0,
                1,
            ],
            [
                373,
                4,
            ],
            [
                270,
                3,
            ],
            [
                340,
                3,
            ],
            [
                356,
                3,
            ],
            [
                362,
                1,
            ],
            [
                373,
                3,
            ],
            [
                0,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                338,
                1,
            ],
            [
                351,
                1,
            ],
            [
                366,
                1,
            ],
            [
                336,
                1,
            ],
            [
                347,
                1,
            ],
            [
                373,
                10,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                1,
            ],
            [
                0,
                1,
            ],
            [
                370,
                6,
            ],
            [
                346,
                1,
            ],
            [
                354,
                1,
            ],
            [
                362,
                3,
            ],
            [
                373,
                1,
            ],
            [
                346,
                1,
            ],
            [
                357,
                1,
            ],
            [
                373,
                5,
            ],
            [
                370,
                6,
            ],
        ],
        'Native Statistical Area': [
            [
                288,
                1,
            ],
            [
                285,
                2,
            ],
            [
                278,
                26,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                2,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                4,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                10,
            ],
            [
                0,
                5,
            ],
            [
                286,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                3,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                6,
            ],
            [
                288,
                3,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                281,
                6,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                0,
                1,
            ],
            [
                281,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                2,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                3,
            ],
            [
                281,
                1,
            ],
            [
                288,
                3,
            ],
            [
                278,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                288,
                12,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                286,
                25,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                4,
            ],
            [
                0,
                1,
            ],
            [
                288,
                4,
            ],
            [
                265,
                3,
            ],
            [
                272,
                3,
            ],
            [
                281,
                4,
            ],
            [
                288,
                3,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                269,
                1,
            ],
            [
                272,
                1,
            ],
            [
                278,
                1,
            ],
            [
                267,
                1,
            ],
            [
                271,
                1,
            ],
            [
                288,
                10,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                5,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                281,
                3,
            ],
            [
                288,
                1,
            ],
            [
                277,
                1,
            ],
            [
                278,
                1,
            ],
            [
                288,
                11,
            ],
        ],
        'Native Subdivision': [
            [
                459,
                3,
            ],
            [
                455,
                26,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                2,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                4,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                10,
            ],
            [
                0,
                5,
            ],
            [
                457,
                4,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                3,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                6,
            ],
            [
                459,
                3,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                450,
                6,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                2,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                3,
            ],
            [
                450,
                1,
            ],
            [
                459,
                3,
            ],
            [
                455,
                4,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                459,
                12,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                457,
                25,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                4,
            ],
            [
                0,
                1,
            ],
            [
                459,
                4,
            ],
            [
                305,
                3,
            ],
            [
                415,
                3,
            ],
            [
                444,
                3,
            ],
            [
                450,
                1,
            ],
            [
                459,
                3,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                433,
                1,
            ],
            [
                435,
                1,
            ],
            [
                447,
                1,
            ],
            [
                429,
                1,
            ],
            [
                433,
                1,
            ],
            [
                459,
                10,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                457,
                5,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                450,
                3,
            ],
            [
                459,
                1,
            ],
            [
                451,
                1,
            ],
            [
                455,
                1,
            ],
            [
                459,
                11,
            ],
        ],
        'Neighborhood': [
            [
                16413,
                1,
            ],
            [
                16412,
                1,
            ],
            [
                16415,
                1,
            ],
            [
                16322,
                26,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                2,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16428,
                3,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16428,
                3,
            ],
            [
                16440,
                7,
            ],
            [
                0,
                5,
            ],
            [
                16415,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16415,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16415,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16407,
                3,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16423,
                1,
            ],
            [
                0,
                6,
            ],
            [
                16440,
                3,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16386,
                6,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16390,
                1,
            ],
            [
                16439,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16424,
                3,
            ],
            [
                16386,
                1,
            ],
            [
                16428,
                3,
            ],
            [
                16322,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16417,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16417,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16417,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16440,
                12,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16410,
                25,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16440,
                2,
            ],
            [
                16424,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                13111,
                3,
            ],
            [
                14731,
                3,
            ],
            [
                16120,
                3,
            ],
            [
                16386,
                1,
            ],
            [
                16440,
                3,
            ],
            [
                0,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                15710,
                1,
            ],
            [
                16261,
                1,
            ],
            [
                16426,
                1,
            ],
            [
                15705,
                1,
            ],
            [
                16253,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                16423,
                5,
            ],
            [
                16440,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                16440,
                1,
            ],
            [
                16422,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16410,
                5,
            ],
            [
                16424,
                1,
            ],
            [
                15851,
                1,
            ],
            [
                16302,
                1,
            ],
            [
                16390,
                3,
            ],
            [
                16440,
                1,
            ],
            [
                15870,
                1,
            ],
            [
                16322,
                1,
            ],
            [
                16440,
                5,
            ],
            [
                16403,
                6,
            ],
        ],
        'School District': [
            [
                13323,
                1,
            ],
            [
                13322,
                2,
            ],
            [
                13326,
                26,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                2,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                4,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                10,
            ],
            [
                0,
                5,
            ],
            [
                13328,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13328,
                3,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13331,
                3,
            ],
            [
                13326,
                2,
            ],
            [
                13322,
                6,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                4,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13322,
                1,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13331,
                3,
            ],
            [
                13322,
                1,
            ],
            [
                13331,
                3,
            ],
            [
                13326,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13331,
                12,
            ],
            [
                13326,
                2,
            ],
            [
                13329,
                25,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13331,
                4,
            ],
            [
                12744,
                3,
            ],
            [
                13228,
                3,
            ],
            [
                13310,
                3,
            ],
            [
                13322,
                1,
            ],
            [
                13331,
                3,
            ],
            [
                0,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13272,
                1,
            ],
            [
                13290,
                1,
            ],
            [
                13318,
                1,
            ],
            [
                13269,
                1,
            ],
            [
                13286,
                1,
            ],
            [
                13331,
                1,
            ],
            [
                13330,
                5,
            ],
            [
                13331,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13331,
                1,
            ],
            [
                13330,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13329,
                5,
            ],
            [
                13328,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13322,
                3,
            ],
            [
                13331,
                1,
            ],
            [
                13326,
                2,
            ],
            [
                13331,
                5,
            ],
            [
                13328,
                6,
            ],
        ],
        'State House District': [
            [
                4834,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4834,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4834,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                4,
            ],
            [
                4825,
                3,
            ],
            [
                4832,
                3,
            ],
            [
                4834,
                7,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4834,
                25,
            ],
        ],
        'State Senate District': [
            [
                1942,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1942,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1942,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1942,
                25,
            ],
        ],
        'Subnational Region': [
            [
                51,
                3,
            ],
            [
                52,
                51,
            ],
            [
                56,
                1,
            ],
            [
                52,
                12,
            ],
            [
                56,
                1,
            ],
            [
                52,
                6,
            ],
            [
                0,
                5,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                7,
            ],
            [
                52,
                18,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                2,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                11,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                46,
            ],
            [
                0,
                1,
            ],
            [
                52,
                17,
            ],
            [
                0,
                1,
            ],
            [
                52,
                17,
            ],
            [
                51,
                1,
            ],
            [
                52,
                1,
            ],
            [
                51,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                25,
            ],
        ],
        'USDA County Type': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Urban Area': [
            [
                2612,
                3,
            ],
            [
                2638,
                27,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                3,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                5,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                11,
            ],
            [
                0,
                5,
            ],
            [
                2638,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2638,
                3,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                8,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                12,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                27,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                5,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                4,
            ],
            [
                2594,
                3,
            ],
            [
                2631,
                3,
            ],
            [
                2638,
                7,
            ],
            [
                0,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                2,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                8,
            ],
            [
                2612,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                2612,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2638,
                6,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                5,
            ],
            [
                2636,
                1,
            ],
            [
                2638,
                12,
            ],
        ],
        'Urban Center': [
            [
                321,
                3,
            ],
            [
                324,
                51,
            ],
            [
                337,
                1,
            ],
            [
                324,
                12,
            ],
            [
                337,
                1,
            ],
            [
                324,
                6,
            ],
            [
                0,
                5,
            ],
            [
                324,
                4,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                4,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                337,
                7,
            ],
            [
                324,
                18,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                4,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                2,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                11,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                46,
            ],
            [
                0,
                1,
            ],
            [
                324,
                4,
            ],
            [
                323,
                3,
            ],
            [
                324,
                10,
            ],
            [
                0,
                1,
            ],
            [
                324,
                17,
            ],
            [
                321,
                1,
            ],
            [
                324,
                1,
            ],
            [
                321,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                324,
                25,
            ],
        ],
        'ZIP': [
            [
                32834,
                1,
            ],
            [
                32833,
                1,
            ],
            [
                32834,
                1,
            ],
            [
                32919,
                26,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                2,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32964,
                3,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32964,
                3,
            ],
            [
                32967,
                7,
            ],
            [
                0,
                5,
            ],
            [
                32951,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32951,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32951,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32940,
                3,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32956,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32967,
                3,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32843,
                6,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32864,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32864,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32864,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32963,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32963,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32957,
                3,
            ],
            [
                32843,
                1,
            ],
            [
                32964,
                3,
            ],
            [
                32919,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32967,
                12,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32947,
                25,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32967,
                2,
            ],
            [
                32948,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                27919,
                3,
            ],
            [
                31605,
                3,
            ],
            [
                32626,
                3,
            ],
            [
                32843,
                1,
            ],
            [
                32967,
                3,
            ],
            [
                0,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32218,
                1,
            ],
            [
                32562,
                1,
            ],
            [
                32879,
                1,
            ],
            [
                32176,
                1,
            ],
            [
                32506,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32956,
                5,
            ],
            [
                32967,
                1,
            ],
            [
                32836,
                1,
            ],
            [
                32967,
                1,
            ],
            [
                32836,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32949,
                5,
            ],
            [
                32925,
                1,
            ],
            [
                32760,
                1,
            ],
            [
                32773,
                1,
            ],
            [
                32864,
                3,
            ],
            [
                32967,
                1,
            ],
            [
                32825,
                1,
            ],
            [
                32919,
                1,
            ],
            [
                32967,
                5,
            ],
            [
                32884,
                6,
            ],
        ],
    },
    'Uruguay': {
        'overall': [
            [
                0,
                54,
            ],
            [
                36,
                1,
            ],
            [
                0,
                12,
            ],
            [
                36,
                1,
            ],
            [
                0,
                35,
            ],
            [
                36,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                35,
            ],
            [
                19,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Uzbekistan': {
        'overall': [
            [
                0,
                54,
            ],
            [
                98,
                1,
            ],
            [
                0,
                12,
            ],
            [
                98,
                1,
            ],
            [
                0,
                35,
            ],
            [
                98,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                56,
                1,
            ],
            [
                0,
                12,
            ],
            [
                56,
                1,
            ],
            [
                0,
                35,
            ],
            [
                56,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Vatican City': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Saint Vincent and the Grenadines': {
        'overall': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Venezuela': {
        'overall': [
            [
                0,
                54,
            ],
            [
                124,
                1,
            ],
            [
                0,
                12,
            ],
            [
                124,
                1,
            ],
            [
                0,
                35,
            ],
            [
                124,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                25,
                1,
            ],
            [
                0,
                12,
            ],
            [
                25,
                1,
            ],
            [
                0,
                35,
            ],
            [
                25,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                76,
                1,
            ],
            [
                0,
                12,
            ],
            [
                76,
                1,
            ],
            [
                0,
                35,
            ],
            [
                76,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'British Virgin Islands': {
        'overall': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Vietnam': {
        'overall': [
            [
                0,
                54,
            ],
            [
                251,
                1,
            ],
            [
                0,
                12,
            ],
            [
                251,
                1,
            ],
            [
                0,
                35,
            ],
            [
                251,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                35,
            ],
            [
                14,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                35,
            ],
            [
                27,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                63,
                1,
            ],
            [
                0,
                12,
            ],
            [
                63,
                1,
            ],
            [
                0,
                35,
            ],
            [
                63,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                132,
                1,
            ],
            [
                0,
                12,
            ],
            [
                132,
                1,
            ],
            [
                0,
                35,
            ],
            [
                132,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Vanuatu': {
        'overall': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                35,
            ],
            [
                7,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                35,
            ],
            [
                6,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Samoa': {
        'overall': [
            [
                0,
                54,
            ],
            [
                12,
                1,
            ],
            [
                0,
                12,
            ],
            [
                12,
                1,
            ],
            [
                0,
                35,
            ],
            [
                12,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                35,
            ],
            [
                11,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Yemen': {
        'overall': [
            [
                0,
                54,
            ],
            [
                74,
                1,
            ],
            [
                0,
                12,
            ],
            [
                74,
                1,
            ],
            [
                0,
                35,
            ],
            [
                74,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                35,
            ],
            [
                5,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                35,
            ],
            [
                23,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                35,
            ],
            [
                28,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'South Africa': {
        'overall': [
            [
                0,
                54,
            ],
            [
                118,
                1,
            ],
            [
                0,
                12,
            ],
            [
                118,
                1,
            ],
            [
                0,
                35,
            ],
            [
                118,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                35,
            ],
            [
                15,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                77,
                1,
            ],
            [
                0,
                12,
            ],
            [
                77,
                1,
            ],
            [
                0,
                35,
            ],
            [
                77,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Zambia': {
        'overall': [
            [
                0,
                54,
            ],
            [
                76,
                1,
            ],
            [
                0,
                12,
            ],
            [
                76,
                1,
            ],
            [
                0,
                35,
            ],
            [
                76,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '500M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                9,
                1,
            ],
            [
                0,
                12,
            ],
            [
                9,
                1,
            ],
            [
                0,
                35,
            ],
            [
                9,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                39,
                1,
            ],
            [
                0,
                12,
            ],
            [
                39,
                1,
            ],
            [
                0,
                35,
            ],
            [
                39,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Zimbabwe': {
        'overall': [
            [
                0,
                54,
            ],
            [
                48,
                1,
            ],
            [
                0,
                12,
            ],
            [
                48,
                1,
            ],
            [
                0,
                35,
            ],
            [
                48,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                8,
                1,
            ],
            [
                0,
                12,
            ],
            [
                8,
                1,
            ],
            [
                0,
                35,
            ],
            [
                8,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Country': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                35,
            ],
            [
                10,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Urban Center': [
            [
                0,
                54,
            ],
            [
                20,
                1,
            ],
            [
                0,
                12,
            ],
            [
                20,
                1,
            ],
            [
                0,
                35,
            ],
            [
                20,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Alabama, USA': {
        'overall': [
            [
                2699,
                30,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                3,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                5,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                11,
            ],
            [
                0,
                5,
            ],
            [
                2697,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2695,
                3,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                5,
            ],
            [
                2699,
                3,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2695,
                6,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2699,
                3,
            ],
            [
                2695,
                1,
            ],
            [
                2699,
                7,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2699,
                12,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2697,
                25,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                5,
            ],
            [
                0,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                2698,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2158,
                3,
            ],
            [
                2553,
                3,
            ],
            [
                2676,
                3,
            ],
            [
                2695,
                1,
            ],
            [
                2699,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                2,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2676,
                1,
            ],
            [
                2690,
                1,
            ],
            [
                2695,
                1,
            ],
            [
                2674,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2699,
                1,
            ],
            [
                2697,
                5,
            ],
            [
                2699,
                4,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2697,
                5,
            ],
            [
                2699,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2696,
                1,
            ],
            [
                2697,
                3,
            ],
            [
                2699,
                1,
            ],
            [
                2691,
                1,
            ],
            [
                2699,
                6,
            ],
            [
                2696,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'CCD': [
            [
                390,
                74,
            ],
            [
                0,
                5,
            ],
            [
                390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                6,
            ],
            [
                390,
                20,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                2,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                11,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                46,
            ],
            [
                0,
                1,
            ],
            [
                390,
                4,
            ],
            [
                272,
                3,
            ],
            [
                360,
                3,
            ],
            [
                386,
                3,
            ],
            [
                390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                390,
                21,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                25,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                593,
                30,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                2,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                2,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                2,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                2,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                3,
            ],
            [
                590,
                1,
            ],
            [
                593,
                5,
            ],
            [
                590,
                1,
            ],
            [
                593,
                11,
            ],
            [
                0,
                5,
            ],
            [
                593,
                4,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                4,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                6,
            ],
            [
                593,
                3,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                592,
                6,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                2,
            ],
            [
                590,
                1,
            ],
            [
                593,
                2,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                2,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                3,
            ],
            [
                592,
                1,
            ],
            [
                593,
                7,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                12,
            ],
            [
                590,
                1,
            ],
            [
                593,
                27,
            ],
            [
                590,
                1,
            ],
            [
                593,
                5,
            ],
            [
                0,
                1,
            ],
            [
                593,
                4,
            ],
            [
                405,
                3,
            ],
            [
                541,
                3,
            ],
            [
                583,
                3,
            ],
            [
                592,
                1,
            ],
            [
                593,
                3,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                2,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                582,
                1,
            ],
            [
                590,
                1,
            ],
            [
                592,
                1,
            ],
            [
                582,
                1,
            ],
            [
                589,
                1,
            ],
            [
                593,
                11,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                6,
            ],
            [
                590,
                1,
            ],
            [
                593,
                1,
            ],
            [
                592,
                3,
            ],
            [
                593,
                1,
            ],
            [
                590,
                1,
            ],
            [
                593,
                12,
            ],
        ],
        'Congressional District': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'County': [
            [
                67,
                74,
            ],
            [
                0,
                5,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                6,
            ],
            [
                67,
                20,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                46,
            ],
            [
                0,
                1,
            ],
            [
                67,
                17,
            ],
            [
                0,
                1,
            ],
            [
                67,
                21,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                25,
            ],
        ],
        'County Cross CD': [
            [
                73,
                74,
            ],
            [
                0,
                5,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                6,
            ],
            [
                73,
                20,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                2,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                11,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                46,
            ],
            [
                0,
                1,
            ],
            [
                73,
                4,
            ],
            [
                72,
                3,
            ],
            [
                73,
                10,
            ],
            [
                0,
                1,
            ],
            [
                73,
                21,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                147,
                74,
            ],
            [
                0,
                5,
            ],
            [
                147,
                4,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                4,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                6,
            ],
            [
                147,
                20,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                4,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                2,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                11,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                46,
            ],
            [
                0,
                1,
            ],
            [
                147,
                17,
            ],
            [
                0,
                1,
            ],
            [
                147,
                21,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                147,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                77,
                74,
            ],
            [
                0,
                5,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                6,
            ],
            [
                77,
                20,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                2,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                11,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                46,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                76,
                3,
            ],
            [
                77,
                10,
            ],
            [
                0,
                1,
            ],
            [
                77,
                21,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Media Market': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Native Area': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Neighborhood': [
            [
                291,
                30,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                2,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                2,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                2,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                2,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                3,
            ],
            [
                290,
                1,
            ],
            [
                291,
                5,
            ],
            [
                290,
                1,
            ],
            [
                291,
                11,
            ],
            [
                0,
                5,
            ],
            [
                290,
                4,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                289,
                3,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                6,
            ],
            [
                291,
                3,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                6,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                2,
            ],
            [
                290,
                1,
            ],
            [
                291,
                2,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                2,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                3,
            ],
            [
                290,
                1,
            ],
            [
                291,
                7,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                12,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                25,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                5,
            ],
            [
                0,
                1,
            ],
            [
                291,
                4,
            ],
            [
                207,
                3,
            ],
            [
                261,
                3,
            ],
            [
                289,
                3,
            ],
            [
                290,
                1,
            ],
            [
                291,
                3,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                2,
            ],
            [
                290,
                1,
            ],
            [
                291,
                1,
            ],
            [
                288,
                1,
            ],
            [
                289,
                1,
            ],
            [
                290,
                1,
            ],
            [
                287,
                1,
            ],
            [
                288,
                1,
            ],
            [
                291,
                1,
            ],
            [
                290,
                5,
            ],
            [
                291,
                4,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                5,
            ],
            [
                291,
                1,
            ],
            [
                290,
                1,
            ],
            [
                291,
                5,
            ],
            [
                290,
                1,
            ],
            [
                291,
                6,
            ],
            [
                290,
                6,
            ],
        ],
        'School District': [
            [
                140,
                74,
            ],
            [
                0,
                5,
            ],
            [
                140,
                4,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                4,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                6,
            ],
            [
                140,
                20,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                4,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                2,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                11,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                46,
            ],
            [
                0,
                1,
            ],
            [
                140,
                4,
            ],
            [
                137,
                3,
            ],
            [
                140,
                10,
            ],
            [
                0,
                1,
            ],
            [
                140,
                21,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                1,
            ],
            [
                0,
                1,
            ],
            [
                140,
                25,
            ],
        ],
        'State House District': [
            [
                105,
                74,
            ],
            [
                0,
                5,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                6,
            ],
            [
                105,
                20,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                2,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                11,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                46,
            ],
            [
                0,
                1,
            ],
            [
                105,
                17,
            ],
            [
                0,
                1,
            ],
            [
                105,
                21,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                25,
            ],
        ],
        'State Senate District': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                51,
                74,
            ],
            [
                0,
                5,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                6,
            ],
            [
                51,
                20,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                2,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                11,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                46,
            ],
            [
                0,
                1,
            ],
            [
                51,
                17,
            ],
            [
                0,
                1,
            ],
            [
                51,
                21,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                25,
            ],
        ],
        'Urban Center': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'ZIP': [
            [
                641,
                30,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                2,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                2,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                2,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                2,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                3,
            ],
            [
                637,
                1,
            ],
            [
                641,
                5,
            ],
            [
                637,
                1,
            ],
            [
                641,
                11,
            ],
            [
                0,
                5,
            ],
            [
                640,
                4,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                3,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                6,
            ],
            [
                641,
                3,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                639,
                6,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                2,
            ],
            [
                637,
                1,
            ],
            [
                641,
                2,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                0,
                1,
            ],
            [
                641,
                1,
            ],
            [
                0,
                1,
            ],
            [
                641,
                1,
            ],
            [
                0,
                1,
            ],
            [
                641,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                641,
                3,
            ],
            [
                639,
                1,
            ],
            [
                641,
                7,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                641,
                12,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                640,
                25,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                5,
            ],
            [
                0,
                1,
            ],
            [
                641,
                2,
            ],
            [
                640,
                1,
            ],
            [
                641,
                1,
            ],
            [
                495,
                3,
            ],
            [
                607,
                3,
            ],
            [
                634,
                3,
            ],
            [
                639,
                1,
            ],
            [
                641,
                3,
            ],
            [
                0,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                2,
            ],
            [
                637,
                1,
            ],
            [
                641,
                1,
            ],
            [
                632,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                631,
                1,
            ],
            [
                636,
                1,
            ],
            [
                641,
                1,
            ],
            [
                640,
                5,
            ],
            [
                641,
                4,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                1,
            ],
            [
                0,
                1,
            ],
            [
                640,
                5,
            ],
            [
                641,
                1,
            ],
            [
                633,
                1,
            ],
            [
                638,
                1,
            ],
            [
                640,
                3,
            ],
            [
                641,
                1,
            ],
            [
                637,
                1,
            ],
            [
                641,
                6,
            ],
            [
                639,
                6,
            ],
        ],
    },
    'Alaska, USA': {
        'overall': [
            [
                1101,
                1,
            ],
            [
                1095,
                2,
            ],
            [
                1090,
                26,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                2,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                4,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1099,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1096,
                3,
            ],
            [
                1101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                5,
            ],
            [
                1101,
                3,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1078,
                6,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1098,
                3,
            ],
            [
                1078,
                1,
            ],
            [
                1101,
                3,
            ],
            [
                1090,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1101,
                12,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1099,
                25,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1101,
                4,
            ],
            [
                1017,
                3,
            ],
            [
                1024,
                3,
            ],
            [
                1070,
                3,
            ],
            [
                1078,
                1,
            ],
            [
                1101,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1064,
                1,
            ],
            [
                1071,
                1,
            ],
            [
                1082,
                1,
            ],
            [
                1054,
                1,
            ],
            [
                1066,
                1,
            ],
            [
                1101,
                10,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1099,
                5,
            ],
            [
                1098,
                1,
            ],
            [
                1082,
                1,
            ],
            [
                1084,
                1,
            ],
            [
                1078,
                3,
            ],
            [
                1101,
                1,
            ],
            [
                1086,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                1101,
                5,
            ],
            [
                1095,
                6,
            ],
        ],
        'CCD': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                3,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                3,
            ],
            [
                37,
                8,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                36,
                9,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                25,
            ],
        ],
        'City': [
            [
                350,
                1,
            ],
            [
                348,
                2,
            ],
            [
                349,
                26,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                2,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                4,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                10,
            ],
            [
                0,
                5,
            ],
            [
                350,
                4,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                3,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                6,
            ],
            [
                350,
                3,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                338,
                6,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                2,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                3,
            ],
            [
                338,
                1,
            ],
            [
                350,
                3,
            ],
            [
                349,
                4,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                12,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                26,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                4,
            ],
            [
                0,
                1,
            ],
            [
                350,
                4,
            ],
            [
                309,
                3,
            ],
            [
                307,
                3,
            ],
            [
                332,
                3,
            ],
            [
                338,
                1,
            ],
            [
                350,
                3,
            ],
            [
                0,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                337,
                1,
            ],
            [
                338,
                1,
            ],
            [
                344,
                1,
            ],
            [
                332,
                1,
            ],
            [
                336,
                1,
            ],
            [
                350,
                11,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                350,
                5,
            ],
            [
                348,
                1,
            ],
            [
                345,
                1,
            ],
            [
                346,
                1,
            ],
            [
                338,
                3,
            ],
            [
                350,
                1,
            ],
            [
                348,
                1,
            ],
            [
                349,
                1,
            ],
            [
                350,
                5,
            ],
            [
                347,
                6,
            ],
        ],
        'Congressional District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                17,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'County Cross CD': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                17,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                17,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Media Market': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                217,
                1,
            ],
            [
                214,
                2,
            ],
            [
                207,
                26,
            ],
            [
                217,
                1,
            ],
            [
                207,
                4,
            ],
            [
                217,
                1,
            ],
            [
                207,
                4,
            ],
            [
                217,
                1,
            ],
            [
                207,
                4,
            ],
            [
                217,
                1,
            ],
            [
                207,
                4,
            ],
            [
                217,
                1,
            ],
            [
                207,
                4,
            ],
            [
                217,
                2,
            ],
            [
                207,
                2,
            ],
            [
                217,
                4,
            ],
            [
                207,
                2,
            ],
            [
                217,
                10,
            ],
            [
                0,
                5,
            ],
            [
                215,
                4,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                3,
            ],
            [
                217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                217,
                1,
            ],
            [
                0,
                6,
            ],
            [
                217,
                3,
            ],
            [
                207,
                2,
            ],
            [
                210,
                6,
            ],
            [
                217,
                1,
            ],
            [
                207,
                4,
            ],
            [
                217,
                1,
            ],
            [
                207,
                2,
            ],
            [
                217,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                217,
                1,
            ],
            [
                207,
                2,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                2,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                217,
                3,
            ],
            [
                210,
                1,
            ],
            [
                217,
                3,
            ],
            [
                207,
                4,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                217,
                12,
            ],
            [
                207,
                2,
            ],
            [
                215,
                25,
            ],
            [
                217,
                1,
            ],
            [
                207,
                2,
            ],
            [
                217,
                4,
            ],
            [
                0,
                1,
            ],
            [
                217,
                4,
            ],
            [
                199,
                3,
            ],
            [
                202,
                3,
            ],
            [
                210,
                4,
            ],
            [
                217,
                3,
            ],
            [
                0,
                1,
            ],
            [
                207,
                2,
            ],
            [
                217,
                1,
            ],
            [
                207,
                2,
            ],
            [
                199,
                1,
            ],
            [
                202,
                1,
            ],
            [
                207,
                1,
            ],
            [
                197,
                1,
            ],
            [
                201,
                1,
            ],
            [
                217,
                10,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                5,
            ],
            [
                217,
                1,
            ],
            [
                207,
                2,
            ],
            [
                210,
                3,
            ],
            [
                217,
                1,
            ],
            [
                207,
                2,
            ],
            [
                217,
                11,
            ],
        ],
        'Neighborhood': [
            [
                38,
                74,
            ],
            [
                0,
                5,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                6,
            ],
            [
                38,
                20,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                2,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                11,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                46,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                35,
                6,
            ],
            [
                38,
                7,
            ],
            [
                0,
                1,
            ],
            [
                38,
                6,
            ],
            [
                37,
                1,
            ],
            [
                38,
                2,
            ],
            [
                37,
                1,
            ],
            [
                38,
                11,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                25,
            ],
        ],
        'School District': [
            [
                53,
                74,
            ],
            [
                0,
                5,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                6,
            ],
            [
                53,
                20,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                2,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                11,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                46,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                51,
                3,
            ],
            [
                53,
                10,
            ],
            [
                0,
                1,
            ],
            [
                53,
                21,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                25,
            ],
        ],
        'State House District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'State Senate District': [
            [
                20,
                74,
            ],
            [
                0,
                5,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                6,
            ],
            [
                20,
                20,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                2,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                11,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                46,
            ],
            [
                0,
                1,
            ],
            [
                20,
                17,
            ],
            [
                0,
                1,
            ],
            [
                20,
                21,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Urban Area': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                237,
                1,
            ],
            [
                236,
                2,
            ],
            [
                237,
                27,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                2,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                2,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                2,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                2,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                3,
            ],
            [
                234,
                1,
            ],
            [
                237,
                5,
            ],
            [
                234,
                1,
            ],
            [
                237,
                11,
            ],
            [
                0,
                5,
            ],
            [
                237,
                4,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                236,
                3,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                6,
            ],
            [
                237,
                3,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                233,
                6,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                2,
            ],
            [
                234,
                1,
            ],
            [
                237,
                2,
            ],
            [
                0,
                1,
            ],
            [
                233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                233,
                1,
            ],
            [
                0,
                1,
            ],
            [
                233,
                1,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                2,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                236,
                3,
            ],
            [
                233,
                1,
            ],
            [
                237,
                7,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                12,
            ],
            [
                234,
                1,
            ],
            [
                237,
                27,
            ],
            [
                234,
                1,
            ],
            [
                237,
                5,
            ],
            [
                0,
                1,
            ],
            [
                237,
                4,
            ],
            [
                218,
                3,
            ],
            [
                222,
                3,
            ],
            [
                232,
                3,
            ],
            [
                233,
                1,
            ],
            [
                237,
                3,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                2,
            ],
            [
                234,
                1,
            ],
            [
                237,
                1,
            ],
            [
                231,
                1,
            ],
            [
                235,
                1,
            ],
            [
                234,
                1,
            ],
            [
                228,
                1,
            ],
            [
                233,
                1,
            ],
            [
                237,
                11,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                5,
            ],
            [
                236,
                1,
            ],
            [
                233,
                1,
            ],
            [
                234,
                1,
            ],
            [
                233,
                3,
            ],
            [
                237,
                1,
            ],
            [
                234,
                1,
            ],
            [
                237,
                6,
            ],
            [
                234,
                6,
            ],
        ],
    },
    'Arizona, USA': {
        'overall': [
            [
                2388,
                3,
            ],
            [
                2378,
                26,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2394,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                4,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                3,
            ],
            [
                2394,
                1,
            ],
            [
                2388,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2385,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                3,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                5,
            ],
            [
                2394,
                2,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2370,
                6,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2372,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                3,
            ],
            [
                2370,
                1,
            ],
            [
                2388,
                3,
            ],
            [
                2378,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                12,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2385,
                25,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                4,
            ],
            [
                1696,
                3,
            ],
            [
                2049,
                3,
            ],
            [
                2321,
                3,
            ],
            [
                2370,
                1,
            ],
            [
                2388,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2229,
                1,
            ],
            [
                2348,
                1,
            ],
            [
                2367,
                1,
            ],
            [
                2219,
                1,
            ],
            [
                2338,
                1,
            ],
            [
                2388,
                10,
            ],
            [
                2384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2384,
                5,
            ],
            [
                2387,
                1,
            ],
            [
                2296,
                1,
            ],
            [
                2377,
                1,
            ],
            [
                2372,
                3,
            ],
            [
                2388,
                1,
            ],
            [
                2297,
                1,
            ],
            [
                2378,
                1,
            ],
            [
                2388,
                5,
            ],
            [
                2385,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                54,
            ],
            [
                4,
                1,
            ],
            [
                2,
                12,
            ],
            [
                4,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                80,
                74,
            ],
            [
                0,
                5,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                6,
            ],
            [
                80,
                20,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                2,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                11,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                46,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                67,
                3,
            ],
            [
                76,
                3,
            ],
            [
                79,
                3,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                5,
            ],
            [
                79,
                1,
            ],
            [
                80,
                2,
            ],
            [
                79,
                1,
            ],
            [
                80,
                12,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                25,
            ],
        ],
        'CSA': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'City': [
            [
                465,
                30,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                2,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                2,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                2,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                2,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                3,
            ],
            [
                457,
                1,
            ],
            [
                465,
                5,
            ],
            [
                457,
                1,
            ],
            [
                465,
                11,
            ],
            [
                0,
                5,
            ],
            [
                465,
                4,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                4,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                6,
            ],
            [
                465,
                3,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                462,
                6,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                2,
            ],
            [
                457,
                1,
            ],
            [
                465,
                2,
            ],
            [
                0,
                1,
            ],
            [
                462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                462,
                1,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                2,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                3,
            ],
            [
                462,
                1,
            ],
            [
                465,
                7,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                12,
            ],
            [
                457,
                1,
            ],
            [
                465,
                27,
            ],
            [
                457,
                1,
            ],
            [
                465,
                5,
            ],
            [
                0,
                1,
            ],
            [
                465,
                4,
            ],
            [
                269,
                3,
            ],
            [
                398,
                3,
            ],
            [
                450,
                3,
            ],
            [
                462,
                1,
            ],
            [
                465,
                3,
            ],
            [
                0,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                2,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                429,
                1,
            ],
            [
                456,
                1,
            ],
            [
                457,
                1,
            ],
            [
                423,
                1,
            ],
            [
                450,
                1,
            ],
            [
                465,
                10,
            ],
            [
                464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                464,
                5,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                1,
            ],
            [
                462,
                3,
            ],
            [
                465,
                1,
            ],
            [
                457,
                1,
            ],
            [
                465,
                12,
            ],
        ],
        'Congressional District': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'County': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'County Cross CD': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                27,
                3,
            ],
            [
                30,
                10,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                51,
                74,
            ],
            [
                0,
                5,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                6,
            ],
            [
                51,
                20,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                2,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                11,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                46,
            ],
            [
                0,
                1,
            ],
            [
                51,
                17,
            ],
            [
                0,
                1,
            ],
            [
                51,
                21,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                11,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                17,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Media Market': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Native Area': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                15,
                3,
            ],
            [
                18,
                3,
            ],
            [
                19,
                7,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                121,
                3,
            ],
            [
                119,
                26,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                2,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                4,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                10,
            ],
            [
                0,
                5,
            ],
            [
                119,
                4,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                3,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                6,
            ],
            [
                121,
                3,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                114,
                6,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                114,
                1,
            ],
            [
                0,
                1,
            ],
            [
                114,
                1,
            ],
            [
                0,
                1,
            ],
            [
                114,
                1,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                2,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                3,
            ],
            [
                114,
                1,
            ],
            [
                121,
                3,
            ],
            [
                119,
                4,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                12,
            ],
            [
                120,
                1,
            ],
            [
                119,
                26,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                4,
            ],
            [
                0,
                1,
            ],
            [
                121,
                4,
            ],
            [
                78,
                3,
            ],
            [
                102,
                3,
            ],
            [
                114,
                4,
            ],
            [
                121,
                3,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                103,
                1,
            ],
            [
                101,
                1,
            ],
            [
                110,
                1,
            ],
            [
                99,
                2,
            ],
            [
                121,
                10,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                5,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                114,
                3,
            ],
            [
                121,
                1,
            ],
            [
                120,
                1,
            ],
            [
                119,
                1,
            ],
            [
                121,
                11,
            ],
        ],
        'Neighborhood': [
            [
                782,
                3,
            ],
            [
                776,
                26,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                2,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                4,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                10,
            ],
            [
                0,
                5,
            ],
            [
                781,
                4,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                3,
            ],
            [
                782,
                1,
            ],
            [
                0,
                1,
            ],
            [
                782,
                1,
            ],
            [
                0,
                1,
            ],
            [
                782,
                1,
            ],
            [
                0,
                1,
            ],
            [
                782,
                1,
            ],
            [
                0,
                1,
            ],
            [
                782,
                1,
            ],
            [
                0,
                1,
            ],
            [
                782,
                1,
            ],
            [
                0,
                6,
            ],
            [
                782,
                3,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                780,
                6,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                1,
            ],
            [
                0,
                1,
            ],
            [
                780,
                1,
            ],
            [
                0,
                1,
            ],
            [
                780,
                1,
            ],
            [
                0,
                1,
            ],
            [
                780,
                1,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                2,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                782,
                3,
            ],
            [
                780,
                1,
            ],
            [
                782,
                3,
            ],
            [
                776,
                4,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                782,
                12,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                781,
                25,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                4,
            ],
            [
                0,
                1,
            ],
            [
                782,
                4,
            ],
            [
                464,
                3,
            ],
            [
                566,
                3,
            ],
            [
                750,
                3,
            ],
            [
                780,
                1,
            ],
            [
                782,
                3,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                687,
                1,
            ],
            [
                774,
                1,
            ],
            [
                781,
                1,
            ],
            [
                687,
                1,
            ],
            [
                773,
                1,
            ],
            [
                782,
                10,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                1,
            ],
            [
                0,
                1,
            ],
            [
                781,
                5,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                780,
                3,
            ],
            [
                782,
                1,
            ],
            [
                706,
                1,
            ],
            [
                776,
                1,
            ],
            [
                782,
                11,
            ],
        ],
        'School District': [
            [
                216,
                74,
            ],
            [
                0,
                5,
            ],
            [
                216,
                4,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                4,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                6,
            ],
            [
                216,
                20,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                4,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                2,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                11,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                46,
            ],
            [
                0,
                1,
            ],
            [
                216,
                4,
            ],
            [
                189,
                3,
            ],
            [
                212,
                3,
            ],
            [
                216,
                7,
            ],
            [
                0,
                1,
            ],
            [
                216,
                21,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                1,
            ],
            [
                0,
                1,
            ],
            [
                216,
                25,
            ],
        ],
        'State House District': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                17,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'State Senate District': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                17,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Urban Area': [
            [
                56,
                30,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                3,
            ],
            [
                55,
                1,
            ],
            [
                56,
                5,
            ],
            [
                55,
                1,
            ],
            [
                56,
                11,
            ],
            [
                0,
                5,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                6,
            ],
            [
                56,
                3,
            ],
            [
                55,
                1,
            ],
            [
                56,
                8,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                2,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                11,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                12,
            ],
            [
                55,
                1,
            ],
            [
                56,
                27,
            ],
            [
                55,
                1,
            ],
            [
                56,
                5,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                47,
                3,
            ],
            [
                54,
                3,
            ],
            [
                56,
                7,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                1,
            ],
            [
                55,
                1,
            ],
            [
                56,
                2,
            ],
            [
                55,
                1,
            ],
            [
                56,
                12,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                6,
            ],
            [
                55,
                1,
            ],
            [
                56,
                5,
            ],
            [
                55,
                1,
            ],
            [
                56,
                12,
            ],
        ],
        'Urban Center': [
            [
                5,
                54,
            ],
            [
                8,
                1,
            ],
            [
                5,
                12,
            ],
            [
                8,
                1,
            ],
            [
                5,
                6,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                7,
            ],
            [
                5,
                18,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                4,
                3,
            ],
            [
                5,
                10,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                407,
                3,
            ],
            [
                405,
                26,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                2,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                4,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                10,
            ],
            [
                0,
                5,
            ],
            [
                407,
                4,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                4,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                6,
            ],
            [
                407,
                3,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                401,
                6,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                403,
                1,
            ],
            [
                0,
                1,
            ],
            [
                403,
                1,
            ],
            [
                0,
                1,
            ],
            [
                403,
                1,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                2,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                3,
            ],
            [
                401,
                1,
            ],
            [
                407,
                3,
            ],
            [
                405,
                4,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                12,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                26,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                4,
            ],
            [
                0,
                1,
            ],
            [
                407,
                4,
            ],
            [
                329,
                3,
            ],
            [
                381,
                3,
            ],
            [
                395,
                3,
            ],
            [
                401,
                1,
            ],
            [
                407,
                3,
            ],
            [
                0,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                399,
                1,
            ],
            [
                404,
                1,
            ],
            [
                406,
                1,
            ],
            [
                399,
                1,
            ],
            [
                403,
                1,
            ],
            [
                407,
                11,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                1,
            ],
            [
                0,
                1,
            ],
            [
                407,
                5,
            ],
            [
                406,
                1,
            ],
            [
                401,
                1,
            ],
            [
                404,
                1,
            ],
            [
                403,
                3,
            ],
            [
                407,
                1,
            ],
            [
                402,
                1,
            ],
            [
                405,
                1,
            ],
            [
                407,
                5,
            ],
            [
                404,
                6,
            ],
        ],
    },
    'Arkansas, USA': {
        'overall': [
            [
                3258,
                3,
            ],
            [
                3253,
                26,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                2,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                4,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                10,
            ],
            [
                0,
                5,
            ],
            [
                3257,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3257,
                3,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                5,
            ],
            [
                3258,
                3,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3255,
                6,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3256,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                3,
            ],
            [
                3255,
                1,
            ],
            [
                3258,
                3,
            ],
            [
                3253,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                12,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                26,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                2,
            ],
            [
                3257,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                2446,
                3,
            ],
            [
                3053,
                3,
            ],
            [
                3216,
                3,
            ],
            [
                3255,
                1,
            ],
            [
                3258,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3180,
                1,
            ],
            [
                3216,
                1,
            ],
            [
                3256,
                1,
            ],
            [
                3180,
                1,
            ],
            [
                3215,
                1,
            ],
            [
                3258,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3258,
                5,
            ],
            [
                3257,
                1,
            ],
            [
                3245,
                1,
            ],
            [
                3249,
                1,
            ],
            [
                3256,
                3,
            ],
            [
                3258,
                1,
            ],
            [
                3246,
                1,
            ],
            [
                3253,
                1,
            ],
            [
                3258,
                5,
            ],
            [
                3255,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'CCD': [
            [
                1172,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1172,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1172,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                4,
            ],
            [
                729,
                3,
            ],
            [
                1046,
                3,
            ],
            [
                1147,
                3,
            ],
            [
                1172,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                5,
            ],
            [
                1150,
                1,
            ],
            [
                1163,
                1,
            ],
            [
                1171,
                1,
            ],
            [
                1150,
                1,
            ],
            [
                1163,
                1,
            ],
            [
                1172,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1172,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                625,
                3,
            ],
            [
                622,
                26,
            ],
            [
                625,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                2,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                4,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                10,
            ],
            [
                0,
                5,
            ],
            [
                625,
                4,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                4,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                6,
            ],
            [
                625,
                3,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                7,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                2,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                2,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                7,
            ],
            [
                622,
                4,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                12,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                26,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                4,
            ],
            [
                0,
                1,
            ],
            [
                625,
                4,
            ],
            [
                427,
                3,
            ],
            [
                581,
                3,
            ],
            [
                617,
                3,
            ],
            [
                625,
                4,
            ],
            [
                0,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                1,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                583,
                1,
            ],
            [
                599,
                1,
            ],
            [
                624,
                1,
            ],
            [
                583,
                1,
            ],
            [
                598,
                1,
            ],
            [
                625,
                11,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                1,
            ],
            [
                0,
                1,
            ],
            [
                625,
                6,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                4,
            ],
            [
                618,
                1,
            ],
            [
                622,
                1,
            ],
            [
                625,
                11,
            ],
        ],
        'Congressional District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'County': [
            [
                75,
                74,
            ],
            [
                0,
                5,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                6,
            ],
            [
                75,
                20,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                2,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                11,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                46,
            ],
            [
                0,
                1,
            ],
            [
                75,
                17,
            ],
            [
                0,
                1,
            ],
            [
                75,
                21,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                25,
            ],
        ],
        'County Cross CD': [
            [
                78,
                74,
            ],
            [
                0,
                5,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                6,
            ],
            [
                78,
                20,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                2,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                11,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                46,
            ],
            [
                0,
                1,
            ],
            [
                78,
                17,
            ],
            [
                0,
                1,
            ],
            [
                78,
                21,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                79,
                74,
            ],
            [
                0,
                5,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                6,
            ],
            [
                79,
                20,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                2,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                11,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                46,
            ],
            [
                0,
                1,
            ],
            [
                79,
                17,
            ],
            [
                0,
                1,
            ],
            [
                79,
                21,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                70,
                74,
            ],
            [
                0,
                5,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                6,
            ],
            [
                70,
                20,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                2,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                11,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                46,
            ],
            [
                0,
                1,
            ],
            [
                70,
                17,
            ],
            [
                0,
                1,
            ],
            [
                70,
                21,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                23,
                74,
            ],
            [
                0,
                5,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                20,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                2,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                11,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                46,
            ],
            [
                0,
                1,
            ],
            [
                23,
                17,
            ],
            [
                0,
                1,
            ],
            [
                23,
                21,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Neighborhood': [
            [
                87,
                3,
            ],
            [
                86,
                26,
            ],
            [
                87,
                1,
            ],
            [
                86,
                4,
            ],
            [
                87,
                1,
            ],
            [
                86,
                4,
            ],
            [
                87,
                1,
            ],
            [
                86,
                4,
            ],
            [
                87,
                1,
            ],
            [
                86,
                4,
            ],
            [
                87,
                1,
            ],
            [
                86,
                4,
            ],
            [
                87,
                2,
            ],
            [
                86,
                2,
            ],
            [
                87,
                4,
            ],
            [
                86,
                2,
            ],
            [
                87,
                10,
            ],
            [
                0,
                5,
            ],
            [
                87,
                4,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                4,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                6,
            ],
            [
                87,
                3,
            ],
            [
                86,
                8,
            ],
            [
                87,
                1,
            ],
            [
                86,
                4,
            ],
            [
                87,
                1,
            ],
            [
                86,
                2,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                87,
                1,
            ],
            [
                86,
                2,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                2,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                3,
            ],
            [
                86,
                1,
            ],
            [
                87,
                3,
            ],
            [
                86,
                4,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                12,
            ],
            [
                86,
                2,
            ],
            [
                87,
                26,
            ],
            [
                86,
                2,
            ],
            [
                87,
                4,
            ],
            [
                0,
                1,
            ],
            [
                87,
                2,
            ],
            [
                86,
                1,
            ],
            [
                87,
                1,
            ],
            [
                70,
                3,
            ],
            [
                83,
                3,
            ],
            [
                84,
                3,
            ],
            [
                86,
                1,
            ],
            [
                87,
                3,
            ],
            [
                0,
                1,
            ],
            [
                86,
                2,
            ],
            [
                87,
                1,
            ],
            [
                86,
                2,
            ],
            [
                85,
                1,
            ],
            [
                86,
                1,
            ],
            [
                87,
                1,
            ],
            [
                85,
                1,
            ],
            [
                86,
                1,
            ],
            [
                87,
                11,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                6,
            ],
            [
                86,
                1,
            ],
            [
                85,
                1,
            ],
            [
                86,
                3,
            ],
            [
                87,
                1,
            ],
            [
                86,
                2,
            ],
            [
                87,
                5,
            ],
            [
                86,
                6,
            ],
        ],
        'School District': [
            [
                234,
                74,
            ],
            [
                0,
                5,
            ],
            [
                234,
                4,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                4,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                6,
            ],
            [
                234,
                20,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                4,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                2,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                11,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                46,
            ],
            [
                0,
                1,
            ],
            [
                234,
                4,
            ],
            [
                223,
                3,
            ],
            [
                233,
                3,
            ],
            [
                234,
                7,
            ],
            [
                0,
                1,
            ],
            [
                234,
                21,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                25,
            ],
        ],
        'State House District': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'State Senate District': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                47,
                74,
            ],
            [
                0,
                5,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                6,
            ],
            [
                47,
                20,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                2,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                11,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                46,
            ],
            [
                0,
                1,
            ],
            [
                47,
                17,
            ],
            [
                0,
                1,
            ],
            [
                47,
                21,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                25,
            ],
        ],
        'Urban Center': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'ZIP': [
            [
                595,
                3,
            ],
            [
                594,
                26,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                2,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                4,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                10,
            ],
            [
                0,
                5,
            ],
            [
                594,
                4,
            ],
            [
                0,
                1,
            ],
            [
                594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                594,
                3,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                6,
            ],
            [
                595,
                3,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                593,
                6,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                2,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                3,
            ],
            [
                593,
                1,
            ],
            [
                595,
                3,
            ],
            [
                594,
                4,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                12,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                26,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                4,
            ],
            [
                0,
                1,
            ],
            [
                595,
                4,
            ],
            [
                452,
                3,
            ],
            [
                565,
                3,
            ],
            [
                589,
                3,
            ],
            [
                593,
                1,
            ],
            [
                595,
                3,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                583,
                1,
            ],
            [
                589,
                1,
            ],
            [
                595,
                1,
            ],
            [
                583,
                1,
            ],
            [
                589,
                1,
            ],
            [
                595,
                11,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                1,
            ],
            [
                0,
                1,
            ],
            [
                595,
                5,
            ],
            [
                594,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                3,
            ],
            [
                595,
                1,
            ],
            [
                591,
                1,
            ],
            [
                594,
                1,
            ],
            [
                595,
                5,
            ],
            [
                593,
                6,
            ],
        ],
    },
    'California, USA': {
        'overall': [
            [
                8294,
                2,
            ],
            [
                8302,
                1,
            ],
            [
                8274,
                26,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8313,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                4,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                3,
            ],
            [
                8313,
                1,
            ],
            [
                8304,
                6,
            ],
            [
                0,
                5,
            ],
            [
                8299,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8296,
                3,
            ],
            [
                8304,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                5,
            ],
            [
                8313,
                2,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8275,
                6,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8276,
                1,
            ],
            [
                8303,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8300,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8300,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8300,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8301,
                3,
            ],
            [
                8275,
                1,
            ],
            [
                8304,
                3,
            ],
            [
                8274,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8300,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8300,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8300,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8304,
                12,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8298,
                25,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8304,
                2,
            ],
            [
                8300,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                7425,
                3,
            ],
            [
                7988,
                3,
            ],
            [
                8244,
                3,
            ],
            [
                8275,
                1,
            ],
            [
                8304,
                3,
            ],
            [
                0,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8136,
                1,
            ],
            [
                8244,
                1,
            ],
            [
                8294,
                1,
            ],
            [
                8134,
                1,
            ],
            [
                8240,
                1,
            ],
            [
                8304,
                10,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8298,
                5,
            ],
            [
                8296,
                1,
            ],
            [
                8172,
                1,
            ],
            [
                8262,
                1,
            ],
            [
                8276,
                3,
            ],
            [
                8304,
                1,
            ],
            [
                8178,
                1,
            ],
            [
                8274,
                1,
            ],
            [
                8304,
                5,
            ],
            [
                8292,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                54,
            ],
            [
                5,
                1,
            ],
            [
                3,
                12,
            ],
            [
                5,
                1,
            ],
            [
                3,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                7,
            ],
            [
                3,
                18,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                8,
                54,
            ],
            [
                11,
                1,
            ],
            [
                8,
                12,
            ],
            [
                11,
                1,
            ],
            [
                8,
                6,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                7,
            ],
            [
                8,
                18,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'CCD': [
            [
                404,
                74,
            ],
            [
                0,
                5,
            ],
            [
                404,
                4,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                4,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                6,
            ],
            [
                404,
                20,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                4,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                2,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                11,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                46,
            ],
            [
                0,
                1,
            ],
            [
                404,
                4,
            ],
            [
                388,
                3,
            ],
            [
                403,
                3,
            ],
            [
                404,
                7,
            ],
            [
                0,
                1,
            ],
            [
                404,
                21,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                25,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                1604,
                2,
            ],
            [
                1607,
                1,
            ],
            [
                1600,
                26,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                2,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                4,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1606,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1605,
                3,
            ],
            [
                1607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1607,
                3,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1599,
                6,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                3,
            ],
            [
                1599,
                1,
            ],
            [
                1607,
                3,
            ],
            [
                1600,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                12,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1606,
                25,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1607,
                4,
            ],
            [
                1272,
                3,
            ],
            [
                1511,
                3,
            ],
            [
                1589,
                3,
            ],
            [
                1599,
                1,
            ],
            [
                1607,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1558,
                1,
            ],
            [
                1585,
                1,
            ],
            [
                1602,
                1,
            ],
            [
                1557,
                1,
            ],
            [
                1584,
                1,
            ],
            [
                1607,
                10,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1606,
                5,
            ],
            [
                1607,
                1,
            ],
            [
                1574,
                1,
            ],
            [
                1600,
                4,
            ],
            [
                1607,
                1,
            ],
            [
                1575,
                1,
            ],
            [
                1600,
                1,
            ],
            [
                1607,
                5,
            ],
            [
                1606,
                6,
            ],
        ],
        'Congressional District': [
            [
                52,
                74,
            ],
            [
                0,
                5,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                6,
            ],
            [
                52,
                20,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                2,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                11,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                46,
            ],
            [
                0,
                1,
            ],
            [
                52,
                17,
            ],
            [
                0,
                1,
            ],
            [
                52,
                21,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                25,
            ],
        ],
        'County': [
            [
                58,
                74,
            ],
            [
                0,
                5,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                6,
            ],
            [
                58,
                20,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                2,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                11,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                46,
            ],
            [
                0,
                1,
            ],
            [
                58,
                17,
            ],
            [
                0,
                1,
            ],
            [
                58,
                21,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                25,
            ],
        ],
        'County Cross CD': [
            [
                123,
                74,
            ],
            [
                0,
                5,
            ],
            [
                123,
                4,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                4,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                6,
            ],
            [
                123,
                20,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                4,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                2,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                11,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                46,
            ],
            [
                0,
                1,
            ],
            [
                123,
                17,
            ],
            [
                0,
                1,
            ],
            [
                123,
                21,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                529,
                74,
            ],
            [
                0,
                5,
            ],
            [
                529,
                4,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                4,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                6,
            ],
            [
                529,
                20,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                4,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                2,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                11,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                46,
            ],
            [
                0,
                1,
            ],
            [
                529,
                17,
            ],
            [
                0,
                1,
            ],
            [
                529,
                21,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                1,
            ],
            [
                0,
                1,
            ],
            [
                529,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                27,
                74,
            ],
            [
                0,
                5,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                20,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                2,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                11,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                46,
            ],
            [
                0,
                1,
            ],
            [
                27,
                17,
            ],
            [
                0,
                1,
            ],
            [
                27,
                21,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                226,
                74,
            ],
            [
                0,
                5,
            ],
            [
                226,
                4,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                4,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                6,
            ],
            [
                226,
                20,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                4,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                2,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                11,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                46,
            ],
            [
                0,
                1,
            ],
            [
                226,
                4,
            ],
            [
                225,
                3,
            ],
            [
                226,
                10,
            ],
            [
                0,
                1,
            ],
            [
                226,
                21,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'MSA': [
            [
                34,
                74,
            ],
            [
                0,
                5,
            ],
            [
                34,
                4,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                4,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                6,
            ],
            [
                34,
                20,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                4,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                2,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                11,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                46,
            ],
            [
                0,
                1,
            ],
            [
                34,
                17,
            ],
            [
                0,
                1,
            ],
            [
                34,
                21,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                25,
            ],
        ],
        'Media Market': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'Native Area': [
            [
                94,
                2,
            ],
            [
                95,
                1,
            ],
            [
                91,
                26,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                2,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                4,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                10,
            ],
            [
                0,
                5,
            ],
            [
                94,
                4,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                3,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                6,
            ],
            [
                95,
                3,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                93,
                6,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                2,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                3,
            ],
            [
                93,
                1,
            ],
            [
                95,
                3,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                12,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                94,
                25,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                61,
                3,
            ],
            [
                88,
                3,
            ],
            [
                93,
                4,
            ],
            [
                95,
                3,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                87,
                1,
            ],
            [
                89,
                1,
            ],
            [
                93,
                1,
            ],
            [
                87,
                1,
            ],
            [
                88,
                1,
            ],
            [
                95,
                10,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                5,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                93,
                3,
            ],
            [
                95,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                1,
            ],
            [
                95,
                11,
            ],
        ],
        'Native Statistical Area': [
            [
                2,
                30,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                1,
                1,
            ],
            [
                2,
                2,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                1,
                1,
            ],
            [
                2,
                2,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                1,
                1,
            ],
            [
                2,
                2,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                1,
                1,
            ],
            [
                2,
                2,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                1,
                1,
            ],
            [
                2,
                3,
            ],
            [
                1,
                1,
            ],
            [
                2,
                5,
            ],
            [
                1,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                3,
            ],
            [
                1,
                1,
            ],
            [
                2,
                8,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                1,
                1,
            ],
            [
                2,
                2,
            ],
            [
                1,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                12,
            ],
            [
                1,
                1,
            ],
            [
                2,
                27,
            ],
            [
                1,
                1,
            ],
            [
                2,
                5,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                2,
                2,
            ],
            [
                1,
                1,
            ],
            [
                2,
                1,
            ],
            [
                1,
                2,
            ],
            [
                2,
                1,
            ],
            [
                1,
                2,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                6,
            ],
            [
                1,
                1,
            ],
            [
                2,
                5,
            ],
            [
                1,
                1,
            ],
            [
                2,
                12,
            ],
        ],
        'Native Subdivision': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                5,
            ],
            [
                3,
                6,
            ],
            [
                4,
                9,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                3,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                3,
            ],
            [
                3,
                1,
            ],
            [
                4,
                7,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                1,
                3,
            ],
            [
                3,
                7,
            ],
            [
                4,
                3,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                8,
            ],
            [
                3,
                3,
            ],
            [
                4,
                14,
            ],
        ],
        'Neighborhood': [
            [
                2000,
                2,
            ],
            [
                2003,
                1,
            ],
            [
                1992,
                26,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                2,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                4,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                10,
            ],
            [
                0,
                5,
            ],
            [
                2003,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2001,
                3,
            ],
            [
                2005,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2005,
                3,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1995,
                6,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1995,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1995,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1995,
                1,
            ],
            [
                2004,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2004,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2004,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2004,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2003,
                3,
            ],
            [
                1995,
                1,
            ],
            [
                2005,
                3,
            ],
            [
                1992,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2004,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2004,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2004,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2005,
                12,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2002,
                25,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2005,
                2,
            ],
            [
                2004,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                1693,
                3,
            ],
            [
                1850,
                3,
            ],
            [
                1984,
                3,
            ],
            [
                1995,
                1,
            ],
            [
                2005,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                1910,
                1,
            ],
            [
                1982,
                1,
            ],
            [
                2003,
                1,
            ],
            [
                1909,
                1,
            ],
            [
                1980,
                1,
            ],
            [
                2005,
                10,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2002,
                5,
            ],
            [
                1998,
                1,
            ],
            [
                1924,
                1,
            ],
            [
                1986,
                1,
            ],
            [
                1995,
                3,
            ],
            [
                2005,
                1,
            ],
            [
                1926,
                1,
            ],
            [
                1992,
                1,
            ],
            [
                2005,
                5,
            ],
            [
                1997,
                6,
            ],
        ],
        'School District': [
            [
                979,
                74,
            ],
            [
                0,
                5,
            ],
            [
                979,
                4,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                4,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                6,
            ],
            [
                979,
                5,
            ],
            [
                978,
                6,
            ],
            [
                979,
                9,
            ],
            [
                0,
                1,
            ],
            [
                978,
                1,
            ],
            [
                0,
                1,
            ],
            [
                978,
                1,
            ],
            [
                0,
                1,
            ],
            [
                978,
                1,
            ],
            [
                979,
                3,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                2,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                3,
            ],
            [
                978,
                1,
            ],
            [
                979,
                7,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                46,
            ],
            [
                0,
                1,
            ],
            [
                979,
                4,
            ],
            [
                942,
                3,
            ],
            [
                975,
                3,
            ],
            [
                977,
                3,
            ],
            [
                978,
                1,
            ],
            [
                979,
                3,
            ],
            [
                0,
                1,
            ],
            [
                979,
                5,
            ],
            [
                978,
                1,
            ],
            [
                979,
                2,
            ],
            [
                978,
                1,
            ],
            [
                979,
                12,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                8,
            ],
            [
                978,
                3,
            ],
            [
                979,
                14,
            ],
        ],
        'State House District': [
            [
                80,
                74,
            ],
            [
                0,
                5,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                6,
            ],
            [
                80,
                20,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                2,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                11,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                46,
            ],
            [
                0,
                1,
            ],
            [
                80,
                17,
            ],
            [
                0,
                1,
            ],
            [
                80,
                21,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                25,
            ],
        ],
        'State Senate District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Urban Area': [
            [
                190,
                30,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                3,
            ],
            [
                189,
                1,
            ],
            [
                190,
                5,
            ],
            [
                189,
                1,
            ],
            [
                190,
                11,
            ],
            [
                0,
                5,
            ],
            [
                190,
                4,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                4,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                6,
            ],
            [
                190,
                3,
            ],
            [
                189,
                1,
            ],
            [
                190,
                8,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                2,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                11,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                12,
            ],
            [
                189,
                1,
            ],
            [
                190,
                27,
            ],
            [
                189,
                1,
            ],
            [
                190,
                5,
            ],
            [
                0,
                1,
            ],
            [
                190,
                4,
            ],
            [
                186,
                3,
            ],
            [
                188,
                3,
            ],
            [
                190,
                7,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                1,
            ],
            [
                189,
                1,
            ],
            [
                190,
                2,
            ],
            [
                189,
                1,
            ],
            [
                190,
                12,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                190,
                6,
            ],
            [
                189,
                1,
            ],
            [
                190,
                5,
            ],
            [
                189,
                1,
            ],
            [
                190,
                12,
            ],
        ],
        'Urban Center': [
            [
                47,
                54,
            ],
            [
                50,
                1,
            ],
            [
                47,
                12,
            ],
            [
                50,
                1,
            ],
            [
                47,
                6,
            ],
            [
                0,
                5,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                7,
            ],
            [
                47,
                18,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                2,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                11,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                46,
            ],
            [
                0,
                1,
            ],
            [
                47,
                17,
            ],
            [
                0,
                1,
            ],
            [
                47,
                21,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                25,
            ],
        ],
        'ZIP': [
            [
                1757,
                2,
            ],
            [
                1758,
                1,
            ],
            [
                1752,
                26,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                2,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                4,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1757,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                3,
            ],
            [
                1758,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1758,
                3,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1751,
                6,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1751,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1751,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1751,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                3,
            ],
            [
                1751,
                1,
            ],
            [
                1758,
                3,
            ],
            [
                1752,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1758,
                12,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1757,
                25,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1758,
                2,
            ],
            [
                1755,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1621,
                3,
            ],
            [
                1708,
                3,
            ],
            [
                1742,
                3,
            ],
            [
                1751,
                1,
            ],
            [
                1758,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1745,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1745,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1758,
                10,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                6,
            ],
            [
                1747,
                1,
            ],
            [
                1746,
                1,
            ],
            [
                1751,
                3,
            ],
            [
                1758,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1758,
                5,
            ],
            [
                1755,
                6,
            ],
        ],
    },
    'Colorado, USA': {
        'overall': [
            [
                2390,
                3,
            ],
            [
                2385,
                26,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2391,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                4,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                3,
            ],
            [
                2391,
                1,
            ],
            [
                2390,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2389,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2389,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2389,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2389,
                3,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                5,
            ],
            [
                2391,
                2,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2387,
                6,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                3,
            ],
            [
                2387,
                1,
            ],
            [
                2390,
                3,
            ],
            [
                2385,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                12,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                26,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                4,
            ],
            [
                2028,
                3,
            ],
            [
                2227,
                3,
            ],
            [
                2358,
                3,
            ],
            [
                2387,
                1,
            ],
            [
                2390,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2299,
                1,
            ],
            [
                2370,
                1,
            ],
            [
                2388,
                1,
            ],
            [
                2298,
                1,
            ],
            [
                2369,
                1,
            ],
            [
                2390,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2390,
                5,
            ],
            [
                2389,
                1,
            ],
            [
                2349,
                1,
            ],
            [
                2383,
                1,
            ],
            [
                2388,
                3,
            ],
            [
                2390,
                1,
            ],
            [
                2350,
                1,
            ],
            [
                2385,
                1,
            ],
            [
                2390,
                5,
            ],
            [
                2388,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                209,
                74,
            ],
            [
                0,
                5,
            ],
            [
                209,
                4,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                4,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                6,
            ],
            [
                209,
                20,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                4,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                2,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                11,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                46,
            ],
            [
                0,
                1,
            ],
            [
                209,
                4,
            ],
            [
                192,
                3,
            ],
            [
                205,
                3,
            ],
            [
                209,
                7,
            ],
            [
                0,
                1,
            ],
            [
                209,
                21,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                1,
            ],
            [
                0,
                1,
            ],
            [
                209,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                480,
                30,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                476,
                1,
            ],
            [
                480,
                2,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                476,
                1,
            ],
            [
                480,
                2,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                476,
                1,
            ],
            [
                480,
                2,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                476,
                1,
            ],
            [
                480,
                2,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                476,
                1,
            ],
            [
                480,
                3,
            ],
            [
                476,
                1,
            ],
            [
                480,
                5,
            ],
            [
                476,
                1,
            ],
            [
                480,
                11,
            ],
            [
                0,
                5,
            ],
            [
                480,
                4,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                4,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                6,
            ],
            [
                480,
                3,
            ],
            [
                476,
                1,
            ],
            [
                480,
                8,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                476,
                1,
            ],
            [
                480,
                2,
            ],
            [
                476,
                1,
            ],
            [
                480,
                2,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                2,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                2,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                11,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                12,
            ],
            [
                476,
                1,
            ],
            [
                480,
                27,
            ],
            [
                476,
                1,
            ],
            [
                480,
                5,
            ],
            [
                0,
                1,
            ],
            [
                480,
                4,
            ],
            [
                382,
                3,
            ],
            [
                430,
                3,
            ],
            [
                472,
                3,
            ],
            [
                480,
                4,
            ],
            [
                0,
                1,
            ],
            [
                476,
                1,
            ],
            [
                480,
                2,
            ],
            [
                476,
                1,
            ],
            [
                480,
                1,
            ],
            [
                456,
                1,
            ],
            [
                473,
                1,
            ],
            [
                480,
                1,
            ],
            [
                456,
                1,
            ],
            [
                473,
                1,
            ],
            [
                480,
                11,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                1,
            ],
            [
                0,
                1,
            ],
            [
                480,
                6,
            ],
            [
                476,
                1,
            ],
            [
                480,
                5,
            ],
            [
                476,
                1,
            ],
            [
                480,
                12,
            ],
        ],
        'Congressional District': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'County': [
            [
                64,
                74,
            ],
            [
                0,
                5,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                6,
            ],
            [
                64,
                20,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                2,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                11,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                46,
            ],
            [
                0,
                1,
            ],
            [
                64,
                17,
            ],
            [
                0,
                1,
            ],
            [
                64,
                21,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                25,
            ],
        ],
        'County Cross CD': [
            [
                76,
                74,
            ],
            [
                0,
                5,
            ],
            [
                76,
                4,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                4,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                6,
            ],
            [
                76,
                20,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                4,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                2,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                11,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                46,
            ],
            [
                0,
                1,
            ],
            [
                76,
                4,
            ],
            [
                74,
                3,
            ],
            [
                75,
                3,
            ],
            [
                76,
                7,
            ],
            [
                0,
                1,
            ],
            [
                76,
                21,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                1,
            ],
            [
                0,
                1,
            ],
            [
                76,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                17,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                51,
                74,
            ],
            [
                0,
                5,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                6,
            ],
            [
                51,
                20,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                2,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                11,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                46,
            ],
            [
                0,
                1,
            ],
            [
                51,
                17,
            ],
            [
                0,
                1,
            ],
            [
                51,
                21,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                17,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'Media Market': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Native Area': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                1,
                3,
            ],
            [
                2,
                10,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Neighborhood': [
            [
                543,
                3,
            ],
            [
                539,
                26,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                2,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                4,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                10,
            ],
            [
                0,
                5,
            ],
            [
                542,
                4,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                3,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                6,
            ],
            [
                543,
                3,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                540,
                6,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                2,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                3,
            ],
            [
                540,
                1,
            ],
            [
                543,
                3,
            ],
            [
                539,
                4,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                12,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                26,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                4,
            ],
            [
                0,
                1,
            ],
            [
                543,
                4,
            ],
            [
                388,
                3,
            ],
            [
                461,
                3,
            ],
            [
                524,
                3,
            ],
            [
                540,
                1,
            ],
            [
                543,
                3,
            ],
            [
                0,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                493,
                1,
            ],
            [
                537,
                1,
            ],
            [
                542,
                1,
            ],
            [
                492,
                1,
            ],
            [
                537,
                1,
            ],
            [
                543,
                11,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                1,
            ],
            [
                0,
                1,
            ],
            [
                543,
                5,
            ],
            [
                542,
                1,
            ],
            [
                509,
                1,
            ],
            [
                539,
                1,
            ],
            [
                541,
                3,
            ],
            [
                543,
                1,
            ],
            [
                510,
                1,
            ],
            [
                539,
                1,
            ],
            [
                543,
                5,
            ],
            [
                541,
                6,
            ],
        ],
        'School District': [
            [
                178,
                74,
            ],
            [
                0,
                5,
            ],
            [
                178,
                4,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                4,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                6,
            ],
            [
                178,
                20,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                4,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                2,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                11,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                46,
            ],
            [
                0,
                1,
            ],
            [
                178,
                4,
            ],
            [
                173,
                3,
            ],
            [
                176,
                3,
            ],
            [
                178,
                7,
            ],
            [
                0,
                1,
            ],
            [
                178,
                21,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                1,
            ],
            [
                0,
                1,
            ],
            [
                178,
                25,
            ],
        ],
        'State House District': [
            [
                65,
                74,
            ],
            [
                0,
                5,
            ],
            [
                65,
                4,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                4,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                6,
            ],
            [
                65,
                20,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                4,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                2,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                11,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                46,
            ],
            [
                0,
                1,
            ],
            [
                65,
                17,
            ],
            [
                0,
                1,
            ],
            [
                65,
                21,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                25,
            ],
        ],
        'State Senate District': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                60,
                74,
            ],
            [
                0,
                5,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                6,
            ],
            [
                60,
                20,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                2,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                11,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                46,
            ],
            [
                0,
                1,
            ],
            [
                60,
                4,
            ],
            [
                57,
                3,
            ],
            [
                60,
                10,
            ],
            [
                0,
                1,
            ],
            [
                60,
                21,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                25,
            ],
        ],
        'Urban Center': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'ZIP': [
            [
                520,
                3,
            ],
            [
                519,
                26,
            ],
            [
                520,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                2,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                4,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                10,
            ],
            [
                0,
                5,
            ],
            [
                520,
                4,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                4,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                6,
            ],
            [
                520,
                3,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                7,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                2,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                2,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                7,
            ],
            [
                519,
                4,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                12,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                26,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                4,
            ],
            [
                0,
                1,
            ],
            [
                520,
                4,
            ],
            [
                439,
                3,
            ],
            [
                496,
                3,
            ],
            [
                515,
                3,
            ],
            [
                520,
                4,
            ],
            [
                0,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                1,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                503,
                1,
            ],
            [
                513,
                1,
            ],
            [
                519,
                1,
            ],
            [
                503,
                1,
            ],
            [
                512,
                1,
            ],
            [
                520,
                11,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                0,
                1,
            ],
            [
                520,
                6,
            ],
            [
                517,
                2,
            ],
            [
                520,
                4,
            ],
            [
                517,
                1,
            ],
            [
                519,
                1,
            ],
            [
                520,
                11,
            ],
        ],
    },
    'Connecticut, USA': {
        'overall': [
            [
                1350,
                3,
            ],
            [
                1349,
                26,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1351,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                4,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                3,
            ],
            [
                1351,
                1,
            ],
            [
                1350,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1350,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                3,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                5,
            ],
            [
                1351,
                2,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1345,
                6,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1346,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1346,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1346,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                3,
            ],
            [
                1345,
                1,
            ],
            [
                1350,
                3,
            ],
            [
                1349,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                12,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                26,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1350,
                2,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1271,
                3,
            ],
            [
                1306,
                3,
            ],
            [
                1338,
                3,
            ],
            [
                1345,
                1,
            ],
            [
                1350,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1337,
                1,
            ],
            [
                1344,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1337,
                1,
            ],
            [
                1344,
                1,
            ],
            [
                1350,
                10,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1349,
                6,
            ],
            [
                1342,
                1,
            ],
            [
                1346,
                4,
            ],
            [
                1350,
                1,
            ],
            [
                1343,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1350,
                5,
            ],
            [
                1347,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                169,
                74,
            ],
            [
                0,
                5,
            ],
            [
                169,
                4,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                4,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                6,
            ],
            [
                169,
                20,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                4,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                2,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                11,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                46,
            ],
            [
                0,
                1,
            ],
            [
                169,
                4,
            ],
            [
                162,
                3,
            ],
            [
                167,
                3,
            ],
            [
                169,
                7,
            ],
            [
                0,
                1,
            ],
            [
                169,
                21,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                25,
            ],
        ],
        'CSA': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'City': [
            [
                215,
                30,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                2,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                2,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                2,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                2,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                3,
            ],
            [
                213,
                1,
            ],
            [
                215,
                5,
            ],
            [
                213,
                1,
            ],
            [
                215,
                11,
            ],
            [
                0,
                5,
            ],
            [
                215,
                4,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                4,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                6,
            ],
            [
                215,
                3,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                6,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                2,
            ],
            [
                213,
                1,
            ],
            [
                215,
                2,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                2,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                3,
            ],
            [
                213,
                1,
            ],
            [
                215,
                7,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                12,
            ],
            [
                213,
                1,
            ],
            [
                215,
                27,
            ],
            [
                213,
                1,
            ],
            [
                215,
                5,
            ],
            [
                0,
                1,
            ],
            [
                215,
                4,
            ],
            [
                179,
                3,
            ],
            [
                192,
                3,
            ],
            [
                208,
                3,
            ],
            [
                213,
                1,
            ],
            [
                215,
                3,
            ],
            [
                0,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                2,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                210,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                1,
            ],
            [
                210,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                11,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                1,
            ],
            [
                0,
                1,
            ],
            [
                215,
                6,
            ],
            [
                213,
                1,
            ],
            [
                214,
                4,
            ],
            [
                215,
                1,
            ],
            [
                213,
                1,
            ],
            [
                215,
                6,
            ],
            [
                214,
                6,
            ],
        ],
        'Congressional District': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'County': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'County Cross CD': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                58,
                74,
            ],
            [
                0,
                5,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                6,
            ],
            [
                58,
                20,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                2,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                11,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                46,
            ],
            [
                0,
                1,
            ],
            [
                58,
                17,
            ],
            [
                0,
                1,
            ],
            [
                58,
                21,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                27,
                74,
            ],
            [
                0,
                5,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                20,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                2,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                11,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                46,
            ],
            [
                0,
                1,
            ],
            [
                27,
                17,
            ],
            [
                0,
                1,
            ],
            [
                27,
                21,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Media Market': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Native Area': [
            [
                4,
                30,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                3,
            ],
            [
                3,
                1,
            ],
            [
                4,
                5,
            ],
            [
                3,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                3,
            ],
            [
                3,
                1,
            ],
            [
                4,
                8,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                12,
            ],
            [
                3,
                1,
            ],
            [
                4,
                27,
            ],
            [
                3,
                1,
            ],
            [
                4,
                5,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                2,
                3,
            ],
            [
                4,
                10,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                5,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                6,
            ],
            [
                3,
                1,
            ],
            [
                4,
                5,
            ],
            [
                3,
                1,
            ],
            [
                4,
                12,
            ],
        ],
        'Neighborhood': [
            [
                167,
                30,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                3,
            ],
            [
                165,
                1,
            ],
            [
                167,
                5,
            ],
            [
                165,
                1,
            ],
            [
                167,
                11,
            ],
            [
                0,
                5,
            ],
            [
                167,
                4,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                3,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                6,
            ],
            [
                167,
                3,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                166,
                6,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                4,
            ],
            [
                167,
                7,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                12,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                166,
                25,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                5,
            ],
            [
                0,
                1,
            ],
            [
                167,
                2,
            ],
            [
                166,
                1,
            ],
            [
                167,
                1,
            ],
            [
                158,
                3,
            ],
            [
                160,
                3,
            ],
            [
                166,
                4,
            ],
            [
                167,
                3,
            ],
            [
                0,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                2,
            ],
            [
                165,
                1,
            ],
            [
                167,
                11,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                5,
            ],
            [
                167,
                1,
            ],
            [
                164,
                1,
            ],
            [
                166,
                4,
            ],
            [
                167,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                6,
            ],
            [
                166,
                6,
            ],
        ],
        'School District': [
            [
                166,
                74,
            ],
            [
                0,
                5,
            ],
            [
                166,
                4,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                4,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                6,
            ],
            [
                166,
                20,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                4,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                2,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                11,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                46,
            ],
            [
                0,
                1,
            ],
            [
                166,
                4,
            ],
            [
                160,
                3,
            ],
            [
                165,
                3,
            ],
            [
                166,
                7,
            ],
            [
                0,
                1,
            ],
            [
                166,
                21,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                0,
                1,
            ],
            [
                166,
                25,
            ],
        ],
        'State House District': [
            [
                151,
                74,
            ],
            [
                0,
                5,
            ],
            [
                151,
                4,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                4,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                6,
            ],
            [
                151,
                20,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                4,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                2,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                11,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                46,
            ],
            [
                0,
                1,
            ],
            [
                151,
                17,
            ],
            [
                0,
                1,
            ],
            [
                151,
                21,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                25,
            ],
        ],
        'State Senate District': [
            [
                36,
                74,
            ],
            [
                0,
                5,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36,
                20,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                2,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                11,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                46,
            ],
            [
                0,
                1,
            ],
            [
                36,
                17,
            ],
            [
                0,
                1,
            ],
            [
                36,
                21,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                17,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'Urban Center': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'ZIP': [
            [
                280,
                3,
            ],
            [
                279,
                26,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                2,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                4,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                10,
            ],
            [
                0,
                5,
            ],
            [
                280,
                4,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                4,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                6,
            ],
            [
                280,
                3,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                278,
                6,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                278,
                1,
            ],
            [
                0,
                1,
            ],
            [
                278,
                1,
            ],
            [
                0,
                1,
            ],
            [
                278,
                1,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                2,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                3,
            ],
            [
                278,
                1,
            ],
            [
                280,
                3,
            ],
            [
                279,
                4,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                12,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                26,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                4,
            ],
            [
                0,
                1,
            ],
            [
                280,
                4,
            ],
            [
                261,
                3,
            ],
            [
                269,
                3,
            ],
            [
                276,
                3,
            ],
            [
                278,
                1,
            ],
            [
                280,
                3,
            ],
            [
                0,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                275,
                1,
            ],
            [
                277,
                1,
            ],
            [
                280,
                1,
            ],
            [
                275,
                1,
            ],
            [
                277,
                1,
            ],
            [
                280,
                11,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                1,
            ],
            [
                0,
                1,
            ],
            [
                280,
                5,
            ],
            [
                279,
                1,
            ],
            [
                278,
                5,
            ],
            [
                280,
                1,
            ],
            [
                278,
                1,
            ],
            [
                279,
                1,
            ],
            [
                280,
                5,
            ],
            [
                279,
                6,
            ],
        ],
    },
    'Delaware, USA': {
        'overall': [
            [
                326,
                30,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                323,
                1,
            ],
            [
                326,
                2,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                323,
                1,
            ],
            [
                326,
                2,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                323,
                1,
            ],
            [
                326,
                2,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                323,
                1,
            ],
            [
                326,
                2,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                323,
                1,
            ],
            [
                326,
                3,
            ],
            [
                323,
                1,
            ],
            [
                326,
                5,
            ],
            [
                323,
                1,
            ],
            [
                326,
                11,
            ],
            [
                0,
                5,
            ],
            [
                326,
                4,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                4,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                5,
            ],
            [
                326,
                3,
            ],
            [
                323,
                1,
            ],
            [
                326,
                8,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                323,
                1,
            ],
            [
                326,
                2,
            ],
            [
                323,
                1,
            ],
            [
                326,
                2,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                2,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                2,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                11,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                12,
            ],
            [
                323,
                1,
            ],
            [
                326,
                27,
            ],
            [
                323,
                1,
            ],
            [
                326,
                5,
            ],
            [
                0,
                1,
            ],
            [
                326,
                4,
            ],
            [
                288,
                3,
            ],
            [
                317,
                3,
            ],
            [
                325,
                3,
            ],
            [
                326,
                4,
            ],
            [
                0,
                1,
            ],
            [
                323,
                1,
            ],
            [
                326,
                2,
            ],
            [
                323,
                1,
            ],
            [
                326,
                1,
            ],
            [
                321,
                1,
            ],
            [
                326,
                2,
            ],
            [
                321,
                1,
            ],
            [
                326,
                12,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                1,
            ],
            [
                0,
                1,
            ],
            [
                326,
                6,
            ],
            [
                322,
                1,
            ],
            [
                325,
                1,
            ],
            [
                326,
                4,
            ],
            [
                323,
                1,
            ],
            [
                326,
                12,
            ],
        ],
        'CCD': [
            [
                27,
                74,
            ],
            [
                0,
                5,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                20,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                2,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                11,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                46,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                26,
                3,
            ],
            [
                27,
                10,
            ],
            [
                0,
                1,
            ],
            [
                27,
                21,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        'CSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                79,
                74,
            ],
            [
                0,
                5,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                6,
            ],
            [
                79,
                20,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                2,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                11,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                46,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                57,
                3,
            ],
            [
                76,
                3,
            ],
            [
                78,
                3,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                5,
            ],
            [
                78,
                1,
            ],
            [
                79,
                2,
            ],
            [
                78,
                1,
            ],
            [
                79,
                12,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                25,
            ],
        ],
        'Congressional District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'County Cross CD': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Media Market': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Neighborhood': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                11,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                36,
                6,
            ],
            [
                37,
                7,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                6,
            ],
            [
                36,
                1,
            ],
            [
                37,
                18,
            ],
        ],
        'School District': [
            [
                16,
                74,
            ],
            [
                0,
                5,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                6,
            ],
            [
                16,
                20,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                2,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                11,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                46,
            ],
            [
                0,
                1,
            ],
            [
                16,
                17,
            ],
            [
                0,
                1,
            ],
            [
                16,
                21,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
        ],
        'State House District': [
            [
                41,
                74,
            ],
            [
                0,
                5,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                6,
            ],
            [
                41,
                20,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                2,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                11,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                46,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                40,
                3,
            ],
            [
                41,
                10,
            ],
            [
                0,
                1,
            ],
            [
                41,
                21,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                25,
            ],
        ],
        'State Senate District': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                11,
                3,
            ],
            [
                12,
                10,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                67,
                30,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                64,
                1,
            ],
            [
                67,
                2,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                64,
                1,
            ],
            [
                67,
                2,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                64,
                1,
            ],
            [
                67,
                2,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                64,
                1,
            ],
            [
                67,
                2,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                64,
                1,
            ],
            [
                67,
                3,
            ],
            [
                64,
                1,
            ],
            [
                67,
                5,
            ],
            [
                64,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                5,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                6,
            ],
            [
                67,
                3,
            ],
            [
                64,
                1,
            ],
            [
                67,
                8,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                64,
                1,
            ],
            [
                67,
                2,
            ],
            [
                64,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                12,
            ],
            [
                64,
                1,
            ],
            [
                67,
                27,
            ],
            [
                64,
                1,
            ],
            [
                67,
                5,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                55,
                3,
            ],
            [
                62,
                3,
            ],
            [
                67,
                7,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                67,
                2,
            ],
            [
                64,
                1,
            ],
            [
                67,
                1,
            ],
            [
                63,
                1,
            ],
            [
                67,
                2,
            ],
            [
                63,
                1,
            ],
            [
                67,
                12,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                6,
            ],
            [
                64,
                1,
            ],
            [
                66,
                1,
            ],
            [
                67,
                4,
            ],
            [
                64,
                1,
            ],
            [
                67,
                12,
            ],
        ],
    },
    'Florida, USA': {
        'overall': [
            [
                4472,
                1,
            ],
            [
                4471,
                2,
            ],
            [
                4451,
                26,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4475,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4469,
                3,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4469,
                3,
            ],
            [
                4475,
                1,
            ],
            [
                4473,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4467,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4465,
                3,
            ],
            [
                4469,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4469,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4469,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4469,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4469,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4469,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                5,
            ],
            [
                4475,
                2,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4465,
                6,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4465,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4469,
                3,
            ],
            [
                4465,
                1,
            ],
            [
                4469,
                3,
            ],
            [
                4451,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4473,
                12,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4467,
                25,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4473,
                2,
            ],
            [
                4467,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                3885,
                3,
            ],
            [
                4291,
                3,
            ],
            [
                4430,
                3,
            ],
            [
                4465,
                1,
            ],
            [
                4473,
                3,
            ],
            [
                0,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4382,
                1,
            ],
            [
                4438,
                1,
            ],
            [
                4470,
                1,
            ],
            [
                4382,
                1,
            ],
            [
                4437,
                1,
            ],
            [
                4473,
                1,
            ],
            [
                4469,
                5,
            ],
            [
                4473,
                4,
            ],
            [
                4466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4467,
                5,
            ],
            [
                4471,
                1,
            ],
            [
                4402,
                1,
            ],
            [
                4443,
                1,
            ],
            [
                4465,
                3,
            ],
            [
                4473,
                1,
            ],
            [
                4405,
                1,
            ],
            [
                4451,
                1,
            ],
            [
                4473,
                5,
            ],
            [
                4466,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                5,
                54,
            ],
            [
                6,
                1,
            ],
            [
                5,
                12,
            ],
            [
                6,
                1,
            ],
            [
                5,
                6,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                7,
            ],
            [
                5,
                18,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'CCD': [
            [
                315,
                3,
            ],
            [
                316,
                27,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                3,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                314,
                3,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                314,
                3,
            ],
            [
                316,
                7,
            ],
            [
                0,
                5,
            ],
            [
                314,
                4,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                3,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                6,
            ],
            [
                316,
                3,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                314,
                6,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                2,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                7,
            ],
            [
                316,
                4,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                316,
                12,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                314,
                25,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                5,
            ],
            [
                0,
                1,
            ],
            [
                316,
                2,
            ],
            [
                314,
                1,
            ],
            [
                316,
                1,
            ],
            [
                284,
                3,
            ],
            [
                311,
                3,
            ],
            [
                313,
                3,
            ],
            [
                314,
                1,
            ],
            [
                316,
                3,
            ],
            [
                0,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                315,
                1,
            ],
            [
                316,
                2,
            ],
            [
                314,
                5,
            ],
            [
                316,
                4,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                1,
            ],
            [
                0,
                1,
            ],
            [
                314,
                5,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                1,
            ],
            [
                314,
                3,
            ],
            [
                316,
                1,
            ],
            [
                315,
                1,
            ],
            [
                316,
                6,
            ],
            [
                314,
                6,
            ],
        ],
        'CSA': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'City': [
            [
                955,
                3,
            ],
            [
                953,
                26,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                2,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                4,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                10,
            ],
            [
                0,
                5,
            ],
            [
                955,
                4,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                954,
                3,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                6,
            ],
            [
                955,
                3,
            ],
            [
                948,
                1,
            ],
            [
                953,
                7,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                953,
                1,
            ],
            [
                0,
                1,
            ],
            [
                953,
                1,
            ],
            [
                0,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                2,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                3,
            ],
            [
                953,
                1,
            ],
            [
                955,
                3,
            ],
            [
                953,
                4,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                12,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                26,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                4,
            ],
            [
                0,
                1,
            ],
            [
                955,
                2,
            ],
            [
                954,
                1,
            ],
            [
                955,
                1,
            ],
            [
                771,
                3,
            ],
            [
                900,
                3,
            ],
            [
                939,
                3,
            ],
            [
                953,
                1,
            ],
            [
                955,
                3,
            ],
            [
                0,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                943,
                1,
            ],
            [
                949,
                1,
            ],
            [
                955,
                1,
            ],
            [
                943,
                1,
            ],
            [
                949,
                1,
            ],
            [
                955,
                10,
            ],
            [
                954,
                1,
            ],
            [
                0,
                1,
            ],
            [
                954,
                1,
            ],
            [
                0,
                1,
            ],
            [
                954,
                1,
            ],
            [
                0,
                1,
            ],
            [
                954,
                1,
            ],
            [
                0,
                1,
            ],
            [
                955,
                6,
            ],
            [
                948,
                1,
            ],
            [
                952,
                1,
            ],
            [
                953,
                3,
            ],
            [
                955,
                1,
            ],
            [
                948,
                1,
            ],
            [
                953,
                1,
            ],
            [
                955,
                5,
            ],
            [
                954,
                6,
            ],
        ],
        'Congressional District': [
            [
                28,
                74,
            ],
            [
                0,
                5,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                6,
            ],
            [
                28,
                20,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                2,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                11,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                46,
            ],
            [
                0,
                1,
            ],
            [
                28,
                17,
            ],
            [
                0,
                1,
            ],
            [
                28,
                21,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                25,
            ],
        ],
        'County': [
            [
                67,
                74,
            ],
            [
                0,
                5,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                6,
            ],
            [
                67,
                20,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                46,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                66,
                3,
            ],
            [
                67,
                10,
            ],
            [
                0,
                1,
            ],
            [
                67,
                21,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                25,
            ],
        ],
        'County Cross CD': [
            [
                96,
                74,
            ],
            [
                0,
                5,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                6,
            ],
            [
                96,
                20,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                2,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                11,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                46,
            ],
            [
                0,
                1,
            ],
            [
                96,
                4,
            ],
            [
                93,
                3,
            ],
            [
                95,
                3,
            ],
            [
                96,
                7,
            ],
            [
                0,
                1,
            ],
            [
                96,
                21,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                241,
                74,
            ],
            [
                0,
                5,
            ],
            [
                241,
                4,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                4,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                6,
            ],
            [
                241,
                20,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                4,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                2,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                11,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                46,
            ],
            [
                0,
                1,
            ],
            [
                241,
                17,
            ],
            [
                0,
                1,
            ],
            [
                241,
                21,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                17,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                116,
                74,
            ],
            [
                0,
                5,
            ],
            [
                116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                6,
            ],
            [
                116,
                20,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                2,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                11,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                46,
            ],
            [
                0,
                1,
            ],
            [
                116,
                17,
            ],
            [
                0,
                1,
            ],
            [
                116,
                21,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                29,
                74,
            ],
            [
                0,
                5,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                6,
            ],
            [
                29,
                20,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                2,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                11,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                46,
            ],
            [
                0,
                1,
            ],
            [
                29,
                17,
            ],
            [
                0,
                1,
            ],
            [
                29,
                21,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                25,
            ],
        ],
        'Media Market': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Native Area': [
            [
                6,
                30,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                3,
            ],
            [
                5,
                1,
            ],
            [
                6,
                5,
            ],
            [
                5,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                3,
            ],
            [
                5,
                1,
            ],
            [
                6,
                8,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                12,
            ],
            [
                5,
                1,
            ],
            [
                6,
                27,
            ],
            [
                5,
                1,
            ],
            [
                6,
                5,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                3,
                3,
            ],
            [
                5,
                6,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                1,
            ],
            [
                4,
                1,
            ],
            [
                6,
                2,
            ],
            [
                4,
                1,
            ],
            [
                6,
                12,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                6,
            ],
            [
                5,
                1,
            ],
            [
                6,
                5,
            ],
            [
                5,
                1,
            ],
            [
                6,
                12,
            ],
        ],
        'Neighborhood': [
            [
                1259,
                1,
            ],
            [
                1258,
                2,
            ],
            [
                1240,
                26,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                2,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1257,
                3,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1257,
                3,
            ],
            [
                1259,
                7,
            ],
            [
                0,
                5,
            ],
            [
                1255,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                3,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1259,
                3,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1257,
                6,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1257,
                7,
            ],
            [
                1240,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1259,
                12,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1255,
                25,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1259,
                2,
            ],
            [
                1257,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1014,
                3,
            ],
            [
                1162,
                3,
            ],
            [
                1244,
                3,
            ],
            [
                1257,
                1,
            ],
            [
                1259,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1198,
                1,
            ],
            [
                1233,
                1,
            ],
            [
                1256,
                1,
            ],
            [
                1198,
                1,
            ],
            [
                1232,
                1,
            ],
            [
                1259,
                1,
            ],
            [
                1257,
                5,
            ],
            [
                1259,
                4,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1255,
                5,
            ],
            [
                1258,
                1,
            ],
            [
                1209,
                1,
            ],
            [
                1238,
                1,
            ],
            [
                1257,
                3,
            ],
            [
                1259,
                1,
            ],
            [
                1211,
                1,
            ],
            [
                1240,
                1,
            ],
            [
                1259,
                5,
            ],
            [
                1257,
                6,
            ],
        ],
        'School District': [
            [
                67,
                74,
            ],
            [
                0,
                5,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                6,
            ],
            [
                67,
                20,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                46,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                66,
                3,
            ],
            [
                67,
                10,
            ],
            [
                0,
                1,
            ],
            [
                67,
                21,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                25,
            ],
        ],
        'State House District': [
            [
                120,
                74,
            ],
            [
                0,
                5,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                6,
            ],
            [
                120,
                20,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                2,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                11,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                46,
            ],
            [
                0,
                1,
            ],
            [
                120,
                17,
            ],
            [
                0,
                1,
            ],
            [
                120,
                21,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                25,
            ],
        ],
        'State Senate District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                78,
                74,
            ],
            [
                0,
                5,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                6,
            ],
            [
                78,
                20,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                2,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                11,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                46,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                74,
                3,
            ],
            [
                78,
                10,
            ],
            [
                0,
                1,
            ],
            [
                78,
                21,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                25,
            ],
        ],
        'Urban Center': [
            [
                24,
                74,
            ],
            [
                0,
                5,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                25,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                2,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                11,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                46,
            ],
            [
                0,
                1,
            ],
            [
                24,
                17,
            ],
            [
                0,
                1,
            ],
            [
                24,
                21,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                25,
            ],
        ],
        'ZIP': [
            [
                981,
                3,
            ],
            [
                980,
                26,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                2,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                4,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                10,
            ],
            [
                0,
                5,
            ],
            [
                981,
                4,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                4,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                6,
            ],
            [
                981,
                3,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                979,
                6,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                1,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                2,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                3,
            ],
            [
                979,
                1,
            ],
            [
                981,
                3,
            ],
            [
                980,
                4,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                12,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                26,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                4,
            ],
            [
                0,
                1,
            ],
            [
                981,
                2,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                866,
                3,
            ],
            [
                958,
                3,
            ],
            [
                973,
                3,
            ],
            [
                979,
                1,
            ],
            [
                981,
                3,
            ],
            [
                0,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                966,
                1,
            ],
            [
                978,
                1,
            ],
            [
                981,
                1,
            ],
            [
                966,
                1,
            ],
            [
                978,
                1,
            ],
            [
                981,
                11,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                1,
            ],
            [
                0,
                1,
            ],
            [
                981,
                5,
            ],
            [
                980,
                1,
            ],
            [
                969,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                3,
            ],
            [
                981,
                1,
            ],
            [
                970,
                1,
            ],
            [
                980,
                1,
            ],
            [
                981,
                5,
            ],
            [
                979,
                6,
            ],
        ],
    },
    'Georgia, USA': {
        'overall': [
            [
                3805,
                3,
            ],
            [
                3803,
                26,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3807,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                4,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                3,
            ],
            [
                3807,
                1,
            ],
            [
                3806,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3806,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                5,
            ],
            [
                3807,
                2,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3804,
                6,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                3,
            ],
            [
                3804,
                1,
            ],
            [
                3806,
                3,
            ],
            [
                3803,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                12,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3804,
                25,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3806,
                4,
            ],
            [
                3220,
                3,
            ],
            [
                3668,
                3,
            ],
            [
                3787,
                3,
            ],
            [
                3804,
                1,
            ],
            [
                3806,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3780,
                1,
            ],
            [
                3794,
                1,
            ],
            [
                3805,
                1,
            ],
            [
                3779,
                1,
            ],
            [
                3793,
                1,
            ],
            [
                3806,
                10,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3804,
                5,
            ],
            [
                3805,
                1,
            ],
            [
                3794,
                1,
            ],
            [
                3793,
                1,
            ],
            [
                3804,
                3,
            ],
            [
                3806,
                1,
            ],
            [
                3797,
                1,
            ],
            [
                3803,
                1,
            ],
            [
                3806,
                5,
            ],
            [
                3805,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'CCD': [
            [
                585,
                74,
            ],
            [
                0,
                5,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                6,
            ],
            [
                585,
                20,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                2,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                11,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                46,
            ],
            [
                0,
                1,
            ],
            [
                585,
                4,
            ],
            [
                418,
                3,
            ],
            [
                565,
                3,
            ],
            [
                583,
                3,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                21,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                25,
            ],
        ],
        'CSA': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'City': [
            [
                674,
                74,
            ],
            [
                0,
                5,
            ],
            [
                674,
                4,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                4,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                6,
            ],
            [
                674,
                20,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                4,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                2,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                11,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                46,
            ],
            [
                0,
                1,
            ],
            [
                674,
                4,
            ],
            [
                488,
                3,
            ],
            [
                641,
                3,
            ],
            [
                672,
                3,
            ],
            [
                674,
                4,
            ],
            [
                0,
                1,
            ],
            [
                674,
                5,
            ],
            [
                663,
                1,
            ],
            [
                668,
                1,
            ],
            [
                673,
                1,
            ],
            [
                662,
                1,
            ],
            [
                667,
                1,
            ],
            [
                674,
                11,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                1,
            ],
            [
                0,
                1,
            ],
            [
                674,
                25,
            ],
        ],
        'Congressional District': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'County': [
            [
                159,
                74,
            ],
            [
                0,
                5,
            ],
            [
                159,
                4,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                4,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                6,
            ],
            [
                159,
                20,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                4,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                2,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                11,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                46,
            ],
            [
                0,
                1,
            ],
            [
                159,
                4,
            ],
            [
                158,
                3,
            ],
            [
                159,
                10,
            ],
            [
                0,
                1,
            ],
            [
                159,
                21,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                0,
                1,
            ],
            [
                159,
                25,
            ],
        ],
        'County Cross CD': [
            [
                180,
                74,
            ],
            [
                0,
                5,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                6,
            ],
            [
                180,
                20,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                2,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                11,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                46,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                179,
                3,
            ],
            [
                180,
                10,
            ],
            [
                0,
                1,
            ],
            [
                180,
                21,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                270,
                74,
            ],
            [
                0,
                5,
            ],
            [
                270,
                4,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                4,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                6,
            ],
            [
                270,
                20,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                4,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                2,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                11,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                46,
            ],
            [
                0,
                1,
            ],
            [
                270,
                17,
            ],
            [
                0,
                1,
            ],
            [
                270,
                21,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                1,
            ],
            [
                0,
                1,
            ],
            [
                270,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                117,
                74,
            ],
            [
                0,
                5,
            ],
            [
                117,
                4,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                4,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                6,
            ],
            [
                117,
                20,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                4,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                2,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                11,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                46,
            ],
            [
                0,
                1,
            ],
            [
                117,
                17,
            ],
            [
                0,
                1,
            ],
            [
                117,
                21,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                1,
            ],
            [
                0,
                1,
            ],
            [
                117,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                39,
                74,
            ],
            [
                0,
                5,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                6,
            ],
            [
                39,
                20,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                2,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                11,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                46,
            ],
            [
                0,
                1,
            ],
            [
                39,
                17,
            ],
            [
                0,
                1,
            ],
            [
                39,
                21,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                25,
            ],
        ],
        'Media Market': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                7,
            ],
            [
                0,
                3,
            ],
            [
                1,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Neighborhood': [
            [
                481,
                3,
            ],
            [
                479,
                26,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                2,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                4,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                10,
            ],
            [
                0,
                5,
            ],
            [
                481,
                4,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                4,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                6,
            ],
            [
                481,
                3,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                7,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                2,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                7,
            ],
            [
                479,
                4,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                12,
            ],
            [
                475,
                1,
            ],
            [
                479,
                26,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                4,
            ],
            [
                0,
                1,
            ],
            [
                481,
                4,
            ],
            [
                360,
                3,
            ],
            [
                415,
                3,
            ],
            [
                470,
                3,
            ],
            [
                481,
                4,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                473,
                1,
            ],
            [
                477,
                1,
            ],
            [
                481,
                1,
            ],
            [
                473,
                1,
            ],
            [
                477,
                1,
            ],
            [
                481,
                10,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                1,
            ],
            [
                0,
                1,
            ],
            [
                479,
                5,
            ],
            [
                481,
                1,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                4,
            ],
            [
                475,
                1,
            ],
            [
                479,
                1,
            ],
            [
                481,
                11,
            ],
        ],
        'School District': [
            [
                183,
                3,
            ],
            [
                184,
                71,
            ],
            [
                0,
                5,
            ],
            [
                184,
                4,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                4,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                6,
            ],
            [
                184,
                20,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                4,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                2,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                11,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                46,
            ],
            [
                0,
                1,
            ],
            [
                184,
                4,
            ],
            [
                183,
                3,
            ],
            [
                184,
                10,
            ],
            [
                0,
                1,
            ],
            [
                184,
                21,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                1,
            ],
            [
                0,
                1,
            ],
            [
                184,
                25,
            ],
        ],
        'State House District': [
            [
                180,
                74,
            ],
            [
                0,
                5,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                6,
            ],
            [
                180,
                20,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                2,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                11,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                46,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                179,
                3,
            ],
            [
                180,
                10,
            ],
            [
                0,
                1,
            ],
            [
                180,
                21,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                25,
            ],
        ],
        'State Senate District': [
            [
                56,
                74,
            ],
            [
                0,
                5,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                6,
            ],
            [
                56,
                20,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                2,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                11,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                46,
            ],
            [
                0,
                1,
            ],
            [
                56,
                17,
            ],
            [
                0,
                1,
            ],
            [
                56,
                21,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                80,
                74,
            ],
            [
                0,
                5,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                6,
            ],
            [
                80,
                20,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                2,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                11,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                46,
            ],
            [
                0,
                1,
            ],
            [
                80,
                17,
            ],
            [
                0,
                1,
            ],
            [
                80,
                21,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                25,
            ],
        ],
        'Urban Center': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                734,
                3,
            ],
            [
                733,
                26,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                2,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                4,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                10,
            ],
            [
                0,
                5,
            ],
            [
                734,
                4,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                4,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                6,
            ],
            [
                734,
                3,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                732,
                6,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                732,
                1,
            ],
            [
                0,
                1,
            ],
            [
                732,
                1,
            ],
            [
                0,
                1,
            ],
            [
                732,
                1,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                2,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                3,
            ],
            [
                732,
                1,
            ],
            [
                734,
                3,
            ],
            [
                733,
                4,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                12,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                26,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                4,
            ],
            [
                0,
                1,
            ],
            [
                734,
                4,
            ],
            [
                626,
                3,
            ],
            [
                716,
                3,
            ],
            [
                730,
                3,
            ],
            [
                732,
                1,
            ],
            [
                734,
                3,
            ],
            [
                0,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                727,
                1,
            ],
            [
                732,
                1,
            ],
            [
                734,
                1,
            ],
            [
                727,
                1,
            ],
            [
                732,
                1,
            ],
            [
                734,
                11,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                5,
            ],
            [
                733,
                1,
            ],
            [
                728,
                1,
            ],
            [
                723,
                1,
            ],
            [
                732,
                3,
            ],
            [
                734,
                1,
            ],
            [
                731,
                1,
            ],
            [
                733,
                1,
            ],
            [
                734,
                5,
            ],
            [
                733,
                6,
            ],
        ],
    },
    'Hawaii, USA': {
        'overall': [
            [
                514,
                3,
            ],
            [
                502,
                26,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                2,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                513,
                3,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                513,
                3,
            ],
            [
                514,
                7,
            ],
            [
                0,
                5,
            ],
            [
                511,
                4,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                3,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                513,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                5,
            ],
            [
                514,
                3,
            ],
            [
                490,
                1,
            ],
            [
                502,
                7,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                0,
                1,
            ],
            [
                503,
                1,
            ],
            [
                0,
                1,
            ],
            [
                503,
                1,
            ],
            [
                0,
                1,
            ],
            [
                503,
                1,
            ],
            [
                513,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                2,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                3,
            ],
            [
                502,
                1,
            ],
            [
                513,
                3,
            ],
            [
                502,
                4,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                514,
                12,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                511,
                25,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                4,
            ],
            [
                0,
                1,
            ],
            [
                514,
                2,
            ],
            [
                513,
                1,
            ],
            [
                514,
                1,
            ],
            [
                457,
                3,
            ],
            [
                489,
                3,
            ],
            [
                500,
                3,
            ],
            [
                502,
                1,
            ],
            [
                514,
                3,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                489,
                1,
            ],
            [
                500,
                1,
            ],
            [
                511,
                1,
            ],
            [
                488,
                1,
            ],
            [
                498,
                1,
            ],
            [
                514,
                1,
            ],
            [
                513,
                5,
            ],
            [
                514,
                4,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                5,
            ],
            [
                509,
                1,
            ],
            [
                489,
                1,
            ],
            [
                496,
                1,
            ],
            [
                503,
                3,
            ],
            [
                514,
                1,
            ],
            [
                490,
                1,
            ],
            [
                502,
                1,
            ],
            [
                514,
                5,
            ],
            [
                507,
                6,
            ],
        ],
        'CCD': [
            [
                44,
                58,
            ],
            [
                43,
                3,
            ],
            [
                44,
                3,
            ],
            [
                43,
                3,
            ],
            [
                44,
                7,
            ],
            [
                0,
                5,
            ],
            [
                43,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                6,
            ],
            [
                44,
                5,
            ],
            [
                43,
                6,
            ],
            [
                44,
                9,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                44,
                3,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                2,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                7,
            ],
            [
                44,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                14,
            ],
            [
                43,
                25,
            ],
            [
                44,
                7,
            ],
            [
                0,
                1,
            ],
            [
                44,
                2,
            ],
            [
                43,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                3,
            ],
            [
                43,
                7,
            ],
            [
                44,
                3,
            ],
            [
                0,
                1,
            ],
            [
                44,
                6,
            ],
            [
                43,
                1,
            ],
            [
                44,
                2,
            ],
            [
                43,
                1,
            ],
            [
                44,
                1,
            ],
            [
                43,
                5,
            ],
            [
                44,
                4,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                5,
            ],
            [
                44,
                3,
            ],
            [
                43,
                3,
            ],
            [
                44,
                8,
            ],
            [
                43,
                6,
            ],
        ],
        'City': [
            [
                163,
                30,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                3,
            ],
            [
                159,
                1,
            ],
            [
                163,
                5,
            ],
            [
                159,
                1,
            ],
            [
                163,
                11,
            ],
            [
                0,
                5,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                6,
            ],
            [
                163,
                3,
            ],
            [
                159,
                1,
            ],
            [
                163,
                8,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                2,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                11,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                12,
            ],
            [
                159,
                1,
            ],
            [
                163,
                27,
            ],
            [
                159,
                1,
            ],
            [
                163,
                5,
            ],
            [
                0,
                1,
            ],
            [
                163,
                4,
            ],
            [
                146,
                3,
            ],
            [
                156,
                3,
            ],
            [
                162,
                3,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                1,
            ],
            [
                159,
                1,
            ],
            [
                163,
                2,
            ],
            [
                159,
                1,
            ],
            [
                163,
                12,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                6,
            ],
            [
                159,
                1,
            ],
            [
                163,
                5,
            ],
            [
                159,
                1,
            ],
            [
                163,
                12,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'County': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'County Cross CD': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Media Market': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Area': [
            [
                60,
                3,
            ],
            [
                49,
                26,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                2,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                4,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                10,
            ],
            [
                0,
                5,
            ],
            [
                58,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                3,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                6,
            ],
            [
                60,
                3,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                52,
                6,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                59,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                2,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                3,
            ],
            [
                52,
                1,
            ],
            [
                60,
                3,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                12,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                58,
                25,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                4,
            ],
            [
                31,
                3,
            ],
            [
                46,
                3,
            ],
            [
                51,
                3,
            ],
            [
                52,
                1,
            ],
            [
                60,
                3,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                41,
                1,
            ],
            [
                48,
                1,
            ],
            [
                57,
                1,
            ],
            [
                40,
                1,
            ],
            [
                46,
                1,
            ],
            [
                60,
                10,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                1,
            ],
            [
                0,
                1,
            ],
            [
                58,
                5,
            ],
            [
                57,
                1,
            ],
            [
                42,
                1,
            ],
            [
                46,
                1,
            ],
            [
                52,
                3,
            ],
            [
                60,
                1,
            ],
            [
                42,
                1,
            ],
            [
                49,
                1,
            ],
            [
                60,
                5,
            ],
            [
                57,
                6,
            ],
        ],
        'Neighborhood': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                18,
                3,
            ],
            [
                19,
                10,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'School District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'State House District': [
            [
                51,
                74,
            ],
            [
                0,
                5,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                6,
            ],
            [
                51,
                20,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                4,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                2,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                11,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                46,
            ],
            [
                0,
                1,
            ],
            [
                51,
                17,
            ],
            [
                0,
                1,
            ],
            [
                51,
                21,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                1,
            ],
            [
                0,
                1,
            ],
            [
                51,
                25,
            ],
        ],
        'State Senate District': [
            [
                25,
                74,
            ],
            [
                0,
                5,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                6,
            ],
            [
                25,
                20,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                2,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                11,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                46,
            ],
            [
                0,
                1,
            ],
            [
                25,
                17,
            ],
            [
                0,
                1,
            ],
            [
                25,
                21,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                93,
                3,
            ],
            [
                92,
                26,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                2,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                4,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                10,
            ],
            [
                0,
                5,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                6,
            ],
            [
                93,
                3,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                90,
                6,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                2,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                3,
            ],
            [
                90,
                1,
            ],
            [
                93,
                3,
            ],
            [
                92,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                12,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                26,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                85,
                3,
            ],
            [
                90,
                7,
            ],
            [
                93,
                3,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                11,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                5,
            ],
            [
                91,
                1,
            ],
            [
                90,
                1,
            ],
            [
                89,
                1,
            ],
            [
                91,
                3,
            ],
            [
                93,
                1,
            ],
            [
                91,
                1,
            ],
            [
                92,
                1,
            ],
            [
                93,
                5,
            ],
            [
                90,
                6,
            ],
        ],
    },
    'Idaho, USA': {
        'overall': [
            [
                1116,
                30,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1121,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                5,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                4,
            ],
            [
                1121,
                1,
            ],
            [
                1116,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                5,
            ],
            [
                1121,
                2,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                6,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1115,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                3,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                12,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                27,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                5,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                4,
            ],
            [
                955,
                3,
            ],
            [
                1067,
                3,
            ],
            [
                1101,
                3,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                2,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                1,
            ],
            [
                1103,
                2,
            ],
            [
                1116,
                1,
            ],
            [
                1103,
                2,
            ],
            [
                1116,
                10,
            ],
            [
                1113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1116,
                6,
            ],
            [
                1114,
                1,
            ],
            [
                1115,
                4,
            ],
            [
                1116,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1116,
                12,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CCD': [
            [
                168,
                74,
            ],
            [
                0,
                5,
            ],
            [
                168,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                6,
            ],
            [
                168,
                20,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                2,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                11,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                46,
            ],
            [
                0,
                1,
            ],
            [
                168,
                4,
            ],
            [
                132,
                3,
            ],
            [
                157,
                3,
            ],
            [
                164,
                3,
            ],
            [
                168,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                6,
            ],
            [
                167,
                1,
            ],
            [
                168,
                2,
            ],
            [
                167,
                1,
            ],
            [
                168,
                10,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                237,
                30,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                2,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                2,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                2,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                2,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                3,
            ],
            [
                236,
                1,
            ],
            [
                237,
                5,
            ],
            [
                236,
                1,
            ],
            [
                237,
                11,
            ],
            [
                0,
                5,
            ],
            [
                237,
                4,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                4,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                6,
            ],
            [
                237,
                3,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                6,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                2,
            ],
            [
                236,
                1,
            ],
            [
                237,
                2,
            ],
            [
                0,
                1,
            ],
            [
                236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                2,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                3,
            ],
            [
                236,
                1,
            ],
            [
                237,
                7,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                12,
            ],
            [
                236,
                1,
            ],
            [
                237,
                27,
            ],
            [
                236,
                1,
            ],
            [
                237,
                5,
            ],
            [
                0,
                1,
            ],
            [
                237,
                4,
            ],
            [
                181,
                3,
            ],
            [
                218,
                3,
            ],
            [
                231,
                3,
            ],
            [
                236,
                1,
            ],
            [
                237,
                3,
            ],
            [
                0,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                2,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                230,
                1,
            ],
            [
                229,
                1,
            ],
            [
                237,
                1,
            ],
            [
                230,
                1,
            ],
            [
                229,
                1,
            ],
            [
                237,
                10,
            ],
            [
                236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                236,
                1,
            ],
            [
                0,
                1,
            ],
            [
                237,
                6,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                236,
                3,
            ],
            [
                237,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                12,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'County': [
            [
                44,
                74,
            ],
            [
                0,
                5,
            ],
            [
                44,
                4,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                4,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                6,
            ],
            [
                44,
                20,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                4,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                2,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                11,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                46,
            ],
            [
                0,
                1,
            ],
            [
                44,
                4,
            ],
            [
                43,
                3,
            ],
            [
                44,
                10,
            ],
            [
                0,
                1,
            ],
            [
                44,
                21,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                44,
                25,
            ],
        ],
        'County Cross CD': [
            [
                45,
                74,
            ],
            [
                0,
                5,
            ],
            [
                45,
                4,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                4,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                6,
            ],
            [
                45,
                20,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                4,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                2,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                11,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                46,
            ],
            [
                0,
                1,
            ],
            [
                45,
                4,
            ],
            [
                44,
                3,
            ],
            [
                45,
                10,
            ],
            [
                0,
                1,
            ],
            [
                45,
                21,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                1,
            ],
            [
                0,
                1,
            ],
            [
                45,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                17,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'Media Market': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Native Area': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                4,
                3,
            ],
            [
                5,
                10,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Neighborhood': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                11,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                29,
                3,
            ],
            [
                36,
                3,
            ],
            [
                37,
                7,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                25,
            ],
        ],
        'School District': [
            [
                115,
                74,
            ],
            [
                0,
                5,
            ],
            [
                115,
                4,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                4,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                6,
            ],
            [
                115,
                20,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                4,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                2,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                11,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                46,
            ],
            [
                0,
                1,
            ],
            [
                115,
                4,
            ],
            [
                109,
                3,
            ],
            [
                115,
                10,
            ],
            [
                0,
                1,
            ],
            [
                115,
                21,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                25,
            ],
        ],
        'State House District': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'State Senate District': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                27,
                74,
            ],
            [
                0,
                5,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                20,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                2,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                11,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                46,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                25,
                3,
            ],
            [
                27,
                10,
            ],
            [
                0,
                1,
            ],
            [
                27,
                21,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        'Urban Center': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'ZIP': [
            [
                284,
                30,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                2,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                2,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                2,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                2,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                3,
            ],
            [
                283,
                1,
            ],
            [
                284,
                5,
            ],
            [
                283,
                1,
            ],
            [
                284,
                11,
            ],
            [
                0,
                5,
            ],
            [
                284,
                4,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                4,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                6,
            ],
            [
                284,
                3,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                283,
                6,
            ],
            [
                284,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                2,
            ],
            [
                283,
                1,
            ],
            [
                284,
                2,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                2,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                2,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                3,
            ],
            [
                283,
                1,
            ],
            [
                284,
                7,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                12,
            ],
            [
                283,
                1,
            ],
            [
                284,
                27,
            ],
            [
                283,
                1,
            ],
            [
                284,
                5,
            ],
            [
                0,
                1,
            ],
            [
                284,
                4,
            ],
            [
                234,
                3,
            ],
            [
                266,
                3,
            ],
            [
                279,
                3,
            ],
            [
                283,
                1,
            ],
            [
                284,
                3,
            ],
            [
                0,
                1,
            ],
            [
                283,
                1,
            ],
            [
                284,
                2,
            ],
            [
                283,
                1,
            ],
            [
                284,
                1,
            ],
            [
                278,
                1,
            ],
            [
                280,
                1,
            ],
            [
                284,
                1,
            ],
            [
                278,
                1,
            ],
            [
                280,
                1,
            ],
            [
                284,
                10,
            ],
            [
                283,
                1,
            ],
            [
                0,
                1,
            ],
            [
                283,
                1,
            ],
            [
                0,
                1,
            ],
            [
                283,
                1,
            ],
            [
                0,
                1,
            ],
            [
                283,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                6,
            ],
            [
                283,
                2,
            ],
            [
                284,
                4,
            ],
            [
                283,
                1,
            ],
            [
                284,
                12,
            ],
        ],
    },
    'Illinois, USA': {
        'overall': [
            [
                6924,
                3,
            ],
            [
                6916,
                26,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6925,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6923,
                3,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6923,
                3,
            ],
            [
                6925,
                1,
            ],
            [
                6924,
                6,
            ],
            [
                0,
                5,
            ],
            [
                6923,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                5,
            ],
            [
                6925,
                2,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6922,
                6,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6922,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6922,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6922,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                3,
            ],
            [
                6922,
                1,
            ],
            [
                6923,
                3,
            ],
            [
                6916,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6924,
                12,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6923,
                25,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6924,
                2,
            ],
            [
                6923,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6096,
                3,
            ],
            [
                6701,
                3,
            ],
            [
                6886,
                3,
            ],
            [
                6922,
                1,
            ],
            [
                6924,
                3,
            ],
            [
                0,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6744,
                1,
            ],
            [
                6821,
                1,
            ],
            [
                6910,
                1,
            ],
            [
                6740,
                1,
            ],
            [
                6814,
                1,
            ],
            [
                6924,
                1,
            ],
            [
                6923,
                5,
            ],
            [
                6924,
                4,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6923,
                5,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6922,
                3,
            ],
            [
                6924,
                1,
            ],
            [
                6908,
                1,
            ],
            [
                6916,
                1,
            ],
            [
                6924,
                5,
            ],
            [
                6923,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'CCD': [
            [
                1705,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1705,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1705,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                4,
            ],
            [
                1365,
                3,
            ],
            [
                1606,
                3,
            ],
            [
                1689,
                3,
            ],
            [
                1705,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                5,
            ],
            [
                1667,
                1,
            ],
            [
                1686,
                1,
            ],
            [
                1703,
                1,
            ],
            [
                1667,
                1,
            ],
            [
                1685,
                1,
            ],
            [
                1705,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1705,
                25,
            ],
        ],
        'CSA': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'City': [
            [
                1464,
                3,
            ],
            [
                1457,
                26,
            ],
            [
                1464,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                2,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                4,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1464,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1464,
                3,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                7,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                2,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                7,
            ],
            [
                1457,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                12,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                26,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                4,
            ],
            [
                1167,
                3,
            ],
            [
                1378,
                3,
            ],
            [
                1449,
                3,
            ],
            [
                1464,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1360,
                1,
            ],
            [
                1398,
                1,
            ],
            [
                1458,
                1,
            ],
            [
                1359,
                1,
            ],
            [
                1395,
                1,
            ],
            [
                1464,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1464,
                6,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                4,
            ],
            [
                1453,
                1,
            ],
            [
                1457,
                1,
            ],
            [
                1464,
                11,
            ],
        ],
        'Congressional District': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                17,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'County': [
            [
                102,
                74,
            ],
            [
                0,
                5,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                6,
            ],
            [
                102,
                20,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                2,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                11,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                46,
            ],
            [
                0,
                1,
            ],
            [
                102,
                17,
            ],
            [
                0,
                1,
            ],
            [
                102,
                21,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                25,
            ],
        ],
        'County Cross CD': [
            [
                155,
                74,
            ],
            [
                0,
                5,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                6,
            ],
            [
                155,
                20,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                2,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                11,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                46,
            ],
            [
                0,
                1,
            ],
            [
                155,
                17,
            ],
            [
                0,
                1,
            ],
            [
                155,
                21,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                390,
                74,
            ],
            [
                0,
                5,
            ],
            [
                390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                6,
            ],
            [
                390,
                20,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                4,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                2,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                11,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                46,
            ],
            [
                0,
                1,
            ],
            [
                390,
                17,
            ],
            [
                0,
                1,
            ],
            [
                390,
                21,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                1,
            ],
            [
                0,
                1,
            ],
            [
                390,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                22,
                74,
            ],
            [
                0,
                5,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                6,
            ],
            [
                22,
                20,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                2,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                11,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                46,
            ],
            [
                0,
                1,
            ],
            [
                22,
                17,
            ],
            [
                0,
                1,
            ],
            [
                22,
                21,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                145,
                74,
            ],
            [
                0,
                5,
            ],
            [
                145,
                4,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                4,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                6,
            ],
            [
                145,
                20,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                4,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                2,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                11,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                46,
            ],
            [
                0,
                1,
            ],
            [
                145,
                17,
            ],
            [
                0,
                1,
            ],
            [
                145,
                21,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                1,
            ],
            [
                0,
                1,
            ],
            [
                145,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                17,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Media Market': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Neighborhood': [
            [
                313,
                30,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                3,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                3,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                3,
            ],
            [
                313,
                7,
            ],
            [
                0,
                5,
            ],
            [
                312,
                4,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                4,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                6,
            ],
            [
                313,
                3,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                6,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                2,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                7,
            ],
            [
                313,
                4,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                12,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                25,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                5,
            ],
            [
                0,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                281,
                3,
            ],
            [
                302,
                3,
            ],
            [
                310,
                3,
            ],
            [
                312,
                1,
            ],
            [
                313,
                3,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                1,
            ],
            [
                313,
                2,
            ],
            [
                312,
                5,
            ],
            [
                313,
                4,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                5,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                1,
            ],
            [
                312,
                3,
            ],
            [
                313,
                1,
            ],
            [
                312,
                1,
            ],
            [
                313,
                6,
            ],
            [
                312,
                6,
            ],
        ],
        'School District': [
            [
                862,
                3,
            ],
            [
                861,
                26,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                2,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                4,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                10,
            ],
            [
                0,
                5,
            ],
            [
                862,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                6,
            ],
            [
                862,
                3,
            ],
            [
                860,
                1,
            ],
            [
                861,
                7,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                861,
                1,
            ],
            [
                0,
                1,
            ],
            [
                861,
                1,
            ],
            [
                0,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                2,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                3,
            ],
            [
                861,
                1,
            ],
            [
                862,
                3,
            ],
            [
                861,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                12,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                26,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                4,
            ],
            [
                835,
                3,
            ],
            [
                860,
                3,
            ],
            [
                861,
                4,
            ],
            [
                862,
                3,
            ],
            [
                0,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                857,
                1,
            ],
            [
                859,
                1,
            ],
            [
                860,
                1,
            ],
            [
                857,
                1,
            ],
            [
                859,
                1,
            ],
            [
                862,
                11,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                6,
            ],
            [
                860,
                1,
            ],
            [
                861,
                4,
            ],
            [
                862,
                1,
            ],
            [
                860,
                1,
            ],
            [
                861,
                1,
            ],
            [
                862,
                11,
            ],
        ],
        'State House District': [
            [
                118,
                74,
            ],
            [
                0,
                5,
            ],
            [
                118,
                4,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                4,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                6,
            ],
            [
                118,
                20,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                4,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                2,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                11,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                46,
            ],
            [
                0,
                1,
            ],
            [
                118,
                17,
            ],
            [
                0,
                1,
            ],
            [
                118,
                21,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                25,
            ],
        ],
        'State Senate District': [
            [
                59,
                74,
            ],
            [
                0,
                5,
            ],
            [
                59,
                4,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                4,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                6,
            ],
            [
                59,
                20,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                4,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                2,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                11,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                46,
            ],
            [
                0,
                1,
            ],
            [
                59,
                17,
            ],
            [
                0,
                1,
            ],
            [
                59,
                21,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                1,
            ],
            [
                0,
                1,
            ],
            [
                59,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                103,
                74,
            ],
            [
                0,
                5,
            ],
            [
                103,
                4,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                4,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                6,
            ],
            [
                103,
                20,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                4,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                2,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                11,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                46,
            ],
            [
                0,
                1,
            ],
            [
                103,
                4,
            ],
            [
                102,
                3,
            ],
            [
                103,
                10,
            ],
            [
                0,
                1,
            ],
            [
                103,
                21,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                25,
            ],
        ],
        'Urban Center': [
            [
                16,
                74,
            ],
            [
                0,
                5,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                2,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                11,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                46,
            ],
            [
                0,
                1,
            ],
            [
                16,
                17,
            ],
            [
                0,
                1,
            ],
            [
                16,
                21,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
        ],
        'ZIP': [
            [
                1384,
                30,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                3,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                5,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                11,
            ],
            [
                0,
                5,
            ],
            [
                1384,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1384,
                3,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                8,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                12,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                27,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                5,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                4,
            ],
            [
                1253,
                3,
            ],
            [
                1359,
                3,
            ],
            [
                1381,
                3,
            ],
            [
                1384,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                2,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                1352,
                1,
            ],
            [
                1369,
                1,
            ],
            [
                1380,
                1,
            ],
            [
                1349,
                1,
            ],
            [
                1366,
                1,
            ],
            [
                1384,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1384,
                6,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                5,
            ],
            [
                1382,
                1,
            ],
            [
                1384,
                12,
            ],
        ],
    },
    'Indiana, USA': {
        'overall': [
            [
                4601,
                3,
            ],
            [
                4594,
                26,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4604,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                4,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                3,
            ],
            [
                4604,
                1,
            ],
            [
                4601,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4601,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                5,
            ],
            [
                4604,
                2,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                7,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                2,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                7,
            ],
            [
                4594,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                12,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                26,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                4,
            ],
            [
                3673,
                3,
            ],
            [
                4249,
                3,
            ],
            [
                4511,
                3,
            ],
            [
                4601,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4420,
                1,
            ],
            [
                4520,
                1,
            ],
            [
                4580,
                1,
            ],
            [
                4412,
                1,
            ],
            [
                4509,
                1,
            ],
            [
                4601,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4601,
                6,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                4,
            ],
            [
                4571,
                1,
            ],
            [
                4594,
                1,
            ],
            [
                4601,
                11,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                4,
                54,
            ],
            [
                5,
                1,
            ],
            [
                4,
                12,
            ],
            [
                5,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'CCD': [
            [
                1009,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1009,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1009,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                4,
            ],
            [
                730,
                3,
            ],
            [
                923,
                3,
            ],
            [
                997,
                3,
            ],
            [
                1009,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                5,
            ],
            [
                1003,
                1,
            ],
            [
                1007,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                1003,
                1,
            ],
            [
                1007,
                1,
            ],
            [
                1009,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                25,
            ],
        ],
        'CSA': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'City': [
            [
                967,
                30,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                965,
                1,
            ],
            [
                967,
                2,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                965,
                1,
            ],
            [
                967,
                2,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                965,
                1,
            ],
            [
                967,
                2,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                965,
                1,
            ],
            [
                967,
                2,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                965,
                1,
            ],
            [
                967,
                3,
            ],
            [
                965,
                1,
            ],
            [
                967,
                5,
            ],
            [
                965,
                1,
            ],
            [
                967,
                11,
            ],
            [
                0,
                5,
            ],
            [
                967,
                4,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                4,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                6,
            ],
            [
                967,
                3,
            ],
            [
                965,
                1,
            ],
            [
                967,
                8,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                965,
                1,
            ],
            [
                967,
                2,
            ],
            [
                965,
                1,
            ],
            [
                967,
                2,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                2,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                2,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                11,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                12,
            ],
            [
                965,
                1,
            ],
            [
                967,
                27,
            ],
            [
                965,
                1,
            ],
            [
                967,
                5,
            ],
            [
                0,
                1,
            ],
            [
                967,
                4,
            ],
            [
                631,
                3,
            ],
            [
                818,
                3,
            ],
            [
                926,
                3,
            ],
            [
                967,
                4,
            ],
            [
                0,
                1,
            ],
            [
                965,
                1,
            ],
            [
                967,
                2,
            ],
            [
                965,
                1,
            ],
            [
                967,
                1,
            ],
            [
                847,
                1,
            ],
            [
                905,
                1,
            ],
            [
                947,
                1,
            ],
            [
                839,
                1,
            ],
            [
                895,
                1,
            ],
            [
                967,
                11,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                1,
            ],
            [
                0,
                1,
            ],
            [
                967,
                6,
            ],
            [
                965,
                1,
            ],
            [
                967,
                5,
            ],
            [
                965,
                1,
            ],
            [
                967,
                12,
            ],
        ],
        'Congressional District': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'County': [
            [
                92,
                74,
            ],
            [
                0,
                5,
            ],
            [
                92,
                4,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                4,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                6,
            ],
            [
                92,
                20,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                4,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                2,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                11,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                46,
            ],
            [
                0,
                1,
            ],
            [
                92,
                17,
            ],
            [
                0,
                1,
            ],
            [
                92,
                21,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                1,
            ],
            [
                0,
                1,
            ],
            [
                92,
                25,
            ],
        ],
        'County Cross CD': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                268,
                74,
            ],
            [
                0,
                5,
            ],
            [
                268,
                4,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                4,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                6,
            ],
            [
                268,
                20,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                4,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                2,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                11,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                46,
            ],
            [
                0,
                1,
            ],
            [
                268,
                17,
            ],
            [
                0,
                1,
            ],
            [
                268,
                21,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                1,
            ],
            [
                0,
                1,
            ],
            [
                268,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                13,
                74,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13,
                20,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                94,
                74,
            ],
            [
                0,
                5,
            ],
            [
                94,
                4,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                4,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                6,
            ],
            [
                94,
                20,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                4,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                2,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                11,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                46,
            ],
            [
                0,
                1,
            ],
            [
                94,
                17,
            ],
            [
                0,
                1,
            ],
            [
                94,
                21,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                1,
            ],
            [
                0,
                1,
            ],
            [
                94,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                39,
                74,
            ],
            [
                0,
                5,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                6,
            ],
            [
                39,
                20,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                2,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                11,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                46,
            ],
            [
                0,
                1,
            ],
            [
                39,
                17,
            ],
            [
                0,
                1,
            ],
            [
                39,
                21,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                25,
            ],
        ],
        'Media Market': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Neighborhood': [
            [
                669,
                3,
            ],
            [
                663,
                26,
            ],
            [
                669,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                2,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                4,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                10,
            ],
            [
                0,
                5,
            ],
            [
                669,
                4,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                4,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                6,
            ],
            [
                669,
                3,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                7,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                2,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                2,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                7,
            ],
            [
                663,
                4,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                12,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                26,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                4,
            ],
            [
                0,
                1,
            ],
            [
                669,
                4,
            ],
            [
                488,
                3,
            ],
            [
                588,
                3,
            ],
            [
                643,
                3,
            ],
            [
                669,
                4,
            ],
            [
                0,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                1,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                630,
                1,
            ],
            [
                662,
                1,
            ],
            [
                669,
                1,
            ],
            [
                630,
                1,
            ],
            [
                662,
                1,
            ],
            [
                669,
                11,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                6,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                4,
            ],
            [
                641,
                1,
            ],
            [
                663,
                1,
            ],
            [
                669,
                11,
            ],
        ],
        'School District': [
            [
                291,
                74,
            ],
            [
                0,
                5,
            ],
            [
                291,
                4,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                4,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                6,
            ],
            [
                291,
                20,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                4,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                2,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                11,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                46,
            ],
            [
                0,
                1,
            ],
            [
                291,
                4,
            ],
            [
                283,
                3,
            ],
            [
                291,
                10,
            ],
            [
                0,
                1,
            ],
            [
                291,
                21,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                291,
                25,
            ],
        ],
        'State House District': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'State Senate District': [
            [
                50,
                74,
            ],
            [
                0,
                5,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                20,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                2,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                11,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                46,
            ],
            [
                0,
                1,
            ],
            [
                50,
                17,
            ],
            [
                0,
                1,
            ],
            [
                50,
                21,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                81,
                74,
            ],
            [
                0,
                5,
            ],
            [
                81,
                4,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                4,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                6,
            ],
            [
                81,
                20,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                4,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                2,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                11,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                46,
            ],
            [
                0,
                1,
            ],
            [
                81,
                4,
            ],
            [
                80,
                3,
            ],
            [
                81,
                10,
            ],
            [
                0,
                1,
            ],
            [
                81,
                21,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                1,
            ],
            [
                0,
                1,
            ],
            [
                81,
                25,
            ],
        ],
        'Urban Center': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'ZIP': [
            [
                775,
                3,
            ],
            [
                774,
                26,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                3,
            ],
            [
                774,
                1,
            ],
            [
                775,
                5,
            ],
            [
                774,
                1,
            ],
            [
                775,
                10,
            ],
            [
                0,
                5,
            ],
            [
                775,
                4,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                4,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                6,
            ],
            [
                775,
                4,
            ],
            [
                774,
                1,
            ],
            [
                775,
                8,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                3,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                2,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                7,
            ],
            [
                774,
                4,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                13,
            ],
            [
                774,
                1,
            ],
            [
                775,
                27,
            ],
            [
                774,
                1,
            ],
            [
                775,
                4,
            ],
            [
                0,
                1,
            ],
            [
                775,
                4,
            ],
            [
                652,
                3,
            ],
            [
                739,
                3,
            ],
            [
                764,
                3,
            ],
            [
                775,
                4,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                774,
                1,
            ],
            [
                759,
                1,
            ],
            [
                764,
                1,
            ],
            [
                775,
                11,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                7,
            ],
            [
                774,
                1,
            ],
            [
                775,
                5,
            ],
            [
                774,
                1,
            ],
            [
                775,
                11,
            ],
        ],
    },
    'Iowa, USA': {
        'overall': [
            [
                4806,
                3,
            ],
            [
                4805,
                26,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4808,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                4,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                3,
            ],
            [
                4808,
                1,
            ],
            [
                4806,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4806,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                5,
            ],
            [
                4808,
                2,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4801,
                6,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                3,
            ],
            [
                4801,
                1,
            ],
            [
                4806,
                3,
            ],
            [
                4805,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                12,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                26,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                2,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4204,
                3,
            ],
            [
                4640,
                3,
            ],
            [
                4780,
                3,
            ],
            [
                4801,
                1,
            ],
            [
                4806,
                3,
            ],
            [
                0,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4543,
                1,
            ],
            [
                4659,
                1,
            ],
            [
                4765,
                1,
            ],
            [
                4523,
                1,
            ],
            [
                4635,
                1,
            ],
            [
                4806,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4806,
                5,
            ],
            [
                4805,
                1,
            ],
            [
                4799,
                1,
            ],
            [
                4804,
                1,
            ],
            [
                4801,
                3,
            ],
            [
                4806,
                1,
            ],
            [
                4801,
                1,
            ],
            [
                4805,
                1,
            ],
            [
                4806,
                5,
            ],
            [
                4804,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                3,
                54,
            ],
            [
                4,
                1,
            ],
            [
                3,
                12,
            ],
            [
                4,
                1,
            ],
            [
                3,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                7,
            ],
            [
                3,
                18,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'CCD': [
            [
                1661,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1661,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1661,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                4,
            ],
            [
                1363,
                3,
            ],
            [
                1569,
                3,
            ],
            [
                1651,
                3,
            ],
            [
                1661,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                5,
            ],
            [
                1559,
                1,
            ],
            [
                1597,
                1,
            ],
            [
                1648,
                1,
            ],
            [
                1552,
                1,
            ],
            [
                1587,
                1,
            ],
            [
                1661,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1661,
                25,
            ],
        ],
        'CSA': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'City': [
            [
                1027,
                3,
            ],
            [
                1026,
                26,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                2,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                4,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1027,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1027,
                3,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1024,
                6,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1024,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1024,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1024,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                3,
            ],
            [
                1024,
                1,
            ],
            [
                1027,
                3,
            ],
            [
                1026,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                12,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                26,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                4,
            ],
            [
                826,
                3,
            ],
            [
                972,
                3,
            ],
            [
                1017,
                3,
            ],
            [
                1024,
                1,
            ],
            [
                1027,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                897,
                1,
            ],
            [
                956,
                1,
            ],
            [
                1005,
                1,
            ],
            [
                887,
                1,
            ],
            [
                946,
                1,
            ],
            [
                1027,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1027,
                6,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1024,
                3,
            ],
            [
                1027,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1027,
                11,
            ],
        ],
        'Congressional District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'County': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                17,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'County Cross CD': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                17,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                93,
                74,
            ],
            [
                0,
                5,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                6,
            ],
            [
                93,
                20,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                2,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                11,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                46,
            ],
            [
                0,
                1,
            ],
            [
                93,
                17,
            ],
            [
                0,
                1,
            ],
            [
                93,
                21,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                17,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Media Market': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Neighborhood': [
            [
                171,
                30,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                168,
                1,
            ],
            [
                171,
                2,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                168,
                1,
            ],
            [
                171,
                2,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                168,
                1,
            ],
            [
                171,
                2,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                168,
                1,
            ],
            [
                171,
                2,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                168,
                1,
            ],
            [
                171,
                3,
            ],
            [
                168,
                1,
            ],
            [
                171,
                5,
            ],
            [
                168,
                1,
            ],
            [
                171,
                11,
            ],
            [
                0,
                5,
            ],
            [
                171,
                4,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                4,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                6,
            ],
            [
                171,
                3,
            ],
            [
                168,
                1,
            ],
            [
                171,
                8,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                168,
                1,
            ],
            [
                171,
                2,
            ],
            [
                168,
                1,
            ],
            [
                171,
                2,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                2,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                2,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                11,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                12,
            ],
            [
                168,
                1,
            ],
            [
                171,
                27,
            ],
            [
                168,
                1,
            ],
            [
                171,
                5,
            ],
            [
                0,
                1,
            ],
            [
                171,
                4,
            ],
            [
                145,
                3,
            ],
            [
                165,
                3,
            ],
            [
                169,
                3,
            ],
            [
                171,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                171,
                2,
            ],
            [
                168,
                1,
            ],
            [
                171,
                1,
            ],
            [
                167,
                1,
            ],
            [
                171,
                2,
            ],
            [
                167,
                1,
            ],
            [
                171,
                12,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                6,
            ],
            [
                168,
                1,
            ],
            [
                171,
                5,
            ],
            [
                168,
                1,
            ],
            [
                171,
                12,
            ],
        ],
        'School District': [
            [
                327,
                74,
            ],
            [
                0,
                5,
            ],
            [
                327,
                4,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                4,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                6,
            ],
            [
                327,
                20,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                4,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                2,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                11,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                46,
            ],
            [
                0,
                1,
            ],
            [
                327,
                17,
            ],
            [
                0,
                1,
            ],
            [
                327,
                21,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                327,
                25,
            ],
        ],
        'State House District': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'State Senate District': [
            [
                50,
                74,
            ],
            [
                0,
                5,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                20,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                2,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                11,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                46,
            ],
            [
                0,
                1,
            ],
            [
                50,
                17,
            ],
            [
                0,
                1,
            ],
            [
                50,
                21,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                70,
                74,
            ],
            [
                0,
                5,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                6,
            ],
            [
                70,
                20,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                2,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                11,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                46,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                69,
                3,
            ],
            [
                70,
                10,
            ],
            [
                0,
                1,
            ],
            [
                70,
                21,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                25,
            ],
        ],
        'Urban Center': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'ZIP': [
            [
                934,
                74,
            ],
            [
                0,
                5,
            ],
            [
                934,
                4,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                4,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                6,
            ],
            [
                934,
                5,
            ],
            [
                932,
                6,
            ],
            [
                934,
                9,
            ],
            [
                0,
                1,
            ],
            [
                932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                932,
                1,
            ],
            [
                934,
                3,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                2,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                3,
            ],
            [
                932,
                1,
            ],
            [
                934,
                7,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                46,
            ],
            [
                0,
                1,
            ],
            [
                934,
                2,
            ],
            [
                933,
                1,
            ],
            [
                934,
                1,
            ],
            [
                858,
                3,
            ],
            [
                921,
                3,
            ],
            [
                930,
                3,
            ],
            [
                932,
                1,
            ],
            [
                934,
                3,
            ],
            [
                0,
                1,
            ],
            [
                934,
                5,
            ],
            [
                907,
                1,
            ],
            [
                922,
                1,
            ],
            [
                928,
                1,
            ],
            [
                904,
                1,
            ],
            [
                918,
                1,
            ],
            [
                934,
                11,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                934,
                5,
            ],
            [
                933,
                1,
            ],
            [
                932,
                1,
            ],
            [
                933,
                1,
            ],
            [
                932,
                3,
            ],
            [
                934,
                8,
            ],
            [
                932,
                6,
            ],
        ],
    },
    'Kansas, USA': {
        'overall': [
            [
                4115,
                2,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                26,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4127,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4124,
                3,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4124,
                3,
            ],
            [
                4127,
                1,
            ],
            [
                4125,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4124,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                5,
            ],
            [
                4127,
                2,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4119,
                6,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4120,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                3,
            ],
            [
                4119,
                1,
            ],
            [
                4124,
                3,
            ],
            [
                4123,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4125,
                12,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                26,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4125,
                2,
            ],
            [
                4124,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                3327,
                3,
            ],
            [
                3964,
                3,
            ],
            [
                4093,
                3,
            ],
            [
                4119,
                1,
            ],
            [
                4125,
                3,
            ],
            [
                0,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                3826,
                1,
            ],
            [
                3884,
                1,
            ],
            [
                4048,
                1,
            ],
            [
                3796,
                1,
            ],
            [
                3850,
                1,
            ],
            [
                4125,
                1,
            ],
            [
                4124,
                5,
            ],
            [
                4125,
                4,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4123,
                5,
            ],
            [
                4125,
                1,
            ],
            [
                4116,
                1,
            ],
            [
                4119,
                1,
            ],
            [
                4120,
                3,
            ],
            [
                4125,
                1,
            ],
            [
                4117,
                1,
            ],
            [
                4123,
                1,
            ],
            [
                4125,
                5,
            ],
            [
                4123,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'CCD': [
            [
                1525,
                30,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                3,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                5,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                11,
            ],
            [
                0,
                5,
            ],
            [
                1525,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1525,
                3,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1523,
                6,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1523,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                3,
            ],
            [
                1523,
                1,
            ],
            [
                1525,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                12,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                27,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                5,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                4,
            ],
            [
                1102,
                3,
            ],
            [
                1455,
                3,
            ],
            [
                1517,
                3,
            ],
            [
                1523,
                1,
            ],
            [
                1525,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                2,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1301,
                1,
            ],
            [
                1347,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1279,
                1,
            ],
            [
                1320,
                1,
            ],
            [
                1525,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1525,
                6,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                1,
            ],
            [
                1523,
                3,
            ],
            [
                1525,
                1,
            ],
            [
                1524,
                1,
            ],
            [
                1525,
                12,
            ],
        ],
        'CSA': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'City': [
            [
                731,
                2,
            ],
            [
                733,
                1,
            ],
            [
                739,
                26,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                2,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                4,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                10,
            ],
            [
                0,
                5,
            ],
            [
                740,
                4,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                4,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                6,
            ],
            [
                740,
                3,
            ],
            [
                738,
                1,
            ],
            [
                739,
                7,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                2,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                3,
            ],
            [
                739,
                1,
            ],
            [
                740,
                3,
            ],
            [
                739,
                4,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                12,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                26,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                4,
            ],
            [
                0,
                1,
            ],
            [
                740,
                4,
            ],
            [
                520,
                3,
            ],
            [
                690,
                3,
            ],
            [
                732,
                3,
            ],
            [
                739,
                1,
            ],
            [
                740,
                3,
            ],
            [
                0,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                682,
                1,
            ],
            [
                683,
                1,
            ],
            [
                726,
                1,
            ],
            [
                675,
                1,
            ],
            [
                677,
                1,
            ],
            [
                740,
                11,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                6,
            ],
            [
                738,
                1,
            ],
            [
                739,
                4,
            ],
            [
                740,
                1,
            ],
            [
                738,
                1,
            ],
            [
                739,
                1,
            ],
            [
                740,
                11,
            ],
        ],
        'Congressional District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'County': [
            [
                105,
                74,
            ],
            [
                0,
                5,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                6,
            ],
            [
                105,
                20,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                2,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                11,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                46,
            ],
            [
                0,
                1,
            ],
            [
                105,
                17,
            ],
            [
                0,
                1,
            ],
            [
                105,
                21,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                25,
            ],
        ],
        'County Cross CD': [
            [
                109,
                74,
            ],
            [
                0,
                5,
            ],
            [
                109,
                4,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                4,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                6,
            ],
            [
                109,
                20,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                4,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                2,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                11,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                46,
            ],
            [
                0,
                1,
            ],
            [
                109,
                17,
            ],
            [
                0,
                1,
            ],
            [
                109,
                21,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                80,
                74,
            ],
            [
                0,
                5,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                6,
            ],
            [
                80,
                20,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                2,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                11,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                46,
            ],
            [
                0,
                1,
            ],
            [
                80,
                17,
            ],
            [
                0,
                1,
            ],
            [
                80,
                21,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                122,
                74,
            ],
            [
                0,
                5,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                6,
            ],
            [
                122,
                20,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                2,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                11,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                46,
            ],
            [
                0,
                1,
            ],
            [
                122,
                17,
            ],
            [
                0,
                1,
            ],
            [
                122,
                21,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                22,
                74,
            ],
            [
                0,
                5,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                6,
            ],
            [
                22,
                20,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                2,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                11,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                46,
            ],
            [
                0,
                1,
            ],
            [
                22,
                17,
            ],
            [
                0,
                1,
            ],
            [
                22,
                21,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                25,
            ],
        ],
        'Media Market': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Native Area': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Neighborhood': [
            [
                189,
                30,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                185,
                1,
            ],
            [
                189,
                2,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                185,
                1,
            ],
            [
                189,
                2,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                185,
                1,
            ],
            [
                189,
                2,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                185,
                1,
            ],
            [
                189,
                2,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                185,
                1,
            ],
            [
                189,
                3,
            ],
            [
                185,
                1,
            ],
            [
                189,
                5,
            ],
            [
                185,
                1,
            ],
            [
                189,
                11,
            ],
            [
                0,
                5,
            ],
            [
                189,
                4,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                4,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                6,
            ],
            [
                189,
                3,
            ],
            [
                185,
                1,
            ],
            [
                189,
                8,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                185,
                1,
            ],
            [
                189,
                2,
            ],
            [
                185,
                1,
            ],
            [
                189,
                2,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                2,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                2,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                11,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                12,
            ],
            [
                185,
                1,
            ],
            [
                189,
                27,
            ],
            [
                185,
                1,
            ],
            [
                189,
                5,
            ],
            [
                0,
                1,
            ],
            [
                189,
                4,
            ],
            [
                142,
                3,
            ],
            [
                164,
                3,
            ],
            [
                178,
                3,
            ],
            [
                189,
                4,
            ],
            [
                0,
                1,
            ],
            [
                185,
                1,
            ],
            [
                189,
                2,
            ],
            [
                185,
                1,
            ],
            [
                189,
                1,
            ],
            [
                184,
                1,
            ],
            [
                189,
                2,
            ],
            [
                184,
                1,
            ],
            [
                189,
                12,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                189,
                6,
            ],
            [
                185,
                1,
            ],
            [
                189,
                5,
            ],
            [
                185,
                1,
            ],
            [
                189,
                12,
            ],
        ],
        'School District': [
            [
                286,
                74,
            ],
            [
                0,
                5,
            ],
            [
                286,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                6,
            ],
            [
                286,
                20,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                4,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                2,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                11,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                46,
            ],
            [
                0,
                1,
            ],
            [
                286,
                4,
            ],
            [
                281,
                3,
            ],
            [
                286,
                10,
            ],
            [
                0,
                1,
            ],
            [
                286,
                21,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                25,
            ],
        ],
        'State House District': [
            [
                125,
                74,
            ],
            [
                0,
                5,
            ],
            [
                125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                6,
            ],
            [
                125,
                20,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                2,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                11,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                46,
            ],
            [
                0,
                1,
            ],
            [
                125,
                4,
            ],
            [
                124,
                3,
            ],
            [
                125,
                10,
            ],
            [
                0,
                1,
            ],
            [
                125,
                21,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                25,
            ],
        ],
        'State Senate District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                46,
                74,
            ],
            [
                0,
                5,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                6,
            ],
            [
                46,
                20,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                2,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                46,
            ],
            [
                0,
                1,
            ],
            [
                46,
                17,
            ],
            [
                0,
                1,
            ],
            [
                46,
                21,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                25,
            ],
        ],
        'Urban Center': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                698,
                29,
            ],
            [
                699,
                1,
            ],
            [
                698,
                4,
            ],
            [
                699,
                1,
            ],
            [
                698,
                4,
            ],
            [
                699,
                1,
            ],
            [
                698,
                4,
            ],
            [
                699,
                1,
            ],
            [
                698,
                4,
            ],
            [
                699,
                1,
            ],
            [
                698,
                4,
            ],
            [
                699,
                2,
            ],
            [
                698,
                5,
            ],
            [
                699,
                1,
            ],
            [
                698,
                5,
            ],
            [
                699,
                7,
            ],
            [
                0,
                5,
            ],
            [
                698,
                4,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                4,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                6,
            ],
            [
                699,
                3,
            ],
            [
                698,
                2,
            ],
            [
                696,
                6,
            ],
            [
                699,
                1,
            ],
            [
                698,
                4,
            ],
            [
                699,
                1,
            ],
            [
                698,
                2,
            ],
            [
                699,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                699,
                1,
            ],
            [
                698,
                2,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                3,
            ],
            [
                696,
                1,
            ],
            [
                698,
                7,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                0,
                1,
            ],
            [
                699,
                12,
            ],
            [
                698,
                2,
            ],
            [
                697,
                25,
            ],
            [
                699,
                1,
            ],
            [
                698,
                2,
            ],
            [
                699,
                4,
            ],
            [
                0,
                1,
            ],
            [
                699,
                2,
            ],
            [
                698,
                1,
            ],
            [
                699,
                1,
            ],
            [
                597,
                3,
            ],
            [
                683,
                3,
            ],
            [
                694,
                3,
            ],
            [
                696,
                1,
            ],
            [
                699,
                3,
            ],
            [
                0,
                1,
            ],
            [
                698,
                2,
            ],
            [
                699,
                1,
            ],
            [
                698,
                2,
            ],
            [
                687,
                1,
            ],
            [
                693,
                1,
            ],
            [
                698,
                1,
            ],
            [
                686,
                1,
            ],
            [
                692,
                1,
            ],
            [
                699,
                1,
            ],
            [
                698,
                5,
            ],
            [
                699,
                4,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                1,
            ],
            [
                0,
                1,
            ],
            [
                697,
                5,
            ],
            [
                699,
                1,
            ],
            [
                697,
                1,
            ],
            [
                694,
                1,
            ],
            [
                697,
                3,
            ],
            [
                699,
                1,
            ],
            [
                698,
                2,
            ],
            [
                699,
                5,
            ],
            [
                697,
                6,
            ],
        ],
    },
    'Kentucky, USA': {
        'overall': [
            [
                2931,
                3,
            ],
            [
                2930,
                26,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2935,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2932,
                3,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2932,
                3,
            ],
            [
                2935,
                1,
            ],
            [
                2934,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2931,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                3,
            ],
            [
                2932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2932,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                5,
            ],
            [
                2935,
                2,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2928,
                6,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2929,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2929,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2929,
                1,
            ],
            [
                2933,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2932,
                3,
            ],
            [
                2928,
                1,
            ],
            [
                2932,
                3,
            ],
            [
                2930,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2934,
                12,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2931,
                25,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2934,
                2,
            ],
            [
                2932,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2368,
                3,
            ],
            [
                2797,
                3,
            ],
            [
                2906,
                3,
            ],
            [
                2928,
                1,
            ],
            [
                2934,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2871,
                1,
            ],
            [
                2887,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2869,
                1,
            ],
            [
                2883,
                1,
            ],
            [
                2934,
                1,
            ],
            [
                2932,
                5,
            ],
            [
                2934,
                4,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2931,
                6,
            ],
            [
                2923,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2929,
                3,
            ],
            [
                2934,
                1,
            ],
            [
                2925,
                1,
            ],
            [
                2930,
                1,
            ],
            [
                2934,
                5,
            ],
            [
                2928,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'CCD': [
            [
                489,
                3,
            ],
            [
                491,
                26,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                2,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                3,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                3,
            ],
            [
                492,
                7,
            ],
            [
                0,
                5,
            ],
            [
                489,
                4,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                3,
            ],
            [
                490,
                1,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                0,
                6,
            ],
            [
                492,
                3,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                489,
                6,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                2,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                490,
                3,
            ],
            [
                489,
                1,
            ],
            [
                490,
                3,
            ],
            [
                491,
                4,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                492,
                12,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                489,
                25,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                4,
            ],
            [
                0,
                1,
            ],
            [
                492,
                2,
            ],
            [
                490,
                1,
            ],
            [
                492,
                1,
            ],
            [
                338,
                3,
            ],
            [
                464,
                3,
            ],
            [
                487,
                3,
            ],
            [
                489,
                1,
            ],
            [
                492,
                3,
            ],
            [
                0,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                488,
                1,
            ],
            [
                489,
                2,
            ],
            [
                488,
                2,
            ],
            [
                492,
                1,
            ],
            [
                490,
                5,
            ],
            [
                492,
                4,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                1,
            ],
            [
                0,
                1,
            ],
            [
                489,
                5,
            ],
            [
                491,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                489,
                3,
            ],
            [
                492,
                1,
            ],
            [
                490,
                1,
            ],
            [
                491,
                1,
            ],
            [
                492,
                5,
            ],
            [
                489,
                6,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                554,
                3,
            ],
            [
                551,
                26,
            ],
            [
                554,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                2,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                4,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                10,
            ],
            [
                0,
                5,
            ],
            [
                554,
                4,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                4,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                6,
            ],
            [
                554,
                3,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                7,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                2,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                2,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                7,
            ],
            [
                551,
                4,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                12,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                26,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                4,
            ],
            [
                0,
                1,
            ],
            [
                554,
                4,
            ],
            [
                397,
                3,
            ],
            [
                512,
                3,
            ],
            [
                544,
                3,
            ],
            [
                554,
                4,
            ],
            [
                0,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                1,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                530,
                1,
            ],
            [
                531,
                1,
            ],
            [
                554,
                1,
            ],
            [
                530,
                1,
            ],
            [
                531,
                1,
            ],
            [
                554,
                11,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                6,
            ],
            [
                553,
                1,
            ],
            [
                550,
                1,
            ],
            [
                554,
                4,
            ],
            [
                553,
                1,
            ],
            [
                551,
                1,
            ],
            [
                554,
                11,
            ],
        ],
        'Congressional District': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'County': [
            [
                120,
                74,
            ],
            [
                0,
                5,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                6,
            ],
            [
                120,
                20,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                2,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                11,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                46,
            ],
            [
                0,
                1,
            ],
            [
                120,
                17,
            ],
            [
                0,
                1,
            ],
            [
                120,
                21,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                25,
            ],
        ],
        'County Cross CD': [
            [
                126,
                74,
            ],
            [
                0,
                5,
            ],
            [
                126,
                4,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                4,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                6,
            ],
            [
                126,
                20,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                4,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                2,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                11,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                46,
            ],
            [
                0,
                1,
            ],
            [
                126,
                4,
            ],
            [
                125,
                3,
            ],
            [
                126,
                10,
            ],
            [
                0,
                1,
            ],
            [
                126,
                21,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                206,
                74,
            ],
            [
                0,
                5,
            ],
            [
                206,
                4,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                4,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                6,
            ],
            [
                206,
                20,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                4,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                2,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                11,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                46,
            ],
            [
                0,
                1,
            ],
            [
                206,
                17,
            ],
            [
                0,
                1,
            ],
            [
                206,
                21,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                1,
            ],
            [
                0,
                1,
            ],
            [
                206,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                88,
                74,
            ],
            [
                0,
                5,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                6,
            ],
            [
                88,
                20,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                2,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                11,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                46,
            ],
            [
                0,
                1,
            ],
            [
                88,
                17,
            ],
            [
                0,
                1,
            ],
            [
                88,
                21,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Media Market': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Neighborhood': [
            [
                121,
                74,
            ],
            [
                0,
                5,
            ],
            [
                121,
                4,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                4,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                6,
            ],
            [
                121,
                20,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                4,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                2,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                11,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                46,
            ],
            [
                0,
                1,
            ],
            [
                121,
                4,
            ],
            [
                116,
                3,
            ],
            [
                121,
                10,
            ],
            [
                0,
                1,
            ],
            [
                121,
                21,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                25,
            ],
        ],
        'School District': [
            [
                180,
                74,
            ],
            [
                0,
                5,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                6,
            ],
            [
                180,
                20,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                2,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                11,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                46,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                177,
                3,
            ],
            [
                180,
                10,
            ],
            [
                0,
                1,
            ],
            [
                180,
                5,
            ],
            [
                179,
                5,
            ],
            [
                180,
                11,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                25,
            ],
        ],
        'State House District': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'State Senate District': [
            [
                38,
                74,
            ],
            [
                0,
                5,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                6,
            ],
            [
                38,
                20,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                2,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                11,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                46,
            ],
            [
                0,
                1,
            ],
            [
                38,
                17,
            ],
            [
                0,
                1,
            ],
            [
                38,
                21,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                66,
                74,
            ],
            [
                0,
                5,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                6,
            ],
            [
                66,
                20,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                2,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                11,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                46,
            ],
            [
                0,
                1,
            ],
            [
                66,
                17,
            ],
            [
                0,
                1,
            ],
            [
                66,
                21,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                25,
            ],
        ],
        'Urban Center': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                765,
                30,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                2,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                2,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                2,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                2,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                3,
            ],
            [
                759,
                1,
            ],
            [
                765,
                5,
            ],
            [
                759,
                1,
            ],
            [
                765,
                11,
            ],
            [
                0,
                5,
            ],
            [
                765,
                4,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                4,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                6,
            ],
            [
                765,
                3,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                762,
                6,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                2,
            ],
            [
                759,
                1,
            ],
            [
                765,
                2,
            ],
            [
                0,
                1,
            ],
            [
                763,
                1,
            ],
            [
                0,
                1,
            ],
            [
                763,
                1,
            ],
            [
                0,
                1,
            ],
            [
                763,
                1,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                2,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                3,
            ],
            [
                762,
                1,
            ],
            [
                765,
                7,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                12,
            ],
            [
                759,
                1,
            ],
            [
                765,
                27,
            ],
            [
                759,
                1,
            ],
            [
                765,
                5,
            ],
            [
                0,
                1,
            ],
            [
                765,
                4,
            ],
            [
                519,
                3,
            ],
            [
                698,
                3,
            ],
            [
                752,
                3,
            ],
            [
                762,
                1,
            ],
            [
                765,
                3,
            ],
            [
                0,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                2,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                731,
                1,
            ],
            [
                745,
                1,
            ],
            [
                760,
                1,
            ],
            [
                729,
                1,
            ],
            [
                742,
                1,
            ],
            [
                765,
                11,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                765,
                5,
            ],
            [
                763,
                1,
            ],
            [
                757,
                1,
            ],
            [
                761,
                1,
            ],
            [
                763,
                3,
            ],
            [
                765,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                6,
            ],
            [
                762,
                6,
            ],
        ],
    },
    'Louisiana, USA': {
        'overall': [
            [
                2406,
                30,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                3,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                5,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                11,
            ],
            [
                0,
                5,
            ],
            [
                2406,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                5,
            ],
            [
                2406,
                3,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2405,
                6,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2405,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2405,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2405,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                3,
            ],
            [
                2405,
                1,
            ],
            [
                2406,
                7,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                12,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                27,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                5,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                4,
            ],
            [
                2024,
                3,
            ],
            [
                2290,
                3,
            ],
            [
                2374,
                3,
            ],
            [
                2405,
                1,
            ],
            [
                2406,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                2,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                2396,
                1,
            ],
            [
                2402,
                1,
            ],
            [
                2404,
                1,
            ],
            [
                2395,
                1,
            ],
            [
                2401,
                1,
            ],
            [
                2406,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2406,
                5,
            ],
            [
                2405,
                1,
            ],
            [
                2401,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2405,
                3,
            ],
            [
                2406,
                1,
            ],
            [
                2403,
                1,
            ],
            [
                2406,
                6,
            ],
            [
                2405,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                576,
                74,
            ],
            [
                0,
                5,
            ],
            [
                576,
                4,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                4,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                6,
            ],
            [
                576,
                20,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                4,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                2,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                11,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                46,
            ],
            [
                0,
                1,
            ],
            [
                576,
                4,
            ],
            [
                495,
                3,
            ],
            [
                556,
                3,
            ],
            [
                567,
                3,
            ],
            [
                576,
                4,
            ],
            [
                0,
                1,
            ],
            [
                576,
                21,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                576,
                25,
            ],
        ],
        'CSA': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'City': [
            [
                488,
                30,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                486,
                1,
            ],
            [
                488,
                2,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                486,
                1,
            ],
            [
                488,
                2,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                486,
                1,
            ],
            [
                488,
                2,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                486,
                1,
            ],
            [
                488,
                2,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                486,
                1,
            ],
            [
                488,
                3,
            ],
            [
                486,
                1,
            ],
            [
                488,
                5,
            ],
            [
                486,
                1,
            ],
            [
                488,
                11,
            ],
            [
                0,
                5,
            ],
            [
                488,
                4,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                4,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                6,
            ],
            [
                488,
                3,
            ],
            [
                486,
                1,
            ],
            [
                488,
                8,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                486,
                1,
            ],
            [
                488,
                2,
            ],
            [
                486,
                1,
            ],
            [
                488,
                2,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                2,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                2,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                11,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                12,
            ],
            [
                486,
                1,
            ],
            [
                488,
                27,
            ],
            [
                486,
                1,
            ],
            [
                488,
                5,
            ],
            [
                0,
                1,
            ],
            [
                488,
                4,
            ],
            [
                331,
                3,
            ],
            [
                445,
                3,
            ],
            [
                476,
                3,
            ],
            [
                488,
                4,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                488,
                2,
            ],
            [
                486,
                1,
            ],
            [
                488,
                1,
            ],
            [
                482,
                1,
            ],
            [
                485,
                1,
            ],
            [
                487,
                1,
            ],
            [
                481,
                1,
            ],
            [
                485,
                1,
            ],
            [
                488,
                11,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                488,
                6,
            ],
            [
                486,
                1,
            ],
            [
                488,
                5,
            ],
            [
                486,
                1,
            ],
            [
                488,
                12,
            ],
        ],
        'Congressional District': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'County': [
            [
                64,
                74,
            ],
            [
                0,
                5,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                6,
            ],
            [
                64,
                20,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                2,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                11,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                46,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                63,
                3,
            ],
            [
                64,
                10,
            ],
            [
                0,
                1,
            ],
            [
                64,
                21,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                25,
            ],
        ],
        'County Cross CD': [
            [
                79,
                74,
            ],
            [
                0,
                5,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                6,
            ],
            [
                79,
                20,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                2,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                11,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                46,
            ],
            [
                0,
                1,
            ],
            [
                79,
                4,
            ],
            [
                77,
                3,
            ],
            [
                78,
                3,
            ],
            [
                79,
                7,
            ],
            [
                0,
                1,
            ],
            [
                79,
                21,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                1,
            ],
            [
                0,
                1,
            ],
            [
                79,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                123,
                74,
            ],
            [
                0,
                5,
            ],
            [
                123,
                4,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                4,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                6,
            ],
            [
                123,
                20,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                4,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                2,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                11,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                46,
            ],
            [
                0,
                1,
            ],
            [
                123,
                17,
            ],
            [
                0,
                1,
            ],
            [
                123,
                21,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                1,
            ],
            [
                0,
                1,
            ],
            [
                123,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                69,
                74,
            ],
            [
                0,
                5,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                6,
            ],
            [
                69,
                20,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                2,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                11,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                46,
            ],
            [
                0,
                1,
            ],
            [
                69,
                17,
            ],
            [
                0,
                1,
            ],
            [
                69,
                21,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                17,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Area': [
            [
                4,
                30,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                3,
            ],
            [
                3,
                1,
            ],
            [
                4,
                5,
            ],
            [
                3,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                3,
            ],
            [
                3,
                1,
            ],
            [
                4,
                8,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                12,
            ],
            [
                3,
                1,
            ],
            [
                4,
                27,
            ],
            [
                3,
                1,
            ],
            [
                4,
                5,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                2,
                3,
            ],
            [
                4,
                10,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                1,
            ],
            [
                3,
                1,
            ],
            [
                4,
                2,
            ],
            [
                3,
                1,
            ],
            [
                4,
                12,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                6,
            ],
            [
                3,
                1,
            ],
            [
                4,
                5,
            ],
            [
                3,
                1,
            ],
            [
                4,
                12,
            ],
        ],
        'Native Statistical Area': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                9,
                3,
            ],
            [
                10,
                10,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Neighborhood': [
            [
                154,
                74,
            ],
            [
                0,
                5,
            ],
            [
                154,
                4,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                4,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                6,
            ],
            [
                154,
                20,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                4,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                2,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                11,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                46,
            ],
            [
                0,
                1,
            ],
            [
                154,
                4,
            ],
            [
                148,
                3,
            ],
            [
                151,
                3,
            ],
            [
                154,
                7,
            ],
            [
                0,
                1,
            ],
            [
                154,
                21,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                0,
                1,
            ],
            [
                154,
                25,
            ],
        ],
        'School District': [
            [
                69,
                74,
            ],
            [
                0,
                5,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                6,
            ],
            [
                69,
                20,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                2,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                11,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                46,
            ],
            [
                0,
                1,
            ],
            [
                69,
                4,
            ],
            [
                68,
                3,
            ],
            [
                69,
                10,
            ],
            [
                0,
                1,
            ],
            [
                69,
                21,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                25,
            ],
        ],
        'State House District': [
            [
                105,
                74,
            ],
            [
                0,
                5,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                6,
            ],
            [
                105,
                20,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                4,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                2,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                11,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                46,
            ],
            [
                0,
                1,
            ],
            [
                105,
                17,
            ],
            [
                0,
                1,
            ],
            [
                105,
                21,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                1,
            ],
            [
                0,
                1,
            ],
            [
                105,
                25,
            ],
        ],
        'State Senate District': [
            [
                39,
                74,
            ],
            [
                0,
                5,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                6,
            ],
            [
                39,
                20,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                2,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                11,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                46,
            ],
            [
                0,
                1,
            ],
            [
                39,
                17,
            ],
            [
                0,
                1,
            ],
            [
                39,
                21,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                17,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'Urban Center': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'ZIP': [
            [
                512,
                74,
            ],
            [
                0,
                5,
            ],
            [
                512,
                4,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                4,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                6,
            ],
            [
                512,
                5,
            ],
            [
                511,
                6,
            ],
            [
                512,
                9,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                0,
                1,
            ],
            [
                511,
                1,
            ],
            [
                512,
                3,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                2,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                3,
            ],
            [
                511,
                1,
            ],
            [
                512,
                7,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                46,
            ],
            [
                0,
                1,
            ],
            [
                512,
                4,
            ],
            [
                381,
                3,
            ],
            [
                463,
                3,
            ],
            [
                501,
                3,
            ],
            [
                511,
                1,
            ],
            [
                512,
                3,
            ],
            [
                0,
                1,
            ],
            [
                512,
                5,
            ],
            [
                509,
                1,
            ],
            [
                511,
                2,
            ],
            [
                509,
                1,
            ],
            [
                510,
                1,
            ],
            [
                512,
                11,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                1,
            ],
            [
                0,
                1,
            ],
            [
                512,
                5,
            ],
            [
                511,
                1,
            ],
            [
                510,
                1,
            ],
            [
                509,
                1,
            ],
            [
                511,
                3,
            ],
            [
                512,
                8,
            ],
            [
                511,
                6,
            ],
        ],
    },
    'Maine, USA': {
        'overall': [
            [
                1795,
                1,
            ],
            [
                1793,
                2,
            ],
            [
                1798,
                26,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1805,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                4,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                3,
            ],
            [
                1805,
                1,
            ],
            [
                1802,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1796,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                3,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                5,
            ],
            [
                1805,
                2,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                6,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1799,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1802,
                3,
            ],
            [
                1791,
                1,
            ],
            [
                1802,
                3,
            ],
            [
                1798,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1802,
                12,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1796,
                25,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1802,
                4,
            ],
            [
                1531,
                3,
            ],
            [
                1714,
                3,
            ],
            [
                1768,
                3,
            ],
            [
                1791,
                1,
            ],
            [
                1802,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1718,
                1,
            ],
            [
                1762,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1713,
                1,
            ],
            [
                1753,
                1,
            ],
            [
                1802,
                1,
            ],
            [
                1799,
                5,
            ],
            [
                1802,
                4,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1796,
                5,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1791,
                3,
            ],
            [
                1802,
                1,
            ],
            [
                1791,
                1,
            ],
            [
                1798,
                1,
            ],
            [
                1802,
                11,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'CCD': [
            [
                521,
                1,
            ],
            [
                520,
                2,
            ],
            [
                524,
                26,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                2,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                4,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                10,
            ],
            [
                0,
                5,
            ],
            [
                523,
                4,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                3,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                6,
            ],
            [
                526,
                3,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                519,
                6,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                519,
                1,
            ],
            [
                0,
                1,
            ],
            [
                519,
                1,
            ],
            [
                0,
                1,
            ],
            [
                519,
                1,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                3,
            ],
            [
                519,
                1,
            ],
            [
                526,
                3,
            ],
            [
                524,
                4,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                12,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                523,
                25,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                4,
            ],
            [
                0,
                1,
            ],
            [
                526,
                4,
            ],
            [
                393,
                3,
            ],
            [
                487,
                3,
            ],
            [
                509,
                3,
            ],
            [
                519,
                1,
            ],
            [
                526,
                3,
            ],
            [
                0,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                487,
                1,
            ],
            [
                506,
                1,
            ],
            [
                517,
                1,
            ],
            [
                485,
                1,
            ],
            [
                502,
                1,
            ],
            [
                526,
                1,
            ],
            [
                524,
                5,
            ],
            [
                526,
                4,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                1,
            ],
            [
                0,
                1,
            ],
            [
                523,
                5,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                519,
                3,
            ],
            [
                526,
                1,
            ],
            [
                520,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                11,
            ],
        ],
        'CSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                155,
                74,
            ],
            [
                0,
                5,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                6,
            ],
            [
                155,
                20,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                2,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                11,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                46,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                147,
                3,
            ],
            [
                150,
                3,
            ],
            [
                155,
                7,
            ],
            [
                0,
                1,
            ],
            [
                155,
                5,
            ],
            [
                152,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                152,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                11,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                25,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'County': [
            [
                16,
                74,
            ],
            [
                0,
                5,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                6,
            ],
            [
                16,
                20,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                2,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                11,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                46,
            ],
            [
                0,
                1,
            ],
            [
                16,
                17,
            ],
            [
                0,
                1,
            ],
            [
                16,
                21,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
        ],
        'County Cross CD': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                17,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                103,
                74,
            ],
            [
                0,
                5,
            ],
            [
                103,
                4,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                4,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                6,
            ],
            [
                103,
                20,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                4,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                2,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                11,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                46,
            ],
            [
                0,
                1,
            ],
            [
                103,
                17,
            ],
            [
                0,
                1,
            ],
            [
                103,
                21,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                1,
            ],
            [
                0,
                1,
            ],
            [
                103,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                17,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Media Market': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Native Area': [
            [
                5,
                29,
            ],
            [
                6,
                1,
            ],
            [
                5,
                4,
            ],
            [
                6,
                1,
            ],
            [
                5,
                4,
            ],
            [
                6,
                1,
            ],
            [
                5,
                4,
            ],
            [
                6,
                1,
            ],
            [
                5,
                4,
            ],
            [
                6,
                1,
            ],
            [
                5,
                4,
            ],
            [
                6,
                2,
            ],
            [
                5,
                2,
            ],
            [
                6,
                4,
            ],
            [
                5,
                2,
            ],
            [
                6,
                10,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                3,
            ],
            [
                5,
                2,
            ],
            [
                6,
                7,
            ],
            [
                5,
                4,
            ],
            [
                6,
                1,
            ],
            [
                5,
                2,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                7,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                12,
            ],
            [
                5,
                2,
            ],
            [
                6,
                26,
            ],
            [
                5,
                2,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                5,
                3,
            ],
            [
                6,
                10,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                6,
                1,
            ],
            [
                5,
                4,
            ],
            [
                6,
                1,
            ],
            [
                5,
                2,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                6,
            ],
            [
                5,
                2,
            ],
            [
                6,
                4,
            ],
            [
                5,
                2,
            ],
            [
                6,
                11,
            ],
        ],
        'Neighborhood': [
            [
                26,
                30,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                2,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                2,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                2,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                2,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                3,
            ],
            [
                24,
                1,
            ],
            [
                26,
                5,
            ],
            [
                24,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                5,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                3,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                3,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                25,
                6,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                2,
            ],
            [
                24,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                2,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                3,
            ],
            [
                25,
                1,
            ],
            [
                26,
                7,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                12,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                25,
                25,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                5,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                21,
                6,
            ],
            [
                23,
                3,
            ],
            [
                25,
                1,
            ],
            [
                26,
                3,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                2,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                23,
                1,
            ],
            [
                24,
                2,
            ],
            [
                23,
                2,
            ],
            [
                26,
                10,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                5,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                1,
            ],
            [
                25,
                3,
            ],
            [
                26,
                1,
            ],
            [
                24,
                1,
            ],
            [
                26,
                12,
            ],
        ],
        'School District': [
            [
                265,
                1,
            ],
            [
                264,
                2,
            ],
            [
                265,
                26,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                2,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                4,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                10,
            ],
            [
                0,
                5,
            ],
            [
                264,
                4,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                3,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                6,
            ],
            [
                266,
                3,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                263,
                6,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                1,
            ],
            [
                0,
                1,
            ],
            [
                263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                263,
                1,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                266,
                3,
            ],
            [
                263,
                1,
            ],
            [
                266,
                3,
            ],
            [
                265,
                4,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                266,
                12,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                264,
                25,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                4,
            ],
            [
                0,
                1,
            ],
            [
                266,
                4,
            ],
            [
                216,
                3,
            ],
            [
                247,
                3,
            ],
            [
                258,
                3,
            ],
            [
                263,
                1,
            ],
            [
                266,
                3,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                248,
                1,
            ],
            [
                257,
                1,
            ],
            [
                259,
                1,
            ],
            [
                246,
                1,
            ],
            [
                254,
                1,
            ],
            [
                266,
                1,
            ],
            [
                265,
                5,
            ],
            [
                266,
                4,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                5,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                263,
                3,
            ],
            [
                266,
                1,
            ],
            [
                264,
                1,
            ],
            [
                265,
                1,
            ],
            [
                266,
                11,
            ],
        ],
        'State House District': [
            [
                151,
                74,
            ],
            [
                0,
                5,
            ],
            [
                151,
                4,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                4,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                6,
            ],
            [
                151,
                20,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                4,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                2,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                11,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                46,
            ],
            [
                0,
                1,
            ],
            [
                151,
                17,
            ],
            [
                0,
                1,
            ],
            [
                151,
                21,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                1,
            ],
            [
                0,
                1,
            ],
            [
                151,
                25,
            ],
        ],
        'State Senate District': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                431,
                74,
            ],
            [
                0,
                5,
            ],
            [
                431,
                4,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                4,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                6,
            ],
            [
                431,
                20,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                4,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                2,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                11,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                46,
            ],
            [
                0,
                1,
            ],
            [
                431,
                4,
            ],
            [
                357,
                3,
            ],
            [
                411,
                3,
            ],
            [
                425,
                3,
            ],
            [
                431,
                4,
            ],
            [
                0,
                1,
            ],
            [
                431,
                5,
            ],
            [
                411,
                1,
            ],
            [
                424,
                1,
            ],
            [
                429,
                1,
            ],
            [
                410,
                1,
            ],
            [
                423,
                1,
            ],
            [
                431,
                11,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                1,
            ],
            [
                0,
                1,
            ],
            [
                431,
                25,
            ],
        ],
    },
    'Maryland, USA': {
        'overall': [
            [
                2076,
                3,
            ],
            [
                2073,
                26,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                2,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                4,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                10,
            ],
            [
                0,
                5,
            ],
            [
                2074,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                3,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                5,
            ],
            [
                2076,
                3,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2063,
                6,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2066,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2066,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2066,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2075,
                3,
            ],
            [
                2063,
                1,
            ],
            [
                2076,
                3,
            ],
            [
                2073,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2076,
                12,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                26,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2076,
                2,
            ],
            [
                2075,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                1734,
                3,
            ],
            [
                1930,
                3,
            ],
            [
                2054,
                3,
            ],
            [
                2063,
                1,
            ],
            [
                2076,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                2053,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2005,
                1,
            ],
            [
                2051,
                1,
            ],
            [
                2076,
                1,
            ],
            [
                2074,
                5,
            ],
            [
                2076,
                4,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2073,
                5,
            ],
            [
                2071,
                1,
            ],
            [
                2050,
                1,
            ],
            [
                2066,
                4,
            ],
            [
                2076,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2073,
                1,
            ],
            [
                2076,
                5,
            ],
            [
                2066,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'CCD': [
            [
                290,
                74,
            ],
            [
                0,
                5,
            ],
            [
                290,
                4,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                4,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                6,
            ],
            [
                290,
                20,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                4,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                2,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                11,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                46,
            ],
            [
                0,
                1,
            ],
            [
                290,
                4,
            ],
            [
                237,
                3,
            ],
            [
                277,
                3,
            ],
            [
                289,
                3,
            ],
            [
                290,
                4,
            ],
            [
                0,
                1,
            ],
            [
                290,
                21,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                25,
            ],
        ],
        'CSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                536,
                30,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                2,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                2,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                2,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                2,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                3,
            ],
            [
                530,
                1,
            ],
            [
                536,
                5,
            ],
            [
                530,
                1,
            ],
            [
                536,
                11,
            ],
            [
                0,
                5,
            ],
            [
                536,
                4,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                4,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                6,
            ],
            [
                536,
                3,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                535,
                6,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                2,
            ],
            [
                530,
                1,
            ],
            [
                536,
                2,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                2,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                3,
            ],
            [
                535,
                1,
            ],
            [
                536,
                7,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                12,
            ],
            [
                530,
                1,
            ],
            [
                536,
                27,
            ],
            [
                530,
                1,
            ],
            [
                536,
                5,
            ],
            [
                0,
                1,
            ],
            [
                536,
                4,
            ],
            [
                394,
                3,
            ],
            [
                467,
                3,
            ],
            [
                533,
                3,
            ],
            [
                535,
                1,
            ],
            [
                536,
                3,
            ],
            [
                0,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                2,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                488,
                1,
            ],
            [
                523,
                1,
            ],
            [
                533,
                1,
            ],
            [
                488,
                1,
            ],
            [
                521,
                1,
            ],
            [
                536,
                11,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                1,
            ],
            [
                0,
                1,
            ],
            [
                536,
                6,
            ],
            [
                530,
                1,
            ],
            [
                536,
                1,
            ],
            [
                535,
                3,
            ],
            [
                536,
                1,
            ],
            [
                530,
                1,
            ],
            [
                536,
                12,
            ],
        ],
        'Congressional District': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'County': [
            [
                24,
                74,
            ],
            [
                0,
                5,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                6,
            ],
            [
                24,
                20,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                2,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                11,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                46,
            ],
            [
                0,
                1,
            ],
            [
                24,
                17,
            ],
            [
                0,
                1,
            ],
            [
                24,
                21,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                25,
            ],
        ],
        'County Cross CD': [
            [
                32,
                74,
            ],
            [
                0,
                5,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32,
                20,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                2,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                11,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                46,
            ],
            [
                0,
                1,
            ],
            [
                32,
                17,
            ],
            [
                0,
                1,
            ],
            [
                32,
                21,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                164,
                74,
            ],
            [
                0,
                5,
            ],
            [
                164,
                4,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                4,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                6,
            ],
            [
                164,
                20,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                4,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                2,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                11,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                46,
            ],
            [
                0,
                1,
            ],
            [
                164,
                17,
            ],
            [
                0,
                1,
            ],
            [
                164,
                21,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                32,
                74,
            ],
            [
                0,
                5,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32,
                20,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                2,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                11,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                46,
            ],
            [
                0,
                1,
            ],
            [
                32,
                17,
            ],
            [
                0,
                1,
            ],
            [
                32,
                21,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Media Market': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Neighborhood': [
            [
                312,
                3,
            ],
            [
                309,
                26,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                2,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                4,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                10,
            ],
            [
                0,
                5,
            ],
            [
                310,
                4,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                309,
                3,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                6,
            ],
            [
                312,
                3,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                306,
                6,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                308,
                1,
            ],
            [
                0,
                1,
            ],
            [
                308,
                1,
            ],
            [
                0,
                1,
            ],
            [
                308,
                1,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                3,
            ],
            [
                306,
                1,
            ],
            [
                312,
                3,
            ],
            [
                309,
                4,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                12,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                310,
                25,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                4,
            ],
            [
                0,
                1,
            ],
            [
                312,
                4,
            ],
            [
                268,
                3,
            ],
            [
                289,
                3,
            ],
            [
                303,
                3,
            ],
            [
                306,
                1,
            ],
            [
                312,
                3,
            ],
            [
                0,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                300,
                1,
            ],
            [
                306,
                1,
            ],
            [
                312,
                1,
            ],
            [
                300,
                1,
            ],
            [
                306,
                1,
            ],
            [
                312,
                1,
            ],
            [
                310,
                5,
            ],
            [
                312,
                4,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                1,
            ],
            [
                0,
                1,
            ],
            [
                310,
                6,
            ],
            [
                300,
                1,
            ],
            [
                308,
                4,
            ],
            [
                312,
                1,
            ],
            [
                302,
                1,
            ],
            [
                309,
                1,
            ],
            [
                312,
                5,
            ],
            [
                307,
                6,
            ],
        ],
        'School District': [
            [
                24,
                74,
            ],
            [
                0,
                5,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                6,
            ],
            [
                24,
                20,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                2,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                11,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                46,
            ],
            [
                0,
                1,
            ],
            [
                24,
                17,
            ],
            [
                0,
                1,
            ],
            [
                24,
                21,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                25,
            ],
        ],
        'State House District': [
            [
                71,
                74,
            ],
            [
                0,
                5,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                6,
            ],
            [
                71,
                20,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                2,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                11,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                46,
            ],
            [
                0,
                1,
            ],
            [
                71,
                17,
            ],
            [
                0,
                1,
            ],
            [
                71,
                21,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                25,
            ],
        ],
        'State Senate District': [
            [
                47,
                74,
            ],
            [
                0,
                5,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                6,
            ],
            [
                47,
                20,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                2,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                11,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                46,
            ],
            [
                0,
                1,
            ],
            [
                47,
                17,
            ],
            [
                0,
                1,
            ],
            [
                47,
                21,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                32,
                3,
            ],
            [
                33,
                10,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Urban Center': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'ZIP': [
            [
                466,
                30,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                2,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                2,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                2,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                2,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                3,
            ],
            [
                463,
                1,
            ],
            [
                466,
                5,
            ],
            [
                463,
                1,
            ],
            [
                466,
                11,
            ],
            [
                0,
                5,
            ],
            [
                466,
                4,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                4,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                6,
            ],
            [
                466,
                3,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                460,
                6,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                2,
            ],
            [
                463,
                1,
            ],
            [
                466,
                2,
            ],
            [
                0,
                1,
            ],
            [
                461,
                1,
            ],
            [
                0,
                1,
            ],
            [
                461,
                1,
            ],
            [
                0,
                1,
            ],
            [
                461,
                1,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                3,
            ],
            [
                460,
                1,
            ],
            [
                466,
                7,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                1,
            ],
            [
                0,
                1,
            ],
            [
                466,
                12,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                465,
                25,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                5,
            ],
            [
                0,
                1,
            ],
            [
                466,
                2,
            ],
            [
                465,
                1,
            ],
            [
                466,
                1,
            ],
            [
                364,
                3,
            ],
            [
                425,
                3,
            ],
            [
                457,
                3,
            ],
            [
                460,
                1,
            ],
            [
                466,
                3,
            ],
            [
                0,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                2,
            ],
            [
                463,
                1,
            ],
            [
                466,
                1,
            ],
            [
                455,
                1,
            ],
            [
                462,
                1,
            ],
            [
                466,
                1,
            ],
            [
                455,
                1,
            ],
            [
                462,
                1,
            ],
            [
                466,
                10,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                1,
            ],
            [
                0,
                1,
            ],
            [
                465,
                5,
            ],
            [
                463,
                1,
            ],
            [
                458,
                1,
            ],
            [
                460,
                1,
            ],
            [
                461,
                3,
            ],
            [
                466,
                1,
            ],
            [
                463,
                1,
            ],
            [
                466,
                6,
            ],
            [
                461,
                6,
            ],
        ],
    },
    'Massachusetts, USA': {
        'overall': [
            [
                2488,
                3,
            ],
            [
                2487,
                26,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2489,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                4,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                3,
            ],
            [
                2489,
                1,
            ],
            [
                2488,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2488,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                5,
            ],
            [
                2489,
                2,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2484,
                6,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2484,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2484,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2484,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                3,
            ],
            [
                2484,
                1,
            ],
            [
                2488,
                3,
            ],
            [
                2487,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                12,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                26,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                4,
            ],
            [
                2385,
                3,
            ],
            [
                2424,
                3,
            ],
            [
                2472,
                3,
            ],
            [
                2484,
                1,
            ],
            [
                2488,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2469,
                1,
            ],
            [
                2483,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2468,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2488,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2488,
                5,
            ],
            [
                2486,
                1,
            ],
            [
                2481,
                1,
            ],
            [
                2484,
                4,
            ],
            [
                2488,
                1,
            ],
            [
                2482,
                1,
            ],
            [
                2487,
                1,
            ],
            [
                2488,
                5,
            ],
            [
                2486,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                351,
                74,
            ],
            [
                0,
                5,
            ],
            [
                351,
                4,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                4,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                6,
            ],
            [
                351,
                20,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                4,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                2,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                11,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                46,
            ],
            [
                0,
                1,
            ],
            [
                351,
                4,
            ],
            [
                334,
                3,
            ],
            [
                340,
                3,
            ],
            [
                350,
                3,
            ],
            [
                351,
                4,
            ],
            [
                0,
                1,
            ],
            [
                351,
                5,
            ],
            [
                349,
                1,
            ],
            [
                351,
                2,
            ],
            [
                349,
                1,
            ],
            [
                351,
                12,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                1,
            ],
            [
                0,
                1,
            ],
            [
                351,
                25,
            ],
        ],
        'CSA': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'City': [
            [
                248,
                74,
            ],
            [
                0,
                5,
            ],
            [
                248,
                4,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                4,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                6,
            ],
            [
                248,
                20,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                4,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                2,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                11,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                46,
            ],
            [
                0,
                1,
            ],
            [
                248,
                4,
            ],
            [
                224,
                3,
            ],
            [
                237,
                3,
            ],
            [
                245,
                3,
            ],
            [
                248,
                4,
            ],
            [
                0,
                1,
            ],
            [
                248,
                5,
            ],
            [
                246,
                1,
            ],
            [
                247,
                1,
            ],
            [
                248,
                1,
            ],
            [
                246,
                1,
            ],
            [
                247,
                1,
            ],
            [
                248,
                11,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                1,
            ],
            [
                0,
                1,
            ],
            [
                248,
                25,
            ],
        ],
        'Congressional District': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'County': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'County Cross CD': [
            [
                36,
                74,
            ],
            [
                0,
                5,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36,
                20,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                2,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                11,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                46,
            ],
            [
                0,
                1,
            ],
            [
                36,
                17,
            ],
            [
                0,
                1,
            ],
            [
                36,
                21,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                386,
                74,
            ],
            [
                0,
                5,
            ],
            [
                386,
                4,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                4,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                6,
            ],
            [
                386,
                20,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                4,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                2,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                11,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                46,
            ],
            [
                0,
                1,
            ],
            [
                386,
                17,
            ],
            [
                0,
                1,
            ],
            [
                386,
                21,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                1,
            ],
            [
                0,
                1,
            ],
            [
                386,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                66,
                74,
            ],
            [
                0,
                5,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                6,
            ],
            [
                66,
                20,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                2,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                11,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                46,
            ],
            [
                0,
                1,
            ],
            [
                66,
                17,
            ],
            [
                0,
                1,
            ],
            [
                66,
                21,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Media Market': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Native Area': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                2,
                3,
            ],
            [
                3,
                10,
            ],
            [
                0,
                1,
            ],
            [
                3,
                5,
            ],
            [
                2,
                3,
            ],
            [
                1,
                2,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Neighborhood': [
            [
                277,
                3,
            ],
            [
                276,
                26,
            ],
            [
                277,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                2,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                4,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                10,
            ],
            [
                0,
                5,
            ],
            [
                277,
                4,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                4,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                6,
            ],
            [
                277,
                3,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                7,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                2,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                2,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                7,
            ],
            [
                276,
                4,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                12,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                26,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                4,
            ],
            [
                0,
                1,
            ],
            [
                277,
                4,
            ],
            [
                250,
                3,
            ],
            [
                262,
                3,
            ],
            [
                274,
                3,
            ],
            [
                277,
                4,
            ],
            [
                0,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                1,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                272,
                1,
            ],
            [
                275,
                1,
            ],
            [
                277,
                1,
            ],
            [
                272,
                1,
            ],
            [
                275,
                1,
            ],
            [
                277,
                11,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                1,
            ],
            [
                0,
                1,
            ],
            [
                277,
                6,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                4,
            ],
            [
                274,
                1,
            ],
            [
                276,
                1,
            ],
            [
                277,
                11,
            ],
        ],
        'School District': [
            [
                303,
                74,
            ],
            [
                0,
                5,
            ],
            [
                303,
                4,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                4,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                6,
            ],
            [
                303,
                20,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                4,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                2,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                11,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                46,
            ],
            [
                0,
                1,
            ],
            [
                303,
                4,
            ],
            [
                297,
                3,
            ],
            [
                301,
                3,
            ],
            [
                303,
                7,
            ],
            [
                0,
                1,
            ],
            [
                303,
                5,
            ],
            [
                302,
                1,
            ],
            [
                303,
                2,
            ],
            [
                302,
                1,
            ],
            [
                303,
                12,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                1,
            ],
            [
                0,
                1,
            ],
            [
                303,
                25,
            ],
        ],
        'State House District': [
            [
                160,
                74,
            ],
            [
                0,
                5,
            ],
            [
                160,
                4,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                4,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                6,
            ],
            [
                160,
                20,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                4,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                2,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                11,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                46,
            ],
            [
                0,
                1,
            ],
            [
                160,
                17,
            ],
            [
                0,
                1,
            ],
            [
                160,
                21,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                1,
            ],
            [
                0,
                1,
            ],
            [
                160,
                25,
            ],
        ],
        'State Senate District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                23,
                74,
            ],
            [
                0,
                5,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                20,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                2,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                11,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                46,
            ],
            [
                0,
                1,
            ],
            [
                23,
                17,
            ],
            [
                0,
                1,
            ],
            [
                23,
                21,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                25,
            ],
        ],
        'Urban Center': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'ZIP': [
            [
                535,
                30,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                2,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                2,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                2,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                2,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                3,
            ],
            [
                532,
                1,
            ],
            [
                535,
                5,
            ],
            [
                532,
                1,
            ],
            [
                535,
                11,
            ],
            [
                0,
                5,
            ],
            [
                535,
                4,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                4,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                6,
            ],
            [
                535,
                3,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                531,
                6,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                2,
            ],
            [
                532,
                1,
            ],
            [
                535,
                2,
            ],
            [
                0,
                1,
            ],
            [
                531,
                1,
            ],
            [
                0,
                1,
            ],
            [
                531,
                1,
            ],
            [
                0,
                1,
            ],
            [
                531,
                1,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                2,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                3,
            ],
            [
                531,
                1,
            ],
            [
                535,
                7,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                12,
            ],
            [
                532,
                1,
            ],
            [
                535,
                27,
            ],
            [
                532,
                1,
            ],
            [
                535,
                5,
            ],
            [
                0,
                1,
            ],
            [
                535,
                4,
            ],
            [
                507,
                3,
            ],
            [
                510,
                3,
            ],
            [
                526,
                3,
            ],
            [
                531,
                1,
            ],
            [
                535,
                3,
            ],
            [
                0,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                2,
            ],
            [
                532,
                1,
            ],
            [
                535,
                1,
            ],
            [
                527,
                1,
            ],
            [
                534,
                1,
            ],
            [
                535,
                1,
            ],
            [
                527,
                1,
            ],
            [
                534,
                1,
            ],
            [
                535,
                11,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                535,
                5,
            ],
            [
                533,
                1,
            ],
            [
                531,
                1,
            ],
            [
                532,
                1,
            ],
            [
                531,
                3,
            ],
            [
                535,
                1,
            ],
            [
                532,
                1,
            ],
            [
                535,
                6,
            ],
            [
                533,
                6,
            ],
        ],
    },
    'Michigan, USA': {
        'overall': [
            [
                5060,
                3,
            ],
            [
                5053,
                26,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5069,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5059,
                3,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5059,
                3,
            ],
            [
                5069,
                1,
            ],
            [
                5060,
                6,
            ],
            [
                0,
                5,
            ],
            [
                5058,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                5,
            ],
            [
                5069,
                2,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5055,
                6,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5056,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5056,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5056,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                3,
            ],
            [
                5055,
                1,
            ],
            [
                5059,
                3,
            ],
            [
                5053,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5060,
                12,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5058,
                25,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5060,
                2,
            ],
            [
                5058,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                4551,
                3,
            ],
            [
                4892,
                3,
            ],
            [
                5031,
                3,
            ],
            [
                5055,
                1,
            ],
            [
                5060,
                3,
            ],
            [
                0,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5014,
                1,
            ],
            [
                5037,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5012,
                1,
            ],
            [
                5037,
                1,
            ],
            [
                5060,
                1,
            ],
            [
                5058,
                5,
            ],
            [
                5060,
                4,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5058,
                5,
            ],
            [
                5059,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5052,
                1,
            ],
            [
                5056,
                3,
            ],
            [
                5060,
                1,
            ],
            [
                5043,
                1,
            ],
            [
                5053,
                1,
            ],
            [
                5060,
                5,
            ],
            [
                5056,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                54,
            ],
            [
                3,
                1,
            ],
            [
                1,
                12,
            ],
            [
                3,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                3,
                1,
            ],
            [
                1,
                12,
            ],
            [
                3,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                3,
                54,
            ],
            [
                4,
                1,
            ],
            [
                3,
                12,
            ],
            [
                4,
                1,
            ],
            [
                3,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                7,
            ],
            [
                3,
                18,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'CCD': [
            [
                1535,
                3,
            ],
            [
                1534,
                26,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                4,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                4,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                4,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                4,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                4,
            ],
            [
                1535,
                2,
            ],
            [
                1534,
                2,
            ],
            [
                1535,
                4,
            ],
            [
                1534,
                2,
            ],
            [
                1535,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1535,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1535,
                3,
            ],
            [
                1534,
                8,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                4,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                2,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1534,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                3,
            ],
            [
                1534,
                1,
            ],
            [
                1535,
                3,
            ],
            [
                1534,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                12,
            ],
            [
                1534,
                2,
            ],
            [
                1535,
                26,
            ],
            [
                1534,
                2,
            ],
            [
                1535,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                4,
            ],
            [
                1293,
                3,
            ],
            [
                1491,
                3,
            ],
            [
                1532,
                3,
            ],
            [
                1534,
                1,
            ],
            [
                1535,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1534,
                2,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                2,
            ],
            [
                1529,
                1,
            ],
            [
                1530,
                1,
            ],
            [
                1534,
                1,
            ],
            [
                1529,
                1,
            ],
            [
                1530,
                1,
            ],
            [
                1535,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1535,
                6,
            ],
            [
                1534,
                5,
            ],
            [
                1535,
                1,
            ],
            [
                1534,
                2,
            ],
            [
                1535,
                11,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                745,
                74,
            ],
            [
                0,
                5,
            ],
            [
                745,
                4,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                4,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                6,
            ],
            [
                745,
                20,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                4,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                2,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                11,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                46,
            ],
            [
                0,
                1,
            ],
            [
                745,
                4,
            ],
            [
                644,
                3,
            ],
            [
                711,
                3,
            ],
            [
                742,
                3,
            ],
            [
                745,
                4,
            ],
            [
                0,
                1,
            ],
            [
                745,
                5,
            ],
            [
                735,
                1,
            ],
            [
                738,
                1,
            ],
            [
                745,
                1,
            ],
            [
                735,
                1,
            ],
            [
                738,
                1,
            ],
            [
                745,
                11,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                745,
                25,
            ],
        ],
        'Congressional District': [
            [
                13,
                74,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13,
                20,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        'County': [
            [
                83,
                74,
            ],
            [
                0,
                5,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                6,
            ],
            [
                83,
                20,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                2,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                11,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                46,
            ],
            [
                0,
                1,
            ],
            [
                83,
                17,
            ],
            [
                0,
                1,
            ],
            [
                83,
                21,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                25,
            ],
        ],
        'County Cross CD': [
            [
                104,
                74,
            ],
            [
                0,
                5,
            ],
            [
                104,
                4,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                4,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                6,
            ],
            [
                104,
                20,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                4,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                2,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                11,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                46,
            ],
            [
                0,
                1,
            ],
            [
                104,
                4,
            ],
            [
                102,
                3,
            ],
            [
                103,
                3,
            ],
            [
                104,
                7,
            ],
            [
                0,
                1,
            ],
            [
                104,
                21,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                1,
            ],
            [
                0,
                1,
            ],
            [
                104,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                230,
                74,
            ],
            [
                0,
                5,
            ],
            [
                230,
                4,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                4,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                6,
            ],
            [
                230,
                20,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                4,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                2,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                11,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                46,
            ],
            [
                0,
                1,
            ],
            [
                230,
                17,
            ],
            [
                0,
                1,
            ],
            [
                230,
                21,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                1,
            ],
            [
                0,
                1,
            ],
            [
                230,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                20,
                74,
            ],
            [
                0,
                5,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                6,
            ],
            [
                20,
                20,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                2,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                11,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                46,
            ],
            [
                0,
                1,
            ],
            [
                20,
                17,
            ],
            [
                0,
                1,
            ],
            [
                20,
                21,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                116,
                74,
            ],
            [
                0,
                5,
            ],
            [
                116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                6,
            ],
            [
                116,
                20,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                4,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                2,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                11,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                46,
            ],
            [
                0,
                1,
            ],
            [
                116,
                17,
            ],
            [
                0,
                1,
            ],
            [
                116,
                21,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                1,
            ],
            [
                0,
                1,
            ],
            [
                116,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'Media Market': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Native Area': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                11,
                3,
            ],
            [
                12,
                10,
            ],
            [
                0,
                1,
            ],
            [
                12,
                5,
            ],
            [
                11,
                1,
            ],
            [
                12,
                2,
            ],
            [
                11,
                1,
            ],
            [
                12,
                12,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Neighborhood': [
            [
                365,
                3,
            ],
            [
                363,
                26,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                2,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                4,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                10,
            ],
            [
                0,
                5,
            ],
            [
                365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                6,
            ],
            [
                365,
                3,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                364,
                6,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                364,
                1,
            ],
            [
                0,
                1,
            ],
            [
                364,
                1,
            ],
            [
                0,
                1,
            ],
            [
                364,
                1,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                2,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                3,
            ],
            [
                364,
                1,
            ],
            [
                365,
                3,
            ],
            [
                363,
                4,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                12,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                26,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                4,
            ],
            [
                0,
                1,
            ],
            [
                365,
                4,
            ],
            [
                287,
                3,
            ],
            [
                314,
                3,
            ],
            [
                351,
                3,
            ],
            [
                364,
                1,
            ],
            [
                365,
                3,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                351,
                1,
            ],
            [
                362,
                1,
            ],
            [
                365,
                1,
            ],
            [
                351,
                1,
            ],
            [
                362,
                1,
            ],
            [
                365,
                11,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                1,
            ],
            [
                0,
                1,
            ],
            [
                365,
                5,
            ],
            [
                364,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                364,
                3,
            ],
            [
                365,
                1,
            ],
            [
                354,
                1,
            ],
            [
                363,
                1,
            ],
            [
                365,
                5,
            ],
            [
                364,
                6,
            ],
        ],
        'School District': [
            [
                541,
                74,
            ],
            [
                0,
                5,
            ],
            [
                541,
                4,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                4,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                6,
            ],
            [
                541,
                20,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                4,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                2,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                11,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                46,
            ],
            [
                0,
                1,
            ],
            [
                541,
                4,
            ],
            [
                536,
                3,
            ],
            [
                538,
                3,
            ],
            [
                539,
                3,
            ],
            [
                541,
                4,
            ],
            [
                0,
                1,
            ],
            [
                541,
                5,
            ],
            [
                537,
                1,
            ],
            [
                540,
                2,
            ],
            [
                536,
                1,
            ],
            [
                540,
                1,
            ],
            [
                541,
                11,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                1,
            ],
            [
                0,
                1,
            ],
            [
                541,
                25,
            ],
        ],
        'State House District': [
            [
                110,
                74,
            ],
            [
                0,
                5,
            ],
            [
                110,
                4,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                4,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                6,
            ],
            [
                110,
                20,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                4,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                2,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                11,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                46,
            ],
            [
                0,
                1,
            ],
            [
                110,
                17,
            ],
            [
                0,
                1,
            ],
            [
                110,
                21,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                25,
            ],
        ],
        'State Senate District': [
            [
                38,
                74,
            ],
            [
                0,
                5,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                6,
            ],
            [
                38,
                20,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                2,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                11,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                46,
            ],
            [
                0,
                1,
            ],
            [
                38,
                17,
            ],
            [
                0,
                1,
            ],
            [
                38,
                21,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                98,
                3,
            ],
            [
                99,
                10,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'Urban Center': [
            [
                8,
                54,
            ],
            [
                11,
                1,
            ],
            [
                8,
                12,
            ],
            [
                11,
                1,
            ],
            [
                8,
                6,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                7,
            ],
            [
                8,
                18,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'ZIP': [
            [
                979,
                3,
            ],
            [
                975,
                26,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                2,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                978,
                3,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                978,
                3,
            ],
            [
                979,
                7,
            ],
            [
                0,
                5,
            ],
            [
                977,
                4,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                4,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                6,
            ],
            [
                979,
                3,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                976,
                6,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                0,
                1,
            ],
            [
                978,
                1,
            ],
            [
                0,
                1,
            ],
            [
                978,
                1,
            ],
            [
                0,
                1,
            ],
            [
                978,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                978,
                3,
            ],
            [
                976,
                1,
            ],
            [
                978,
                3,
            ],
            [
                975,
                4,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                979,
                12,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                977,
                25,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                4,
            ],
            [
                0,
                1,
            ],
            [
                979,
                2,
            ],
            [
                977,
                1,
            ],
            [
                979,
                1,
            ],
            [
                900,
                3,
            ],
            [
                944,
                3,
            ],
            [
                972,
                3,
            ],
            [
                976,
                1,
            ],
            [
                979,
                3,
            ],
            [
                0,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                968,
                1,
            ],
            [
                972,
                1,
            ],
            [
                978,
                1,
            ],
            [
                967,
                1,
            ],
            [
                972,
                1,
            ],
            [
                979,
                1,
            ],
            [
                977,
                5,
            ],
            [
                979,
                4,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                1,
            ],
            [
                0,
                1,
            ],
            [
                977,
                5,
            ],
            [
                979,
                1,
            ],
            [
                974,
                2,
            ],
            [
                977,
                3,
            ],
            [
                979,
                1,
            ],
            [
                974,
                1,
            ],
            [
                975,
                1,
            ],
            [
                979,
                5,
            ],
            [
                976,
                6,
            ],
        ],
    },
    'Minnesota, USA': {
        'overall': [
            [
                5962,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                5960,
                1,
            ],
            [
                5956,
                26,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5968,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                4,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                3,
            ],
            [
                5968,
                1,
            ],
            [
                5965,
                6,
            ],
            [
                0,
                5,
            ],
            [
                5959,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                3,
            ],
            [
                5964,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5964,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5964,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5964,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5964,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5964,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                5,
            ],
            [
                5968,
                2,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5954,
                6,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5954,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5954,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5954,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5960,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5960,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5960,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5965,
                3,
            ],
            [
                5954,
                1,
            ],
            [
                5965,
                3,
            ],
            [
                5956,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5965,
                12,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5959,
                25,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5965,
                4,
            ],
            [
                5065,
                3,
            ],
            [
                5759,
                3,
            ],
            [
                5924,
                3,
            ],
            [
                5954,
                1,
            ],
            [
                5965,
                3,
            ],
            [
                0,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5570,
                1,
            ],
            [
                5704,
                1,
            ],
            [
                5891,
                1,
            ],
            [
                5535,
                1,
            ],
            [
                5672,
                1,
            ],
            [
                5965,
                1,
            ],
            [
                5964,
                5,
            ],
            [
                5965,
                4,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5959,
                5,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5954,
                3,
            ],
            [
                5965,
                1,
            ],
            [
                5948,
                1,
            ],
            [
                5956,
                1,
            ],
            [
                5965,
                5,
            ],
            [
                5964,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CCD': [
            [
                2740,
                1,
            ],
            [
                2737,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                2736,
                26,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                4,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                4,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                4,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                4,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                4,
            ],
            [
                2743,
                2,
            ],
            [
                2736,
                2,
            ],
            [
                2743,
                4,
            ],
            [
                2736,
                2,
            ],
            [
                2743,
                10,
            ],
            [
                0,
                5,
            ],
            [
                2738,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                3,
            ],
            [
                2743,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2743,
                3,
            ],
            [
                2736,
                2,
            ],
            [
                2735,
                6,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                4,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                2,
            ],
            [
                2743,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2735,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2735,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2735,
                1,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                3,
            ],
            [
                2735,
                1,
            ],
            [
                2743,
                3,
            ],
            [
                2736,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                12,
            ],
            [
                2736,
                2,
            ],
            [
                2738,
                25,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                2,
            ],
            [
                2743,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2743,
                4,
            ],
            [
                2134,
                3,
            ],
            [
                2615,
                3,
            ],
            [
                2721,
                3,
            ],
            [
                2735,
                1,
            ],
            [
                2743,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2736,
                2,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                2,
            ],
            [
                2451,
                1,
            ],
            [
                2549,
                1,
            ],
            [
                2686,
                1,
            ],
            [
                2426,
                1,
            ],
            [
                2527,
                1,
            ],
            [
                2743,
                10,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2738,
                5,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                2,
            ],
            [
                2735,
                3,
            ],
            [
                2743,
                1,
            ],
            [
                2736,
                2,
            ],
            [
                2743,
                11,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                914,
                30,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                2,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                2,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                2,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                2,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                3,
            ],
            [
                911,
                1,
            ],
            [
                914,
                5,
            ],
            [
                911,
                1,
            ],
            [
                914,
                11,
            ],
            [
                0,
                5,
            ],
            [
                914,
                4,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                4,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                6,
            ],
            [
                914,
                3,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                913,
                6,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                2,
            ],
            [
                911,
                1,
            ],
            [
                914,
                2,
            ],
            [
                0,
                1,
            ],
            [
                913,
                1,
            ],
            [
                0,
                1,
            ],
            [
                913,
                1,
            ],
            [
                0,
                1,
            ],
            [
                913,
                1,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                2,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                3,
            ],
            [
                913,
                1,
            ],
            [
                914,
                7,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                12,
            ],
            [
                911,
                1,
            ],
            [
                914,
                27,
            ],
            [
                911,
                1,
            ],
            [
                914,
                5,
            ],
            [
                0,
                1,
            ],
            [
                914,
                4,
            ],
            [
                761,
                3,
            ],
            [
                886,
                3,
            ],
            [
                911,
                3,
            ],
            [
                913,
                1,
            ],
            [
                914,
                3,
            ],
            [
                0,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                2,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                837,
                1,
            ],
            [
                861,
                1,
            ],
            [
                902,
                1,
            ],
            [
                831,
                1,
            ],
            [
                855,
                1,
            ],
            [
                914,
                11,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                1,
            ],
            [
                0,
                1,
            ],
            [
                914,
                6,
            ],
            [
                911,
                1,
            ],
            [
                914,
                1,
            ],
            [
                913,
                3,
            ],
            [
                914,
                1,
            ],
            [
                911,
                1,
            ],
            [
                914,
                12,
            ],
        ],
        'Congressional District': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'County': [
            [
                87,
                74,
            ],
            [
                0,
                5,
            ],
            [
                87,
                4,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                4,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                6,
            ],
            [
                87,
                20,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                4,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                2,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                11,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                46,
            ],
            [
                0,
                1,
            ],
            [
                87,
                17,
            ],
            [
                0,
                1,
            ],
            [
                87,
                21,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                1,
            ],
            [
                0,
                1,
            ],
            [
                87,
                25,
            ],
        ],
        'County Cross CD': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                98,
                3,
            ],
            [
                99,
                10,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                120,
                74,
            ],
            [
                0,
                5,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                6,
            ],
            [
                120,
                20,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                2,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                11,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                46,
            ],
            [
                0,
                1,
            ],
            [
                120,
                17,
            ],
            [
                0,
                1,
            ],
            [
                120,
                21,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                129,
                74,
            ],
            [
                0,
                5,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                6,
            ],
            [
                129,
                20,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                2,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                11,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                46,
            ],
            [
                0,
                1,
            ],
            [
                129,
                17,
            ],
            [
                0,
                1,
            ],
            [
                129,
                21,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Area': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                10,
                3,
            ],
            [
                12,
                10,
            ],
            [
                0,
                1,
            ],
            [
                12,
                5,
            ],
            [
                11,
                1,
            ],
            [
                12,
                2,
            ],
            [
                11,
                1,
            ],
            [
                12,
                12,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                17,
                3,
            ],
            [
                16,
                26,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                2,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                4,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                10,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                3,
            ],
            [
                13,
                1,
            ],
            [
                16,
                7,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                3,
            ],
            [
                16,
                1,
            ],
            [
                17,
                3,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                12,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                26,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                10,
                3,
            ],
            [
                13,
                6,
            ],
            [
                16,
                1,
            ],
            [
                17,
                3,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                6,
            ],
            [
                13,
                1,
            ],
            [
                16,
                4,
            ],
            [
                17,
                1,
            ],
            [
                13,
                1,
            ],
            [
                16,
                1,
            ],
            [
                17,
                11,
            ],
        ],
        'Neighborhood': [
            [
                285,
                30,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                2,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                2,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                2,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                2,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                3,
            ],
            [
                284,
                1,
            ],
            [
                285,
                5,
            ],
            [
                284,
                1,
            ],
            [
                285,
                11,
            ],
            [
                0,
                5,
            ],
            [
                284,
                4,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                4,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                6,
            ],
            [
                285,
                3,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                6,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                2,
            ],
            [
                284,
                1,
            ],
            [
                285,
                2,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                0,
                1,
            ],
            [
                285,
                1,
            ],
            [
                0,
                1,
            ],
            [
                285,
                1,
            ],
            [
                0,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                285,
                3,
            ],
            [
                284,
                1,
            ],
            [
                285,
                7,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                285,
                12,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                25,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                5,
            ],
            [
                0,
                1,
            ],
            [
                285,
                4,
            ],
            [
                226,
                3,
            ],
            [
                252,
                3,
            ],
            [
                276,
                3,
            ],
            [
                284,
                1,
            ],
            [
                285,
                3,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                2,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                281,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                281,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                5,
            ],
            [
                285,
                4,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                1,
            ],
            [
                0,
                1,
            ],
            [
                284,
                5,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                1,
            ],
            [
                284,
                3,
            ],
            [
                285,
                1,
            ],
            [
                284,
                1,
            ],
            [
                285,
                6,
            ],
            [
                284,
                6,
            ],
        ],
        'School District': [
            [
                331,
                74,
            ],
            [
                0,
                5,
            ],
            [
                331,
                4,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                4,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                6,
            ],
            [
                331,
                20,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                4,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                2,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                11,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                46,
            ],
            [
                0,
                1,
            ],
            [
                331,
                4,
            ],
            [
                330,
                3,
            ],
            [
                331,
                10,
            ],
            [
                0,
                1,
            ],
            [
                331,
                21,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                1,
            ],
            [
                0,
                1,
            ],
            [
                331,
                25,
            ],
        ],
        'State House District': [
            [
                134,
                74,
            ],
            [
                0,
                5,
            ],
            [
                134,
                4,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                4,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                6,
            ],
            [
                134,
                20,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                4,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                2,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                11,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                46,
            ],
            [
                0,
                1,
            ],
            [
                134,
                17,
            ],
            [
                0,
                1,
            ],
            [
                134,
                21,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                1,
            ],
            [
                0,
                1,
            ],
            [
                134,
                25,
            ],
        ],
        'State Senate District': [
            [
                67,
                74,
            ],
            [
                0,
                5,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                6,
            ],
            [
                67,
                20,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                46,
            ],
            [
                0,
                1,
            ],
            [
                67,
                17,
            ],
            [
                0,
                1,
            ],
            [
                67,
                21,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                73,
                74,
            ],
            [
                0,
                5,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                6,
            ],
            [
                73,
                20,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                2,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                11,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                46,
            ],
            [
                0,
                1,
            ],
            [
                73,
                17,
            ],
            [
                0,
                1,
            ],
            [
                73,
                21,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                25,
            ],
        ],
        'Urban Center': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'ZIP': [
            [
                887,
                3,
            ],
            [
                886,
                26,
            ],
            [
                887,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                2,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                4,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                10,
            ],
            [
                0,
                5,
            ],
            [
                887,
                4,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                4,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                6,
            ],
            [
                887,
                3,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                7,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                2,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                2,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                7,
            ],
            [
                886,
                4,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                12,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                26,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                4,
            ],
            [
                0,
                1,
            ],
            [
                887,
                4,
            ],
            [
                819,
                3,
            ],
            [
                874,
                3,
            ],
            [
                884,
                3,
            ],
            [
                887,
                4,
            ],
            [
                0,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                1,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                870,
                1,
            ],
            [
                875,
                1,
            ],
            [
                882,
                1,
            ],
            [
                866,
                1,
            ],
            [
                871,
                1,
            ],
            [
                887,
                11,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                887,
                6,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                4,
            ],
            [
                885,
                1,
            ],
            [
                886,
                1,
            ],
            [
                887,
                11,
            ],
        ],
    },
    'Mississippi, USA': {
        'overall': [
            [
                2189,
                3,
            ],
            [
                2188,
                26,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                2,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                4,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                10,
            ],
            [
                0,
                5,
            ],
            [
                2189,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2187,
                3,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                5,
            ],
            [
                2189,
                3,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                7,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                2,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                3,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                3,
            ],
            [
                2188,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                12,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                26,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                4,
            ],
            [
                1866,
                3,
            ],
            [
                2056,
                3,
            ],
            [
                2175,
                3,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2174,
                1,
            ],
            [
                2175,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                2174,
                1,
            ],
            [
                2175,
                1,
            ],
            [
                2189,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2189,
                6,
            ],
            [
                2185,
                1,
            ],
            [
                2186,
                1,
            ],
            [
                2189,
                4,
            ],
            [
                2185,
                1,
            ],
            [
                2188,
                1,
            ],
            [
                2189,
                5,
            ],
            [
                2188,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'CCD': [
            [
                410,
                74,
            ],
            [
                0,
                5,
            ],
            [
                410,
                4,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                4,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                6,
            ],
            [
                410,
                20,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                4,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                2,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                11,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                46,
            ],
            [
                0,
                1,
            ],
            [
                410,
                4,
            ],
            [
                378,
                3,
            ],
            [
                403,
                3,
            ],
            [
                406,
                3,
            ],
            [
                410,
                4,
            ],
            [
                0,
                1,
            ],
            [
                410,
                21,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                1,
            ],
            [
                0,
                1,
            ],
            [
                410,
                25,
            ],
        ],
        'CSA': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'City': [
            [
                427,
                3,
            ],
            [
                426,
                26,
            ],
            [
                427,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                2,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                4,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                10,
            ],
            [
                0,
                5,
            ],
            [
                427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                3,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                6,
            ],
            [
                427,
                3,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                7,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                2,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                2,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                7,
            ],
            [
                426,
                4,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                12,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                26,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                427,
                4,
            ],
            [
                283,
                3,
            ],
            [
                363,
                3,
            ],
            [
                423,
                3,
            ],
            [
                427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                415,
                1,
            ],
            [
                419,
                1,
            ],
            [
                427,
                1,
            ],
            [
                415,
                1,
            ],
            [
                419,
                1,
            ],
            [
                427,
                11,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                6,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                4,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                427,
                11,
            ],
        ],
        'Congressional District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'County': [
            [
                82,
                74,
            ],
            [
                0,
                5,
            ],
            [
                82,
                4,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                4,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                6,
            ],
            [
                82,
                20,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                4,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                2,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                11,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                46,
            ],
            [
                0,
                1,
            ],
            [
                82,
                17,
            ],
            [
                0,
                1,
            ],
            [
                82,
                21,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                25,
            ],
        ],
        'County Cross CD': [
            [
                86,
                74,
            ],
            [
                0,
                5,
            ],
            [
                86,
                4,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                4,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                6,
            ],
            [
                86,
                20,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                4,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                2,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                11,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                46,
            ],
            [
                0,
                1,
            ],
            [
                86,
                17,
            ],
            [
                0,
                1,
            ],
            [
                86,
                21,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                129,
                74,
            ],
            [
                0,
                5,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                6,
            ],
            [
                129,
                20,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                2,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                11,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                46,
            ],
            [
                0,
                1,
            ],
            [
                129,
                17,
            ],
            [
                0,
                1,
            ],
            [
                129,
                21,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                65,
                74,
            ],
            [
                0,
                5,
            ],
            [
                65,
                4,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                4,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                6,
            ],
            [
                65,
                20,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                4,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                2,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                11,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                46,
            ],
            [
                0,
                1,
            ],
            [
                65,
                17,
            ],
            [
                0,
                1,
            ],
            [
                65,
                21,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                1,
            ],
            [
                0,
                1,
            ],
            [
                65,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                23,
                74,
            ],
            [
                0,
                5,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                20,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                2,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                11,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                46,
            ],
            [
                0,
                1,
            ],
            [
                23,
                17,
            ],
            [
                0,
                1,
            ],
            [
                23,
                21,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                25,
            ],
        ],
        'Media Market': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Neighborhood': [
            [
                155,
                30,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                2,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                2,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                2,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                2,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                3,
            ],
            [
                154,
                1,
            ],
            [
                155,
                5,
            ],
            [
                154,
                1,
            ],
            [
                155,
                11,
            ],
            [
                0,
                5,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                6,
            ],
            [
                155,
                3,
            ],
            [
                154,
                1,
            ],
            [
                155,
                8,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                2,
            ],
            [
                154,
                1,
            ],
            [
                155,
                2,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                2,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                2,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                11,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                12,
            ],
            [
                154,
                1,
            ],
            [
                155,
                27,
            ],
            [
                154,
                1,
            ],
            [
                155,
                5,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                99,
                3,
            ],
            [
                124,
                3,
            ],
            [
                155,
                7,
            ],
            [
                0,
                1,
            ],
            [
                154,
                1,
            ],
            [
                155,
                2,
            ],
            [
                154,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                151,
                1,
            ],
            [
                155,
                1,
            ],
            [
                154,
                1,
            ],
            [
                151,
                1,
            ],
            [
                155,
                11,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                6,
            ],
            [
                154,
                1,
            ],
            [
                155,
                5,
            ],
            [
                154,
                1,
            ],
            [
                155,
                12,
            ],
        ],
        'School District': [
            [
                137,
                74,
            ],
            [
                0,
                5,
            ],
            [
                137,
                4,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                4,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                6,
            ],
            [
                137,
                20,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                4,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                2,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                11,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                46,
            ],
            [
                0,
                1,
            ],
            [
                137,
                17,
            ],
            [
                0,
                1,
            ],
            [
                137,
                21,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                25,
            ],
        ],
        'State House District': [
            [
                122,
                74,
            ],
            [
                0,
                5,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                6,
            ],
            [
                122,
                20,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                2,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                11,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                46,
            ],
            [
                0,
                1,
            ],
            [
                122,
                4,
            ],
            [
                121,
                6,
            ],
            [
                122,
                7,
            ],
            [
                0,
                1,
            ],
            [
                122,
                21,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                25,
            ],
        ],
        'State Senate District': [
            [
                52,
                74,
            ],
            [
                0,
                5,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                6,
            ],
            [
                52,
                20,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                2,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                11,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                46,
            ],
            [
                0,
                1,
            ],
            [
                52,
                17,
            ],
            [
                0,
                1,
            ],
            [
                52,
                21,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                11,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                17,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                421,
                30,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                2,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                2,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                2,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                2,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                3,
            ],
            [
                420,
                1,
            ],
            [
                421,
                5,
            ],
            [
                420,
                1,
            ],
            [
                421,
                11,
            ],
            [
                0,
                5,
            ],
            [
                421,
                4,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                420,
                3,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                6,
            ],
            [
                421,
                3,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                420,
                6,
            ],
            [
                421,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                2,
            ],
            [
                420,
                1,
            ],
            [
                421,
                2,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                2,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                2,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                3,
            ],
            [
                420,
                1,
            ],
            [
                421,
                7,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                12,
            ],
            [
                420,
                1,
            ],
            [
                421,
                27,
            ],
            [
                420,
                1,
            ],
            [
                421,
                5,
            ],
            [
                0,
                1,
            ],
            [
                421,
                4,
            ],
            [
                331,
                3,
            ],
            [
                391,
                3,
            ],
            [
                415,
                3,
            ],
            [
                420,
                1,
            ],
            [
                421,
                3,
            ],
            [
                0,
                1,
            ],
            [
                420,
                1,
            ],
            [
                421,
                2,
            ],
            [
                420,
                1,
            ],
            [
                421,
                1,
            ],
            [
                419,
                2,
            ],
            [
                421,
                1,
            ],
            [
                419,
                2,
            ],
            [
                421,
                11,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                6,
            ],
            [
                420,
                1,
            ],
            [
                419,
                1,
            ],
            [
                421,
                4,
            ],
            [
                420,
                1,
            ],
            [
                421,
                6,
            ],
            [
                420,
                6,
            ],
        ],
    },
    'Missouri, USA': {
        'overall': [
            [
                5262,
                3,
            ],
            [
                5257,
                26,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5265,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                4,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                3,
            ],
            [
                5265,
                1,
            ],
            [
                5263,
                6,
            ],
            [
                0,
                5,
            ],
            [
                5263,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                5,
            ],
            [
                5265,
                2,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                7,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                3,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                3,
            ],
            [
                5257,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                12,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                26,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                4,
            ],
            [
                4468,
                3,
            ],
            [
                5039,
                3,
            ],
            [
                5229,
                3,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                3,
            ],
            [
                0,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5097,
                1,
            ],
            [
                5150,
                1,
            ],
            [
                5247,
                1,
            ],
            [
                5092,
                1,
            ],
            [
                5141,
                1,
            ],
            [
                5263,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5263,
                5,
            ],
            [
                5262,
                1,
            ],
            [
                5249,
                1,
            ],
            [
                5256,
                1,
            ],
            [
                5257,
                3,
            ],
            [
                5263,
                1,
            ],
            [
                5250,
                1,
            ],
            [
                5257,
                1,
            ],
            [
                5263,
                5,
            ],
            [
                5262,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'CCD': [
            [
                1394,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1394,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1394,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                4,
            ],
            [
                1071,
                3,
            ],
            [
                1310,
                3,
            ],
            [
                1389,
                3,
            ],
            [
                1394,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                5,
            ],
            [
                1350,
                1,
            ],
            [
                1372,
                1,
            ],
            [
                1389,
                1,
            ],
            [
                1347,
                1,
            ],
            [
                1368,
                1,
            ],
            [
                1394,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1394,
                25,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                1078,
                3,
            ],
            [
                1076,
                26,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                2,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                4,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1078,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1078,
                3,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                6,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                3,
            ],
            [
                1074,
                1,
            ],
            [
                1078,
                3,
            ],
            [
                1076,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                12,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                26,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                4,
            ],
            [
                837,
                3,
            ],
            [
                1005,
                3,
            ],
            [
                1063,
                3,
            ],
            [
                1074,
                1,
            ],
            [
                1078,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                987,
                1,
            ],
            [
                1008,
                1,
            ],
            [
                1071,
                1,
            ],
            [
                986,
                1,
            ],
            [
                1005,
                1,
            ],
            [
                1078,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1078,
                6,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1074,
                3,
            ],
            [
                1078,
                1,
            ],
            [
                1074,
                1,
            ],
            [
                1076,
                1,
            ],
            [
                1078,
                11,
            ],
        ],
        'Congressional District': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'County': [
            [
                115,
                74,
            ],
            [
                0,
                5,
            ],
            [
                115,
                4,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                4,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                6,
            ],
            [
                115,
                20,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                4,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                2,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                11,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                46,
            ],
            [
                0,
                1,
            ],
            [
                115,
                17,
            ],
            [
                0,
                1,
            ],
            [
                115,
                21,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                1,
            ],
            [
                0,
                1,
            ],
            [
                115,
                25,
            ],
        ],
        'County Cross CD': [
            [
                125,
                74,
            ],
            [
                0,
                5,
            ],
            [
                125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                6,
            ],
            [
                125,
                20,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                2,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                11,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                46,
            ],
            [
                0,
                1,
            ],
            [
                125,
                17,
            ],
            [
                0,
                1,
            ],
            [
                125,
                21,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                222,
                74,
            ],
            [
                0,
                5,
            ],
            [
                222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                6,
            ],
            [
                222,
                20,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                4,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                2,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                11,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                46,
            ],
            [
                0,
                1,
            ],
            [
                222,
                17,
            ],
            [
                0,
                1,
            ],
            [
                222,
                21,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                1,
            ],
            [
                0,
                1,
            ],
            [
                222,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                85,
                74,
            ],
            [
                0,
                5,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                6,
            ],
            [
                85,
                20,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                2,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                11,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                46,
            ],
            [
                0,
                1,
            ],
            [
                85,
                17,
            ],
            [
                0,
                1,
            ],
            [
                85,
                21,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                28,
                74,
            ],
            [
                0,
                5,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                6,
            ],
            [
                28,
                20,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                2,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                11,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                46,
            ],
            [
                0,
                1,
            ],
            [
                28,
                17,
            ],
            [
                0,
                1,
            ],
            [
                28,
                21,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                25,
            ],
        ],
        'Media Market': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Neighborhood': [
            [
                348,
                3,
            ],
            [
                347,
                26,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                2,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                4,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                10,
            ],
            [
                0,
                5,
            ],
            [
                349,
                4,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                4,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                6,
            ],
            [
                349,
                3,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                7,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                2,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                2,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                7,
            ],
            [
                347,
                4,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                12,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                26,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                4,
            ],
            [
                0,
                1,
            ],
            [
                349,
                4,
            ],
            [
                294,
                3,
            ],
            [
                323,
                3,
            ],
            [
                343,
                3,
            ],
            [
                349,
                4,
            ],
            [
                0,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                1,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                11,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                349,
                6,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                4,
            ],
            [
                343,
                1,
            ],
            [
                347,
                1,
            ],
            [
                349,
                11,
            ],
        ],
        'School District': [
            [
                516,
                74,
            ],
            [
                0,
                5,
            ],
            [
                516,
                4,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                4,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                6,
            ],
            [
                516,
                20,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                4,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                2,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                11,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                46,
            ],
            [
                0,
                1,
            ],
            [
                516,
                4,
            ],
            [
                495,
                3,
            ],
            [
                515,
                3,
            ],
            [
                516,
                7,
            ],
            [
                0,
                1,
            ],
            [
                516,
                21,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                1,
            ],
            [
                0,
                1,
            ],
            [
                516,
                25,
            ],
        ],
        'State House District': [
            [
                163,
                74,
            ],
            [
                0,
                5,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                6,
            ],
            [
                163,
                20,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                2,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                11,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                46,
            ],
            [
                0,
                1,
            ],
            [
                163,
                17,
            ],
            [
                0,
                1,
            ],
            [
                163,
                21,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                0,
                1,
            ],
            [
                163,
                25,
            ],
        ],
        'State Senate District': [
            [
                34,
                74,
            ],
            [
                0,
                5,
            ],
            [
                34,
                4,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                4,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                6,
            ],
            [
                34,
                20,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                4,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                2,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                11,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                46,
            ],
            [
                0,
                1,
            ],
            [
                34,
                17,
            ],
            [
                0,
                1,
            ],
            [
                34,
                21,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                80,
                74,
            ],
            [
                0,
                5,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                6,
            ],
            [
                80,
                20,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                4,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                2,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                11,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                46,
            ],
            [
                0,
                1,
            ],
            [
                80,
                17,
            ],
            [
                0,
                1,
            ],
            [
                80,
                21,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                1,
            ],
            [
                0,
                1,
            ],
            [
                80,
                25,
            ],
        ],
        'Urban Center': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                1025,
                3,
            ],
            [
                1023,
                26,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                2,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                4,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1025,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1025,
                3,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                7,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                3,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                3,
            ],
            [
                1023,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                12,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                26,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                4,
            ],
            [
                870,
                3,
            ],
            [
                985,
                3,
            ],
            [
                1017,
                3,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1000,
                1,
            ],
            [
                1006,
                1,
            ],
            [
                1021,
                1,
            ],
            [
                999,
                1,
            ],
            [
                1004,
                1,
            ],
            [
                1025,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1025,
                5,
            ],
            [
                1024,
                1,
            ],
            [
                1021,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                3,
            ],
            [
                1025,
                1,
            ],
            [
                1022,
                1,
            ],
            [
                1023,
                1,
            ],
            [
                1025,
                5,
            ],
            [
                1024,
                6,
            ],
        ],
    },
    'Montana, USA': {
        'overall': [
            [
                1888,
                3,
            ],
            [
                1857,
                26,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1894,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1887,
                3,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1887,
                3,
            ],
            [
                1894,
                1,
            ],
            [
                1888,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1886,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                5,
            ],
            [
                1894,
                2,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                7,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1887,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1887,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1887,
                3,
            ],
            [
                1857,
                1,
            ],
            [
                1887,
                3,
            ],
            [
                1857,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                12,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1886,
                25,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                2,
            ],
            [
                1887,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1557,
                3,
            ],
            [
                1768,
                3,
            ],
            [
                1849,
                3,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1745,
                1,
            ],
            [
                1748,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                1744,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1886,
                5,
            ],
            [
                1888,
                4,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1886,
                5,
            ],
            [
                1866,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1847,
                1,
            ],
            [
                1859,
                3,
            ],
            [
                1888,
                1,
            ],
            [
                1838,
                1,
            ],
            [
                1857,
                1,
            ],
            [
                1888,
                5,
            ],
            [
                1866,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                3,
                1,
            ],
            [
                1,
                12,
            ],
            [
                3,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CCD': [
            [
                194,
                58,
            ],
            [
                193,
                3,
            ],
            [
                194,
                3,
            ],
            [
                193,
                3,
            ],
            [
                194,
                7,
            ],
            [
                0,
                5,
            ],
            [
                192,
                4,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                4,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                6,
            ],
            [
                194,
                5,
            ],
            [
                192,
                6,
            ],
            [
                194,
                9,
            ],
            [
                0,
                1,
            ],
            [
                193,
                1,
            ],
            [
                0,
                1,
            ],
            [
                193,
                1,
            ],
            [
                0,
                1,
            ],
            [
                193,
                1,
            ],
            [
                194,
                3,
            ],
            [
                0,
                1,
            ],
            [
                193,
                1,
            ],
            [
                0,
                1,
            ],
            [
                193,
                1,
            ],
            [
                0,
                1,
            ],
            [
                193,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                193,
                3,
            ],
            [
                192,
                1,
            ],
            [
                193,
                3,
            ],
            [
                194,
                4,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                194,
                14,
            ],
            [
                192,
                25,
            ],
            [
                194,
                7,
            ],
            [
                0,
                1,
            ],
            [
                194,
                2,
            ],
            [
                193,
                1,
            ],
            [
                194,
                1,
            ],
            [
                157,
                3,
            ],
            [
                184,
                3,
            ],
            [
                191,
                3,
            ],
            [
                192,
                1,
            ],
            [
                194,
                3,
            ],
            [
                0,
                1,
            ],
            [
                194,
                5,
            ],
            [
                192,
                2,
            ],
            [
                194,
                1,
            ],
            [
                192,
                2,
            ],
            [
                194,
                1,
            ],
            [
                192,
                5,
            ],
            [
                194,
                4,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                1,
            ],
            [
                0,
                1,
            ],
            [
                192,
                5,
            ],
            [
                194,
                3,
            ],
            [
                193,
                3,
            ],
            [
                194,
                14,
            ],
        ],
        'City': [
            [
                486,
                3,
            ],
            [
                455,
                26,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                2,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                4,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                10,
            ],
            [
                0,
                5,
            ],
            [
                486,
                4,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                4,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                6,
            ],
            [
                486,
                3,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                460,
                6,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                461,
                1,
            ],
            [
                0,
                1,
            ],
            [
                461,
                1,
            ],
            [
                0,
                1,
            ],
            [
                461,
                1,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                2,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                3,
            ],
            [
                460,
                1,
            ],
            [
                486,
                3,
            ],
            [
                455,
                4,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                12,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                26,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                4,
            ],
            [
                0,
                1,
            ],
            [
                486,
                4,
            ],
            [
                333,
                3,
            ],
            [
                412,
                3,
            ],
            [
                453,
                3,
            ],
            [
                460,
                1,
            ],
            [
                486,
                3,
            ],
            [
                0,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                379,
                1,
            ],
            [
                381,
                1,
            ],
            [
                462,
                1,
            ],
            [
                372,
                1,
            ],
            [
                379,
                1,
            ],
            [
                486,
                11,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                1,
            ],
            [
                0,
                1,
            ],
            [
                486,
                5,
            ],
            [
                467,
                1,
            ],
            [
                436,
                1,
            ],
            [
                445,
                1,
            ],
            [
                461,
                3,
            ],
            [
                486,
                1,
            ],
            [
                436,
                1,
            ],
            [
                455,
                1,
            ],
            [
                486,
                5,
            ],
            [
                467,
                6,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'County': [
            [
                56,
                74,
            ],
            [
                0,
                5,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                6,
            ],
            [
                56,
                20,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                2,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                11,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                46,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                54,
                3,
            ],
            [
                55,
                3,
            ],
            [
                56,
                7,
            ],
            [
                0,
                1,
            ],
            [
                56,
                21,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                25,
            ],
        ],
        'County Cross CD': [
            [
                57,
                74,
            ],
            [
                0,
                5,
            ],
            [
                57,
                4,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                4,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                6,
            ],
            [
                57,
                20,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                4,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                2,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                11,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                46,
            ],
            [
                0,
                1,
            ],
            [
                57,
                4,
            ],
            [
                54,
                3,
            ],
            [
                56,
                3,
            ],
            [
                57,
                7,
            ],
            [
                0,
                1,
            ],
            [
                57,
                21,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                1,
            ],
            [
                0,
                1,
            ],
            [
                57,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                46,
                74,
            ],
            [
                0,
                5,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                6,
            ],
            [
                46,
                20,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                2,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                46,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                45,
                3,
            ],
            [
                46,
                10,
            ],
            [
                0,
                1,
            ],
            [
                46,
                21,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Media Market': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Native Area': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                23,
                74,
            ],
            [
                0,
                5,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                20,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                2,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                11,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                46,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                21,
                3,
            ],
            [
                23,
                10,
            ],
            [
                0,
                1,
            ],
            [
                23,
                21,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                25,
            ],
        ],
        'Neighborhood': [
            [
                46,
                74,
            ],
            [
                0,
                5,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                6,
            ],
            [
                46,
                20,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                2,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                46,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                43,
                3,
            ],
            [
                45,
                3,
            ],
            [
                46,
                7,
            ],
            [
                0,
                1,
            ],
            [
                46,
                6,
            ],
            [
                45,
                1,
            ],
            [
                46,
                2,
            ],
            [
                45,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                25,
            ],
        ],
        'School District': [
            [
                399,
                74,
            ],
            [
                0,
                5,
            ],
            [
                399,
                4,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                4,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                6,
            ],
            [
                399,
                5,
            ],
            [
                396,
                6,
            ],
            [
                399,
                9,
            ],
            [
                0,
                1,
            ],
            [
                396,
                1,
            ],
            [
                0,
                1,
            ],
            [
                396,
                1,
            ],
            [
                0,
                1,
            ],
            [
                396,
                1,
            ],
            [
                399,
                3,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                2,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                3,
            ],
            [
                396,
                1,
            ],
            [
                399,
                7,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                46,
            ],
            [
                0,
                1,
            ],
            [
                399,
                4,
            ],
            [
                339,
                3,
            ],
            [
                386,
                3,
            ],
            [
                396,
                4,
            ],
            [
                399,
                3,
            ],
            [
                0,
                1,
            ],
            [
                399,
                5,
            ],
            [
                386,
                1,
            ],
            [
                388,
                1,
            ],
            [
                398,
                1,
            ],
            [
                386,
                1,
            ],
            [
                388,
                1,
            ],
            [
                399,
                11,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                1,
            ],
            [
                0,
                1,
            ],
            [
                399,
                5,
            ],
            [
                396,
                1,
            ],
            [
                399,
                2,
            ],
            [
                396,
                3,
            ],
            [
                399,
                8,
            ],
            [
                396,
                6,
            ],
        ],
        'State House District': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'State Senate District': [
            [
                50,
                74,
            ],
            [
                0,
                5,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                20,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                2,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                11,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                46,
            ],
            [
                0,
                1,
            ],
            [
                50,
                17,
            ],
            [
                0,
                1,
            ],
            [
                50,
                21,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                22,
                74,
            ],
            [
                0,
                5,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                6,
            ],
            [
                22,
                20,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                2,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                11,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                46,
            ],
            [
                0,
                1,
            ],
            [
                22,
                17,
            ],
            [
                0,
                1,
            ],
            [
                22,
                21,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                362,
                74,
            ],
            [
                0,
                5,
            ],
            [
                362,
                4,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                4,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                6,
            ],
            [
                362,
                20,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                4,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                2,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                11,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                46,
            ],
            [
                0,
                1,
            ],
            [
                362,
                4,
            ],
            [
                292,
                3,
            ],
            [
                342,
                3,
            ],
            [
                362,
                7,
            ],
            [
                0,
                1,
            ],
            [
                362,
                5,
            ],
            [
                341,
                2,
            ],
            [
                356,
                1,
            ],
            [
                340,
                1,
            ],
            [
                339,
                1,
            ],
            [
                362,
                11,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                1,
            ],
            [
                0,
                1,
            ],
            [
                362,
                25,
            ],
        ],
    },
    'Nebraska, USA': {
        'overall': [
            [
                3078,
                1,
            ],
            [
                3070,
                2,
            ],
            [
                3078,
                27,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                2,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                2,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                2,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                2,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3080,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                5,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                4,
            ],
            [
                3080,
                1,
            ],
            [
                3078,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3077,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                3,
            ],
            [
                3078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                5,
            ],
            [
                3080,
                2,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3073,
                6,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                2,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3073,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3073,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                3,
            ],
            [
                3073,
                1,
            ],
            [
                3078,
                7,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                12,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                25,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                5,
            ],
            [
                0,
                1,
            ],
            [
                3078,
                4,
            ],
            [
                2554,
                3,
            ],
            [
                3014,
                3,
            ],
            [
                3072,
                3,
            ],
            [
                3073,
                1,
            ],
            [
                3078,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                2,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                1,
            ],
            [
                2776,
                1,
            ],
            [
                2831,
                1,
            ],
            [
                2997,
                1,
            ],
            [
                2748,
                1,
            ],
            [
                2802,
                1,
            ],
            [
                3078,
                10,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3077,
                5,
            ],
            [
                3078,
                1,
            ],
            [
                3076,
                2,
            ],
            [
                3073,
                3,
            ],
            [
                3078,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3078,
                12,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                1187,
                1,
            ],
            [
                1182,
                2,
            ],
            [
                1187,
                71,
            ],
            [
                0,
                5,
            ],
            [
                1186,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                3,
            ],
            [
                1187,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1187,
                5,
            ],
            [
                1186,
                6,
            ],
            [
                1187,
                9,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                1187,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                3,
            ],
            [
                1186,
                1,
            ],
            [
                1187,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                14,
            ],
            [
                1186,
                25,
            ],
            [
                1187,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                4,
            ],
            [
                912,
                3,
            ],
            [
                1163,
                3,
            ],
            [
                1186,
                4,
            ],
            [
                1187,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                5,
            ],
            [
                995,
                1,
            ],
            [
                1021,
                1,
            ],
            [
                1118,
                1,
            ],
            [
                974,
                1,
            ],
            [
                995,
                1,
            ],
            [
                1187,
                10,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1186,
                5,
            ],
            [
                1187,
                3,
            ],
            [
                1186,
                3,
            ],
            [
                1187,
                14,
            ],
        ],
        'CSA': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'City': [
            [
                590,
                1,
            ],
            [
                587,
                2,
            ],
            [
                590,
                27,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                2,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                2,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                2,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                2,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                3,
            ],
            [
                589,
                1,
            ],
            [
                590,
                5,
            ],
            [
                589,
                1,
            ],
            [
                590,
                11,
            ],
            [
                0,
                5,
            ],
            [
                590,
                4,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                4,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                6,
            ],
            [
                590,
                3,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                587,
                6,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                2,
            ],
            [
                589,
                1,
            ],
            [
                590,
                2,
            ],
            [
                0,
                1,
            ],
            [
                587,
                1,
            ],
            [
                0,
                1,
            ],
            [
                587,
                1,
            ],
            [
                0,
                1,
            ],
            [
                587,
                1,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                2,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                3,
            ],
            [
                587,
                1,
            ],
            [
                590,
                7,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                12,
            ],
            [
                589,
                1,
            ],
            [
                590,
                27,
            ],
            [
                589,
                1,
            ],
            [
                590,
                5,
            ],
            [
                0,
                1,
            ],
            [
                590,
                4,
            ],
            [
                423,
                3,
            ],
            [
                560,
                3,
            ],
            [
                586,
                3,
            ],
            [
                587,
                1,
            ],
            [
                590,
                3,
            ],
            [
                0,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                2,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                505,
                1,
            ],
            [
                520,
                1,
            ],
            [
                579,
                1,
            ],
            [
                499,
                1,
            ],
            [
                518,
                1,
            ],
            [
                590,
                11,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                0,
                1,
            ],
            [
                590,
                6,
            ],
            [
                589,
                1,
            ],
            [
                590,
                1,
            ],
            [
                587,
                3,
            ],
            [
                590,
                1,
            ],
            [
                589,
                1,
            ],
            [
                590,
                12,
            ],
        ],
        'Congressional District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'County': [
            [
                93,
                74,
            ],
            [
                0,
                5,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                6,
            ],
            [
                93,
                20,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                2,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                11,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                46,
            ],
            [
                0,
                1,
            ],
            [
                93,
                4,
            ],
            [
                89,
                3,
            ],
            [
                92,
                3,
            ],
            [
                93,
                7,
            ],
            [
                0,
                1,
            ],
            [
                93,
                21,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                1,
            ],
            [
                0,
                1,
            ],
            [
                93,
                25,
            ],
        ],
        'County Cross CD': [
            [
                95,
                74,
            ],
            [
                0,
                5,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                6,
            ],
            [
                95,
                20,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                2,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                11,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                46,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                91,
                3,
            ],
            [
                94,
                3,
            ],
            [
                95,
                7,
            ],
            [
                0,
                1,
            ],
            [
                95,
                21,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                36,
                74,
            ],
            [
                0,
                5,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36,
                20,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                2,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                11,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                46,
            ],
            [
                0,
                1,
            ],
            [
                36,
                17,
            ],
            [
                0,
                1,
            ],
            [
                36,
                21,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                82,
                74,
            ],
            [
                0,
                5,
            ],
            [
                82,
                4,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                4,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                6,
            ],
            [
                82,
                20,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                4,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                2,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                11,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                46,
            ],
            [
                0,
                1,
            ],
            [
                82,
                17,
            ],
            [
                0,
                1,
            ],
            [
                82,
                21,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                1,
            ],
            [
                0,
                1,
            ],
            [
                82,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                13,
                74,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13,
                20,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Area': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                5,
            ],
            [
                5,
                6,
            ],
            [
                6,
                9,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                6,
                3,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                3,
            ],
            [
                5,
                1,
            ],
            [
                6,
                7,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                5,
                10,
            ],
            [
                6,
                3,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                8,
            ],
            [
                5,
                3,
            ],
            [
                6,
                14,
            ],
        ],
        'Neighborhood': [
            [
                41,
                74,
            ],
            [
                0,
                5,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                6,
            ],
            [
                41,
                20,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                2,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                11,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                46,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                35,
                3,
            ],
            [
                40,
                3,
            ],
            [
                41,
                7,
            ],
            [
                0,
                1,
            ],
            [
                41,
                21,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                6,
            ],
            [
                40,
                1,
            ],
            [
                41,
                18,
            ],
        ],
        'School District': [
            [
                244,
                74,
            ],
            [
                0,
                5,
            ],
            [
                244,
                4,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                4,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                6,
            ],
            [
                244,
                20,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                4,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                2,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                11,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                46,
            ],
            [
                0,
                1,
            ],
            [
                244,
                4,
            ],
            [
                240,
                3,
            ],
            [
                244,
                10,
            ],
            [
                0,
                1,
            ],
            [
                244,
                21,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                1,
            ],
            [
                0,
                1,
            ],
            [
                244,
                25,
            ],
        ],
        'State Senate District': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                17,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                17,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'Urban Center': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'ZIP': [
            [
                583,
                74,
            ],
            [
                0,
                5,
            ],
            [
                583,
                4,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                4,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                6,
            ],
            [
                583,
                20,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                4,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                2,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                11,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                46,
            ],
            [
                0,
                1,
            ],
            [
                583,
                4,
            ],
            [
                520,
                3,
            ],
            [
                577,
                3,
            ],
            [
                583,
                7,
            ],
            [
                0,
                1,
            ],
            [
                583,
                5,
            ],
            [
                558,
                1,
            ],
            [
                572,
                1,
            ],
            [
                582,
                1,
            ],
            [
                557,
                1,
            ],
            [
                571,
                1,
            ],
            [
                583,
                11,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                1,
            ],
            [
                0,
                1,
            ],
            [
                583,
                7,
            ],
            [
                581,
                1,
            ],
            [
                583,
                17,
            ],
        ],
    },
    'Nevada, USA': {
        'overall': [
            [
                671,
                3,
            ],
            [
                670,
                26,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                675,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                4,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                3,
            ],
            [
                675,
                1,
            ],
            [
                671,
                6,
            ],
            [
                0,
                5,
            ],
            [
                670,
                4,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                4,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                5,
            ],
            [
                675,
                2,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                666,
                6,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                0,
                1,
            ],
            [
                666,
                1,
            ],
            [
                0,
                1,
            ],
            [
                666,
                1,
            ],
            [
                0,
                1,
            ],
            [
                666,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                671,
                1,
            ],
            [
                0,
                1,
            ],
            [
                671,
                1,
            ],
            [
                0,
                1,
            ],
            [
                671,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                3,
            ],
            [
                666,
                1,
            ],
            [
                671,
                3,
            ],
            [
                670,
                4,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                671,
                12,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                669,
                25,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                4,
            ],
            [
                0,
                1,
            ],
            [
                671,
                2,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                542,
                3,
            ],
            [
                613,
                3,
            ],
            [
                654,
                3,
            ],
            [
                666,
                1,
            ],
            [
                671,
                3,
            ],
            [
                0,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                658,
                1,
            ],
            [
                667,
                1,
            ],
            [
                668,
                1,
            ],
            [
                657,
                1,
            ],
            [
                666,
                1,
            ],
            [
                671,
                1,
            ],
            [
                670,
                5,
            ],
            [
                671,
                4,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                1,
            ],
            [
                0,
                1,
            ],
            [
                670,
                6,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                666,
                3,
            ],
            [
                671,
                1,
            ],
            [
                667,
                1,
            ],
            [
                670,
                1,
            ],
            [
                671,
                5,
            ],
            [
                669,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                54,
            ],
            [
                4,
                1,
            ],
            [
                2,
                12,
            ],
            [
                4,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                70,
                74,
            ],
            [
                0,
                5,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                6,
            ],
            [
                70,
                20,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                2,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                11,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                46,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                50,
                3,
            ],
            [
                61,
                3,
            ],
            [
                67,
                3,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                5,
            ],
            [
                69,
                1,
            ],
            [
                70,
                2,
            ],
            [
                69,
                1,
            ],
            [
                70,
                12,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                25,
            ],
        ],
        'CSA': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'City': [
            [
                133,
                30,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                2,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                2,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                2,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                2,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                3,
            ],
            [
                132,
                1,
            ],
            [
                133,
                5,
            ],
            [
                132,
                1,
            ],
            [
                133,
                11,
            ],
            [
                0,
                5,
            ],
            [
                133,
                4,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                4,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                6,
            ],
            [
                133,
                3,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                6,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                2,
            ],
            [
                132,
                1,
            ],
            [
                133,
                2,
            ],
            [
                0,
                1,
            ],
            [
                132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                132,
                1,
            ],
            [
                0,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                2,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                3,
            ],
            [
                132,
                1,
            ],
            [
                133,
                7,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                12,
            ],
            [
                132,
                1,
            ],
            [
                133,
                27,
            ],
            [
                132,
                1,
            ],
            [
                133,
                5,
            ],
            [
                0,
                1,
            ],
            [
                133,
                4,
            ],
            [
                84,
                3,
            ],
            [
                107,
                3,
            ],
            [
                124,
                3,
            ],
            [
                132,
                1,
            ],
            [
                133,
                3,
            ],
            [
                0,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                2,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                125,
                1,
            ],
            [
                130,
                1,
            ],
            [
                132,
                1,
            ],
            [
                125,
                1,
            ],
            [
                130,
                1,
            ],
            [
                133,
                11,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                6,
            ],
            [
                132,
                1,
            ],
            [
                133,
                1,
            ],
            [
                132,
                3,
            ],
            [
                133,
                1,
            ],
            [
                132,
                1,
            ],
            [
                133,
                12,
            ],
        ],
        'Congressional District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'County': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                15,
                3,
            ],
            [
                17,
                10,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'County Cross CD': [
            [
                22,
                3,
            ],
            [
                21,
                26,
            ],
            [
                22,
                1,
            ],
            [
                21,
                4,
            ],
            [
                22,
                1,
            ],
            [
                21,
                4,
            ],
            [
                22,
                1,
            ],
            [
                21,
                4,
            ],
            [
                22,
                1,
            ],
            [
                21,
                4,
            ],
            [
                22,
                1,
            ],
            [
                21,
                4,
            ],
            [
                22,
                2,
            ],
            [
                21,
                2,
            ],
            [
                22,
                4,
            ],
            [
                21,
                2,
            ],
            [
                22,
                10,
            ],
            [
                0,
                5,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                6,
            ],
            [
                22,
                3,
            ],
            [
                21,
                8,
            ],
            [
                22,
                1,
            ],
            [
                21,
                4,
            ],
            [
                22,
                1,
            ],
            [
                21,
                2,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                22,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                2,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                3,
            ],
            [
                21,
                1,
            ],
            [
                22,
                3,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                12,
            ],
            [
                21,
                2,
            ],
            [
                22,
                26,
            ],
            [
                21,
                2,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                18,
                3,
            ],
            [
                21,
                7,
            ],
            [
                22,
                3,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                22,
                1,
            ],
            [
                21,
                4,
            ],
            [
                20,
                3,
            ],
            [
                22,
                11,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                6,
            ],
            [
                21,
                5,
            ],
            [
                22,
                1,
            ],
            [
                21,
                2,
            ],
            [
                22,
                11,
            ],
        ],
        'Historical Congressional District': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                16,
                3,
            ],
            [
                17,
                10,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Media Market': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Native Area': [
            [
                27,
                74,
            ],
            [
                0,
                5,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                20,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                2,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                11,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                46,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                20,
                3,
            ],
            [
                25,
                3,
            ],
            [
                27,
                7,
            ],
            [
                0,
                1,
            ],
            [
                27,
                21,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        'Neighborhood': [
            [
                64,
                74,
            ],
            [
                0,
                5,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                6,
            ],
            [
                64,
                20,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                2,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                11,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                46,
            ],
            [
                0,
                1,
            ],
            [
                64,
                4,
            ],
            [
                60,
                3,
            ],
            [
                63,
                3,
            ],
            [
                64,
                7,
            ],
            [
                0,
                1,
            ],
            [
                64,
                21,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                25,
            ],
        ],
        'School District': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                15,
                3,
            ],
            [
                17,
                10,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'State House District': [
            [
                42,
                74,
            ],
            [
                0,
                5,
            ],
            [
                42,
                4,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                4,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                6,
            ],
            [
                42,
                20,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                4,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                2,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                11,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                46,
            ],
            [
                0,
                1,
            ],
            [
                42,
                17,
            ],
            [
                0,
                1,
            ],
            [
                42,
                21,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                25,
            ],
        ],
        'State Senate District': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                20,
                74,
            ],
            [
                0,
                5,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                6,
            ],
            [
                20,
                20,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                2,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                11,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                46,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                19,
                3,
            ],
            [
                20,
                10,
            ],
            [
                0,
                1,
            ],
            [
                20,
                21,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                25,
            ],
        ],
        'Urban Center': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'ZIP': [
            [
                175,
                30,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                2,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                2,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                2,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                2,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                3,
            ],
            [
                173,
                1,
            ],
            [
                175,
                5,
            ],
            [
                173,
                1,
            ],
            [
                175,
                11,
            ],
            [
                0,
                5,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                4,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                6,
            ],
            [
                175,
                3,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                172,
                6,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                2,
            ],
            [
                173,
                1,
            ],
            [
                175,
                2,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                0,
                1,
            ],
            [
                175,
                1,
            ],
            [
                0,
                1,
            ],
            [
                175,
                1,
            ],
            [
                0,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                3,
            ],
            [
                172,
                1,
            ],
            [
                175,
                7,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                175,
                12,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                173,
                25,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                5,
            ],
            [
                0,
                1,
            ],
            [
                175,
                2,
            ],
            [
                174,
                1,
            ],
            [
                175,
                1,
            ],
            [
                136,
                3,
            ],
            [
                156,
                3,
            ],
            [
                171,
                3,
            ],
            [
                172,
                1,
            ],
            [
                175,
                3,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                2,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                172,
                1,
            ],
            [
                175,
                2,
            ],
            [
                172,
                1,
            ],
            [
                175,
                2,
            ],
            [
                174,
                5,
            ],
            [
                175,
                4,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                1,
            ],
            [
                0,
                1,
            ],
            [
                174,
                6,
            ],
            [
                173,
                1,
            ],
            [
                175,
                1,
            ],
            [
                172,
                3,
            ],
            [
                175,
                1,
            ],
            [
                173,
                1,
            ],
            [
                175,
                6,
            ],
            [
                173,
                6,
            ],
        ],
    },
    'New Hampshire, USA': {
        'overall': [
            [
                1105,
                3,
            ],
            [
                1108,
                26,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1114,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                4,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                3,
            ],
            [
                1114,
                1,
            ],
            [
                1112,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1110,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                3,
            ],
            [
                1112,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                5,
            ],
            [
                1114,
                2,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                6,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                3,
            ],
            [
                1109,
                1,
            ],
            [
                1112,
                3,
            ],
            [
                1108,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                12,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1110,
                25,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1112,
                4,
            ],
            [
                1015,
                3,
            ],
            [
                1086,
                3,
            ],
            [
                1106,
                3,
            ],
            [
                1109,
                1,
            ],
            [
                1112,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1093,
                1,
            ],
            [
                1101,
                1,
            ],
            [
                1107,
                1,
            ],
            [
                1092,
                1,
            ],
            [
                1098,
                1,
            ],
            [
                1112,
                10,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1110,
                5,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1109,
                3,
            ],
            [
                1112,
                1,
            ],
            [
                1109,
                1,
            ],
            [
                1108,
                1,
            ],
            [
                1112,
                11,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CCD': [
            [
                239,
                3,
            ],
            [
                241,
                26,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                2,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                4,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                10,
            ],
            [
                0,
                5,
            ],
            [
                243,
                4,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                3,
            ],
            [
                245,
                1,
            ],
            [
                0,
                1,
            ],
            [
                245,
                1,
            ],
            [
                0,
                1,
            ],
            [
                245,
                1,
            ],
            [
                0,
                1,
            ],
            [
                245,
                1,
            ],
            [
                0,
                1,
            ],
            [
                245,
                1,
            ],
            [
                0,
                1,
            ],
            [
                245,
                1,
            ],
            [
                0,
                6,
            ],
            [
                245,
                3,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                6,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                0,
                1,
            ],
            [
                242,
                1,
            ],
            [
                0,
                1,
            ],
            [
                242,
                1,
            ],
            [
                0,
                1,
            ],
            [
                242,
                1,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                2,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                245,
                3,
            ],
            [
                242,
                1,
            ],
            [
                245,
                3,
            ],
            [
                241,
                4,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                245,
                12,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                243,
                25,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                4,
            ],
            [
                0,
                1,
            ],
            [
                245,
                4,
            ],
            [
                208,
                3,
            ],
            [
                234,
                3,
            ],
            [
                241,
                3,
            ],
            [
                242,
                1,
            ],
            [
                245,
                3,
            ],
            [
                0,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                236,
                1,
            ],
            [
                237,
                1,
            ],
            [
                240,
                1,
            ],
            [
                235,
                1,
            ],
            [
                234,
                1,
            ],
            [
                245,
                10,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                1,
            ],
            [
                0,
                1,
            ],
            [
                243,
                5,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                3,
            ],
            [
                245,
                1,
            ],
            [
                242,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                11,
            ],
        ],
        'CSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                94,
                3,
            ],
            [
                97,
                3,
            ],
            [
                99,
                3,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                5,
            ],
            [
                98,
                1,
            ],
            [
                100,
                2,
            ],
            [
                98,
                1,
            ],
            [
                100,
                12,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'County': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'County Cross CD': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                25,
                74,
            ],
            [
                0,
                5,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                6,
            ],
            [
                25,
                20,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                2,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                11,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                46,
            ],
            [
                0,
                1,
            ],
            [
                25,
                17,
            ],
            [
                0,
                1,
            ],
            [
                25,
                21,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                28,
                74,
            ],
            [
                0,
                5,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                6,
            ],
            [
                28,
                20,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                2,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                11,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                46,
            ],
            [
                0,
                1,
            ],
            [
                28,
                17,
            ],
            [
                0,
                1,
            ],
            [
                28,
                21,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Media Market': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Neighborhood': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                28,
                3,
            ],
            [
                30,
                10,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'School District': [
            [
                179,
                3,
            ],
            [
                180,
                71,
            ],
            [
                0,
                5,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                6,
            ],
            [
                180,
                20,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                2,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                11,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                46,
            ],
            [
                0,
                1,
            ],
            [
                180,
                4,
            ],
            [
                160,
                3,
            ],
            [
                174,
                3,
            ],
            [
                180,
                7,
            ],
            [
                0,
                1,
            ],
            [
                180,
                5,
            ],
            [
                179,
                1,
            ],
            [
                178,
                1,
            ],
            [
                180,
                1,
            ],
            [
                179,
                1,
            ],
            [
                178,
                1,
            ],
            [
                180,
                11,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                1,
            ],
            [
                0,
                1,
            ],
            [
                180,
                25,
            ],
        ],
        'State House District': [
            [
                164,
                74,
            ],
            [
                0,
                5,
            ],
            [
                164,
                4,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                4,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                6,
            ],
            [
                164,
                20,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                4,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                2,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                11,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                46,
            ],
            [
                0,
                1,
            ],
            [
                164,
                4,
            ],
            [
                161,
                3,
            ],
            [
                163,
                3,
            ],
            [
                164,
                7,
            ],
            [
                0,
                1,
            ],
            [
                164,
                21,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                1,
            ],
            [
                0,
                1,
            ],
            [
                164,
                25,
            ],
        ],
        'State Senate District': [
            [
                24,
                74,
            ],
            [
                0,
                5,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                6,
            ],
            [
                24,
                20,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                2,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                11,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                46,
            ],
            [
                0,
                1,
            ],
            [
                24,
                17,
            ],
            [
                0,
                1,
            ],
            [
                24,
                21,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'Urban Center': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'ZIP': [
            [
                247,
                74,
            ],
            [
                0,
                5,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                6,
            ],
            [
                247,
                20,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                2,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                11,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                46,
            ],
            [
                0,
                1,
            ],
            [
                247,
                4,
            ],
            [
                218,
                3,
            ],
            [
                242,
                3,
            ],
            [
                246,
                3,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                5,
            ],
            [
                240,
                1,
            ],
            [
                246,
                1,
            ],
            [
                247,
                1,
            ],
            [
                240,
                1,
            ],
            [
                246,
                1,
            ],
            [
                247,
                11,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                25,
            ],
        ],
    },
    'New Jersey, USA': {
        'overall': [
            [
                3098,
                3,
            ],
            [
                3094,
                26,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3099,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                4,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                3,
            ],
            [
                3099,
                1,
            ],
            [
                3098,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3098,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                3,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                5,
            ],
            [
                3099,
                2,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3097,
                6,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                4,
            ],
            [
                3098,
                3,
            ],
            [
                3094,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                12,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3097,
                25,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3098,
                2,
            ],
            [
                3097,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                2803,
                3,
            ],
            [
                2988,
                3,
            ],
            [
                3074,
                3,
            ],
            [
                3097,
                1,
            ],
            [
                3098,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3076,
                1,
            ],
            [
                3089,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                3076,
                1,
            ],
            [
                3089,
                1,
            ],
            [
                3098,
                10,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3097,
                5,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3097,
                3,
            ],
            [
                3098,
                1,
            ],
            [
                3088,
                1,
            ],
            [
                3094,
                1,
            ],
            [
                3098,
                5,
            ],
            [
                3097,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                5,
                54,
            ],
            [
                6,
                1,
            ],
            [
                5,
                12,
            ],
            [
                6,
                1,
            ],
            [
                5,
                6,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                7,
            ],
            [
                5,
                18,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'CCD': [
            [
                564,
                74,
            ],
            [
                0,
                5,
            ],
            [
                564,
                4,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                4,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                6,
            ],
            [
                564,
                20,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                4,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                2,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                11,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                46,
            ],
            [
                0,
                1,
            ],
            [
                564,
                4,
            ],
            [
                520,
                3,
            ],
            [
                551,
                3,
            ],
            [
                562,
                3,
            ],
            [
                564,
                4,
            ],
            [
                0,
                1,
            ],
            [
                564,
                5,
            ],
            [
                563,
                2,
            ],
            [
                564,
                1,
            ],
            [
                563,
                2,
            ],
            [
                564,
                11,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                564,
                25,
            ],
        ],
        'CSA': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'City': [
            [
                700,
                3,
            ],
            [
                696,
                26,
            ],
            [
                700,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                2,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                4,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                10,
            ],
            [
                0,
                5,
            ],
            [
                700,
                4,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                4,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                6,
            ],
            [
                700,
                3,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                7,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                2,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                2,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                7,
            ],
            [
                696,
                4,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                12,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                26,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                4,
            ],
            [
                0,
                1,
            ],
            [
                700,
                4,
            ],
            [
                565,
                3,
            ],
            [
                646,
                3,
            ],
            [
                685,
                3,
            ],
            [
                700,
                4,
            ],
            [
                0,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                1,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                693,
                1,
            ],
            [
                694,
                1,
            ],
            [
                699,
                1,
            ],
            [
                693,
                1,
            ],
            [
                694,
                1,
            ],
            [
                700,
                11,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                1,
            ],
            [
                0,
                1,
            ],
            [
                700,
                6,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                4,
            ],
            [
                698,
                1,
            ],
            [
                696,
                1,
            ],
            [
                700,
                11,
            ],
        ],
        'Congressional District': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'County': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'County Cross CD': [
            [
                41,
                74,
            ],
            [
                0,
                5,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                6,
            ],
            [
                41,
                20,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                2,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                11,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                46,
            ],
            [
                0,
                1,
            ],
            [
                41,
                17,
            ],
            [
                0,
                1,
            ],
            [
                41,
                21,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                231,
                74,
            ],
            [
                0,
                5,
            ],
            [
                231,
                4,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                4,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                6,
            ],
            [
                231,
                20,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                4,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                2,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                11,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                46,
            ],
            [
                0,
                1,
            ],
            [
                231,
                17,
            ],
            [
                0,
                1,
            ],
            [
                231,
                21,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                1,
            ],
            [
                0,
                1,
            ],
            [
                231,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                67,
                74,
            ],
            [
                0,
                5,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                6,
            ],
            [
                67,
                20,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                46,
            ],
            [
                0,
                1,
            ],
            [
                67,
                17,
            ],
            [
                0,
                1,
            ],
            [
                67,
                21,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Media Market': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Neighborhood': [
            [
                167,
                30,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                163,
                1,
            ],
            [
                167,
                2,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                163,
                1,
            ],
            [
                167,
                2,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                163,
                1,
            ],
            [
                167,
                2,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                163,
                1,
            ],
            [
                167,
                2,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                163,
                1,
            ],
            [
                167,
                3,
            ],
            [
                163,
                1,
            ],
            [
                167,
                5,
            ],
            [
                163,
                1,
            ],
            [
                167,
                11,
            ],
            [
                0,
                5,
            ],
            [
                167,
                4,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                4,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                6,
            ],
            [
                167,
                3,
            ],
            [
                163,
                1,
            ],
            [
                167,
                8,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                163,
                1,
            ],
            [
                167,
                2,
            ],
            [
                163,
                1,
            ],
            [
                167,
                2,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                2,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                2,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                11,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                12,
            ],
            [
                163,
                1,
            ],
            [
                167,
                27,
            ],
            [
                163,
                1,
            ],
            [
                167,
                5,
            ],
            [
                0,
                1,
            ],
            [
                167,
                4,
            ],
            [
                133,
                3,
            ],
            [
                153,
                3,
            ],
            [
                166,
                3,
            ],
            [
                167,
                4,
            ],
            [
                0,
                1,
            ],
            [
                163,
                1,
            ],
            [
                167,
                2,
            ],
            [
                163,
                1,
            ],
            [
                167,
                1,
            ],
            [
                161,
                1,
            ],
            [
                167,
                2,
            ],
            [
                161,
                1,
            ],
            [
                167,
                12,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                1,
            ],
            [
                0,
                1,
            ],
            [
                167,
                6,
            ],
            [
                163,
                1,
            ],
            [
                167,
                5,
            ],
            [
                163,
                1,
            ],
            [
                167,
                12,
            ],
        ],
        'School District': [
            [
                557,
                74,
            ],
            [
                0,
                5,
            ],
            [
                557,
                4,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                4,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                6,
            ],
            [
                557,
                20,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                4,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                2,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                11,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                46,
            ],
            [
                0,
                1,
            ],
            [
                557,
                4,
            ],
            [
                523,
                3,
            ],
            [
                548,
                3,
            ],
            [
                556,
                3,
            ],
            [
                557,
                4,
            ],
            [
                0,
                1,
            ],
            [
                557,
                21,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                25,
            ],
        ],
        'State House District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'State Senate District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'Urban Center': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'ZIP': [
            [
                592,
                30,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                2,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                2,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                2,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                2,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                3,
            ],
            [
                588,
                1,
            ],
            [
                592,
                5,
            ],
            [
                588,
                1,
            ],
            [
                592,
                11,
            ],
            [
                0,
                5,
            ],
            [
                592,
                4,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                3,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                6,
            ],
            [
                592,
                3,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                591,
                6,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                2,
            ],
            [
                588,
                1,
            ],
            [
                592,
                2,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                4,
            ],
            [
                592,
                7,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                12,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                591,
                25,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                5,
            ],
            [
                0,
                1,
            ],
            [
                592,
                2,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                544,
                3,
            ],
            [
                572,
                3,
            ],
            [
                587,
                3,
            ],
            [
                591,
                1,
            ],
            [
                592,
                3,
            ],
            [
                0,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                2,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                584,
                1,
            ],
            [
                590,
                1,
            ],
            [
                592,
                1,
            ],
            [
                584,
                1,
            ],
            [
                590,
                1,
            ],
            [
                592,
                10,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                5,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                1,
            ],
            [
                591,
                3,
            ],
            [
                592,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                6,
            ],
            [
                591,
                6,
            ],
        ],
    },
    'New Mexico, USA': {
        'overall': [
            [
                1739,
                3,
            ],
            [
                1735,
                26,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1741,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                4,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                3,
            ],
            [
                1741,
                1,
            ],
            [
                1739,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1739,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                3,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                5,
            ],
            [
                1741,
                2,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1734,
                6,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1734,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1737,
                3,
            ],
            [
                1734,
                1,
            ],
            [
                1739,
                3,
            ],
            [
                1735,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                12,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1737,
                25,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                2,
            ],
            [
                1737,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1163,
                3,
            ],
            [
                1498,
                3,
            ],
            [
                1680,
                3,
            ],
            [
                1734,
                1,
            ],
            [
                1739,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1704,
                1,
            ],
            [
                1727,
                1,
            ],
            [
                1737,
                1,
            ],
            [
                1704,
                1,
            ],
            [
                1726,
                1,
            ],
            [
                1739,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1739,
                5,
            ],
            [
                1738,
                1,
            ],
            [
                1721,
                1,
            ],
            [
                1733,
                1,
            ],
            [
                1734,
                3,
            ],
            [
                1739,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1735,
                1,
            ],
            [
                1739,
                5,
            ],
            [
                1738,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CCD': [
            [
                130,
                74,
            ],
            [
                0,
                5,
            ],
            [
                130,
                4,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                4,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                6,
            ],
            [
                130,
                20,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                4,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                2,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                11,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                46,
            ],
            [
                0,
                1,
            ],
            [
                130,
                4,
            ],
            [
                93,
                3,
            ],
            [
                117,
                3,
            ],
            [
                126,
                3,
            ],
            [
                130,
                4,
            ],
            [
                0,
                1,
            ],
            [
                130,
                21,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                25,
            ],
        ],
        'CSA': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'City': [
            [
                526,
                3,
            ],
            [
                524,
                26,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                2,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                4,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                10,
            ],
            [
                0,
                5,
            ],
            [
                526,
                4,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                3,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                6,
            ],
            [
                526,
                3,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                525,
                6,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                1,
            ],
            [
                0,
                1,
            ],
            [
                525,
                4,
            ],
            [
                526,
                3,
            ],
            [
                524,
                4,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                12,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                525,
                25,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                4,
            ],
            [
                0,
                1,
            ],
            [
                526,
                2,
            ],
            [
                525,
                1,
            ],
            [
                526,
                1,
            ],
            [
                265,
                3,
            ],
            [
                410,
                3,
            ],
            [
                494,
                3,
            ],
            [
                525,
                1,
            ],
            [
                526,
                3,
            ],
            [
                0,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                502,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                502,
                1,
            ],
            [
                520,
                1,
            ],
            [
                526,
                11,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                6,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                525,
                3,
            ],
            [
                526,
                1,
            ],
            [
                514,
                1,
            ],
            [
                524,
                1,
            ],
            [
                526,
                11,
            ],
        ],
        'Congressional District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'County': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                32,
                3,
            ],
            [
                33,
                10,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'County Cross CD': [
            [
                43,
                74,
            ],
            [
                0,
                5,
            ],
            [
                43,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                6,
            ],
            [
                43,
                20,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                4,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                2,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                11,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                46,
            ],
            [
                0,
                1,
            ],
            [
                43,
                4,
            ],
            [
                41,
                3,
            ],
            [
                42,
                3,
            ],
            [
                43,
                7,
            ],
            [
                0,
                1,
            ],
            [
                43,
                21,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                17,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                17,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Media Market': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Native Area': [
            [
                23,
                74,
            ],
            [
                0,
                5,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                20,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                2,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                11,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                46,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                17,
                3,
            ],
            [
                22,
                3,
            ],
            [
                23,
                7,
            ],
            [
                0,
                1,
            ],
            [
                23,
                21,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                54,
                74,
            ],
            [
                0,
                5,
            ],
            [
                54,
                4,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                4,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                6,
            ],
            [
                54,
                20,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                4,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                2,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                11,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                46,
            ],
            [
                0,
                1,
            ],
            [
                54,
                4,
            ],
            [
                23,
                3,
            ],
            [
                43,
                3,
            ],
            [
                52,
                3,
            ],
            [
                54,
                4,
            ],
            [
                0,
                1,
            ],
            [
                54,
                6,
            ],
            [
                53,
                1,
            ],
            [
                54,
                2,
            ],
            [
                53,
                1,
            ],
            [
                54,
                11,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                25,
            ],
        ],
        'Neighborhood': [
            [
                234,
                3,
            ],
            [
                232,
                26,
            ],
            [
                234,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                2,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                4,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                10,
            ],
            [
                0,
                5,
            ],
            [
                234,
                4,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                4,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                6,
            ],
            [
                234,
                3,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                7,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                2,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                2,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                7,
            ],
            [
                232,
                4,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                12,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                26,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                4,
            ],
            [
                0,
                1,
            ],
            [
                234,
                4,
            ],
            [
                165,
                3,
            ],
            [
                199,
                3,
            ],
            [
                230,
                3,
            ],
            [
                234,
                4,
            ],
            [
                0,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                227,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                1,
            ],
            [
                227,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                11,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                1,
            ],
            [
                0,
                1,
            ],
            [
                234,
                6,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                4,
            ],
            [
                229,
                1,
            ],
            [
                232,
                1,
            ],
            [
                234,
                11,
            ],
        ],
        'School District': [
            [
                89,
                74,
            ],
            [
                0,
                5,
            ],
            [
                89,
                4,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                4,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                6,
            ],
            [
                89,
                20,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                4,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                2,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                11,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                46,
            ],
            [
                0,
                1,
            ],
            [
                89,
                4,
            ],
            [
                75,
                3,
            ],
            [
                87,
                3,
            ],
            [
                89,
                7,
            ],
            [
                0,
                1,
            ],
            [
                89,
                21,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                1,
            ],
            [
                0,
                1,
            ],
            [
                89,
                25,
            ],
        ],
        'State House District': [
            [
                70,
                74,
            ],
            [
                0,
                5,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                6,
            ],
            [
                70,
                20,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                2,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                11,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                46,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                69,
                3,
            ],
            [
                70,
                10,
            ],
            [
                0,
                1,
            ],
            [
                70,
                21,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                25,
            ],
        ],
        'State Senate District': [
            [
                42,
                74,
            ],
            [
                0,
                5,
            ],
            [
                42,
                4,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                4,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                6,
            ],
            [
                42,
                20,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                4,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                2,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                11,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                46,
            ],
            [
                0,
                1,
            ],
            [
                42,
                17,
            ],
            [
                0,
                1,
            ],
            [
                42,
                21,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                32,
                74,
            ],
            [
                0,
                5,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32,
                20,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                2,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                11,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                46,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                31,
                3,
            ],
            [
                32,
                10,
            ],
            [
                0,
                1,
            ],
            [
                32,
                21,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                25,
            ],
        ],
        'Urban Center': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'ZIP': [
            [
                372,
                74,
            ],
            [
                0,
                5,
            ],
            [
                372,
                4,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                3,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                6,
            ],
            [
                372,
                5,
            ],
            [
                368,
                6,
            ],
            [
                372,
                9,
            ],
            [
                0,
                1,
            ],
            [
                368,
                1,
            ],
            [
                0,
                1,
            ],
            [
                368,
                1,
            ],
            [
                0,
                1,
            ],
            [
                368,
                1,
            ],
            [
                372,
                3,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                3,
            ],
            [
                368,
                1,
            ],
            [
                372,
                7,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                14,
            ],
            [
                371,
                25,
            ],
            [
                372,
                7,
            ],
            [
                0,
                1,
            ],
            [
                372,
                2,
            ],
            [
                371,
                1,
            ],
            [
                372,
                1,
            ],
            [
                219,
                3,
            ],
            [
                310,
                3,
            ],
            [
                355,
                3,
            ],
            [
                368,
                1,
            ],
            [
                372,
                3,
            ],
            [
                0,
                1,
            ],
            [
                372,
                5,
            ],
            [
                368,
                2,
            ],
            [
                372,
                1,
            ],
            [
                368,
                2,
            ],
            [
                372,
                11,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                1,
            ],
            [
                0,
                1,
            ],
            [
                372,
                5,
            ],
            [
                371,
                2,
            ],
            [
                370,
                1,
            ],
            [
                368,
                3,
            ],
            [
                372,
                8,
            ],
            [
                371,
                6,
            ],
        ],
    },
    'New York, USA': {
        'overall': [
            [
                7251,
                3,
            ],
            [
                7241,
                26,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7264,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7247,
                3,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7247,
                3,
            ],
            [
                7264,
                1,
            ],
            [
                7254,
                6,
            ],
            [
                1,
                5,
            ],
            [
                7240,
                4,
            ],
            [
                1,
                1,
            ],
            [
                7240,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7240,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7232,
                3,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                31,
                5,
            ],
            [
                7264,
                2,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7221,
                6,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7226,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7226,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7226,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7247,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7247,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7247,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7246,
                3,
            ],
            [
                7221,
                1,
            ],
            [
                7247,
                3,
            ],
            [
                7241,
                4,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7243,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7254,
                12,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7242,
                25,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                4,
            ],
            [
                1,
                1,
            ],
            [
                7254,
                2,
            ],
            [
                7243,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                6731,
                3,
            ],
            [
                7004,
                3,
            ],
            [
                7182,
                3,
            ],
            [
                7221,
                1,
            ],
            [
                7254,
                3,
            ],
            [
                1,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7181,
                1,
            ],
            [
                7212,
                1,
            ],
            [
                7249,
                1,
            ],
            [
                7178,
                1,
            ],
            [
                7207,
                1,
            ],
            [
                7254,
                1,
            ],
            [
                7243,
                5,
            ],
            [
                7254,
                4,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                1,
            ],
            [
                1,
                1,
            ],
            [
                7242,
                5,
            ],
            [
                7250,
                1,
            ],
            [
                7229,
                1,
            ],
            [
                7221,
                1,
            ],
            [
                7226,
                3,
            ],
            [
                7254,
                1,
            ],
            [
                7237,
                1,
            ],
            [
                7241,
                1,
            ],
            [
                7254,
                5,
            ],
            [
                7233,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                54,
            ],
            [
                6,
                1,
            ],
            [
                3,
                12,
            ],
            [
                6,
                1,
            ],
            [
                3,
                6,
            ],
            [
                1,
                5,
            ],
            [
                3,
                4,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                4,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                6,
                7,
            ],
            [
                3,
                18,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                4,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                2,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                11,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                46,
            ],
            [
                1,
                1,
            ],
            [
                3,
                17,
            ],
            [
                1,
                1,
            ],
            [
                3,
                21,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                3,
                1,
            ],
            [
                1,
                12,
            ],
            [
                3,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                6,
                54,
            ],
            [
                9,
                1,
            ],
            [
                6,
                12,
            ],
            [
                9,
                1,
            ],
            [
                6,
                6,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                7,
            ],
            [
                6,
                18,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'CCD': [
            [
                1011,
                3,
            ],
            [
                1010,
                26,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                3,
            ],
            [
                1010,
                4,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                4,
            ],
            [
                1011,
                7,
            ],
            [
                0,
                5,
            ],
            [
                1009,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1009,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1006,
                3,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1011,
                4,
            ],
            [
                1010,
                7,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1011,
                13,
            ],
            [
                1010,
                26,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                909,
                3,
            ],
            [
                988,
                3,
            ],
            [
                1004,
                3,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1007,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1006,
                2,
            ],
            [
                1011,
                1,
            ],
            [
                1010,
                5,
            ],
            [
                1011,
                4,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1010,
                5,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                4,
            ],
            [
                1011,
                2,
            ],
            [
                1010,
                1,
            ],
            [
                1011,
                11,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                1293,
                30,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                3,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                5,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                11,
            ],
            [
                0,
                5,
            ],
            [
                1292,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1292,
                3,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1293,
                3,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1288,
                6,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1290,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                3,
            ],
            [
                1288,
                1,
            ],
            [
                1293,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                12,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                27,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                5,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                1292,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1113,
                3,
            ],
            [
                1194,
                3,
            ],
            [
                1273,
                3,
            ],
            [
                1288,
                1,
            ],
            [
                1293,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                2,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                1272,
                1,
            ],
            [
                1285,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1271,
                1,
            ],
            [
                1283,
                1,
            ],
            [
                1293,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1293,
                5,
            ],
            [
                1292,
                1,
            ],
            [
                1290,
                5,
            ],
            [
                1293,
                1,
            ],
            [
                1291,
                1,
            ],
            [
                1293,
                6,
            ],
            [
                1289,
                6,
            ],
        ],
        'Congressional District': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'County': [
            [
                62,
                74,
            ],
            [
                0,
                5,
            ],
            [
                62,
                4,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                4,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                6,
            ],
            [
                62,
                20,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                4,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                2,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                11,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                46,
            ],
            [
                0,
                1,
            ],
            [
                62,
                17,
            ],
            [
                0,
                1,
            ],
            [
                62,
                21,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                25,
            ],
        ],
        'County Cross CD': [
            [
                88,
                74,
            ],
            [
                0,
                5,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                6,
            ],
            [
                88,
                20,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                2,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                11,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                46,
            ],
            [
                0,
                1,
            ],
            [
                88,
                17,
            ],
            [
                0,
                1,
            ],
            [
                88,
                21,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                1204,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1204,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1204,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1204,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                17,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                143,
                74,
            ],
            [
                0,
                5,
            ],
            [
                143,
                4,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                4,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                6,
            ],
            [
                143,
                20,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                4,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                2,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                11,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                46,
            ],
            [
                0,
                1,
            ],
            [
                143,
                17,
            ],
            [
                0,
                1,
            ],
            [
                143,
                21,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                1,
            ],
            [
                0,
                1,
            ],
            [
                143,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'MSA': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Media Market': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Native Area': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                3,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                5,
                6,
            ],
            [
                6,
                3,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                6,
            ],
            [
                9,
                1,
            ],
            [
                10,
                2,
            ],
            [
                9,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Neighborhood': [
            [
                562,
                3,
            ],
            [
                560,
                26,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                2,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                559,
                3,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                559,
                3,
            ],
            [
                565,
                7,
            ],
            [
                0,
                5,
            ],
            [
                558,
                4,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                556,
                3,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                6,
            ],
            [
                565,
                3,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                554,
                6,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                554,
                1,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                0,
                1,
            ],
            [
                559,
                1,
            ],
            [
                0,
                1,
            ],
            [
                559,
                1,
            ],
            [
                0,
                1,
            ],
            [
                559,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                3,
            ],
            [
                554,
                1,
            ],
            [
                559,
                3,
            ],
            [
                560,
                4,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                558,
                1,
            ],
            [
                0,
                1,
            ],
            [
                565,
                12,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                557,
                25,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                4,
            ],
            [
                0,
                1,
            ],
            [
                565,
                2,
            ],
            [
                558,
                1,
            ],
            [
                565,
                1,
            ],
            [
                523,
                3,
            ],
            [
                528,
                3,
            ],
            [
                548,
                3,
            ],
            [
                554,
                1,
            ],
            [
                565,
                3,
            ],
            [
                0,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                559,
                1,
            ],
            [
                557,
                1,
            ],
            [
                565,
                1,
            ],
            [
                559,
                1,
            ],
            [
                557,
                1,
            ],
            [
                565,
                1,
            ],
            [
                558,
                5,
            ],
            [
                565,
                4,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                1,
            ],
            [
                0,
                1,
            ],
            [
                557,
                5,
            ],
            [
                565,
                1,
            ],
            [
                561,
                1,
            ],
            [
                557,
                1,
            ],
            [
                554,
                3,
            ],
            [
                565,
                1,
            ],
            [
                563,
                1,
            ],
            [
                560,
                1,
            ],
            [
                565,
                5,
            ],
            [
                557,
                6,
            ],
        ],
        'School District': [
            [
                682,
                74,
            ],
            [
                0,
                5,
            ],
            [
                682,
                4,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                4,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                6,
            ],
            [
                682,
                20,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                4,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                2,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                11,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                46,
            ],
            [
                0,
                1,
            ],
            [
                682,
                4,
            ],
            [
                666,
                3,
            ],
            [
                675,
                3,
            ],
            [
                681,
                3,
            ],
            [
                682,
                4,
            ],
            [
                0,
                1,
            ],
            [
                682,
                5,
            ],
            [
                680,
                1,
            ],
            [
                681,
                1,
            ],
            [
                682,
                1,
            ],
            [
                680,
                1,
            ],
            [
                681,
                1,
            ],
            [
                682,
                11,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                1,
            ],
            [
                0,
                1,
            ],
            [
                682,
                25,
            ],
        ],
        'State House District': [
            [
                150,
                74,
            ],
            [
                0,
                5,
            ],
            [
                150,
                4,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                4,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                6,
            ],
            [
                150,
                20,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                4,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                2,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                11,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                46,
            ],
            [
                0,
                1,
            ],
            [
                150,
                17,
            ],
            [
                0,
                1,
            ],
            [
                150,
                21,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                25,
            ],
        ],
        'State Senate District': [
            [
                63,
                74,
            ],
            [
                0,
                5,
            ],
            [
                63,
                4,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                4,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                6,
            ],
            [
                63,
                20,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                4,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                2,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                11,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                46,
            ],
            [
                0,
                1,
            ],
            [
                63,
                17,
            ],
            [
                0,
                1,
            ],
            [
                63,
                21,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                96,
                74,
            ],
            [
                0,
                5,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                6,
            ],
            [
                96,
                20,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                2,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                11,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                46,
            ],
            [
                0,
                1,
            ],
            [
                96,
                17,
            ],
            [
                0,
                1,
            ],
            [
                96,
                21,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                25,
            ],
        ],
        'Urban Center': [
            [
                9,
                54,
            ],
            [
                10,
                1,
            ],
            [
                9,
                12,
            ],
            [
                10,
                1,
            ],
            [
                9,
                6,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                7,
            ],
            [
                9,
                18,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'ZIP': [
            [
                1772,
                3,
            ],
            [
                1765,
                26,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                2,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                4,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1769,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1768,
                3,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1772,
                3,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1756,
                6,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1772,
                3,
            ],
            [
                1756,
                1,
            ],
            [
                1772,
                3,
            ],
            [
                1765,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1772,
                12,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1769,
                25,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1772,
                2,
            ],
            [
                1770,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1594,
                3,
            ],
            [
                1693,
                3,
            ],
            [
                1749,
                3,
            ],
            [
                1756,
                1,
            ],
            [
                1772,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1732,
                1,
            ],
            [
                1752,
                1,
            ],
            [
                1770,
                1,
            ],
            [
                1731,
                1,
            ],
            [
                1750,
                1,
            ],
            [
                1772,
                1,
            ],
            [
                1769,
                5,
            ],
            [
                1772,
                4,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1769,
                6,
            ],
            [
                1754,
                1,
            ],
            [
                1751,
                1,
            ],
            [
                1759,
                3,
            ],
            [
                1772,
                1,
            ],
            [
                1759,
                1,
            ],
            [
                1765,
                1,
            ],
            [
                1772,
                5,
            ],
            [
                1763,
                6,
            ],
        ],
    },
    'North Carolina, USA': {
        'overall': [
            [
                4353,
                3,
            ],
            [
                4345,
                26,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4354,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                4,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                3,
            ],
            [
                4354,
                1,
            ],
            [
                4353,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4353,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                5,
            ],
            [
                4354,
                2,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4349,
                6,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4349,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4349,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                3,
            ],
            [
                4349,
                1,
            ],
            [
                4353,
                3,
            ],
            [
                4345,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                12,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4352,
                25,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4353,
                4,
            ],
            [
                3516,
                3,
            ],
            [
                4205,
                3,
            ],
            [
                4332,
                3,
            ],
            [
                4349,
                1,
            ],
            [
                4353,
                3,
            ],
            [
                0,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4311,
                1,
            ],
            [
                4333,
                1,
            ],
            [
                4351,
                1,
            ],
            [
                4310,
                1,
            ],
            [
                4331,
                1,
            ],
            [
                4353,
                10,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4352,
                6,
            ],
            [
                4332,
                1,
            ],
            [
                4338,
                1,
            ],
            [
                4349,
                3,
            ],
            [
                4353,
                1,
            ],
            [
                4334,
                1,
            ],
            [
                4345,
                1,
            ],
            [
                4353,
                5,
            ],
            [
                4352,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'CCD': [
            [
                1040,
                3,
            ],
            [
                1039,
                26,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                4,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                4,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                4,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                4,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                4,
            ],
            [
                1040,
                2,
            ],
            [
                1039,
                2,
            ],
            [
                1040,
                4,
            ],
            [
                1039,
                2,
            ],
            [
                1040,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1040,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1040,
                3,
            ],
            [
                1039,
                2,
            ],
            [
                1037,
                6,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                4,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                2,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1037,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1037,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1037,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                3,
            ],
            [
                1037,
                1,
            ],
            [
                1040,
                3,
            ],
            [
                1039,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                12,
            ],
            [
                1039,
                2,
            ],
            [
                1040,
                26,
            ],
            [
                1039,
                2,
            ],
            [
                1040,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                4,
            ],
            [
                771,
                3,
            ],
            [
                1008,
                3,
            ],
            [
                1034,
                3,
            ],
            [
                1037,
                1,
            ],
            [
                1040,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1039,
                2,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                2,
            ],
            [
                1037,
                1,
            ],
            [
                1036,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                1037,
                1,
            ],
            [
                1036,
                1,
            ],
            [
                1040,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1040,
                6,
            ],
            [
                1039,
                2,
            ],
            [
                1037,
                3,
            ],
            [
                1040,
                1,
            ],
            [
                1039,
                2,
            ],
            [
                1040,
                11,
            ],
        ],
        'CSA': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'City': [
            [
                775,
                30,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                3,
            ],
            [
                774,
                1,
            ],
            [
                775,
                5,
            ],
            [
                774,
                1,
            ],
            [
                775,
                11,
            ],
            [
                0,
                5,
            ],
            [
                775,
                4,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                4,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                6,
            ],
            [
                775,
                3,
            ],
            [
                774,
                1,
            ],
            [
                775,
                8,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                11,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                1,
            ],
            [
                0,
                1,
            ],
            [
                775,
                12,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                774,
                25,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                5,
            ],
            [
                0,
                1,
            ],
            [
                775,
                4,
            ],
            [
                541,
                3,
            ],
            [
                744,
                3,
            ],
            [
                773,
                3,
            ],
            [
                775,
                4,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                2,
            ],
            [
                774,
                1,
            ],
            [
                775,
                1,
            ],
            [
                767,
                1,
            ],
            [
                773,
                1,
            ],
            [
                775,
                1,
            ],
            [
                767,
                1,
            ],
            [
                773,
                1,
            ],
            [
                775,
                10,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                1,
            ],
            [
                0,
                1,
            ],
            [
                774,
                5,
            ],
            [
                775,
                1,
            ],
            [
                774,
                1,
            ],
            [
                775,
                5,
            ],
            [
                774,
                1,
            ],
            [
                775,
                12,
            ],
        ],
        'Congressional District': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'County': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                98,
                3,
            ],
            [
                100,
                10,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'County Cross CD': [
            [
                113,
                74,
            ],
            [
                0,
                5,
            ],
            [
                113,
                4,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                4,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                6,
            ],
            [
                113,
                20,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                4,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                2,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                11,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                46,
            ],
            [
                0,
                1,
            ],
            [
                113,
                4,
            ],
            [
                111,
                3,
            ],
            [
                113,
                10,
            ],
            [
                0,
                1,
            ],
            [
                113,
                21,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                335,
                74,
            ],
            [
                0,
                5,
            ],
            [
                335,
                4,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                4,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                6,
            ],
            [
                335,
                20,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                4,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                2,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                11,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                46,
            ],
            [
                0,
                1,
            ],
            [
                335,
                17,
            ],
            [
                0,
                1,
            ],
            [
                335,
                21,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                1,
            ],
            [
                0,
                1,
            ],
            [
                335,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                102,
                74,
            ],
            [
                0,
                5,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                6,
            ],
            [
                102,
                20,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                2,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                11,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                46,
            ],
            [
                0,
                1,
            ],
            [
                102,
                17,
            ],
            [
                0,
                1,
            ],
            [
                102,
                21,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                41,
                74,
            ],
            [
                0,
                5,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                6,
            ],
            [
                41,
                20,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                2,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                11,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                46,
            ],
            [
                0,
                1,
            ],
            [
                41,
                17,
            ],
            [
                0,
                1,
            ],
            [
                41,
                21,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                25,
            ],
        ],
        'Media Market': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                6,
                3,
            ],
            [
                7,
                10,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Neighborhood': [
            [
                585,
                3,
            ],
            [
                578,
                26,
            ],
            [
                585,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                2,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                4,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                10,
            ],
            [
                0,
                5,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                6,
            ],
            [
                585,
                3,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                7,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                2,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                2,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                7,
            ],
            [
                578,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                12,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                26,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                585,
                4,
            ],
            [
                414,
                3,
            ],
            [
                517,
                3,
            ],
            [
                575,
                3,
            ],
            [
                585,
                4,
            ],
            [
                0,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                1,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                561,
                1,
            ],
            [
                575,
                1,
            ],
            [
                584,
                1,
            ],
            [
                560,
                1,
            ],
            [
                574,
                1,
            ],
            [
                585,
                11,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                1,
            ],
            [
                0,
                1,
            ],
            [
                585,
                6,
            ],
            [
                571,
                1,
            ],
            [
                577,
                1,
            ],
            [
                585,
                4,
            ],
            [
                571,
                1,
            ],
            [
                578,
                1,
            ],
            [
                585,
                11,
            ],
        ],
        'School District': [
            [
                118,
                74,
            ],
            [
                0,
                5,
            ],
            [
                118,
                4,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                4,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                6,
            ],
            [
                118,
                20,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                4,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                2,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                11,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                46,
            ],
            [
                0,
                1,
            ],
            [
                118,
                4,
            ],
            [
                116,
                3,
            ],
            [
                118,
                10,
            ],
            [
                0,
                1,
            ],
            [
                118,
                21,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                1,
            ],
            [
                0,
                1,
            ],
            [
                118,
                25,
            ],
        ],
        'State House District': [
            [
                120,
                74,
            ],
            [
                0,
                5,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                6,
            ],
            [
                120,
                20,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                4,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                2,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                11,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                46,
            ],
            [
                0,
                1,
            ],
            [
                120,
                17,
            ],
            [
                0,
                1,
            ],
            [
                120,
                21,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                1,
            ],
            [
                0,
                1,
            ],
            [
                120,
                25,
            ],
        ],
        'State Senate District': [
            [
                50,
                74,
            ],
            [
                0,
                5,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                20,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                2,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                11,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                46,
            ],
            [
                0,
                1,
            ],
            [
                50,
                17,
            ],
            [
                0,
                1,
            ],
            [
                50,
                21,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                86,
                74,
            ],
            [
                0,
                5,
            ],
            [
                86,
                4,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                4,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                6,
            ],
            [
                86,
                20,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                4,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                2,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                11,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                46,
            ],
            [
                0,
                1,
            ],
            [
                86,
                4,
            ],
            [
                84,
                3,
            ],
            [
                86,
                10,
            ],
            [
                0,
                1,
            ],
            [
                86,
                21,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                1,
            ],
            [
                0,
                1,
            ],
            [
                86,
                25,
            ],
        ],
        'Urban Center': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'ZIP': [
            [
                804,
                30,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                2,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                2,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                2,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                2,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                3,
            ],
            [
                801,
                1,
            ],
            [
                804,
                5,
            ],
            [
                801,
                1,
            ],
            [
                804,
                11,
            ],
            [
                0,
                5,
            ],
            [
                804,
                4,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                4,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                6,
            ],
            [
                804,
                3,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                803,
                6,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                2,
            ],
            [
                801,
                1,
            ],
            [
                804,
                2,
            ],
            [
                0,
                1,
            ],
            [
                803,
                1,
            ],
            [
                0,
                1,
            ],
            [
                803,
                1,
            ],
            [
                0,
                1,
            ],
            [
                803,
                1,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                2,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                3,
            ],
            [
                803,
                1,
            ],
            [
                804,
                7,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                12,
            ],
            [
                801,
                1,
            ],
            [
                804,
                27,
            ],
            [
                801,
                1,
            ],
            [
                804,
                5,
            ],
            [
                0,
                1,
            ],
            [
                804,
                4,
            ],
            [
                650,
                3,
            ],
            [
                787,
                3,
            ],
            [
                801,
                3,
            ],
            [
                803,
                1,
            ],
            [
                804,
                3,
            ],
            [
                0,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                2,
            ],
            [
                801,
                1,
            ],
            [
                804,
                1,
            ],
            [
                797,
                1,
            ],
            [
                800,
                1,
            ],
            [
                803,
                1,
            ],
            [
                797,
                1,
            ],
            [
                799,
                1,
            ],
            [
                804,
                11,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                804,
                5,
            ],
            [
                803,
                1,
            ],
            [
                799,
                1,
            ],
            [
                798,
                1,
            ],
            [
                803,
                3,
            ],
            [
                804,
                1,
            ],
            [
                801,
                1,
            ],
            [
                804,
                6,
            ],
            [
                803,
                6,
            ],
        ],
    },
    'North Dakota, USA': {
        'overall': [
            [
                3074,
                3,
            ],
            [
                3070,
                26,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3077,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                4,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                3,
            ],
            [
                3077,
                1,
            ],
            [
                3074,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3072,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                3,
            ],
            [
                3074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                5,
            ],
            [
                3077,
                2,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3056,
                6,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3056,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3056,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3056,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                3,
            ],
            [
                3056,
                1,
            ],
            [
                3074,
                3,
            ],
            [
                3070,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                12,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3072,
                25,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3074,
                4,
            ],
            [
                2431,
                3,
            ],
            [
                2757,
                3,
            ],
            [
                3023,
                3,
            ],
            [
                3056,
                1,
            ],
            [
                3074,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                2033,
                1,
            ],
            [
                2183,
                1,
            ],
            [
                2717,
                1,
            ],
            [
                1957,
                1,
            ],
            [
                2082,
                1,
            ],
            [
                3074,
                10,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3072,
                5,
            ],
            [
                3073,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3065,
                1,
            ],
            [
                3056,
                3,
            ],
            [
                3074,
                1,
            ],
            [
                3066,
                1,
            ],
            [
                3070,
                1,
            ],
            [
                3074,
                5,
            ],
            [
                3073,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'CCD': [
            [
                1757,
                3,
            ],
            [
                1756,
                26,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                3,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                5,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1755,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                3,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1757,
                4,
            ],
            [
                1756,
                1,
            ],
            [
                1745,
                6,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1745,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1745,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                3,
            ],
            [
                1745,
                1,
            ],
            [
                1757,
                3,
            ],
            [
                1756,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                13,
            ],
            [
                1756,
                1,
            ],
            [
                1755,
                25,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                4,
            ],
            [
                1284,
                3,
            ],
            [
                1514,
                3,
            ],
            [
                1717,
                3,
            ],
            [
                1745,
                1,
            ],
            [
                1757,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1757,
                1,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                863,
                1,
            ],
            [
                972,
                1,
            ],
            [
                1425,
                1,
            ],
            [
                793,
                1,
            ],
            [
                878,
                1,
            ],
            [
                1757,
                10,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1755,
                5,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1745,
                3,
            ],
            [
                1757,
                2,
            ],
            [
                1756,
                1,
            ],
            [
                1757,
                11,
            ],
        ],
        'CSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                406,
                30,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                2,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                2,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                2,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                2,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                3,
            ],
            [
                405,
                1,
            ],
            [
                406,
                5,
            ],
            [
                405,
                1,
            ],
            [
                406,
                11,
            ],
            [
                0,
                5,
            ],
            [
                406,
                4,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                4,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                6,
            ],
            [
                406,
                3,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                403,
                6,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                2,
            ],
            [
                405,
                1,
            ],
            [
                406,
                2,
            ],
            [
                0,
                1,
            ],
            [
                403,
                1,
            ],
            [
                0,
                1,
            ],
            [
                403,
                1,
            ],
            [
                0,
                1,
            ],
            [
                403,
                1,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                2,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                3,
            ],
            [
                403,
                1,
            ],
            [
                406,
                7,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                12,
            ],
            [
                405,
                1,
            ],
            [
                406,
                27,
            ],
            [
                405,
                1,
            ],
            [
                406,
                5,
            ],
            [
                0,
                1,
            ],
            [
                406,
                4,
            ],
            [
                298,
                3,
            ],
            [
                354,
                3,
            ],
            [
                399,
                3,
            ],
            [
                403,
                1,
            ],
            [
                406,
                3,
            ],
            [
                0,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                2,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                308,
                1,
            ],
            [
                325,
                1,
            ],
            [
                382,
                1,
            ],
            [
                302,
                1,
            ],
            [
                318,
                1,
            ],
            [
                406,
                11,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                1,
            ],
            [
                0,
                1,
            ],
            [
                406,
                6,
            ],
            [
                405,
                1,
            ],
            [
                406,
                1,
            ],
            [
                403,
                3,
            ],
            [
                406,
                1,
            ],
            [
                405,
                1,
            ],
            [
                406,
                12,
            ],
        ],
        'Congressional District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                53,
                74,
            ],
            [
                0,
                5,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                6,
            ],
            [
                53,
                20,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                2,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                11,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                46,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                51,
                3,
            ],
            [
                53,
                10,
            ],
            [
                0,
                1,
            ],
            [
                53,
                21,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                25,
            ],
        ],
        'County Cross CD': [
            [
                53,
                74,
            ],
            [
                0,
                5,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                6,
            ],
            [
                53,
                20,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                2,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                11,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                46,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                51,
                3,
            ],
            [
                53,
                10,
            ],
            [
                0,
                1,
            ],
            [
                53,
                21,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                41,
                74,
            ],
            [
                0,
                5,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                6,
            ],
            [
                41,
                20,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                2,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                11,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                46,
            ],
            [
                0,
                1,
            ],
            [
                41,
                17,
            ],
            [
                0,
                1,
            ],
            [
                41,
                21,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Media Market': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Native Area': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'Neighborhood': [
            [
                47,
                3,
            ],
            [
                44,
                26,
            ],
            [
                47,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                2,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                4,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                10,
            ],
            [
                0,
                5,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                6,
            ],
            [
                47,
                3,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                7,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                2,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                2,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                7,
            ],
            [
                44,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                12,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                26,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                40,
                3,
            ],
            [
                43,
                3,
            ],
            [
                46,
                3,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                38,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                1,
            ],
            [
                38,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                11,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                6,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                4,
            ],
            [
                42,
                1,
            ],
            [
                44,
                1,
            ],
            [
                47,
                11,
            ],
        ],
        'School District': [
            [
                172,
                74,
            ],
            [
                0,
                5,
            ],
            [
                172,
                4,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                4,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                6,
            ],
            [
                172,
                20,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                4,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                2,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                11,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                46,
            ],
            [
                0,
                1,
            ],
            [
                172,
                4,
            ],
            [
                164,
                3,
            ],
            [
                169,
                3,
            ],
            [
                172,
                7,
            ],
            [
                0,
                1,
            ],
            [
                172,
                5,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                172,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                172,
                11,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                1,
            ],
            [
                0,
                1,
            ],
            [
                172,
                25,
            ],
        ],
        'State House District': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                17,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'State Senate District': [
            [
                47,
                74,
            ],
            [
                0,
                5,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                6,
            ],
            [
                47,
                20,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                2,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                11,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                46,
            ],
            [
                0,
                1,
            ],
            [
                47,
                17,
            ],
            [
                0,
                1,
            ],
            [
                47,
                21,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Urban Center': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'ZIP': [
            [
                391,
                30,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                2,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                2,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                2,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                2,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                3,
            ],
            [
                389,
                1,
            ],
            [
                391,
                5,
            ],
            [
                389,
                1,
            ],
            [
                391,
                11,
            ],
            [
                0,
                5,
            ],
            [
                391,
                4,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                4,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                6,
            ],
            [
                391,
                3,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                388,
                6,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                2,
            ],
            [
                389,
                1,
            ],
            [
                391,
                2,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                2,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                3,
            ],
            [
                388,
                1,
            ],
            [
                391,
                7,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                12,
            ],
            [
                389,
                1,
            ],
            [
                391,
                27,
            ],
            [
                389,
                1,
            ],
            [
                391,
                5,
            ],
            [
                0,
                1,
            ],
            [
                391,
                4,
            ],
            [
                348,
                3,
            ],
            [
                376,
                3,
            ],
            [
                388,
                4,
            ],
            [
                391,
                3,
            ],
            [
                0,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                2,
            ],
            [
                389,
                1,
            ],
            [
                391,
                1,
            ],
            [
                353,
                1,
            ],
            [
                372,
                1,
            ],
            [
                390,
                1,
            ],
            [
                353,
                1,
            ],
            [
                372,
                1,
            ],
            [
                391,
                11,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                1,
            ],
            [
                0,
                1,
            ],
            [
                391,
                5,
            ],
            [
                390,
                1,
            ],
            [
                389,
                1,
            ],
            [
                386,
                1,
            ],
            [
                388,
                3,
            ],
            [
                391,
                1,
            ],
            [
                389,
                1,
            ],
            [
                391,
                6,
            ],
            [
                390,
                6,
            ],
        ],
    },
    'Ohio, USA': {
        'overall': [
            [
                6505,
                3,
            ],
            [
                6504,
                26,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6511,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                4,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                3,
            ],
            [
                6511,
                1,
            ],
            [
                6505,
                6,
            ],
            [
                0,
                5,
            ],
            [
                6505,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                5,
            ],
            [
                6511,
                2,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6503,
                6,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6503,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6503,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6503,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                3,
            ],
            [
                6503,
                1,
            ],
            [
                6505,
                3,
            ],
            [
                6504,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                12,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                26,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6505,
                4,
            ],
            [
                5654,
                3,
            ],
            [
                6305,
                3,
            ],
            [
                6470,
                3,
            ],
            [
                6503,
                1,
            ],
            [
                6505,
                3,
            ],
            [
                0,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6417,
                1,
            ],
            [
                6447,
                1,
            ],
            [
                6501,
                1,
            ],
            [
                6416,
                1,
            ],
            [
                6444,
                1,
            ],
            [
                6505,
                10,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6504,
                6,
            ],
            [
                6495,
                1,
            ],
            [
                6500,
                1,
            ],
            [
                6503,
                3,
            ],
            [
                6505,
                1,
            ],
            [
                6497,
                1,
            ],
            [
                6504,
                1,
            ],
            [
                6505,
                5,
            ],
            [
                6504,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                4,
                54,
            ],
            [
                6,
                1,
            ],
            [
                4,
                12,
            ],
            [
                6,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'CCD': [
            [
                1594,
                30,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                3,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                5,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                11,
            ],
            [
                0,
                5,
            ],
            [
                1594,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1594,
                3,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                6,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                3,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                12,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                27,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                5,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                4,
            ],
            [
                1278,
                3,
            ],
            [
                1516,
                3,
            ],
            [
                1583,
                3,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                2,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1584,
                1,
            ],
            [
                1590,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1584,
                1,
            ],
            [
                1590,
                1,
            ],
            [
                1594,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1594,
                6,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                3,
            ],
            [
                1594,
                1,
            ],
            [
                1593,
                1,
            ],
            [
                1594,
                12,
            ],
        ],
        'CSA': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'City': [
            [
                1265,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1265,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1265,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                4,
            ],
            [
                982,
                3,
            ],
            [
                1198,
                3,
            ],
            [
                1252,
                3,
            ],
            [
                1265,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                5,
            ],
            [
                1225,
                1,
            ],
            [
                1239,
                1,
            ],
            [
                1264,
                1,
            ],
            [
                1225,
                1,
            ],
            [
                1239,
                1,
            ],
            [
                1265,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1265,
                25,
            ],
        ],
        'Congressional District': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'County': [
            [
                88,
                74,
            ],
            [
                0,
                5,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                6,
            ],
            [
                88,
                20,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                4,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                2,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                11,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                46,
            ],
            [
                0,
                1,
            ],
            [
                88,
                17,
            ],
            [
                0,
                1,
            ],
            [
                88,
                21,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                1,
            ],
            [
                0,
                1,
            ],
            [
                88,
                25,
            ],
        ],
        'County Cross CD': [
            [
                102,
                74,
            ],
            [
                0,
                5,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                6,
            ],
            [
                102,
                20,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                4,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                2,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                11,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                46,
            ],
            [
                0,
                1,
            ],
            [
                102,
                4,
            ],
            [
                101,
                3,
            ],
            [
                102,
                10,
            ],
            [
                0,
                1,
            ],
            [
                102,
                21,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                1,
            ],
            [
                0,
                1,
            ],
            [
                102,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                639,
                74,
            ],
            [
                0,
                5,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                6,
            ],
            [
                639,
                20,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                2,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                11,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                46,
            ],
            [
                0,
                1,
            ],
            [
                639,
                17,
            ],
            [
                0,
                1,
            ],
            [
                639,
                21,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                122,
                74,
            ],
            [
                0,
                5,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                6,
            ],
            [
                122,
                20,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                4,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                2,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                11,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                46,
            ],
            [
                0,
                1,
            ],
            [
                122,
                17,
            ],
            [
                0,
                1,
            ],
            [
                122,
                21,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                1,
            ],
            [
                0,
                1,
            ],
            [
                122,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                47,
                74,
            ],
            [
                0,
                5,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                6,
            ],
            [
                47,
                20,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                4,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                2,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                11,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                46,
            ],
            [
                0,
                1,
            ],
            [
                47,
                17,
            ],
            [
                0,
                1,
            ],
            [
                47,
                21,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                1,
            ],
            [
                0,
                1,
            ],
            [
                47,
                25,
            ],
        ],
        'Media Market': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Neighborhood': [
            [
                524,
                30,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                2,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                2,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                2,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                2,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                3,
            ],
            [
                521,
                1,
            ],
            [
                524,
                5,
            ],
            [
                521,
                1,
            ],
            [
                524,
                11,
            ],
            [
                0,
                5,
            ],
            [
                524,
                4,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                4,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                6,
            ],
            [
                524,
                3,
            ],
            [
                521,
                1,
            ],
            [
                524,
                8,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                2,
            ],
            [
                521,
                1,
            ],
            [
                524,
                2,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                2,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                2,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                11,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                12,
            ],
            [
                521,
                1,
            ],
            [
                524,
                27,
            ],
            [
                521,
                1,
            ],
            [
                524,
                5,
            ],
            [
                0,
                1,
            ],
            [
                524,
                4,
            ],
            [
                460,
                3,
            ],
            [
                494,
                3,
            ],
            [
                518,
                3,
            ],
            [
                524,
                4,
            ],
            [
                0,
                1,
            ],
            [
                521,
                1,
            ],
            [
                524,
                2,
            ],
            [
                521,
                1,
            ],
            [
                524,
                1,
            ],
            [
                515,
                1,
            ],
            [
                519,
                1,
            ],
            [
                524,
                1,
            ],
            [
                515,
                1,
            ],
            [
                519,
                1,
            ],
            [
                524,
                11,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                1,
            ],
            [
                0,
                1,
            ],
            [
                524,
                6,
            ],
            [
                521,
                1,
            ],
            [
                524,
                5,
            ],
            [
                521,
                1,
            ],
            [
                524,
                12,
            ],
        ],
        'School District': [
            [
                612,
                3,
            ],
            [
                611,
                26,
            ],
            [
                612,
                2,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                611,
                1,
            ],
            [
                612,
                2,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                611,
                1,
            ],
            [
                612,
                2,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                611,
                1,
            ],
            [
                612,
                2,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                611,
                1,
            ],
            [
                612,
                2,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                611,
                1,
            ],
            [
                612,
                3,
            ],
            [
                611,
                1,
            ],
            [
                612,
                5,
            ],
            [
                611,
                1,
            ],
            [
                612,
                10,
            ],
            [
                0,
                5,
            ],
            [
                612,
                4,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                4,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                6,
            ],
            [
                612,
                4,
            ],
            [
                611,
                1,
            ],
            [
                612,
                8,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                611,
                1,
            ],
            [
                612,
                2,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                3,
            ],
            [
                611,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                2,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                7,
            ],
            [
                611,
                4,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                13,
            ],
            [
                611,
                1,
            ],
            [
                612,
                27,
            ],
            [
                611,
                1,
            ],
            [
                612,
                4,
            ],
            [
                0,
                1,
            ],
            [
                612,
                4,
            ],
            [
                602,
                3,
            ],
            [
                612,
                10,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                611,
                1,
            ],
            [
                612,
                2,
            ],
            [
                611,
                1,
            ],
            [
                612,
                1,
            ],
            [
                610,
                1,
            ],
            [
                612,
                2,
            ],
            [
                610,
                1,
            ],
            [
                612,
                11,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                1,
            ],
            [
                0,
                1,
            ],
            [
                612,
                7,
            ],
            [
                611,
                1,
            ],
            [
                612,
                5,
            ],
            [
                611,
                1,
            ],
            [
                612,
                11,
            ],
        ],
        'State House District': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                17,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'State Senate District': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                17,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                113,
                74,
            ],
            [
                0,
                5,
            ],
            [
                113,
                4,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                4,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                6,
            ],
            [
                113,
                20,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                4,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                2,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                11,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                46,
            ],
            [
                0,
                1,
            ],
            [
                113,
                17,
            ],
            [
                0,
                1,
            ],
            [
                113,
                21,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                1,
            ],
            [
                0,
                1,
            ],
            [
                113,
                25,
            ],
        ],
        'Urban Center': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'ZIP': [
            [
                1191,
                30,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                2,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                2,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                2,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                2,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                3,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                5,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                11,
            ],
            [
                0,
                5,
            ],
            [
                1191,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1191,
                3,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1190,
                6,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                2,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                3,
            ],
            [
                1190,
                1,
            ],
            [
                1191,
                7,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                12,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1190,
                25,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                5,
            ],
            [
                0,
                1,
            ],
            [
                1191,
                4,
            ],
            [
                1014,
                3,
            ],
            [
                1166,
                3,
            ],
            [
                1186,
                3,
            ],
            [
                1190,
                1,
            ],
            [
                1191,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                2,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                1,
            ],
            [
                1162,
                1,
            ],
            [
                1170,
                1,
            ],
            [
                1188,
                1,
            ],
            [
                1161,
                1,
            ],
            [
                1167,
                1,
            ],
            [
                1191,
                10,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1190,
                6,
            ],
            [
                1185,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1190,
                3,
            ],
            [
                1191,
                1,
            ],
            [
                1187,
                1,
            ],
            [
                1191,
                6,
            ],
            [
                1190,
                6,
            ],
        ],
    },
    'Oklahoma, USA': {
        'overall': [
            [
                3333,
                3,
            ],
            [
                3329,
                26,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                2,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                4,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                10,
            ],
            [
                0,
                5,
            ],
            [
                3333,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                5,
            ],
            [
                3333,
                3,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                7,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                3,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                3,
            ],
            [
                3329,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                12,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                26,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                4,
            ],
            [
                2720,
                3,
            ],
            [
                3221,
                3,
            ],
            [
                3317,
                3,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3300,
                1,
            ],
            [
                3314,
                1,
            ],
            [
                3327,
                1,
            ],
            [
                3297,
                1,
            ],
            [
                3309,
                1,
            ],
            [
                3333,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3333,
                5,
            ],
            [
                3332,
                1,
            ],
            [
                3315,
                1,
            ],
            [
                3327,
                1,
            ],
            [
                3329,
                3,
            ],
            [
                3333,
                1,
            ],
            [
                3318,
                1,
            ],
            [
                3329,
                1,
            ],
            [
                3333,
                5,
            ],
            [
                3332,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                305,
                74,
            ],
            [
                0,
                5,
            ],
            [
                305,
                4,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                4,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                6,
            ],
            [
                305,
                20,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                4,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                2,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                11,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                46,
            ],
            [
                0,
                1,
            ],
            [
                305,
                4,
            ],
            [
                271,
                3,
            ],
            [
                302,
                3,
            ],
            [
                305,
                7,
            ],
            [
                0,
                1,
            ],
            [
                305,
                21,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                1,
            ],
            [
                0,
                1,
            ],
            [
                305,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                843,
                3,
            ],
            [
                842,
                26,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                2,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                4,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                10,
            ],
            [
                0,
                5,
            ],
            [
                843,
                4,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                4,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                6,
            ],
            [
                843,
                3,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                840,
                6,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                840,
                1,
            ],
            [
                0,
                1,
            ],
            [
                840,
                1,
            ],
            [
                0,
                1,
            ],
            [
                840,
                1,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                2,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                3,
            ],
            [
                840,
                1,
            ],
            [
                843,
                3,
            ],
            [
                842,
                4,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                12,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                26,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                4,
            ],
            [
                0,
                1,
            ],
            [
                843,
                4,
            ],
            [
                551,
                3,
            ],
            [
                797,
                3,
            ],
            [
                838,
                3,
            ],
            [
                840,
                1,
            ],
            [
                843,
                3,
            ],
            [
                0,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                819,
                1,
            ],
            [
                828,
                1,
            ],
            [
                838,
                1,
            ],
            [
                817,
                1,
            ],
            [
                824,
                1,
            ],
            [
                843,
                11,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                1,
            ],
            [
                0,
                1,
            ],
            [
                843,
                6,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                840,
                3,
            ],
            [
                843,
                1,
            ],
            [
                834,
                1,
            ],
            [
                842,
                1,
            ],
            [
                843,
                11,
            ],
        ],
        'Congressional District': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'County': [
            [
                77,
                74,
            ],
            [
                0,
                5,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                6,
            ],
            [
                77,
                20,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                2,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                11,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                46,
            ],
            [
                0,
                1,
            ],
            [
                77,
                17,
            ],
            [
                0,
                1,
            ],
            [
                77,
                21,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                25,
            ],
        ],
        'County Cross CD': [
            [
                84,
                74,
            ],
            [
                0,
                5,
            ],
            [
                84,
                4,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                4,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                6,
            ],
            [
                84,
                20,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                4,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                2,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                11,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                46,
            ],
            [
                0,
                1,
            ],
            [
                84,
                17,
            ],
            [
                0,
                1,
            ],
            [
                84,
                21,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                74,
                74,
            ],
            [
                0,
                5,
            ],
            [
                74,
                4,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                4,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                6,
            ],
            [
                74,
                20,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                4,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                2,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                11,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                46,
            ],
            [
                0,
                1,
            ],
            [
                74,
                17,
            ],
            [
                0,
                1,
            ],
            [
                74,
                21,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                1,
            ],
            [
                0,
                1,
            ],
            [
                74,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                96,
                74,
            ],
            [
                0,
                5,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                6,
            ],
            [
                96,
                20,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                4,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                2,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                11,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                46,
            ],
            [
                0,
                1,
            ],
            [
                96,
                17,
            ],
            [
                0,
                1,
            ],
            [
                96,
                21,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                22,
                74,
            ],
            [
                0,
                5,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                6,
            ],
            [
                22,
                20,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                2,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                11,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                46,
            ],
            [
                0,
                1,
            ],
            [
                22,
                17,
            ],
            [
                0,
                1,
            ],
            [
                22,
                21,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                25,
            ],
        ],
        'Media Market': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                29,
                74,
            ],
            [
                0,
                5,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                6,
            ],
            [
                29,
                20,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                2,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                11,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                46,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                28,
                3,
            ],
            [
                29,
                10,
            ],
            [
                0,
                1,
            ],
            [
                29,
                21,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                155,
                74,
            ],
            [
                0,
                5,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                6,
            ],
            [
                155,
                20,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                2,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                11,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                46,
            ],
            [
                0,
                1,
            ],
            [
                155,
                4,
            ],
            [
                99,
                3,
            ],
            [
                146,
                3,
            ],
            [
                154,
                3,
            ],
            [
                155,
                4,
            ],
            [
                0,
                1,
            ],
            [
                155,
                21,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                25,
            ],
        ],
        'Neighborhood': [
            [
                247,
                3,
            ],
            [
                245,
                26,
            ],
            [
                247,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                2,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                4,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                10,
            ],
            [
                0,
                5,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                6,
            ],
            [
                247,
                3,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                7,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                2,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                2,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                7,
            ],
            [
                245,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                12,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                26,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                247,
                4,
            ],
            [
                173,
                3,
            ],
            [
                206,
                3,
            ],
            [
                239,
                3,
            ],
            [
                247,
                4,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                1,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                239,
                1,
            ],
            [
                244,
                1,
            ],
            [
                247,
                1,
            ],
            [
                239,
                1,
            ],
            [
                244,
                1,
            ],
            [
                247,
                11,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                1,
            ],
            [
                0,
                1,
            ],
            [
                247,
                6,
            ],
            [
                240,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                4,
            ],
            [
                241,
                1,
            ],
            [
                245,
                1,
            ],
            [
                247,
                11,
            ],
        ],
        'School District': [
            [
                509,
                74,
            ],
            [
                0,
                5,
            ],
            [
                509,
                4,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                4,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                6,
            ],
            [
                509,
                20,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                4,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                2,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                11,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                46,
            ],
            [
                0,
                1,
            ],
            [
                509,
                4,
            ],
            [
                465,
                3,
            ],
            [
                509,
                10,
            ],
            [
                0,
                1,
            ],
            [
                509,
                21,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                509,
                25,
            ],
        ],
        'State House District': [
            [
                101,
                74,
            ],
            [
                0,
                5,
            ],
            [
                101,
                4,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                4,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                6,
            ],
            [
                101,
                20,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                4,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                2,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                11,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                46,
            ],
            [
                0,
                1,
            ],
            [
                101,
                17,
            ],
            [
                0,
                1,
            ],
            [
                101,
                21,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                1,
            ],
            [
                0,
                1,
            ],
            [
                101,
                25,
            ],
        ],
        'State Senate District': [
            [
                48,
                74,
            ],
            [
                0,
                5,
            ],
            [
                48,
                4,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                4,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                6,
            ],
            [
                48,
                20,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                4,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                2,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                11,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                46,
            ],
            [
                0,
                1,
            ],
            [
                48,
                17,
            ],
            [
                0,
                1,
            ],
            [
                48,
                21,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                50,
                74,
            ],
            [
                0,
                5,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                20,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                2,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                11,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                46,
            ],
            [
                0,
                1,
            ],
            [
                50,
                17,
            ],
            [
                0,
                1,
            ],
            [
                50,
                21,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                25,
            ],
        ],
        'Urban Center': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                649,
                3,
            ],
            [
                648,
                26,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                3,
            ],
            [
                648,
                1,
            ],
            [
                649,
                5,
            ],
            [
                648,
                1,
            ],
            [
                649,
                10,
            ],
            [
                0,
                5,
            ],
            [
                649,
                4,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                4,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                6,
            ],
            [
                649,
                4,
            ],
            [
                648,
                7,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                648,
                1,
            ],
            [
                0,
                1,
            ],
            [
                648,
                1,
            ],
            [
                0,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                2,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                3,
            ],
            [
                648,
                1,
            ],
            [
                649,
                3,
            ],
            [
                648,
                4,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                13,
            ],
            [
                648,
                1,
            ],
            [
                649,
                27,
            ],
            [
                648,
                1,
            ],
            [
                649,
                4,
            ],
            [
                0,
                1,
            ],
            [
                649,
                4,
            ],
            [
                537,
                3,
            ],
            [
                636,
                3,
            ],
            [
                647,
                3,
            ],
            [
                648,
                1,
            ],
            [
                649,
                3,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                648,
                1,
            ],
            [
                649,
                2,
            ],
            [
                648,
                4,
            ],
            [
                647,
                2,
            ],
            [
                649,
                11,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                1,
            ],
            [
                0,
                1,
            ],
            [
                649,
                5,
            ],
            [
                648,
                1,
            ],
            [
                647,
                1,
            ],
            [
                646,
                1,
            ],
            [
                648,
                3,
            ],
            [
                649,
                2,
            ],
            [
                648,
                1,
            ],
            [
                649,
                5,
            ],
            [
                648,
                6,
            ],
        ],
    },
    'Oregon, USA': {
        'overall': [
            [
                1901,
                3,
            ],
            [
                1898,
                26,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1906,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                4,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                3,
            ],
            [
                1906,
                1,
            ],
            [
                1901,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1901,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                5,
            ],
            [
                1906,
                2,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1899,
                6,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1899,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1899,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1899,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                3,
            ],
            [
                1899,
                1,
            ],
            [
                1901,
                3,
            ],
            [
                1898,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                12,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                26,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                4,
            ],
            [
                1715,
                3,
            ],
            [
                1869,
                3,
            ],
            [
                1893,
                3,
            ],
            [
                1899,
                1,
            ],
            [
                1901,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1882,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1881,
                1,
            ],
            [
                1887,
                1,
            ],
            [
                1901,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1901,
                6,
            ],
            [
                1896,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1899,
                3,
            ],
            [
                1901,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1901,
                11,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CCD': [
            [
                212,
                74,
            ],
            [
                0,
                5,
            ],
            [
                212,
                4,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                4,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                6,
            ],
            [
                212,
                20,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                4,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                2,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                11,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                46,
            ],
            [
                0,
                1,
            ],
            [
                212,
                4,
            ],
            [
                183,
                3,
            ],
            [
                210,
                3,
            ],
            [
                212,
                7,
            ],
            [
                0,
                1,
            ],
            [
                212,
                6,
            ],
            [
                211,
                1,
            ],
            [
                212,
                2,
            ],
            [
                211,
                1,
            ],
            [
                212,
                11,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                426,
                3,
            ],
            [
                424,
                26,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                2,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                4,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                10,
            ],
            [
                0,
                5,
            ],
            [
                426,
                4,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                4,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                6,
            ],
            [
                426,
                3,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                425,
                6,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                425,
                1,
            ],
            [
                0,
                1,
            ],
            [
                425,
                1,
            ],
            [
                0,
                1,
            ],
            [
                425,
                1,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                2,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                3,
            ],
            [
                425,
                1,
            ],
            [
                426,
                3,
            ],
            [
                424,
                4,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                12,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                26,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                4,
            ],
            [
                0,
                1,
            ],
            [
                426,
                4,
            ],
            [
                342,
                3,
            ],
            [
                406,
                3,
            ],
            [
                422,
                3,
            ],
            [
                425,
                1,
            ],
            [
                426,
                3,
            ],
            [
                0,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                413,
                1,
            ],
            [
                420,
                1,
            ],
            [
                424,
                1,
            ],
            [
                412,
                1,
            ],
            [
                420,
                1,
            ],
            [
                426,
                11,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                1,
            ],
            [
                0,
                1,
            ],
            [
                426,
                6,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                425,
                3,
            ],
            [
                426,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                426,
                11,
            ],
        ],
        'Congressional District': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'County': [
            [
                36,
                74,
            ],
            [
                0,
                5,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36,
                20,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                2,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                11,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                46,
            ],
            [
                0,
                1,
            ],
            [
                36,
                17,
            ],
            [
                0,
                1,
            ],
            [
                36,
                21,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                25,
            ],
        ],
        'County Cross CD': [
            [
                46,
                74,
            ],
            [
                0,
                5,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                6,
            ],
            [
                46,
                20,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                2,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                46,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                45,
                3,
            ],
            [
                46,
                10,
            ],
            [
                0,
                1,
            ],
            [
                46,
                6,
            ],
            [
                45,
                1,
            ],
            [
                46,
                2,
            ],
            [
                45,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                11,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                17,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                48,
                74,
            ],
            [
                0,
                5,
            ],
            [
                48,
                4,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                4,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                6,
            ],
            [
                48,
                20,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                4,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                2,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                11,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                46,
            ],
            [
                0,
                1,
            ],
            [
                48,
                17,
            ],
            [
                0,
                1,
            ],
            [
                48,
                21,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                20,
                74,
            ],
            [
                0,
                5,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                6,
            ],
            [
                20,
                20,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                2,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                11,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                46,
            ],
            [
                0,
                1,
            ],
            [
                20,
                17,
            ],
            [
                0,
                1,
            ],
            [
                20,
                21,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Area': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                10,
                3,
            ],
            [
                11,
                10,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Neighborhood': [
            [
                264,
                74,
            ],
            [
                0,
                5,
            ],
            [
                264,
                4,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                4,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                6,
            ],
            [
                264,
                20,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                4,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                2,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                11,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                46,
            ],
            [
                0,
                1,
            ],
            [
                264,
                4,
            ],
            [
                250,
                3,
            ],
            [
                261,
                3,
            ],
            [
                264,
                7,
            ],
            [
                0,
                1,
            ],
            [
                264,
                21,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                1,
            ],
            [
                0,
                1,
            ],
            [
                264,
                25,
            ],
        ],
        'School District': [
            [
                197,
                74,
            ],
            [
                0,
                5,
            ],
            [
                197,
                4,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                4,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                6,
            ],
            [
                197,
                20,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                4,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                2,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                11,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                46,
            ],
            [
                0,
                1,
            ],
            [
                197,
                4,
            ],
            [
                188,
                3,
            ],
            [
                197,
                10,
            ],
            [
                0,
                1,
            ],
            [
                197,
                5,
            ],
            [
                195,
                2,
            ],
            [
                197,
                1,
            ],
            [
                195,
                2,
            ],
            [
                197,
                11,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                1,
            ],
            [
                0,
                1,
            ],
            [
                197,
                25,
            ],
        ],
        'State House District': [
            [
                60,
                74,
            ],
            [
                0,
                5,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                6,
            ],
            [
                60,
                20,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                4,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                2,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                11,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                46,
            ],
            [
                0,
                1,
            ],
            [
                60,
                17,
            ],
            [
                0,
                1,
            ],
            [
                60,
                21,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                1,
            ],
            [
                0,
                1,
            ],
            [
                60,
                25,
            ],
        ],
        'State Senate District': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                17,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                61,
                74,
            ],
            [
                0,
                5,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                6,
            ],
            [
                61,
                20,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                2,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                11,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                46,
            ],
            [
                0,
                1,
            ],
            [
                61,
                17,
            ],
            [
                0,
                1,
            ],
            [
                61,
                21,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                25,
            ],
        ],
        'Urban Center': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'ZIP': [
            [
                419,
                3,
            ],
            [
                418,
                26,
            ],
            [
                419,
                1,
            ],
            [
                418,
                4,
            ],
            [
                419,
                1,
            ],
            [
                418,
                4,
            ],
            [
                419,
                1,
            ],
            [
                418,
                4,
            ],
            [
                419,
                1,
            ],
            [
                418,
                4,
            ],
            [
                419,
                1,
            ],
            [
                418,
                4,
            ],
            [
                419,
                2,
            ],
            [
                418,
                2,
            ],
            [
                419,
                4,
            ],
            [
                418,
                2,
            ],
            [
                419,
                10,
            ],
            [
                0,
                5,
            ],
            [
                419,
                4,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                4,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                6,
            ],
            [
                419,
                3,
            ],
            [
                418,
                8,
            ],
            [
                419,
                1,
            ],
            [
                418,
                4,
            ],
            [
                419,
                1,
            ],
            [
                418,
                2,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                418,
                1,
            ],
            [
                0,
                1,
            ],
            [
                418,
                1,
            ],
            [
                0,
                1,
            ],
            [
                418,
                1,
            ],
            [
                419,
                1,
            ],
            [
                418,
                2,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                2,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                3,
            ],
            [
                418,
                1,
            ],
            [
                419,
                3,
            ],
            [
                418,
                4,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                12,
            ],
            [
                418,
                2,
            ],
            [
                419,
                26,
            ],
            [
                418,
                2,
            ],
            [
                419,
                4,
            ],
            [
                0,
                1,
            ],
            [
                419,
                4,
            ],
            [
                371,
                3,
            ],
            [
                412,
                3,
            ],
            [
                415,
                3,
            ],
            [
                418,
                1,
            ],
            [
                419,
                3,
            ],
            [
                0,
                1,
            ],
            [
                418,
                2,
            ],
            [
                419,
                1,
            ],
            [
                418,
                2,
            ],
            [
                415,
                1,
            ],
            [
                416,
                1,
            ],
            [
                418,
                1,
            ],
            [
                415,
                2,
            ],
            [
                419,
                11,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                6,
            ],
            [
                417,
                2,
            ],
            [
                418,
                3,
            ],
            [
                419,
                1,
            ],
            [
                418,
                2,
            ],
            [
                419,
                11,
            ],
        ],
    },
    'Pennsylvania, USA': {
        'overall': [
            [
                8808,
                3,
            ],
            [
                8790,
                26,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8815,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8805,
                3,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8805,
                3,
            ],
            [
                8815,
                1,
            ],
            [
                8809,
                6,
            ],
            [
                0,
                5,
            ],
            [
                8804,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8804,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8803,
                3,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                5,
            ],
            [
                8815,
                2,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8794,
                6,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8795,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8795,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8795,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                3,
            ],
            [
                8794,
                1,
            ],
            [
                8805,
                3,
            ],
            [
                8790,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8809,
                12,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8805,
                25,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8809,
                2,
            ],
            [
                8804,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                7737,
                3,
            ],
            [
                8535,
                3,
            ],
            [
                8774,
                3,
            ],
            [
                8794,
                1,
            ],
            [
                8809,
                3,
            ],
            [
                0,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8479,
                1,
            ],
            [
                8621,
                1,
            ],
            [
                8778,
                1,
            ],
            [
                8471,
                1,
            ],
            [
                8608,
                1,
            ],
            [
                8809,
                1,
            ],
            [
                8805,
                5,
            ],
            [
                8809,
                4,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8805,
                5,
            ],
            [
                8806,
                1,
            ],
            [
                8763,
                1,
            ],
            [
                8783,
                1,
            ],
            [
                8795,
                3,
            ],
            [
                8809,
                1,
            ],
            [
                8769,
                1,
            ],
            [
                8790,
                1,
            ],
            [
                8809,
                5,
            ],
            [
                8800,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                54,
            ],
            [
                4,
                1,
            ],
            [
                3,
                12,
            ],
            [
                4,
                1,
            ],
            [
                3,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                7,
            ],
            [
                3,
                18,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                3,
                54,
            ],
            [
                5,
                1,
            ],
            [
                3,
                12,
            ],
            [
                5,
                1,
            ],
            [
                3,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                7,
            ],
            [
                3,
                18,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                6,
                54,
            ],
            [
                8,
                1,
            ],
            [
                6,
                12,
            ],
            [
                8,
                1,
            ],
            [
                6,
                6,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                7,
            ],
            [
                6,
                18,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'CCD': [
            [
                2570,
                3,
            ],
            [
                2571,
                71,
            ],
            [
                0,
                5,
            ],
            [
                2571,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2571,
                5,
            ],
            [
                2569,
                6,
            ],
            [
                2571,
                9,
            ],
            [
                0,
                1,
            ],
            [
                2569,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2569,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2569,
                1,
            ],
            [
                2571,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                3,
            ],
            [
                2569,
                1,
            ],
            [
                2571,
                7,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                4,
            ],
            [
                2139,
                3,
            ],
            [
                2508,
                3,
            ],
            [
                2568,
                3,
            ],
            [
                2569,
                1,
            ],
            [
                2571,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                5,
            ],
            [
                2515,
                1,
            ],
            [
                2533,
                1,
            ],
            [
                2567,
                1,
            ],
            [
                2513,
                1,
            ],
            [
                2532,
                1,
            ],
            [
                2571,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2571,
                8,
            ],
            [
                2569,
                3,
            ],
            [
                2571,
                14,
            ],
        ],
        'CSA': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'City': [
            [
                1888,
                3,
            ],
            [
                1872,
                26,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                2,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                4,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1888,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1888,
                3,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1883,
                6,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1884,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1884,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1884,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                3,
            ],
            [
                1883,
                1,
            ],
            [
                1888,
                3,
            ],
            [
                1872,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                12,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                26,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                2,
            ],
            [
                1887,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1524,
                3,
            ],
            [
                1776,
                3,
            ],
            [
                1877,
                3,
            ],
            [
                1883,
                1,
            ],
            [
                1888,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1723,
                1,
            ],
            [
                1794,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1722,
                1,
            ],
            [
                1790,
                1,
            ],
            [
                1888,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1888,
                6,
            ],
            [
                1853,
                1,
            ],
            [
                1870,
                1,
            ],
            [
                1884,
                3,
            ],
            [
                1888,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1872,
                1,
            ],
            [
                1888,
                5,
            ],
            [
                1886,
                6,
            ],
        ],
        'Congressional District': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                17,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'County': [
            [
                67,
                74,
            ],
            [
                0,
                5,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                6,
            ],
            [
                67,
                20,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                4,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                2,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                11,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                46,
            ],
            [
                0,
                1,
            ],
            [
                67,
                17,
            ],
            [
                0,
                1,
            ],
            [
                67,
                21,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                1,
            ],
            [
                0,
                1,
            ],
            [
                67,
                25,
            ],
        ],
        'County Cross CD': [
            [
                83,
                74,
            ],
            [
                0,
                5,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                6,
            ],
            [
                83,
                20,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                2,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                11,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                46,
            ],
            [
                0,
                1,
            ],
            [
                83,
                4,
            ],
            [
                82,
                3,
            ],
            [
                83,
                10,
            ],
            [
                0,
                1,
            ],
            [
                83,
                21,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                872,
                74,
            ],
            [
                0,
                5,
            ],
            [
                872,
                4,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                4,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                6,
            ],
            [
                872,
                20,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                4,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                2,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                11,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                46,
            ],
            [
                0,
                1,
            ],
            [
                872,
                17,
            ],
            [
                0,
                1,
            ],
            [
                872,
                21,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                1,
            ],
            [
                0,
                1,
            ],
            [
                872,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                17,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                136,
                74,
            ],
            [
                0,
                5,
            ],
            [
                136,
                4,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                4,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                6,
            ],
            [
                136,
                20,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                4,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                2,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                11,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                46,
            ],
            [
                0,
                1,
            ],
            [
                136,
                17,
            ],
            [
                0,
                1,
            ],
            [
                136,
                21,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                1,
            ],
            [
                0,
                1,
            ],
            [
                136,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                11,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                17,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                25,
            ],
        ],
        'Media Market': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Neighborhood': [
            [
                424,
                30,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                3,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                421,
                3,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                421,
                3,
            ],
            [
                424,
                7,
            ],
            [
                0,
                5,
            ],
            [
                421,
                4,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                4,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                6,
            ],
            [
                424,
                3,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                421,
                6,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                2,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                7,
            ],
            [
                424,
                4,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                12,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                421,
                25,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                5,
            ],
            [
                0,
                1,
            ],
            [
                424,
                2,
            ],
            [
                421,
                1,
            ],
            [
                424,
                1,
            ],
            [
                382,
                3,
            ],
            [
                387,
                3,
            ],
            [
                411,
                3,
            ],
            [
                421,
                1,
            ],
            [
                424,
                3,
            ],
            [
                0,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                422,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                422,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                421,
                5,
            ],
            [
                424,
                4,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                1,
            ],
            [
                0,
                1,
            ],
            [
                421,
                5,
            ],
            [
                423,
                1,
            ],
            [
                422,
                1,
            ],
            [
                424,
                1,
            ],
            [
                421,
                3,
            ],
            [
                424,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                6,
            ],
            [
                421,
                6,
            ],
        ],
        'School District': [
            [
                500,
                74,
            ],
            [
                0,
                5,
            ],
            [
                500,
                4,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                4,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                6,
            ],
            [
                500,
                20,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                4,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                2,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                11,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                46,
            ],
            [
                0,
                1,
            ],
            [
                500,
                17,
            ],
            [
                0,
                1,
            ],
            [
                500,
                21,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                1,
            ],
            [
                0,
                1,
            ],
            [
                500,
                25,
            ],
        ],
        'State House District': [
            [
                203,
                74,
            ],
            [
                0,
                5,
            ],
            [
                203,
                4,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                4,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                6,
            ],
            [
                203,
                20,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                4,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                2,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                11,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                46,
            ],
            [
                0,
                1,
            ],
            [
                203,
                17,
            ],
            [
                0,
                1,
            ],
            [
                203,
                21,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                25,
            ],
        ],
        'State Senate District': [
            [
                50,
                74,
            ],
            [
                0,
                5,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                20,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                2,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                11,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                46,
            ],
            [
                0,
                1,
            ],
            [
                50,
                17,
            ],
            [
                0,
                1,
            ],
            [
                50,
                21,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                99,
                3,
            ],
            [
                100,
                10,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'Urban Center': [
            [
                16,
                74,
            ],
            [
                0,
                5,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                2,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                11,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                46,
            ],
            [
                0,
                1,
            ],
            [
                16,
                17,
            ],
            [
                0,
                1,
            ],
            [
                16,
                21,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
        ],
        'ZIP': [
            [
                1788,
                3,
            ],
            [
                1785,
                26,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                2,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1787,
                3,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1787,
                3,
            ],
            [
                1788,
                7,
            ],
            [
                0,
                5,
            ],
            [
                1786,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1786,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1786,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1785,
                3,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1788,
                3,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1783,
                6,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1783,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                3,
            ],
            [
                1783,
                1,
            ],
            [
                1787,
                3,
            ],
            [
                1785,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1788,
                12,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1787,
                25,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1788,
                2,
            ],
            [
                1787,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1556,
                3,
            ],
            [
                1726,
                3,
            ],
            [
                1780,
                3,
            ],
            [
                1783,
                1,
            ],
            [
                1788,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1681,
                1,
            ],
            [
                1733,
                1,
            ],
            [
                1777,
                1,
            ],
            [
                1676,
                1,
            ],
            [
                1725,
                1,
            ],
            [
                1788,
                1,
            ],
            [
                1787,
                5,
            ],
            [
                1788,
                4,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1787,
                5,
            ],
            [
                1786,
                1,
            ],
            [
                1779,
                1,
            ],
            [
                1780,
                1,
            ],
            [
                1783,
                3,
            ],
            [
                1788,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1785,
                1,
            ],
            [
                1788,
                5,
            ],
            [
                1784,
                6,
            ],
        ],
    },
    'Rhode Island, USA': {
        'overall': [
            [
                434,
                3,
            ],
            [
                433,
                26,
            ],
            [
                434,
                2,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                2,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                2,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                2,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                2,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                3,
            ],
            [
                433,
                1,
            ],
            [
                434,
                5,
            ],
            [
                433,
                1,
            ],
            [
                434,
                10,
            ],
            [
                0,
                5,
            ],
            [
                434,
                4,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                4,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                5,
            ],
            [
                434,
                4,
            ],
            [
                433,
                1,
            ],
            [
                434,
                8,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                2,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                3,
            ],
            [
                433,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                2,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                7,
            ],
            [
                433,
                4,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                13,
            ],
            [
                433,
                1,
            ],
            [
                434,
                27,
            ],
            [
                433,
                1,
            ],
            [
                434,
                4,
            ],
            [
                0,
                1,
            ],
            [
                434,
                4,
            ],
            [
                421,
                3,
            ],
            [
                423,
                3,
            ],
            [
                433,
                3,
            ],
            [
                434,
                4,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                2,
            ],
            [
                433,
                1,
            ],
            [
                432,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                1,
            ],
            [
                432,
                1,
            ],
            [
                433,
                1,
            ],
            [
                434,
                11,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                1,
            ],
            [
                0,
                1,
            ],
            [
                434,
                7,
            ],
            [
                433,
                1,
            ],
            [
                434,
                5,
            ],
            [
                433,
                1,
            ],
            [
                434,
                11,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                39,
                74,
            ],
            [
                0,
                5,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                6,
            ],
            [
                39,
                20,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                2,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                11,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                46,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                38,
                6,
            ],
            [
                39,
                7,
            ],
            [
                0,
                1,
            ],
            [
                39,
                21,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                25,
            ],
        ],
        'CSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                36,
                74,
            ],
            [
                0,
                5,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36,
                20,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                2,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                11,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                46,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                33,
                3,
            ],
            [
                34,
                3,
            ],
            [
                36,
                7,
            ],
            [
                0,
                1,
            ],
            [
                36,
                21,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                25,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'County': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'County Cross CD': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Media Market': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Neighborhood': [
            [
                73,
                3,
            ],
            [
                72,
                26,
            ],
            [
                73,
                2,
            ],
            [
                72,
                1,
            ],
            [
                73,
                1,
            ],
            [
                72,
                1,
            ],
            [
                73,
                2,
            ],
            [
                72,
                1,
            ],
            [
                73,
                1,
            ],
            [
                72,
                1,
            ],
            [
                73,
                2,
            ],
            [
                72,
                1,
            ],
            [
                73,
                1,
            ],
            [
                72,
                1,
            ],
            [
                73,
                2,
            ],
            [
                72,
                1,
            ],
            [
                73,
                1,
            ],
            [
                72,
                1,
            ],
            [
                73,
                2,
            ],
            [
                72,
                1,
            ],
            [
                73,
                1,
            ],
            [
                72,
                1,
            ],
            [
                73,
                3,
            ],
            [
                72,
                1,
            ],
            [
                73,
                5,
            ],
            [
                72,
                1,
            ],
            [
                73,
                10,
            ],
            [
                0,
                5,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                6,
            ],
            [
                73,
                4,
            ],
            [
                72,
                1,
            ],
            [
                73,
                8,
            ],
            [
                72,
                1,
            ],
            [
                73,
                1,
            ],
            [
                72,
                1,
            ],
            [
                73,
                2,
            ],
            [
                72,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                3,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                2,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                7,
            ],
            [
                72,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                13,
            ],
            [
                72,
                1,
            ],
            [
                73,
                27,
            ],
            [
                72,
                1,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                4,
            ],
            [
                71,
                6,
            ],
            [
                72,
                3,
            ],
            [
                73,
                4,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                72,
                1,
            ],
            [
                73,
                2,
            ],
            [
                72,
                3,
            ],
            [
                73,
                1,
            ],
            [
                72,
                2,
            ],
            [
                73,
                11,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                1,
            ],
            [
                0,
                1,
            ],
            [
                73,
                7,
            ],
            [
                72,
                1,
            ],
            [
                73,
                5,
            ],
            [
                72,
                1,
            ],
            [
                73,
                11,
            ],
        ],
        'School District': [
            [
                36,
                74,
            ],
            [
                0,
                5,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36,
                20,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                2,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                11,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                46,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                35,
                3,
            ],
            [
                36,
                10,
            ],
            [
                0,
                1,
            ],
            [
                36,
                21,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                25,
            ],
        ],
        'State House District': [
            [
                75,
                74,
            ],
            [
                0,
                5,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                6,
            ],
            [
                75,
                20,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                2,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                11,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                46,
            ],
            [
                0,
                1,
            ],
            [
                75,
                17,
            ],
            [
                0,
                1,
            ],
            [
                75,
                21,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                25,
            ],
        ],
        'State Senate District': [
            [
                38,
                74,
            ],
            [
                0,
                5,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                6,
            ],
            [
                38,
                20,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                2,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                11,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                46,
            ],
            [
                0,
                1,
            ],
            [
                38,
                17,
            ],
            [
                0,
                1,
            ],
            [
                38,
                21,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Urban Center': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'ZIP': [
            [
                77,
                74,
            ],
            [
                0,
                5,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                6,
            ],
            [
                77,
                20,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                2,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                11,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                46,
            ],
            [
                0,
                1,
            ],
            [
                77,
                4,
            ],
            [
                71,
                6,
            ],
            [
                77,
                7,
            ],
            [
                0,
                1,
            ],
            [
                77,
                5,
            ],
            [
                76,
                1,
            ],
            [
                77,
                2,
            ],
            [
                76,
                1,
            ],
            [
                77,
                12,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                25,
            ],
        ],
    },
    'South Carolina, USA': {
        'overall': [
            [
                2058,
                3,
            ],
            [
                2057,
                26,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                2,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                4,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                10,
            ],
            [
                0,
                5,
            ],
            [
                2058,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2058,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2058,
                3,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                5,
            ],
            [
                2059,
                3,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2054,
                6,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2054,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2054,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2054,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                3,
            ],
            [
                2054,
                1,
            ],
            [
                2059,
                3,
            ],
            [
                2057,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                12,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                26,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                4,
            ],
            [
                1677,
                3,
            ],
            [
                1993,
                3,
            ],
            [
                2045,
                3,
            ],
            [
                2054,
                1,
            ],
            [
                2059,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2043,
                1,
            ],
            [
                2053,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                2043,
                1,
            ],
            [
                2053,
                1,
            ],
            [
                2059,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2059,
                5,
            ],
            [
                2056,
                1,
            ],
            [
                2047,
                1,
            ],
            [
                2050,
                1,
            ],
            [
                2054,
                3,
            ],
            [
                2059,
                1,
            ],
            [
                2048,
                1,
            ],
            [
                2057,
                1,
            ],
            [
                2059,
                5,
            ],
            [
                2055,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                299,
                74,
            ],
            [
                0,
                5,
            ],
            [
                299,
                4,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                4,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                6,
            ],
            [
                299,
                20,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                4,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                2,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                11,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                46,
            ],
            [
                0,
                1,
            ],
            [
                299,
                4,
            ],
            [
                212,
                3,
            ],
            [
                296,
                3,
            ],
            [
                299,
                7,
            ],
            [
                0,
                1,
            ],
            [
                299,
                21,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                1,
            ],
            [
                0,
                1,
            ],
            [
                299,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                475,
                30,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                474,
                1,
            ],
            [
                475,
                2,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                474,
                1,
            ],
            [
                475,
                2,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                474,
                1,
            ],
            [
                475,
                2,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                474,
                1,
            ],
            [
                475,
                2,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                474,
                1,
            ],
            [
                475,
                3,
            ],
            [
                474,
                1,
            ],
            [
                475,
                5,
            ],
            [
                474,
                1,
            ],
            [
                475,
                11,
            ],
            [
                0,
                5,
            ],
            [
                475,
                4,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                4,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                6,
            ],
            [
                475,
                3,
            ],
            [
                474,
                1,
            ],
            [
                475,
                8,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                474,
                1,
            ],
            [
                475,
                2,
            ],
            [
                474,
                1,
            ],
            [
                475,
                2,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                2,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                2,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                11,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                12,
            ],
            [
                474,
                1,
            ],
            [
                475,
                27,
            ],
            [
                474,
                1,
            ],
            [
                475,
                5,
            ],
            [
                0,
                1,
            ],
            [
                475,
                4,
            ],
            [
                313,
                3,
            ],
            [
                442,
                3,
            ],
            [
                469,
                3,
            ],
            [
                475,
                4,
            ],
            [
                0,
                1,
            ],
            [
                474,
                1,
            ],
            [
                475,
                2,
            ],
            [
                474,
                1,
            ],
            [
                475,
                1,
            ],
            [
                470,
                1,
            ],
            [
                471,
                1,
            ],
            [
                475,
                1,
            ],
            [
                470,
                1,
            ],
            [
                471,
                1,
            ],
            [
                475,
                11,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                1,
            ],
            [
                0,
                1,
            ],
            [
                475,
                6,
            ],
            [
                474,
                1,
            ],
            [
                475,
                5,
            ],
            [
                474,
                1,
            ],
            [
                475,
                12,
            ],
        ],
        'Congressional District': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'County': [
            [
                46,
                74,
            ],
            [
                0,
                5,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                6,
            ],
            [
                46,
                20,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                2,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                46,
            ],
            [
                0,
                1,
            ],
            [
                46,
                17,
            ],
            [
                0,
                1,
            ],
            [
                46,
                21,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                25,
            ],
        ],
        'County Cross CD': [
            [
                56,
                74,
            ],
            [
                0,
                5,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                6,
            ],
            [
                56,
                20,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                2,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                11,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                46,
            ],
            [
                0,
                1,
            ],
            [
                56,
                4,
            ],
            [
                55,
                3,
            ],
            [
                56,
                10,
            ],
            [
                0,
                1,
            ],
            [
                56,
                21,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                1,
            ],
            [
                0,
                1,
            ],
            [
                56,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                168,
                74,
            ],
            [
                0,
                5,
            ],
            [
                168,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                6,
            ],
            [
                168,
                20,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                4,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                2,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                11,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                46,
            ],
            [
                0,
                1,
            ],
            [
                168,
                17,
            ],
            [
                0,
                1,
            ],
            [
                168,
                21,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                168,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                53,
                74,
            ],
            [
                0,
                5,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                6,
            ],
            [
                53,
                20,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                4,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                2,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                11,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                46,
            ],
            [
                0,
                1,
            ],
            [
                53,
                17,
            ],
            [
                0,
                1,
            ],
            [
                53,
                21,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                1,
            ],
            [
                0,
                1,
            ],
            [
                53,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                17,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                5,
                3,
            ],
            [
                7,
                10,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Neighborhood': [
            [
                171,
                30,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                3,
            ],
            [
                166,
                1,
            ],
            [
                171,
                5,
            ],
            [
                166,
                1,
            ],
            [
                171,
                11,
            ],
            [
                0,
                5,
            ],
            [
                171,
                4,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                4,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                6,
            ],
            [
                171,
                3,
            ],
            [
                166,
                1,
            ],
            [
                171,
                8,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                2,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                11,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                12,
            ],
            [
                166,
                1,
            ],
            [
                171,
                27,
            ],
            [
                166,
                1,
            ],
            [
                171,
                5,
            ],
            [
                0,
                1,
            ],
            [
                171,
                4,
            ],
            [
                137,
                3,
            ],
            [
                158,
                3,
            ],
            [
                170,
                3,
            ],
            [
                171,
                4,
            ],
            [
                0,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                1,
            ],
            [
                166,
                1,
            ],
            [
                171,
                2,
            ],
            [
                166,
                1,
            ],
            [
                171,
                12,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                6,
            ],
            [
                166,
                1,
            ],
            [
                171,
                5,
            ],
            [
                166,
                1,
            ],
            [
                171,
                12,
            ],
        ],
        'School District': [
            [
                83,
                29,
            ],
            [
                84,
                2,
            ],
            [
                83,
                1,
            ],
            [
                84,
                1,
            ],
            [
                83,
                1,
            ],
            [
                84,
                2,
            ],
            [
                83,
                1,
            ],
            [
                84,
                1,
            ],
            [
                83,
                1,
            ],
            [
                84,
                2,
            ],
            [
                83,
                1,
            ],
            [
                84,
                1,
            ],
            [
                83,
                1,
            ],
            [
                84,
                2,
            ],
            [
                83,
                1,
            ],
            [
                84,
                1,
            ],
            [
                83,
                1,
            ],
            [
                84,
                2,
            ],
            [
                83,
                1,
            ],
            [
                84,
                1,
            ],
            [
                83,
                1,
            ],
            [
                84,
                3,
            ],
            [
                83,
                1,
            ],
            [
                84,
                5,
            ],
            [
                83,
                1,
            ],
            [
                84,
                10,
            ],
            [
                0,
                5,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                3,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                6,
            ],
            [
                84,
                4,
            ],
            [
                83,
                1,
            ],
            [
                84,
                8,
            ],
            [
                83,
                1,
            ],
            [
                84,
                1,
            ],
            [
                83,
                1,
            ],
            [
                84,
                2,
            ],
            [
                83,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                3,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                2,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                7,
            ],
            [
                83,
                4,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                13,
            ],
            [
                83,
                1,
            ],
            [
                84,
                27,
            ],
            [
                83,
                1,
            ],
            [
                84,
                4,
            ],
            [
                0,
                1,
            ],
            [
                84,
                4,
            ],
            [
                81,
                3,
            ],
            [
                83,
                6,
            ],
            [
                84,
                4,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                83,
                1,
            ],
            [
                84,
                2,
            ],
            [
                83,
                3,
            ],
            [
                84,
                1,
            ],
            [
                83,
                2,
            ],
            [
                84,
                11,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                7,
            ],
            [
                83,
                1,
            ],
            [
                84,
                5,
            ],
            [
                83,
                1,
            ],
            [
                84,
                11,
            ],
        ],
        'State House District': [
            [
                124,
                74,
            ],
            [
                0,
                5,
            ],
            [
                124,
                4,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                4,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                6,
            ],
            [
                124,
                20,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                4,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                2,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                11,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                46,
            ],
            [
                0,
                1,
            ],
            [
                124,
                17,
            ],
            [
                0,
                1,
            ],
            [
                124,
                21,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                1,
            ],
            [
                0,
                1,
            ],
            [
                124,
                25,
            ],
        ],
        'State Senate District': [
            [
                46,
                74,
            ],
            [
                0,
                5,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                6,
            ],
            [
                46,
                20,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                4,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                2,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                11,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                46,
            ],
            [
                0,
                1,
            ],
            [
                46,
                17,
            ],
            [
                0,
                1,
            ],
            [
                46,
                21,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                50,
                74,
            ],
            [
                0,
                5,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                20,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                2,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                11,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                46,
            ],
            [
                0,
                1,
            ],
            [
                50,
                4,
            ],
            [
                49,
                3,
            ],
            [
                50,
                10,
            ],
            [
                0,
                1,
            ],
            [
                50,
                21,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                1,
            ],
            [
                0,
                1,
            ],
            [
                50,
                25,
            ],
        ],
        'Urban Center': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'ZIP': [
            [
                424,
                3,
            ],
            [
                423,
                26,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                2,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                4,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                10,
            ],
            [
                0,
                5,
            ],
            [
                424,
                4,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                4,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                6,
            ],
            [
                424,
                3,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                6,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                2,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                3,
            ],
            [
                419,
                1,
            ],
            [
                424,
                3,
            ],
            [
                423,
                4,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                12,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                26,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                4,
            ],
            [
                0,
                1,
            ],
            [
                424,
                4,
            ],
            [
                332,
                3,
            ],
            [
                408,
                3,
            ],
            [
                418,
                3,
            ],
            [
                419,
                1,
            ],
            [
                424,
                3,
            ],
            [
                0,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                11,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                1,
            ],
            [
                0,
                1,
            ],
            [
                424,
                5,
            ],
            [
                421,
                1,
            ],
            [
                418,
                1,
            ],
            [
                416,
                1,
            ],
            [
                419,
                3,
            ],
            [
                424,
                1,
            ],
            [
                419,
                1,
            ],
            [
                423,
                1,
            ],
            [
                424,
                5,
            ],
            [
                420,
                6,
            ],
        ],
    },
    'South Dakota, USA': {
        'overall': [
            [
                2724,
                3,
            ],
            [
                2687,
                26,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2727,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                4,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                3,
            ],
            [
                2727,
                1,
            ],
            [
                2724,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2721,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                3,
            ],
            [
                2724,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                5,
            ],
            [
                2727,
                2,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2684,
                6,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2684,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2684,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2684,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                3,
            ],
            [
                2684,
                1,
            ],
            [
                2724,
                3,
            ],
            [
                2687,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                12,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2721,
                25,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2724,
                4,
            ],
            [
                2130,
                3,
            ],
            [
                2519,
                3,
            ],
            [
                2675,
                3,
            ],
            [
                2684,
                1,
            ],
            [
                2724,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2106,
                1,
            ],
            [
                2227,
                1,
            ],
            [
                2539,
                1,
            ],
            [
                2054,
                1,
            ],
            [
                2176,
                1,
            ],
            [
                2724,
                10,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2721,
                5,
            ],
            [
                2704,
                1,
            ],
            [
                2681,
                1,
            ],
            [
                2670,
                1,
            ],
            [
                2684,
                3,
            ],
            [
                2724,
                1,
            ],
            [
                2682,
                1,
            ],
            [
                2687,
                1,
            ],
            [
                2724,
                5,
            ],
            [
                2704,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'CCD': [
            [
                1329,
                3,
            ],
            [
                1327,
                26,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                4,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                4,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                4,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                4,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                4,
            ],
            [
                1329,
                2,
            ],
            [
                1327,
                2,
            ],
            [
                1329,
                4,
            ],
            [
                1327,
                2,
            ],
            [
                1329,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1327,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                3,
            ],
            [
                1329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1329,
                3,
            ],
            [
                1327,
                2,
            ],
            [
                1322,
                6,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                4,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                1329,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1322,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1322,
                1,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                3,
            ],
            [
                1322,
                1,
            ],
            [
                1329,
                3,
            ],
            [
                1327,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                12,
            ],
            [
                1327,
                27,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                1329,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1329,
                4,
            ],
            [
                943,
                3,
            ],
            [
                1216,
                3,
            ],
            [
                1317,
                3,
            ],
            [
                1322,
                1,
            ],
            [
                1329,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                848,
                1,
            ],
            [
                952,
                1,
            ],
            [
                1166,
                1,
            ],
            [
                804,
                1,
            ],
            [
                905,
                1,
            ],
            [
                1329,
                10,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1327,
                5,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                1322,
                3,
            ],
            [
                1329,
                1,
            ],
            [
                1327,
                2,
            ],
            [
                1329,
                11,
            ],
        ],
        'CSA': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'City': [
            [
                478,
                3,
            ],
            [
                443,
                26,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                2,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                4,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                10,
            ],
            [
                0,
                5,
            ],
            [
                477,
                4,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                3,
            ],
            [
                478,
                1,
            ],
            [
                0,
                1,
            ],
            [
                478,
                1,
            ],
            [
                0,
                1,
            ],
            [
                478,
                1,
            ],
            [
                0,
                1,
            ],
            [
                478,
                1,
            ],
            [
                0,
                1,
            ],
            [
                478,
                1,
            ],
            [
                0,
                1,
            ],
            [
                478,
                1,
            ],
            [
                0,
                6,
            ],
            [
                478,
                3,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                446,
                6,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                1,
            ],
            [
                0,
                1,
            ],
            [
                446,
                1,
            ],
            [
                0,
                1,
            ],
            [
                446,
                1,
            ],
            [
                0,
                1,
            ],
            [
                446,
                1,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                2,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                478,
                3,
            ],
            [
                446,
                1,
            ],
            [
                478,
                3,
            ],
            [
                443,
                4,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                478,
                12,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                477,
                25,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                4,
            ],
            [
                0,
                1,
            ],
            [
                478,
                4,
            ],
            [
                328,
                3,
            ],
            [
                401,
                3,
            ],
            [
                443,
                3,
            ],
            [
                446,
                1,
            ],
            [
                478,
                3,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                352,
                1,
            ],
            [
                367,
                1,
            ],
            [
                459,
                1,
            ],
            [
                347,
                1,
            ],
            [
                365,
                1,
            ],
            [
                478,
                10,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                1,
            ],
            [
                0,
                1,
            ],
            [
                477,
                5,
            ],
            [
                459,
                1,
            ],
            [
                438,
                1,
            ],
            [
                428,
                1,
            ],
            [
                446,
                3,
            ],
            [
                478,
                1,
            ],
            [
                438,
                1,
            ],
            [
                443,
                1,
            ],
            [
                478,
                5,
            ],
            [
                459,
                6,
            ],
        ],
        'Congressional District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                66,
                74,
            ],
            [
                0,
                5,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                6,
            ],
            [
                66,
                20,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                2,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                11,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                46,
            ],
            [
                0,
                1,
            ],
            [
                66,
                17,
            ],
            [
                0,
                1,
            ],
            [
                66,
                21,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                25,
            ],
        ],
        'County Cross CD': [
            [
                66,
                74,
            ],
            [
                0,
                5,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                6,
            ],
            [
                66,
                20,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                4,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                2,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                11,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                46,
            ],
            [
                0,
                1,
            ],
            [
                66,
                17,
            ],
            [
                0,
                1,
            ],
            [
                66,
                21,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                1,
            ],
            [
                0,
                1,
            ],
            [
                66,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                54,
                74,
            ],
            [
                0,
                5,
            ],
            [
                54,
                4,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                4,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                6,
            ],
            [
                54,
                20,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                4,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                2,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                11,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                46,
            ],
            [
                0,
                1,
            ],
            [
                54,
                17,
            ],
            [
                0,
                1,
            ],
            [
                54,
                21,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Media Market': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Native Area': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                41,
                3,
            ],
            [
                48,
                3,
            ],
            [
                49,
                7,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'Neighborhood': [
            [
                13,
                74,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13,
                20,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                11,
                3,
            ],
            [
                13,
                10,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        'School District': [
            [
                149,
                74,
            ],
            [
                0,
                5,
            ],
            [
                149,
                4,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                4,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                6,
            ],
            [
                149,
                20,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                4,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                2,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                11,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                46,
            ],
            [
                0,
                1,
            ],
            [
                149,
                4,
            ],
            [
                146,
                3,
            ],
            [
                148,
                3,
            ],
            [
                149,
                7,
            ],
            [
                0,
                1,
            ],
            [
                149,
                21,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                25,
            ],
        ],
        'State House District': [
            [
                37,
                74,
            ],
            [
                0,
                5,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                20,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                4,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                2,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                11,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                46,
            ],
            [
                0,
                1,
            ],
            [
                37,
                17,
            ],
            [
                0,
                1,
            ],
            [
                37,
                21,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                25,
            ],
        ],
        'State Senate District': [
            [
                35,
                74,
            ],
            [
                0,
                5,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                6,
            ],
            [
                35,
                20,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                4,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                2,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                11,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                46,
            ],
            [
                0,
                1,
            ],
            [
                35,
                17,
            ],
            [
                0,
                1,
            ],
            [
                35,
                21,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                21,
                74,
            ],
            [
                0,
                5,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                6,
            ],
            [
                21,
                20,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                4,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                2,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                11,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                46,
            ],
            [
                0,
                1,
            ],
            [
                21,
                17,
            ],
            [
                0,
                1,
            ],
            [
                21,
                21,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                383,
                74,
            ],
            [
                0,
                5,
            ],
            [
                383,
                4,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                4,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                6,
            ],
            [
                383,
                5,
            ],
            [
                382,
                6,
            ],
            [
                383,
                9,
            ],
            [
                0,
                1,
            ],
            [
                382,
                1,
            ],
            [
                0,
                1,
            ],
            [
                382,
                1,
            ],
            [
                0,
                1,
            ],
            [
                382,
                1,
            ],
            [
                383,
                3,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                2,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                3,
            ],
            [
                382,
                1,
            ],
            [
                383,
                7,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                46,
            ],
            [
                0,
                1,
            ],
            [
                383,
                4,
            ],
            [
                338,
                3,
            ],
            [
                370,
                3,
            ],
            [
                381,
                3,
            ],
            [
                382,
                1,
            ],
            [
                383,
                3,
            ],
            [
                0,
                1,
            ],
            [
                383,
                5,
            ],
            [
                372,
                1,
            ],
            [
                374,
                1,
            ],
            [
                380,
                1,
            ],
            [
                369,
                1,
            ],
            [
                372,
                1,
            ],
            [
                383,
                11,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                1,
            ],
            [
                0,
                1,
            ],
            [
                383,
                5,
            ],
            [
                382,
                2,
            ],
            [
                381,
                1,
            ],
            [
                382,
                3,
            ],
            [
                383,
                8,
            ],
            [
                382,
                6,
            ],
        ],
    },
    'Tennessee, USA': {
        'overall': [
            [
                3572,
                3,
            ],
            [
                3554,
                26,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                2,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                4,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                10,
            ],
            [
                0,
                5,
            ],
            [
                3572,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3571,
                3,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                5,
            ],
            [
                3572,
                3,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3568,
                6,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3569,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3569,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3569,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                3,
            ],
            [
                3568,
                1,
            ],
            [
                3572,
                3,
            ],
            [
                3554,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                12,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                26,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                2,
            ],
            [
                3571,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3060,
                3,
            ],
            [
                3453,
                3,
            ],
            [
                3556,
                3,
            ],
            [
                3568,
                1,
            ],
            [
                3572,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3457,
                1,
            ],
            [
                3536,
                1,
            ],
            [
                3569,
                1,
            ],
            [
                3457,
                1,
            ],
            [
                3536,
                1,
            ],
            [
                3572,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3572,
                5,
            ],
            [
                3571,
                1,
            ],
            [
                3486,
                1,
            ],
            [
                3549,
                1,
            ],
            [
                3569,
                3,
            ],
            [
                3572,
                1,
            ],
            [
                3488,
                1,
            ],
            [
                3554,
                1,
            ],
            [
                3572,
                5,
            ],
            [
                3569,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'CCD': [
            [
                844,
                74,
            ],
            [
                0,
                5,
            ],
            [
                844,
                4,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                4,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                6,
            ],
            [
                844,
                20,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                4,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                2,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                11,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                46,
            ],
            [
                0,
                1,
            ],
            [
                844,
                4,
            ],
            [
                759,
                3,
            ],
            [
                840,
                3,
            ],
            [
                844,
                7,
            ],
            [
                0,
                1,
            ],
            [
                844,
                21,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                844,
                25,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                504,
                74,
            ],
            [
                0,
                5,
            ],
            [
                504,
                4,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                4,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                6,
            ],
            [
                504,
                20,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                4,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                2,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                11,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                46,
            ],
            [
                0,
                1,
            ],
            [
                504,
                4,
            ],
            [
                383,
                3,
            ],
            [
                484,
                3,
            ],
            [
                501,
                3,
            ],
            [
                504,
                4,
            ],
            [
                0,
                1,
            ],
            [
                504,
                5,
            ],
            [
                491,
                1,
            ],
            [
                496,
                1,
            ],
            [
                504,
                1,
            ],
            [
                491,
                1,
            ],
            [
                496,
                1,
            ],
            [
                504,
                11,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                1,
            ],
            [
                0,
                1,
            ],
            [
                504,
                25,
            ],
        ],
        'Congressional District': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'County': [
            [
                95,
                74,
            ],
            [
                0,
                5,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                6,
            ],
            [
                95,
                20,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                2,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                11,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                46,
            ],
            [
                0,
                1,
            ],
            [
                95,
                17,
            ],
            [
                0,
                1,
            ],
            [
                95,
                21,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                25,
            ],
        ],
        'County Cross CD': [
            [
                106,
                74,
            ],
            [
                0,
                5,
            ],
            [
                106,
                4,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                4,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                6,
            ],
            [
                106,
                20,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                4,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                2,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                11,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                46,
            ],
            [
                0,
                1,
            ],
            [
                106,
                4,
            ],
            [
                105,
                3,
            ],
            [
                106,
                10,
            ],
            [
                0,
                1,
            ],
            [
                106,
                21,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                1,
            ],
            [
                0,
                1,
            ],
            [
                106,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                259,
                74,
            ],
            [
                0,
                5,
            ],
            [
                259,
                4,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                4,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                6,
            ],
            [
                259,
                20,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                4,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                2,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                11,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                46,
            ],
            [
                0,
                1,
            ],
            [
                259,
                17,
            ],
            [
                0,
                1,
            ],
            [
                259,
                21,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                1,
            ],
            [
                0,
                1,
            ],
            [
                259,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                90,
                74,
            ],
            [
                0,
                5,
            ],
            [
                90,
                4,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                4,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                6,
            ],
            [
                90,
                20,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                4,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                2,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                11,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                46,
            ],
            [
                0,
                1,
            ],
            [
                90,
                17,
            ],
            [
                0,
                1,
            ],
            [
                90,
                21,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'MSA': [
            [
                27,
                74,
            ],
            [
                0,
                5,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                20,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                2,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                11,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                46,
            ],
            [
                0,
                1,
            ],
            [
                27,
                17,
            ],
            [
                0,
                1,
            ],
            [
                27,
                21,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        'Media Market': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Neighborhood': [
            [
                608,
                3,
            ],
            [
                591,
                26,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                2,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                4,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                10,
            ],
            [
                0,
                5,
            ],
            [
                608,
                4,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                4,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                6,
            ],
            [
                608,
                3,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                607,
                6,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                607,
                1,
            ],
            [
                0,
                1,
            ],
            [
                607,
                1,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                2,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                3,
            ],
            [
                607,
                1,
            ],
            [
                608,
                3,
            ],
            [
                591,
                4,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                12,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                26,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                4,
            ],
            [
                0,
                1,
            ],
            [
                608,
                4,
            ],
            [
                405,
                3,
            ],
            [
                529,
                3,
            ],
            [
                600,
                3,
            ],
            [
                607,
                1,
            ],
            [
                608,
                3,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                517,
                1,
            ],
            [
                587,
                1,
            ],
            [
                607,
                1,
            ],
            [
                517,
                1,
            ],
            [
                587,
                1,
            ],
            [
                608,
                11,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                1,
            ],
            [
                0,
                1,
            ],
            [
                608,
                6,
            ],
            [
                526,
                1,
            ],
            [
                590,
                1,
            ],
            [
                607,
                3,
            ],
            [
                608,
                1,
            ],
            [
                526,
                1,
            ],
            [
                591,
                1,
            ],
            [
                608,
                11,
            ],
        ],
        'School District': [
            [
                157,
                74,
            ],
            [
                0,
                5,
            ],
            [
                157,
                4,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                4,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                6,
            ],
            [
                157,
                20,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                4,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                2,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                11,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                46,
            ],
            [
                0,
                1,
            ],
            [
                157,
                4,
            ],
            [
                156,
                3,
            ],
            [
                157,
                10,
            ],
            [
                0,
                1,
            ],
            [
                157,
                21,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                1,
            ],
            [
                0,
                1,
            ],
            [
                157,
                25,
            ],
        ],
        'State House District': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                17,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'State Senate District': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                17,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                70,
                74,
            ],
            [
                0,
                5,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                6,
            ],
            [
                70,
                20,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                2,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                11,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                46,
            ],
            [
                0,
                1,
            ],
            [
                70,
                4,
            ],
            [
                69,
                6,
            ],
            [
                70,
                7,
            ],
            [
                0,
                1,
            ],
            [
                70,
                21,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                70,
                25,
            ],
        ],
        'Urban Center': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                628,
                3,
            ],
            [
                627,
                26,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                2,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                4,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                10,
            ],
            [
                0,
                5,
            ],
            [
                628,
                4,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                627,
                3,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                6,
            ],
            [
                628,
                3,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                625,
                6,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                626,
                1,
            ],
            [
                0,
                1,
            ],
            [
                626,
                1,
            ],
            [
                0,
                1,
            ],
            [
                626,
                1,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                2,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                3,
            ],
            [
                625,
                1,
            ],
            [
                628,
                3,
            ],
            [
                627,
                4,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                12,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                26,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                4,
            ],
            [
                0,
                1,
            ],
            [
                628,
                2,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                528,
                3,
            ],
            [
                613,
                3,
            ],
            [
                623,
                3,
            ],
            [
                625,
                1,
            ],
            [
                628,
                3,
            ],
            [
                0,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                617,
                1,
            ],
            [
                621,
                1,
            ],
            [
                626,
                1,
            ],
            [
                617,
                1,
            ],
            [
                621,
                1,
            ],
            [
                628,
                11,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                1,
            ],
            [
                0,
                1,
            ],
            [
                628,
                5,
            ],
            [
                627,
                1,
            ],
            [
                624,
                1,
            ],
            [
                623,
                1,
            ],
            [
                626,
                3,
            ],
            [
                628,
                1,
            ],
            [
                626,
                1,
            ],
            [
                627,
                1,
            ],
            [
                628,
                5,
            ],
            [
                625,
                6,
            ],
        ],
    },
    'Texas, USA': {
        'overall': [
            [
                9003,
                3,
            ],
            [
                8985,
                26,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9011,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                4,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                3,
            ],
            [
                9011,
                1,
            ],
            [
                9003,
                6,
            ],
            [
                0,
                5,
            ],
            [
                9001,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9001,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9001,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9000,
                3,
            ],
            [
                9003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                48,
                5,
            ],
            [
                9011,
                2,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8980,
                6,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8983,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8983,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8983,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                3,
            ],
            [
                8980,
                1,
            ],
            [
                9003,
                3,
            ],
            [
                8985,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                12,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9002,
                25,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9003,
                2,
            ],
            [
                9002,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                7388,
                3,
            ],
            [
                8348,
                3,
            ],
            [
                8856,
                3,
            ],
            [
                8980,
                1,
            ],
            [
                9003,
                3,
            ],
            [
                0,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                8756,
                1,
            ],
            [
                8894,
                1,
            ],
            [
                8973,
                1,
            ],
            [
                8745,
                1,
            ],
            [
                8879,
                1,
            ],
            [
                9003,
                10,
            ],
            [
                9000,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9000,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9000,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9000,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9002,
                5,
            ],
            [
                9000,
                1,
            ],
            [
                8825,
                1,
            ],
            [
                8974,
                1,
            ],
            [
                8983,
                3,
            ],
            [
                9003,
                1,
            ],
            [
                8831,
                1,
            ],
            [
                8985,
                1,
            ],
            [
                9003,
                5,
            ],
            [
                8998,
                6,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                4,
                54,
            ],
            [
                5,
                1,
            ],
            [
                4,
                12,
            ],
            [
                5,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                6,
                54,
            ],
            [
                8,
                1,
            ],
            [
                6,
                12,
            ],
            [
                8,
                1,
            ],
            [
                6,
                6,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                7,
            ],
            [
                6,
                18,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'CCD': [
            [
                862,
                74,
            ],
            [
                0,
                5,
            ],
            [
                862,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                6,
            ],
            [
                862,
                20,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                2,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                11,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                46,
            ],
            [
                0,
                1,
            ],
            [
                862,
                4,
            ],
            [
                683,
                3,
            ],
            [
                810,
                3,
            ],
            [
                851,
                3,
            ],
            [
                862,
                4,
            ],
            [
                0,
                1,
            ],
            [
                862,
                21,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                1,
            ],
            [
                0,
                1,
            ],
            [
                862,
                25,
            ],
        ],
        'CSA': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'City': [
            [
                1859,
                3,
            ],
            [
                1855,
                26,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                2,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                4,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1859,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1859,
                3,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1850,
                6,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1850,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1850,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1850,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                3,
            ],
            [
                1850,
                1,
            ],
            [
                1859,
                3,
            ],
            [
                1855,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                12,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                26,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                4,
            ],
            [
                1237,
                3,
            ],
            [
                1643,
                3,
            ],
            [
                1793,
                3,
            ],
            [
                1850,
                1,
            ],
            [
                1859,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1731,
                1,
            ],
            [
                1771,
                1,
            ],
            [
                1834,
                1,
            ],
            [
                1724,
                1,
            ],
            [
                1758,
                1,
            ],
            [
                1859,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1859,
                6,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1850,
                3,
            ],
            [
                1859,
                1,
            ],
            [
                1782,
                1,
            ],
            [
                1855,
                1,
            ],
            [
                1859,
                11,
            ],
        ],
        'Congressional District': [
            [
                38,
                74,
            ],
            [
                0,
                5,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                6,
            ],
            [
                38,
                20,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                2,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                11,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                46,
            ],
            [
                0,
                1,
            ],
            [
                38,
                17,
            ],
            [
                0,
                1,
            ],
            [
                38,
                21,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                25,
            ],
        ],
        'County': [
            [
                254,
                74,
            ],
            [
                0,
                5,
            ],
            [
                254,
                4,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                4,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                6,
            ],
            [
                254,
                20,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                4,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                2,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                11,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                46,
            ],
            [
                0,
                1,
            ],
            [
                254,
                4,
            ],
            [
                244,
                3,
            ],
            [
                249,
                3,
            ],
            [
                254,
                7,
            ],
            [
                0,
                1,
            ],
            [
                254,
                21,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                25,
            ],
        ],
        'County Cross CD': [
            [
                311,
                74,
            ],
            [
                0,
                5,
            ],
            [
                311,
                4,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                4,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                6,
            ],
            [
                311,
                20,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                4,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                2,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                11,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                46,
            ],
            [
                0,
                1,
            ],
            [
                311,
                4,
            ],
            [
                301,
                3,
            ],
            [
                306,
                3,
            ],
            [
                311,
                7,
            ],
            [
                0,
                1,
            ],
            [
                311,
                21,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                639,
                74,
            ],
            [
                0,
                5,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                6,
            ],
            [
                639,
                20,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                2,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                11,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                46,
            ],
            [
                0,
                1,
            ],
            [
                639,
                17,
            ],
            [
                0,
                1,
            ],
            [
                639,
                21,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                24,
                74,
            ],
            [
                0,
                5,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                6,
            ],
            [
                24,
                20,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                4,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                2,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                11,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                46,
            ],
            [
                0,
                1,
            ],
            [
                24,
                17,
            ],
            [
                0,
                1,
            ],
            [
                24,
                21,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                212,
                74,
            ],
            [
                0,
                5,
            ],
            [
                212,
                4,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                4,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                6,
            ],
            [
                212,
                20,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                4,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                2,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                11,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                46,
            ],
            [
                0,
                1,
            ],
            [
                212,
                4,
            ],
            [
                208,
                3,
            ],
            [
                212,
                10,
            ],
            [
                0,
                1,
            ],
            [
                212,
                21,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                1,
            ],
            [
                0,
                1,
            ],
            [
                212,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'MSA': [
            [
                69,
                74,
            ],
            [
                0,
                5,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                6,
            ],
            [
                69,
                20,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                4,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                2,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                11,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                46,
            ],
            [
                0,
                1,
            ],
            [
                69,
                17,
            ],
            [
                0,
                1,
            ],
            [
                69,
                21,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                1,
            ],
            [
                0,
                1,
            ],
            [
                69,
                25,
            ],
        ],
        'Media Market': [
            [
                20,
                74,
            ],
            [
                0,
                5,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                6,
            ],
            [
                20,
                20,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                2,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                11,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                46,
            ],
            [
                0,
                1,
            ],
            [
                20,
                17,
            ],
            [
                0,
                1,
            ],
            [
                20,
                21,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                25,
            ],
        ],
        'Native Area': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Neighborhood': [
            [
                1324,
                3,
            ],
            [
                1313,
                26,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                2,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                4,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1323,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                3,
            ],
            [
                1324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1324,
                3,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1321,
                6,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1321,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                3,
            ],
            [
                1321,
                1,
            ],
            [
                1324,
                3,
            ],
            [
                1313,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                12,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1323,
                25,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1324,
                4,
            ],
            [
                952,
                3,
            ],
            [
                1073,
                3,
            ],
            [
                1284,
                3,
            ],
            [
                1321,
                1,
            ],
            [
                1324,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1221,
                1,
            ],
            [
                1312,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                1220,
                1,
            ],
            [
                1312,
                1,
            ],
            [
                1324,
                10,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1323,
                5,
            ],
            [
                1324,
                1,
            ],
            [
                1235,
                1,
            ],
            [
                1312,
                1,
            ],
            [
                1321,
                3,
            ],
            [
                1324,
                1,
            ],
            [
                1237,
                1,
            ],
            [
                1313,
                1,
            ],
            [
                1324,
                11,
            ],
        ],
        'School District': [
            [
                1020,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1020,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1020,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                4,
            ],
            [
                933,
                3,
            ],
            [
                1005,
                3,
            ],
            [
                1019,
                3,
            ],
            [
                1020,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1020,
                25,
            ],
        ],
        'State House District': [
            [
                150,
                74,
            ],
            [
                0,
                5,
            ],
            [
                150,
                4,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                4,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                6,
            ],
            [
                150,
                20,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                4,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                2,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                11,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                46,
            ],
            [
                0,
                1,
            ],
            [
                150,
                17,
            ],
            [
                0,
                1,
            ],
            [
                150,
                21,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                1,
            ],
            [
                0,
                1,
            ],
            [
                150,
                25,
            ],
        ],
        'State Senate District': [
            [
                31,
                74,
            ],
            [
                0,
                5,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                6,
            ],
            [
                31,
                20,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                2,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                11,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                46,
            ],
            [
                0,
                1,
            ],
            [
                31,
                17,
            ],
            [
                0,
                1,
            ],
            [
                31,
                21,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Urban Area': [
            [
                195,
                74,
            ],
            [
                0,
                5,
            ],
            [
                195,
                4,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                4,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                6,
            ],
            [
                195,
                20,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                4,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                2,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                11,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                46,
            ],
            [
                0,
                1,
            ],
            [
                195,
                4,
            ],
            [
                193,
                3,
            ],
            [
                194,
                3,
            ],
            [
                195,
                7,
            ],
            [
                0,
                1,
            ],
            [
                195,
                21,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                1,
            ],
            [
                0,
                1,
            ],
            [
                195,
                25,
            ],
        ],
        'Urban Center': [
            [
                26,
                54,
            ],
            [
                29,
                1,
            ],
            [
                26,
                12,
            ],
            [
                29,
                1,
            ],
            [
                26,
                6,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                7,
            ],
            [
                26,
                18,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'ZIP': [
            [
                1931,
                3,
            ],
            [
                1928,
                26,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                2,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                4,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1930,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1930,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1930,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1929,
                3,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1931,
                3,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1920,
                6,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                3,
            ],
            [
                1920,
                1,
            ],
            [
                1931,
                3,
            ],
            [
                1928,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                12,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                26,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                2,
            ],
            [
                1930,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1602,
                3,
            ],
            [
                1821,
                3,
            ],
            [
                1902,
                3,
            ],
            [
                1920,
                1,
            ],
            [
                1931,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1915,
                1,
            ],
            [
                1922,
                1,
            ],
            [
                1927,
                1,
            ],
            [
                1912,
                1,
            ],
            [
                1920,
                1,
            ],
            [
                1931,
                10,
            ],
            [
                1929,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1929,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1929,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1929,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1931,
                5,
            ],
            [
                1928,
                1,
            ],
            [
                1919,
                1,
            ],
            [
                1918,
                1,
            ],
            [
                1923,
                3,
            ],
            [
                1931,
                1,
            ],
            [
                1923,
                1,
            ],
            [
                1928,
                1,
            ],
            [
                1931,
                5,
            ],
            [
                1926,
                6,
            ],
        ],
    },
    'Utah, USA': {
        'overall': [
            [
                1147,
                3,
            ],
            [
                1146,
                26,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1149,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                4,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                3,
            ],
            [
                1149,
                1,
            ],
            [
                1147,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1147,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                5,
            ],
            [
                1149,
                2,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                7,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                3,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                3,
            ],
            [
                1146,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                12,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                26,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                4,
            ],
            [
                906,
                3,
            ],
            [
                1091,
                3,
            ],
            [
                1140,
                3,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1128,
                1,
            ],
            [
                1139,
                1,
            ],
            [
                1143,
                1,
            ],
            [
                1126,
                1,
            ],
            [
                1138,
                1,
            ],
            [
                1147,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1147,
                6,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                4,
            ],
            [
                1147,
                1,
            ],
            [
                1142,
                1,
            ],
            [
                1146,
                1,
            ],
            [
                1147,
                11,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                91,
                74,
            ],
            [
                0,
                5,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                4,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                6,
            ],
            [
                91,
                5,
            ],
            [
                90,
                6,
            ],
            [
                91,
                9,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                0,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                3,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                2,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                3,
            ],
            [
                90,
                1,
            ],
            [
                91,
                7,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                46,
            ],
            [
                0,
                1,
            ],
            [
                91,
                4,
            ],
            [
                76,
                3,
            ],
            [
                85,
                3,
            ],
            [
                89,
                3,
            ],
            [
                90,
                1,
            ],
            [
                91,
                3,
            ],
            [
                0,
                1,
            ],
            [
                91,
                5,
            ],
            [
                89,
                1,
            ],
            [
                90,
                2,
            ],
            [
                89,
                1,
            ],
            [
                90,
                1,
            ],
            [
                91,
                11,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                1,
            ],
            [
                0,
                1,
            ],
            [
                91,
                8,
            ],
            [
                90,
                3,
            ],
            [
                91,
                14,
            ],
        ],
        'CSA': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                332,
                30,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                330,
                1,
            ],
            [
                332,
                2,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                330,
                1,
            ],
            [
                332,
                2,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                330,
                1,
            ],
            [
                332,
                2,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                330,
                1,
            ],
            [
                332,
                2,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                330,
                1,
            ],
            [
                332,
                3,
            ],
            [
                330,
                1,
            ],
            [
                332,
                5,
            ],
            [
                330,
                1,
            ],
            [
                332,
                11,
            ],
            [
                0,
                5,
            ],
            [
                332,
                4,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                4,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                6,
            ],
            [
                332,
                3,
            ],
            [
                330,
                1,
            ],
            [
                332,
                8,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                330,
                1,
            ],
            [
                332,
                2,
            ],
            [
                330,
                1,
            ],
            [
                332,
                2,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                2,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                2,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                11,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                12,
            ],
            [
                330,
                1,
            ],
            [
                332,
                27,
            ],
            [
                330,
                1,
            ],
            [
                332,
                5,
            ],
            [
                0,
                1,
            ],
            [
                332,
                4,
            ],
            [
                205,
                3,
            ],
            [
                303,
                3,
            ],
            [
                327,
                3,
            ],
            [
                332,
                4,
            ],
            [
                0,
                1,
            ],
            [
                330,
                1,
            ],
            [
                332,
                2,
            ],
            [
                330,
                1,
            ],
            [
                332,
                1,
            ],
            [
                321,
                1,
            ],
            [
                329,
                1,
            ],
            [
                331,
                1,
            ],
            [
                320,
                1,
            ],
            [
                328,
                1,
            ],
            [
                332,
                11,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                1,
            ],
            [
                0,
                1,
            ],
            [
                332,
                6,
            ],
            [
                330,
                1,
            ],
            [
                332,
                5,
            ],
            [
                330,
                1,
            ],
            [
                332,
                12,
            ],
        ],
        'Congressional District': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'County': [
            [
                29,
                74,
            ],
            [
                0,
                5,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                6,
            ],
            [
                29,
                20,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                2,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                11,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                46,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                27,
                3,
            ],
            [
                29,
                10,
            ],
            [
                0,
                1,
            ],
            [
                29,
                21,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                25,
            ],
        ],
        'County Cross CD': [
            [
                36,
                74,
            ],
            [
                0,
                5,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                6,
            ],
            [
                36,
                20,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                2,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                11,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                46,
            ],
            [
                0,
                1,
            ],
            [
                36,
                4,
            ],
            [
                34,
                3,
            ],
            [
                36,
                10,
            ],
            [
                0,
                1,
            ],
            [
                36,
                21,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                19,
                74,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                20,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                46,
            ],
            [
                0,
                1,
            ],
            [
                19,
                17,
            ],
            [
                0,
                1,
            ],
            [
                19,
                21,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                31,
                74,
            ],
            [
                0,
                5,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                6,
            ],
            [
                31,
                20,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                2,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                11,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                46,
            ],
            [
                0,
                1,
            ],
            [
                31,
                17,
            ],
            [
                0,
                1,
            ],
            [
                31,
                21,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Media Market': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Area': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                5,
            ],
            [
                8,
                5,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Neighborhood': [
            [
                99,
                30,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                3,
            ],
            [
                98,
                1,
            ],
            [
                99,
                5,
            ],
            [
                98,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                3,
            ],
            [
                98,
                1,
            ],
            [
                99,
                8,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                12,
            ],
            [
                98,
                1,
            ],
            [
                99,
                27,
            ],
            [
                98,
                1,
            ],
            [
                99,
                5,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                84,
                3,
            ],
            [
                94,
                3,
            ],
            [
                99,
                7,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                1,
            ],
            [
                98,
                1,
            ],
            [
                99,
                2,
            ],
            [
                98,
                1,
            ],
            [
                99,
                12,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                6,
            ],
            [
                98,
                1,
            ],
            [
                99,
                5,
            ],
            [
                98,
                1,
            ],
            [
                99,
                12,
            ],
        ],
        'School District': [
            [
                41,
                74,
            ],
            [
                0,
                5,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                6,
            ],
            [
                41,
                20,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                2,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                11,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                46,
            ],
            [
                0,
                1,
            ],
            [
                41,
                4,
            ],
            [
                39,
                3,
            ],
            [
                41,
                10,
            ],
            [
                0,
                1,
            ],
            [
                41,
                21,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                1,
            ],
            [
                0,
                1,
            ],
            [
                41,
                25,
            ],
        ],
        'State House District': [
            [
                75,
                74,
            ],
            [
                0,
                5,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                6,
            ],
            [
                75,
                20,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                4,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                2,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                11,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                46,
            ],
            [
                0,
                1,
            ],
            [
                75,
                17,
            ],
            [
                0,
                1,
            ],
            [
                75,
                21,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                1,
            ],
            [
                0,
                1,
            ],
            [
                75,
                25,
            ],
        ],
        'State Senate District': [
            [
                29,
                74,
            ],
            [
                0,
                5,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                6,
            ],
            [
                29,
                20,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                2,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                11,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                46,
            ],
            [
                0,
                1,
            ],
            [
                29,
                17,
            ],
            [
                0,
                1,
            ],
            [
                29,
                21,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                25,
                74,
            ],
            [
                0,
                5,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                6,
            ],
            [
                25,
                20,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                4,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                2,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                11,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                46,
            ],
            [
                0,
                1,
            ],
            [
                25,
                17,
            ],
            [
                0,
                1,
            ],
            [
                25,
                21,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                25,
            ],
        ],
        'Urban Center': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'ZIP': [
            [
                293,
                3,
            ],
            [
                292,
                26,
            ],
            [
                293,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                2,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                4,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                10,
            ],
            [
                0,
                5,
            ],
            [
                293,
                4,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                4,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                6,
            ],
            [
                293,
                3,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                7,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                2,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                2,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                7,
            ],
            [
                292,
                4,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                12,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                26,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                4,
            ],
            [
                0,
                1,
            ],
            [
                293,
                4,
            ],
            [
                215,
                3,
            ],
            [
                277,
                3,
            ],
            [
                293,
                7,
            ],
            [
                0,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                289,
                1,
            ],
            [
                290,
                1,
            ],
            [
                292,
                1,
            ],
            [
                288,
                1,
            ],
            [
                290,
                1,
            ],
            [
                293,
                11,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                293,
                6,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                4,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                293,
                11,
            ],
        ],
    },
    'Vermont, USA': {
        'overall': [
            [
                1054,
                3,
            ],
            [
                1055,
                26,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                4,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                4,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                4,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                4,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                4,
            ],
            [
                1062,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                2,
            ],
            [
                1060,
                4,
            ],
            [
                1055,
                2,
            ],
            [
                1060,
                3,
            ],
            [
                1062,
                1,
            ],
            [
                1060,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1059,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1059,
                3,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                5,
            ],
            [
                1062,
                2,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                2,
            ],
            [
                1056,
                6,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                4,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                2,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                3,
            ],
            [
                1056,
                1,
            ],
            [
                1060,
                3,
            ],
            [
                1055,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                12,
            ],
            [
                1055,
                2,
            ],
            [
                1060,
                26,
            ],
            [
                1055,
                2,
            ],
            [
                1060,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                4,
            ],
            [
                970,
                3,
            ],
            [
                1028,
                3,
            ],
            [
                1054,
                3,
            ],
            [
                1056,
                1,
            ],
            [
                1060,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1055,
                2,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                2,
            ],
            [
                1017,
                1,
            ],
            [
                1028,
                1,
            ],
            [
                1058,
                1,
            ],
            [
                1017,
                1,
            ],
            [
                1026,
                1,
            ],
            [
                1060,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1060,
                6,
            ],
            [
                1055,
                2,
            ],
            [
                1057,
                3,
            ],
            [
                1060,
                1,
            ],
            [
                1055,
                2,
            ],
            [
                1060,
                5,
            ],
            [
                1059,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                2,
                1,
            ],
            [
                1,
                12,
            ],
            [
                2,
                1,
            ],
            [
                1,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                7,
            ],
            [
                1,
                18,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'CCD': [
            [
                250,
                3,
            ],
            [
                252,
                26,
            ],
            [
                253,
                1,
            ],
            [
                252,
                4,
            ],
            [
                253,
                1,
            ],
            [
                252,
                4,
            ],
            [
                253,
                1,
            ],
            [
                252,
                4,
            ],
            [
                253,
                1,
            ],
            [
                252,
                4,
            ],
            [
                253,
                1,
            ],
            [
                252,
                4,
            ],
            [
                253,
                2,
            ],
            [
                252,
                2,
            ],
            [
                253,
                4,
            ],
            [
                252,
                2,
            ],
            [
                253,
                10,
            ],
            [
                0,
                5,
            ],
            [
                253,
                4,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                4,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                6,
            ],
            [
                253,
                3,
            ],
            [
                252,
                8,
            ],
            [
                253,
                1,
            ],
            [
                252,
                4,
            ],
            [
                253,
                1,
            ],
            [
                252,
                2,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                252,
                1,
            ],
            [
                0,
                1,
            ],
            [
                252,
                1,
            ],
            [
                0,
                1,
            ],
            [
                252,
                1,
            ],
            [
                253,
                1,
            ],
            [
                252,
                2,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                2,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                3,
            ],
            [
                252,
                1,
            ],
            [
                253,
                3,
            ],
            [
                252,
                4,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                12,
            ],
            [
                252,
                2,
            ],
            [
                253,
                26,
            ],
            [
                252,
                2,
            ],
            [
                253,
                4,
            ],
            [
                0,
                1,
            ],
            [
                253,
                4,
            ],
            [
                214,
                3,
            ],
            [
                240,
                3,
            ],
            [
                252,
                4,
            ],
            [
                253,
                3,
            ],
            [
                0,
                1,
            ],
            [
                252,
                2,
            ],
            [
                253,
                1,
            ],
            [
                252,
                2,
            ],
            [
                246,
                1,
            ],
            [
                248,
                1,
            ],
            [
                252,
                1,
            ],
            [
                246,
                1,
            ],
            [
                247,
                1,
            ],
            [
                253,
                11,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                1,
            ],
            [
                0,
                1,
            ],
            [
                253,
                6,
            ],
            [
                252,
                5,
            ],
            [
                253,
                1,
            ],
            [
                252,
                2,
            ],
            [
                253,
                11,
            ],
        ],
        'City': [
            [
                179,
                3,
            ],
            [
                177,
                26,
            ],
            [
                179,
                1,
            ],
            [
                177,
                4,
            ],
            [
                179,
                1,
            ],
            [
                177,
                4,
            ],
            [
                179,
                1,
            ],
            [
                177,
                4,
            ],
            [
                179,
                1,
            ],
            [
                177,
                4,
            ],
            [
                179,
                1,
            ],
            [
                177,
                4,
            ],
            [
                179,
                2,
            ],
            [
                177,
                2,
            ],
            [
                179,
                4,
            ],
            [
                177,
                2,
            ],
            [
                179,
                10,
            ],
            [
                0,
                5,
            ],
            [
                179,
                4,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                4,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                6,
            ],
            [
                179,
                3,
            ],
            [
                177,
                2,
            ],
            [
                179,
                7,
            ],
            [
                177,
                4,
            ],
            [
                179,
                1,
            ],
            [
                177,
                2,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                2,
            ],
            [
                177,
                2,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                2,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                7,
            ],
            [
                177,
                4,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                12,
            ],
            [
                177,
                2,
            ],
            [
                179,
                26,
            ],
            [
                177,
                2,
            ],
            [
                179,
                4,
            ],
            [
                0,
                1,
            ],
            [
                179,
                4,
            ],
            [
                158,
                3,
            ],
            [
                174,
                3,
            ],
            [
                178,
                3,
            ],
            [
                179,
                4,
            ],
            [
                0,
                1,
            ],
            [
                177,
                2,
            ],
            [
                179,
                1,
            ],
            [
                177,
                2,
            ],
            [
                154,
                1,
            ],
            [
                163,
                1,
            ],
            [
                179,
                1,
            ],
            [
                154,
                1,
            ],
            [
                163,
                1,
            ],
            [
                179,
                11,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                1,
            ],
            [
                0,
                1,
            ],
            [
                179,
                6,
            ],
            [
                177,
                2,
            ],
            [
                179,
                4,
            ],
            [
                177,
                2,
            ],
            [
                179,
                11,
            ],
        ],
        'Congressional District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'County Cross CD': [
            [
                14,
                74,
            ],
            [
                0,
                5,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                20,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                4,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                2,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                11,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                46,
            ],
            [
                0,
                1,
            ],
            [
                14,
                17,
            ],
            [
                0,
                1,
            ],
            [
                14,
                21,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                38,
                74,
            ],
            [
                0,
                5,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                6,
            ],
            [
                38,
                20,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                4,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                2,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                11,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                46,
            ],
            [
                0,
                1,
            ],
            [
                38,
                17,
            ],
            [
                0,
                1,
            ],
            [
                38,
                21,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                1,
            ],
            [
                0,
                1,
            ],
            [
                38,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                20,
                74,
            ],
            [
                0,
                5,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                6,
            ],
            [
                20,
                20,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                4,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                2,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                11,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                46,
            ],
            [
                0,
                1,
            ],
            [
                20,
                17,
            ],
            [
                0,
                1,
            ],
            [
                20,
                21,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                1,
            ],
            [
                0,
                1,
            ],
            [
                20,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                21,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'Media Market': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Neighborhood': [
            [
                6,
                74,
            ],
            [
                0,
                5,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                20,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                4,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                2,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                46,
            ],
            [
                0,
                1,
            ],
            [
                6,
                17,
            ],
            [
                0,
                1,
            ],
            [
                6,
                6,
            ],
            [
                5,
                1,
            ],
            [
                6,
                2,
            ],
            [
                5,
                1,
            ],
            [
                6,
                11,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                25,
            ],
        ],
        'School District': [
            [
                123,
                3,
            ],
            [
                125,
                26,
            ],
            [
                126,
                1,
            ],
            [
                125,
                4,
            ],
            [
                126,
                1,
            ],
            [
                125,
                4,
            ],
            [
                126,
                1,
            ],
            [
                125,
                4,
            ],
            [
                126,
                1,
            ],
            [
                125,
                4,
            ],
            [
                126,
                1,
            ],
            [
                125,
                4,
            ],
            [
                126,
                2,
            ],
            [
                125,
                2,
            ],
            [
                126,
                4,
            ],
            [
                125,
                2,
            ],
            [
                126,
                10,
            ],
            [
                0,
                5,
            ],
            [
                126,
                4,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                4,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                6,
            ],
            [
                126,
                3,
            ],
            [
                125,
                8,
            ],
            [
                126,
                1,
            ],
            [
                125,
                4,
            ],
            [
                126,
                1,
            ],
            [
                125,
                2,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                0,
                1,
            ],
            [
                125,
                1,
            ],
            [
                126,
                1,
            ],
            [
                125,
                2,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                2,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                3,
            ],
            [
                125,
                1,
            ],
            [
                126,
                3,
            ],
            [
                125,
                4,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                12,
            ],
            [
                125,
                2,
            ],
            [
                126,
                26,
            ],
            [
                125,
                2,
            ],
            [
                126,
                4,
            ],
            [
                0,
                1,
            ],
            [
                126,
                4,
            ],
            [
                115,
                3,
            ],
            [
                121,
                3,
            ],
            [
                125,
                4,
            ],
            [
                126,
                3,
            ],
            [
                0,
                1,
            ],
            [
                125,
                2,
            ],
            [
                126,
                1,
            ],
            [
                125,
                2,
            ],
            [
                119,
                1,
            ],
            [
                121,
                1,
            ],
            [
                125,
                1,
            ],
            [
                119,
                1,
            ],
            [
                120,
                1,
            ],
            [
                126,
                11,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                1,
            ],
            [
                0,
                1,
            ],
            [
                126,
                6,
            ],
            [
                125,
                5,
            ],
            [
                126,
                1,
            ],
            [
                125,
                2,
            ],
            [
                126,
                11,
            ],
        ],
        'State House District': [
            [
                109,
                74,
            ],
            [
                0,
                5,
            ],
            [
                109,
                4,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                4,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                6,
            ],
            [
                109,
                20,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                4,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                2,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                11,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                46,
            ],
            [
                0,
                1,
            ],
            [
                109,
                17,
            ],
            [
                0,
                1,
            ],
            [
                109,
                21,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                1,
            ],
            [
                0,
                1,
            ],
            [
                109,
                25,
            ],
        ],
        'State Senate District': [
            [
                16,
                74,
            ],
            [
                0,
                5,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                6,
            ],
            [
                16,
                20,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                2,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                11,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                46,
            ],
            [
                0,
                1,
            ],
            [
                16,
                17,
            ],
            [
                0,
                1,
            ],
            [
                16,
                21,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                12,
                74,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                21,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'ZIP': [
            [
                255,
                3,
            ],
            [
                254,
                26,
            ],
            [
                255,
                1,
            ],
            [
                254,
                4,
            ],
            [
                255,
                1,
            ],
            [
                254,
                4,
            ],
            [
                255,
                1,
            ],
            [
                254,
                4,
            ],
            [
                255,
                1,
            ],
            [
                254,
                4,
            ],
            [
                255,
                1,
            ],
            [
                254,
                4,
            ],
            [
                255,
                2,
            ],
            [
                254,
                2,
            ],
            [
                255,
                4,
            ],
            [
                254,
                2,
            ],
            [
                255,
                10,
            ],
            [
                0,
                5,
            ],
            [
                254,
                4,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                3,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                6,
            ],
            [
                255,
                3,
            ],
            [
                254,
                2,
            ],
            [
                253,
                6,
            ],
            [
                255,
                1,
            ],
            [
                254,
                4,
            ],
            [
                255,
                1,
            ],
            [
                254,
                2,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                0,
                1,
            ],
            [
                254,
                1,
            ],
            [
                255,
                1,
            ],
            [
                254,
                2,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                2,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                3,
            ],
            [
                253,
                1,
            ],
            [
                255,
                3,
            ],
            [
                254,
                4,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                12,
            ],
            [
                254,
                2,
            ],
            [
                255,
                26,
            ],
            [
                254,
                2,
            ],
            [
                255,
                4,
            ],
            [
                0,
                1,
            ],
            [
                255,
                4,
            ],
            [
                236,
                3,
            ],
            [
                246,
                3,
            ],
            [
                252,
                3,
            ],
            [
                253,
                1,
            ],
            [
                255,
                3,
            ],
            [
                0,
                1,
            ],
            [
                254,
                2,
            ],
            [
                255,
                1,
            ],
            [
                254,
                2,
            ],
            [
                251,
                1,
            ],
            [
                250,
                1,
            ],
            [
                255,
                1,
            ],
            [
                251,
                1,
            ],
            [
                250,
                1,
            ],
            [
                255,
                11,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                1,
            ],
            [
                0,
                1,
            ],
            [
                255,
                6,
            ],
            [
                254,
                5,
            ],
            [
                255,
                1,
            ],
            [
                254,
                2,
            ],
            [
                255,
                5,
            ],
            [
                254,
                6,
            ],
        ],
    },
    'Virginia, USA': {
        'overall': [
            [
                3896,
                3,
            ],
            [
                3890,
                26,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3898,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                4,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                3,
            ],
            [
                3898,
                1,
            ],
            [
                3896,
                6,
            ],
            [
                0,
                5,
            ],
            [
                3896,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                24,
                5,
            ],
            [
                3898,
                2,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3893,
                6,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3894,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3894,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3894,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                3,
            ],
            [
                3893,
                1,
            ],
            [
                3896,
                3,
            ],
            [
                3890,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                12,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                26,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                4,
            ],
            [
                3254,
                3,
            ],
            [
                3756,
                3,
            ],
            [
                3876,
                3,
            ],
            [
                3893,
                1,
            ],
            [
                3896,
                3,
            ],
            [
                0,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3836,
                1,
            ],
            [
                3869,
                1,
            ],
            [
                3894,
                1,
            ],
            [
                3835,
                1,
            ],
            [
                3868,
                1,
            ],
            [
                3896,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3896,
                6,
            ],
            [
                3871,
                1,
            ],
            [
                3879,
                1,
            ],
            [
                3894,
                3,
            ],
            [
                3896,
                1,
            ],
            [
                3877,
                1,
            ],
            [
                3890,
                1,
            ],
            [
                3896,
                5,
            ],
            [
                3895,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                4,
                54,
            ],
            [
                5,
                1,
            ],
            [
                4,
                12,
            ],
            [
                5,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'CCD': [
            [
                552,
                74,
            ],
            [
                0,
                5,
            ],
            [
                552,
                4,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                4,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                6,
            ],
            [
                552,
                20,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                4,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                2,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                11,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                46,
            ],
            [
                0,
                1,
            ],
            [
                552,
                4,
            ],
            [
                475,
                3,
            ],
            [
                548,
                3,
            ],
            [
                552,
                7,
            ],
            [
                0,
                1,
            ],
            [
                552,
                21,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                1,
            ],
            [
                0,
                1,
            ],
            [
                552,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                672,
                3,
            ],
            [
                669,
                26,
            ],
            [
                672,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                2,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                4,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                10,
            ],
            [
                0,
                5,
            ],
            [
                672,
                4,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                4,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                6,
            ],
            [
                672,
                3,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                7,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                2,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                2,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                7,
            ],
            [
                669,
                4,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                12,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                26,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                4,
            ],
            [
                0,
                1,
            ],
            [
                672,
                4,
            ],
            [
                481,
                3,
            ],
            [
                613,
                3,
            ],
            [
                666,
                3,
            ],
            [
                672,
                4,
            ],
            [
                0,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                1,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                642,
                1,
            ],
            [
                658,
                1,
            ],
            [
                672,
                1,
            ],
            [
                642,
                1,
            ],
            [
                658,
                1,
            ],
            [
                672,
                11,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                1,
            ],
            [
                0,
                1,
            ],
            [
                672,
                6,
            ],
            [
                666,
                1,
            ],
            [
                668,
                1,
            ],
            [
                672,
                4,
            ],
            [
                666,
                1,
            ],
            [
                669,
                1,
            ],
            [
                672,
                11,
            ],
        ],
        'Congressional District': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'County': [
            [
                133,
                74,
            ],
            [
                0,
                5,
            ],
            [
                133,
                4,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                4,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                6,
            ],
            [
                133,
                20,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                4,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                2,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                11,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                46,
            ],
            [
                0,
                1,
            ],
            [
                133,
                4,
            ],
            [
                127,
                3,
            ],
            [
                133,
                10,
            ],
            [
                0,
                1,
            ],
            [
                133,
                21,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                1,
            ],
            [
                0,
                1,
            ],
            [
                133,
                25,
            ],
        ],
        'County Cross CD': [
            [
                144,
                74,
            ],
            [
                0,
                5,
            ],
            [
                144,
                4,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                4,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                6,
            ],
            [
                144,
                20,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                4,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                2,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                11,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                46,
            ],
            [
                0,
                1,
            ],
            [
                144,
                4,
            ],
            [
                138,
                3,
            ],
            [
                144,
                10,
            ],
            [
                0,
                1,
            ],
            [
                144,
                21,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                1,
            ],
            [
                0,
                1,
            ],
            [
                144,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                427,
                74,
            ],
            [
                0,
                5,
            ],
            [
                427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                6,
            ],
            [
                427,
                20,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                4,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                2,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                11,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                46,
            ],
            [
                0,
                1,
            ],
            [
                427,
                17,
            ],
            [
                0,
                1,
            ],
            [
                427,
                21,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                1,
            ],
            [
                0,
                1,
            ],
            [
                427,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                71,
                74,
            ],
            [
                0,
                5,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                6,
            ],
            [
                71,
                20,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                2,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                11,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                46,
            ],
            [
                0,
                1,
            ],
            [
                71,
                17,
            ],
            [
                0,
                1,
            ],
            [
                71,
                21,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                15,
                74,
            ],
            [
                0,
                5,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                20,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                4,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                2,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                11,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                46,
            ],
            [
                0,
                1,
            ],
            [
                15,
                17,
            ],
            [
                0,
                1,
            ],
            [
                15,
                21,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                25,
            ],
        ],
        'Media Market': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'Native Area': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                3,
            ],
            [
                2,
                10,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                2,
                6,
            ],
            [
                3,
                7,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'Neighborhood': [
            [
                593,
                3,
            ],
            [
                591,
                26,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                2,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                4,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                10,
            ],
            [
                0,
                5,
            ],
            [
                593,
                4,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                4,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                6,
            ],
            [
                593,
                3,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                6,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                2,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                3,
            ],
            [
                592,
                1,
            ],
            [
                593,
                3,
            ],
            [
                591,
                4,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                12,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                26,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                4,
            ],
            [
                0,
                1,
            ],
            [
                593,
                4,
            ],
            [
                482,
                3,
            ],
            [
                555,
                3,
            ],
            [
                588,
                3,
            ],
            [
                592,
                1,
            ],
            [
                593,
                3,
            ],
            [
                0,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                579,
                1,
            ],
            [
                590,
                1,
            ],
            [
                592,
                1,
            ],
            [
                579,
                1,
            ],
            [
                589,
                1,
            ],
            [
                593,
                11,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                1,
            ],
            [
                0,
                1,
            ],
            [
                593,
                6,
            ],
            [
                583,
                1,
            ],
            [
                588,
                1,
            ],
            [
                592,
                3,
            ],
            [
                593,
                1,
            ],
            [
                584,
                1,
            ],
            [
                591,
                1,
            ],
            [
                593,
                11,
            ],
        ],
        'School District': [
            [
                137,
                74,
            ],
            [
                0,
                5,
            ],
            [
                137,
                4,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                4,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                6,
            ],
            [
                137,
                20,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                4,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                2,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                11,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                46,
            ],
            [
                0,
                1,
            ],
            [
                137,
                4,
            ],
            [
                130,
                3,
            ],
            [
                137,
                10,
            ],
            [
                0,
                1,
            ],
            [
                137,
                21,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                1,
            ],
            [
                0,
                1,
            ],
            [
                137,
                25,
            ],
        ],
        'State House District': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'State Senate District': [
            [
                40,
                74,
            ],
            [
                0,
                5,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                6,
            ],
            [
                40,
                20,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                4,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                2,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                11,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                46,
            ],
            [
                0,
                1,
            ],
            [
                40,
                17,
            ],
            [
                0,
                1,
            ],
            [
                40,
                21,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                1,
            ],
            [
                0,
                1,
            ],
            [
                40,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                55,
                74,
            ],
            [
                0,
                5,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                6,
            ],
            [
                55,
                20,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                2,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                11,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                46,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                52,
                3,
            ],
            [
                54,
                3,
            ],
            [
                55,
                7,
            ],
            [
                0,
                1,
            ],
            [
                55,
                21,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                25,
            ],
        ],
        'Urban Center': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'ZIP': [
            [
                891,
                3,
            ],
            [
                890,
                26,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                2,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                4,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                10,
            ],
            [
                0,
                5,
            ],
            [
                891,
                4,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                4,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                6,
            ],
            [
                891,
                3,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                889,
                6,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                890,
                1,
            ],
            [
                0,
                1,
            ],
            [
                890,
                1,
            ],
            [
                0,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                2,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                3,
            ],
            [
                889,
                1,
            ],
            [
                891,
                3,
            ],
            [
                890,
                4,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                12,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                26,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                4,
            ],
            [
                0,
                1,
            ],
            [
                891,
                4,
            ],
            [
                653,
                3,
            ],
            [
                854,
                3,
            ],
            [
                882,
                3,
            ],
            [
                889,
                1,
            ],
            [
                891,
                3,
            ],
            [
                0,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                875,
                1,
            ],
            [
                881,
                1,
            ],
            [
                890,
                1,
            ],
            [
                874,
                1,
            ],
            [
                881,
                1,
            ],
            [
                891,
                11,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                1,
            ],
            [
                0,
                1,
            ],
            [
                891,
                6,
            ],
            [
                882,
                1,
            ],
            [
                883,
                1,
            ],
            [
                890,
                3,
            ],
            [
                891,
                1,
            ],
            [
                887,
                1,
            ],
            [
                890,
                1,
            ],
            [
                891,
                5,
            ],
            [
                890,
                6,
            ],
        ],
    },
    'Washington, USA': {
        'overall': [
            [
                2849,
                1,
            ],
            [
                2848,
                2,
            ],
            [
                2841,
                26,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2853,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2848,
                3,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2848,
                3,
            ],
            [
                2853,
                1,
            ],
            [
                2849,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2848,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2847,
                3,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                5,
            ],
            [
                2853,
                2,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2844,
                6,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2844,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2844,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                3,
            ],
            [
                2844,
                1,
            ],
            [
                2848,
                3,
            ],
            [
                2841,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2849,
                12,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2848,
                25,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2849,
                2,
            ],
            [
                2848,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2471,
                3,
            ],
            [
                2737,
                3,
            ],
            [
                2832,
                3,
            ],
            [
                2844,
                1,
            ],
            [
                2849,
                3,
            ],
            [
                0,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2805,
                1,
            ],
            [
                2833,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                2805,
                1,
            ],
            [
                2833,
                1,
            ],
            [
                2849,
                1,
            ],
            [
                2848,
                5,
            ],
            [
                2849,
                4,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2848,
                5,
            ],
            [
                2847,
                1,
            ],
            [
                2816,
                1,
            ],
            [
                2840,
                1,
            ],
            [
                2844,
                3,
            ],
            [
                2849,
                1,
            ],
            [
                2819,
                1,
            ],
            [
                2841,
                1,
            ],
            [
                2849,
                5,
            ],
            [
                2847,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                242,
                58,
            ],
            [
                241,
                3,
            ],
            [
                242,
                3,
            ],
            [
                241,
                3,
            ],
            [
                242,
                7,
            ],
            [
                0,
                5,
            ],
            [
                241,
                4,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                4,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                6,
            ],
            [
                242,
                5,
            ],
            [
                241,
                6,
            ],
            [
                242,
                9,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                242,
                3,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                2,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                7,
            ],
            [
                242,
                4,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                242,
                14,
            ],
            [
                241,
                25,
            ],
            [
                242,
                7,
            ],
            [
                0,
                1,
            ],
            [
                242,
                2,
            ],
            [
                241,
                1,
            ],
            [
                242,
                1,
            ],
            [
                218,
                3,
            ],
            [
                237,
                3,
            ],
            [
                241,
                4,
            ],
            [
                242,
                3,
            ],
            [
                0,
                1,
            ],
            [
                242,
                11,
            ],
            [
                241,
                5,
            ],
            [
                242,
                4,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                1,
            ],
            [
                0,
                1,
            ],
            [
                241,
                5,
            ],
            [
                242,
                3,
            ],
            [
                241,
                3,
            ],
            [
                242,
                14,
            ],
        ],
        'CSA': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'City': [
            [
                639,
                1,
            ],
            [
                638,
                2,
            ],
            [
                639,
                27,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                2,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                2,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                2,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                2,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                3,
            ],
            [
                637,
                1,
            ],
            [
                639,
                5,
            ],
            [
                637,
                1,
            ],
            [
                639,
                11,
            ],
            [
                0,
                5,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                6,
            ],
            [
                639,
                3,
            ],
            [
                637,
                1,
            ],
            [
                639,
                8,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                2,
            ],
            [
                637,
                1,
            ],
            [
                639,
                2,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                2,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                2,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                11,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                12,
            ],
            [
                637,
                1,
            ],
            [
                639,
                27,
            ],
            [
                637,
                1,
            ],
            [
                639,
                5,
            ],
            [
                0,
                1,
            ],
            [
                639,
                4,
            ],
            [
                495,
                3,
            ],
            [
                605,
                3,
            ],
            [
                637,
                3,
            ],
            [
                639,
                4,
            ],
            [
                0,
                1,
            ],
            [
                637,
                1,
            ],
            [
                639,
                2,
            ],
            [
                637,
                1,
            ],
            [
                639,
                1,
            ],
            [
                631,
                1,
            ],
            [
                638,
                1,
            ],
            [
                639,
                1,
            ],
            [
                631,
                1,
            ],
            [
                638,
                1,
            ],
            [
                639,
                11,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                1,
            ],
            [
                0,
                1,
            ],
            [
                639,
                6,
            ],
            [
                637,
                1,
            ],
            [
                639,
                5,
            ],
            [
                637,
                1,
            ],
            [
                639,
                12,
            ],
        ],
        'Congressional District': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                20,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'County': [
            [
                39,
                74,
            ],
            [
                0,
                5,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                6,
            ],
            [
                39,
                20,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                4,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                2,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                11,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                46,
            ],
            [
                0,
                1,
            ],
            [
                39,
                17,
            ],
            [
                0,
                1,
            ],
            [
                39,
                21,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                1,
            ],
            [
                0,
                1,
            ],
            [
                39,
                25,
            ],
        ],
        'County Cross CD': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                17,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                95,
                74,
            ],
            [
                0,
                5,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                6,
            ],
            [
                95,
                20,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                4,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                2,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                11,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                46,
            ],
            [
                0,
                1,
            ],
            [
                95,
                17,
            ],
            [
                0,
                1,
            ],
            [
                95,
                21,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                1,
            ],
            [
                0,
                1,
            ],
            [
                95,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                63,
                74,
            ],
            [
                0,
                5,
            ],
            [
                63,
                4,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                4,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                6,
            ],
            [
                63,
                20,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                4,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                2,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                11,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                46,
            ],
            [
                0,
                1,
            ],
            [
                63,
                17,
            ],
            [
                0,
                1,
            ],
            [
                63,
                21,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                63,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                22,
                74,
            ],
            [
                0,
                5,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                6,
            ],
            [
                22,
                20,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                4,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                2,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                11,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                46,
            ],
            [
                0,
                1,
            ],
            [
                22,
                17,
            ],
            [
                0,
                1,
            ],
            [
                22,
                21,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                25,
            ],
        ],
        'Media Market': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'Native Area': [
            [
                26,
                74,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                24,
                3,
            ],
            [
                26,
                10,
            ],
            [
                0,
                1,
            ],
            [
                26,
                21,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Native Statistical Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                19,
                3,
            ],
            [
                18,
                26,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                2,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                4,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                10,
            ],
            [
                0,
                5,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                3,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                7,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                2,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                7,
            ],
            [
                18,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                12,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                26,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                4,
            ],
            [
                0,
                1,
            ],
            [
                19,
                4,
            ],
            [
                15,
                3,
            ],
            [
                19,
                10,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                1,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                11,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                6,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                4,
            ],
            [
                16,
                1,
            ],
            [
                18,
                1,
            ],
            [
                19,
                11,
            ],
        ],
        'Neighborhood': [
            [
                570,
                3,
            ],
            [
                564,
                26,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                2,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                4,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                10,
            ],
            [
                0,
                5,
            ],
            [
                570,
                4,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                4,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                6,
            ],
            [
                570,
                3,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                567,
                6,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                567,
                1,
            ],
            [
                0,
                1,
            ],
            [
                567,
                1,
            ],
            [
                0,
                1,
            ],
            [
                567,
                1,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                2,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                3,
            ],
            [
                567,
                1,
            ],
            [
                570,
                3,
            ],
            [
                564,
                4,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                12,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                26,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                4,
            ],
            [
                0,
                1,
            ],
            [
                570,
                4,
            ],
            [
                461,
                3,
            ],
            [
                515,
                3,
            ],
            [
                561,
                3,
            ],
            [
                567,
                1,
            ],
            [
                570,
                3,
            ],
            [
                0,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                546,
                1,
            ],
            [
                563,
                1,
            ],
            [
                570,
                1,
            ],
            [
                546,
                1,
            ],
            [
                563,
                1,
            ],
            [
                570,
                11,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                1,
            ],
            [
                0,
                1,
            ],
            [
                570,
                5,
            ],
            [
                568,
                1,
            ],
            [
                546,
                1,
            ],
            [
                564,
                1,
            ],
            [
                567,
                3,
            ],
            [
                570,
                1,
            ],
            [
                547,
                1,
            ],
            [
                564,
                1,
            ],
            [
                570,
                5,
            ],
            [
                568,
                6,
            ],
        ],
        'School District': [
            [
                295,
                74,
            ],
            [
                0,
                5,
            ],
            [
                295,
                4,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                4,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                6,
            ],
            [
                295,
                20,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                4,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                2,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                11,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                46,
            ],
            [
                0,
                1,
            ],
            [
                295,
                4,
            ],
            [
                280,
                3,
            ],
            [
                293,
                3,
            ],
            [
                295,
                7,
            ],
            [
                0,
                1,
            ],
            [
                295,
                21,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                1,
            ],
            [
                0,
                1,
            ],
            [
                295,
                25,
            ],
        ],
        'State House District': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                17,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'State Senate District': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                17,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                61,
                74,
            ],
            [
                0,
                5,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                6,
            ],
            [
                61,
                20,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                4,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                2,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                11,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                46,
            ],
            [
                0,
                1,
            ],
            [
                61,
                4,
            ],
            [
                57,
                3,
            ],
            [
                61,
                10,
            ],
            [
                0,
                1,
            ],
            [
                61,
                21,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                1,
            ],
            [
                0,
                1,
            ],
            [
                61,
                25,
            ],
        ],
        'Urban Center': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'ZIP': [
            [
                592,
                3,
            ],
            [
                591,
                26,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                2,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                4,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                10,
            ],
            [
                0,
                5,
            ],
            [
                592,
                4,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                3,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                6,
            ],
            [
                592,
                3,
            ],
            [
                590,
                1,
            ],
            [
                591,
                7,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                2,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                3,
            ],
            [
                591,
                1,
            ],
            [
                592,
                3,
            ],
            [
                591,
                4,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                12,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                26,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                4,
            ],
            [
                0,
                1,
            ],
            [
                592,
                4,
            ],
            [
                516,
                3,
            ],
            [
                576,
                3,
            ],
            [
                587,
                3,
            ],
            [
                591,
                1,
            ],
            [
                592,
                3,
            ],
            [
                0,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                583,
                1,
            ],
            [
                585,
                1,
            ],
            [
                591,
                1,
            ],
            [
                583,
                1,
            ],
            [
                585,
                1,
            ],
            [
                592,
                11,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                1,
            ],
            [
                0,
                1,
            ],
            [
                592,
                6,
            ],
            [
                588,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                3,
            ],
            [
                592,
                1,
            ],
            [
                590,
                1,
            ],
            [
                591,
                1,
            ],
            [
                592,
                11,
            ],
        ],
    },
    'West Virginia, USA': {
        'overall': [
            [
                1947,
                3,
            ],
            [
                1946,
                26,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1948,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                4,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                3,
            ],
            [
                1948,
                1,
            ],
            [
                1947,
                6,
            ],
            [
                0,
                5,
            ],
            [
                1947,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                5,
            ],
            [
                1948,
                2,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                7,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                3,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                3,
            ],
            [
                1946,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                12,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                26,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                4,
            ],
            [
                1473,
                3,
            ],
            [
                1801,
                3,
            ],
            [
                1905,
                3,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1879,
                1,
            ],
            [
                1903,
                1,
            ],
            [
                1940,
                1,
            ],
            [
                1874,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1947,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1947,
                5,
            ],
            [
                1946,
                1,
            ],
            [
                1944,
                2,
            ],
            [
                1946,
                3,
            ],
            [
                1947,
                1,
            ],
            [
                1945,
                1,
            ],
            [
                1946,
                1,
            ],
            [
                1947,
                5,
            ],
            [
                1946,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                54,
            ],
            [
                3,
                1,
            ],
            [
                2,
                12,
            ],
            [
                3,
                1,
            ],
            [
                2,
                6,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                7,
            ],
            [
                2,
                18,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                226,
                74,
            ],
            [
                0,
                5,
            ],
            [
                226,
                4,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                4,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                6,
            ],
            [
                226,
                20,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                4,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                2,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                11,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                46,
            ],
            [
                0,
                1,
            ],
            [
                226,
                4,
            ],
            [
                198,
                3,
            ],
            [
                219,
                3,
            ],
            [
                225,
                3,
            ],
            [
                226,
                4,
            ],
            [
                0,
                1,
            ],
            [
                226,
                5,
            ],
            [
                225,
                1,
            ],
            [
                226,
                2,
            ],
            [
                225,
                1,
            ],
            [
                226,
                12,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                1,
            ],
            [
                0,
                1,
            ],
            [
                226,
                25,
            ],
        ],
        'CSA': [
            [
                4,
                74,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                20,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'City': [
            [
                438,
                74,
            ],
            [
                0,
                5,
            ],
            [
                438,
                4,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                4,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                6,
            ],
            [
                438,
                20,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                4,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                2,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                11,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                46,
            ],
            [
                0,
                1,
            ],
            [
                438,
                4,
            ],
            [
                290,
                3,
            ],
            [
                387,
                3,
            ],
            [
                426,
                3,
            ],
            [
                438,
                4,
            ],
            [
                0,
                1,
            ],
            [
                438,
                5,
            ],
            [
                399,
                1,
            ],
            [
                410,
                1,
            ],
            [
                434,
                1,
            ],
            [
                396,
                1,
            ],
            [
                407,
                1,
            ],
            [
                438,
                11,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                1,
            ],
            [
                0,
                1,
            ],
            [
                438,
                25,
            ],
        ],
        'Congressional District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'County': [
            [
                55,
                74,
            ],
            [
                0,
                5,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                6,
            ],
            [
                55,
                20,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                2,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                11,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                46,
            ],
            [
                0,
                1,
            ],
            [
                55,
                17,
            ],
            [
                0,
                1,
            ],
            [
                55,
                21,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                25,
            ],
        ],
        'County Cross CD': [
            [
                55,
                74,
            ],
            [
                0,
                5,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                6,
            ],
            [
                55,
                20,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                2,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                11,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                46,
            ],
            [
                0,
                1,
            ],
            [
                55,
                17,
            ],
            [
                0,
                1,
            ],
            [
                55,
                21,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                153,
                74,
            ],
            [
                0,
                5,
            ],
            [
                153,
                4,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                4,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                6,
            ],
            [
                153,
                20,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                4,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                2,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                11,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                46,
            ],
            [
                0,
                1,
            ],
            [
                153,
                17,
            ],
            [
                0,
                1,
            ],
            [
                153,
                21,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                1,
            ],
            [
                0,
                1,
            ],
            [
                153,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                52,
                74,
            ],
            [
                0,
                5,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                6,
            ],
            [
                52,
                20,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                4,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                2,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                11,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                46,
            ],
            [
                0,
                1,
            ],
            [
                52,
                17,
            ],
            [
                0,
                1,
            ],
            [
                52,
                21,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                1,
            ],
            [
                0,
                1,
            ],
            [
                52,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                16,
                74,
            ],
            [
                0,
                5,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                6,
            ],
            [
                16,
                20,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                4,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                2,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                11,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                46,
            ],
            [
                0,
                1,
            ],
            [
                16,
                17,
            ],
            [
                0,
                1,
            ],
            [
                16,
                21,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                25,
            ],
        ],
        'Media Market': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Neighborhood': [
            [
                13,
                74,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13,
                20,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        'School District': [
            [
                55,
                74,
            ],
            [
                0,
                5,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                6,
            ],
            [
                55,
                20,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                4,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                2,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                11,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                46,
            ],
            [
                0,
                1,
            ],
            [
                55,
                17,
            ],
            [
                0,
                1,
            ],
            [
                55,
                21,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                1,
            ],
            [
                0,
                1,
            ],
            [
                55,
                25,
            ],
        ],
        'State House District': [
            [
                100,
                74,
            ],
            [
                0,
                5,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                6,
            ],
            [
                100,
                20,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                4,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                2,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                11,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                46,
            ],
            [
                0,
                1,
            ],
            [
                100,
                17,
            ],
            [
                0,
                1,
            ],
            [
                100,
                21,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                1,
            ],
            [
                0,
                1,
            ],
            [
                100,
                25,
            ],
        ],
        'State Senate District': [
            [
                17,
                74,
            ],
            [
                0,
                5,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                20,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                4,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                2,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                11,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                46,
            ],
            [
                0,
                1,
            ],
            [
                17,
                17,
            ],
            [
                0,
                1,
            ],
            [
                17,
                21,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                28,
                74,
            ],
            [
                0,
                5,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                6,
            ],
            [
                28,
                20,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                4,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                2,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                11,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                46,
            ],
            [
                0,
                1,
            ],
            [
                28,
                17,
            ],
            [
                0,
                1,
            ],
            [
                28,
                21,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                25,
            ],
        ],
        'ZIP': [
            [
                706,
                3,
            ],
            [
                705,
                26,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                2,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                4,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                10,
            ],
            [
                0,
                5,
            ],
            [
                706,
                4,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                4,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                6,
            ],
            [
                706,
                3,
            ],
            [
                704,
                1,
            ],
            [
                705,
                7,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                2,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                3,
            ],
            [
                705,
                1,
            ],
            [
                706,
                3,
            ],
            [
                705,
                4,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                12,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                26,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                4,
            ],
            [
                0,
                1,
            ],
            [
                706,
                4,
            ],
            [
                408,
                3,
            ],
            [
                618,
                3,
            ],
            [
                677,
                3,
            ],
            [
                705,
                1,
            ],
            [
                706,
                3,
            ],
            [
                0,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                678,
                1,
            ],
            [
                690,
                1,
            ],
            [
                703,
                1,
            ],
            [
                676,
                1,
            ],
            [
                688,
                1,
            ],
            [
                706,
                11,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                1,
            ],
            [
                0,
                1,
            ],
            [
                706,
                5,
            ],
            [
                705,
                1,
            ],
            [
                703,
                2,
            ],
            [
                705,
                3,
            ],
            [
                706,
                1,
            ],
            [
                704,
                1,
            ],
            [
                705,
                1,
            ],
            [
                706,
                5,
            ],
            [
                705,
                6,
            ],
        ],
    },
    'Wisconsin, USA': {
        'overall': [
            [
                5058,
                2,
            ],
            [
                5059,
                1,
            ],
            [
                5057,
                26,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5063,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                4,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                3,
            ],
            [
                5063,
                1,
            ],
            [
                5061,
                6,
            ],
            [
                0,
                5,
            ],
            [
                5061,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5060,
                3,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                21,
                5,
            ],
            [
                5063,
                2,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                7,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5060,
                3,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                3,
            ],
            [
                5057,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                12,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5059,
                25,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5061,
                2,
            ],
            [
                5060,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                4812,
                3,
            ],
            [
                5021,
                3,
            ],
            [
                5054,
                3,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                3,
            ],
            [
                0,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                4942,
                1,
            ],
            [
                5017,
                1,
            ],
            [
                5055,
                1,
            ],
            [
                4941,
                1,
            ],
            [
                5015,
                1,
            ],
            [
                5061,
                10,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5059,
                6,
            ],
            [
                5044,
                1,
            ],
            [
                5055,
                1,
            ],
            [
                5057,
                3,
            ],
            [
                5061,
                1,
            ],
            [
                5047,
                1,
            ],
            [
                5057,
                1,
            ],
            [
                5061,
                5,
            ],
            [
                5059,
                6,
            ],
        ],
        '10M Person Circle': [
            [
                3,
                74,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                21,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                4,
                54,
            ],
            [
                5,
                1,
            ],
            [
                4,
                12,
            ],
            [
                5,
                1,
            ],
            [
                4,
                6,
            ],
            [
                0,
                5,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                7,
            ],
            [
                4,
                18,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                4,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                2,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                11,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                46,
            ],
            [
                0,
                1,
            ],
            [
                4,
                17,
            ],
            [
                0,
                1,
            ],
            [
                4,
                21,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                25,
            ],
        ],
        'CCD': [
            [
                1895,
                2,
            ],
            [
                1896,
                1,
            ],
            [
                1897,
                26,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                2,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                4,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1898,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1898,
                3,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                7,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                3,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                3,
            ],
            [
                1897,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                12,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                26,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                4,
            ],
            [
                1767,
                3,
            ],
            [
                1881,
                3,
            ],
            [
                1896,
                3,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1852,
                1,
            ],
            [
                1878,
                1,
            ],
            [
                1896,
                1,
            ],
            [
                1851,
                1,
            ],
            [
                1878,
                1,
            ],
            [
                1898,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1898,
                6,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                4,
            ],
            [
                1898,
                1,
            ],
            [
                1895,
                1,
            ],
            [
                1897,
                1,
            ],
            [
                1898,
                11,
            ],
        ],
        'CSA': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'City': [
            [
                807,
                3,
            ],
            [
                806,
                26,
            ],
            [
                807,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                2,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                4,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                10,
            ],
            [
                0,
                5,
            ],
            [
                807,
                4,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                4,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                6,
            ],
            [
                807,
                3,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                7,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                2,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                2,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                7,
            ],
            [
                806,
                4,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                12,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                26,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                4,
            ],
            [
                0,
                1,
            ],
            [
                807,
                4,
            ],
            [
                752,
                3,
            ],
            [
                797,
                3,
            ],
            [
                807,
                7,
            ],
            [
                0,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                1,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                757,
                1,
            ],
            [
                792,
                1,
            ],
            [
                805,
                1,
            ],
            [
                757,
                1,
            ],
            [
                791,
                1,
            ],
            [
                807,
                11,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                1,
            ],
            [
                0,
                1,
            ],
            [
                807,
                6,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                4,
            ],
            [
                805,
                1,
            ],
            [
                806,
                1,
            ],
            [
                807,
                11,
            ],
        ],
        'Congressional District': [
            [
                8,
                74,
            ],
            [
                0,
                5,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                6,
            ],
            [
                8,
                20,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                4,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                2,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                11,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                46,
            ],
            [
                0,
                1,
            ],
            [
                8,
                17,
            ],
            [
                0,
                1,
            ],
            [
                8,
                21,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                25,
            ],
        ],
        'County': [
            [
                72,
                74,
            ],
            [
                0,
                5,
            ],
            [
                72,
                4,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                4,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                6,
            ],
            [
                72,
                20,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                4,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                2,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                11,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                46,
            ],
            [
                0,
                1,
            ],
            [
                72,
                17,
            ],
            [
                0,
                1,
            ],
            [
                72,
                21,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                1,
            ],
            [
                0,
                1,
            ],
            [
                72,
                25,
            ],
        ],
        'County Cross CD': [
            [
                85,
                74,
            ],
            [
                0,
                5,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                6,
            ],
            [
                85,
                20,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                2,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                11,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                46,
            ],
            [
                0,
                1,
            ],
            [
                85,
                17,
            ],
            [
                0,
                1,
            ],
            [
                85,
                21,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                25,
            ],
        ],
        'Historical Congressional District': [
            [
                129,
                74,
            ],
            [
                0,
                5,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                6,
            ],
            [
                129,
                20,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                4,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                2,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                11,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                46,
            ],
            [
                0,
                1,
            ],
            [
                129,
                17,
            ],
            [
                0,
                1,
            ],
            [
                129,
                21,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                1,
            ],
            [
                0,
                1,
            ],
            [
                129,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                13,
                74,
            ],
            [
                0,
                5,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                6,
            ],
            [
                13,
                20,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                4,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                2,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                11,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                46,
            ],
            [
                0,
                1,
            ],
            [
                13,
                17,
            ],
            [
                0,
                1,
            ],
            [
                13,
                21,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                1,
            ],
            [
                0,
                1,
            ],
            [
                13,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                110,
                74,
            ],
            [
                0,
                5,
            ],
            [
                110,
                4,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                4,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                6,
            ],
            [
                110,
                20,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                4,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                2,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                11,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                46,
            ],
            [
                0,
                1,
            ],
            [
                110,
                17,
            ],
            [
                0,
                1,
            ],
            [
                110,
                21,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'MSA': [
            [
                27,
                74,
            ],
            [
                0,
                5,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                20,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                4,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                2,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                11,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                46,
            ],
            [
                0,
                1,
            ],
            [
                27,
                17,
            ],
            [
                0,
                1,
            ],
            [
                27,
                21,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Area': [
            [
                11,
                74,
            ],
            [
                0,
                5,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                20,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                4,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                2,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                11,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                46,
            ],
            [
                0,
                1,
            ],
            [
                11,
                17,
            ],
            [
                0,
                1,
            ],
            [
                11,
                21,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                25,
            ],
        ],
        'Native Subdivision': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                20,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'Neighborhood': [
            [
                451,
                3,
            ],
            [
                449,
                26,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                2,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                4,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                10,
            ],
            [
                0,
                5,
            ],
            [
                451,
                4,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                4,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                6,
            ],
            [
                451,
                3,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                450,
                6,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                3,
            ],
            [
                450,
                1,
            ],
            [
                451,
                3,
            ],
            [
                449,
                4,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                1,
            ],
            [
                0,
                1,
            ],
            [
                451,
                12,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                450,
                25,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                4,
            ],
            [
                0,
                1,
            ],
            [
                451,
                4,
            ],
            [
                411,
                3,
            ],
            [
                441,
                3,
            ],
            [
                448,
                3,
            ],
            [
                450,
                1,
            ],
            [
                451,
                3,
            ],
            [
                0,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                442,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                1,
            ],
            [
                442,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                10,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                1,
            ],
            [
                0,
                1,
            ],
            [
                450,
                5,
            ],
            [
                451,
                1,
            ],
            [
                443,
                1,
            ],
            [
                449,
                1,
            ],
            [
                450,
                3,
            ],
            [
                451,
                1,
            ],
            [
                444,
                1,
            ],
            [
                449,
                1,
            ],
            [
                451,
                11,
            ],
        ],
        'School District': [
            [
                422,
                74,
            ],
            [
                0,
                5,
            ],
            [
                422,
                4,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                4,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                6,
            ],
            [
                422,
                20,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                4,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                2,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                11,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                46,
            ],
            [
                0,
                1,
            ],
            [
                422,
                4,
            ],
            [
                420,
                3,
            ],
            [
                422,
                10,
            ],
            [
                0,
                1,
            ],
            [
                422,
                5,
            ],
            [
                421,
                1,
            ],
            [
                422,
                2,
            ],
            [
                421,
                1,
            ],
            [
                422,
                12,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                1,
            ],
            [
                0,
                1,
            ],
            [
                422,
                25,
            ],
        ],
        'State House District': [
            [
                99,
                74,
            ],
            [
                0,
                5,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                6,
            ],
            [
                99,
                20,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                4,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                2,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                11,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                46,
            ],
            [
                0,
                1,
            ],
            [
                99,
                17,
            ],
            [
                0,
                1,
            ],
            [
                99,
                21,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                1,
            ],
            [
                0,
                1,
            ],
            [
                99,
                25,
            ],
        ],
        'State Senate District': [
            [
                33,
                74,
            ],
            [
                0,
                5,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                20,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                4,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                2,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                11,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                46,
            ],
            [
                0,
                1,
            ],
            [
                33,
                17,
            ],
            [
                0,
                1,
            ],
            [
                33,
                21,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                85,
                74,
            ],
            [
                0,
                5,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                6,
            ],
            [
                85,
                20,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                4,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                2,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                11,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                46,
            ],
            [
                0,
                1,
            ],
            [
                85,
                17,
            ],
            [
                0,
                1,
            ],
            [
                85,
                21,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                25,
            ],
        ],
        'Urban Center': [
            [
                10,
                74,
            ],
            [
                0,
                5,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                4,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                2,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                11,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                46,
            ],
            [
                0,
                1,
            ],
            [
                10,
                17,
            ],
            [
                0,
                1,
            ],
            [
                10,
                21,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                25,
            ],
        ],
        'ZIP': [
            [
                772,
                30,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                2,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                2,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                2,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                2,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                3,
            ],
            [
                770,
                1,
            ],
            [
                772,
                5,
            ],
            [
                770,
                1,
            ],
            [
                772,
                11,
            ],
            [
                0,
                5,
            ],
            [
                772,
                4,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                3,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                6,
            ],
            [
                772,
                3,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                6,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                2,
            ],
            [
                770,
                1,
            ],
            [
                772,
                2,
            ],
            [
                0,
                1,
            ],
            [
                770,
                1,
            ],
            [
                0,
                1,
            ],
            [
                770,
                1,
            ],
            [
                0,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                3,
            ],
            [
                770,
                1,
            ],
            [
                772,
                7,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                1,
            ],
            [
                0,
                1,
            ],
            [
                772,
                12,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                771,
                25,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                5,
            ],
            [
                0,
                1,
            ],
            [
                772,
                2,
            ],
            [
                771,
                1,
            ],
            [
                772,
                1,
            ],
            [
                751,
                3,
            ],
            [
                769,
                3,
            ],
            [
                770,
                4,
            ],
            [
                772,
                3,
            ],
            [
                0,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                2,
            ],
            [
                770,
                1,
            ],
            [
                772,
                1,
            ],
            [
                759,
                1,
            ],
            [
                765,
                1,
            ],
            [
                770,
                1,
            ],
            [
                759,
                1,
            ],
            [
                764,
                1,
            ],
            [
                772,
                10,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                1,
            ],
            [
                0,
                1,
            ],
            [
                771,
                5,
            ],
            [
                770,
                1,
            ],
            [
                768,
                1,
            ],
            [
                770,
                4,
            ],
            [
                772,
                1,
            ],
            [
                770,
                1,
            ],
            [
                772,
                6,
            ],
            [
                770,
                6,
            ],
        ],
    },
    'Wyoming, USA': {
        'overall': [
            [
                719,
                30,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                2,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                2,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                2,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                2,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                721,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                5,
            ],
            [
                718,
                1,
            ],
            [
                719,
                4,
            ],
            [
                721,
                1,
            ],
            [
                719,
                6,
            ],
            [
                0,
                5,
            ],
            [
                719,
                4,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                4,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                8,
                5,
            ],
            [
                721,
                2,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                6,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                2,
            ],
            [
                718,
                1,
            ],
            [
                719,
                2,
            ],
            [
                0,
                1,
            ],
            [
                718,
                1,
            ],
            [
                0,
                1,
            ],
            [
                718,
                1,
            ],
            [
                0,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                2,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                3,
            ],
            [
                718,
                1,
            ],
            [
                719,
                7,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                12,
            ],
            [
                718,
                1,
            ],
            [
                719,
                27,
            ],
            [
                718,
                1,
            ],
            [
                719,
                5,
            ],
            [
                0,
                1,
            ],
            [
                719,
                4,
            ],
            [
                521,
                3,
            ],
            [
                692,
                3,
            ],
            [
                710,
                3,
            ],
            [
                718,
                1,
            ],
            [
                719,
                3,
            ],
            [
                0,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                2,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                688,
                1,
            ],
            [
                699,
                1,
            ],
            [
                712,
                1,
            ],
            [
                686,
                1,
            ],
            [
                695,
                1,
            ],
            [
                719,
                11,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                1,
            ],
            [
                0,
                1,
            ],
            [
                719,
                6,
            ],
            [
                718,
                1,
            ],
            [
                719,
                1,
            ],
            [
                718,
                3,
            ],
            [
                719,
                1,
            ],
            [
                718,
                1,
            ],
            [
                719,
                12,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                2,
                74,
            ],
            [
                0,
                5,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                4,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                2,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                11,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                46,
            ],
            [
                0,
                1,
            ],
            [
                2,
                17,
            ],
            [
                0,
                1,
            ],
            [
                2,
                21,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                25,
            ],
        ],
        'CCD': [
            [
                71,
                74,
            ],
            [
                0,
                5,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                6,
            ],
            [
                71,
                20,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                4,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                2,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                11,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                46,
            ],
            [
                0,
                1,
            ],
            [
                71,
                4,
            ],
            [
                56,
                3,
            ],
            [
                70,
                3,
            ],
            [
                71,
                7,
            ],
            [
                0,
                1,
            ],
            [
                71,
                21,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                25,
            ],
        ],
        'City': [
            [
                203,
                30,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                2,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                2,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                2,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                2,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                3,
            ],
            [
                202,
                1,
            ],
            [
                203,
                5,
            ],
            [
                202,
                1,
            ],
            [
                203,
                11,
            ],
            [
                0,
                5,
            ],
            [
                203,
                4,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                4,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                6,
            ],
            [
                203,
                3,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                6,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                2,
            ],
            [
                202,
                1,
            ],
            [
                203,
                2,
            ],
            [
                0,
                1,
            ],
            [
                202,
                1,
            ],
            [
                0,
                1,
            ],
            [
                202,
                1,
            ],
            [
                0,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                2,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                3,
            ],
            [
                202,
                1,
            ],
            [
                203,
                7,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                12,
            ],
            [
                202,
                1,
            ],
            [
                203,
                27,
            ],
            [
                202,
                1,
            ],
            [
                203,
                5,
            ],
            [
                0,
                1,
            ],
            [
                203,
                4,
            ],
            [
                98,
                3,
            ],
            [
                184,
                3,
            ],
            [
                195,
                3,
            ],
            [
                202,
                1,
            ],
            [
                203,
                3,
            ],
            [
                0,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                2,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                182,
                1,
            ],
            [
                189,
                1,
            ],
            [
                201,
                1,
            ],
            [
                182,
                1,
            ],
            [
                189,
                1,
            ],
            [
                203,
                11,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                1,
            ],
            [
                0,
                1,
            ],
            [
                203,
                6,
            ],
            [
                202,
                1,
            ],
            [
                203,
                1,
            ],
            [
                202,
                3,
            ],
            [
                203,
                1,
            ],
            [
                202,
                1,
            ],
            [
                203,
                12,
            ],
        ],
        'Congressional District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                23,
                74,
            ],
            [
                0,
                5,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                20,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                2,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                11,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                46,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                22,
                3,
            ],
            [
                23,
                10,
            ],
            [
                0,
                1,
            ],
            [
                23,
                21,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                25,
            ],
        ],
        'County Cross CD': [
            [
                23,
                74,
            ],
            [
                0,
                5,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                20,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                2,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                11,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                46,
            ],
            [
                0,
                1,
            ],
            [
                23,
                4,
            ],
            [
                22,
                3,
            ],
            [
                23,
                10,
            ],
            [
                0,
                1,
            ],
            [
                23,
                21,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                25,
            ],
        ],
        'Hospital Referral Region': [
            [
                5,
                74,
            ],
            [
                0,
                5,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                20,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                4,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                2,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                11,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                46,
            ],
            [
                0,
                1,
            ],
            [
                5,
                17,
            ],
            [
                0,
                1,
            ],
            [
                5,
                21,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                30,
                74,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                30,
                20,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                2,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                11,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                46,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                29,
                3,
            ],
            [
                30,
                10,
            ],
            [
                0,
                1,
            ],
            [
                30,
                21,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                9,
                74,
            ],
            [
                0,
                5,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                6,
            ],
            [
                9,
                20,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                4,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                2,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                11,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                46,
            ],
            [
                0,
                1,
            ],
            [
                9,
                17,
            ],
            [
                0,
                1,
            ],
            [
                9,
                21,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                1,
            ],
            [
                0,
                1,
            ],
            [
                9,
                25,
            ],
        ],
        'Media Market': [
            [
                7,
                74,
            ],
            [
                0,
                5,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                20,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                4,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                2,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                11,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                46,
            ],
            [
                0,
                1,
            ],
            [
                7,
                17,
            ],
            [
                0,
                1,
            ],
            [
                7,
                21,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                25,
            ],
        ],
        'Native Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'School District': [
            [
                49,
                74,
            ],
            [
                0,
                5,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                20,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                2,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                11,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                46,
            ],
            [
                0,
                1,
            ],
            [
                49,
                4,
            ],
            [
                43,
                3,
            ],
            [
                49,
                10,
            ],
            [
                0,
                1,
            ],
            [
                49,
                21,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                25,
            ],
        ],
        'State House District': [
            [
                62,
                74,
            ],
            [
                0,
                5,
            ],
            [
                62,
                4,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                4,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                6,
            ],
            [
                62,
                20,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                4,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                2,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                11,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                46,
            ],
            [
                0,
                1,
            ],
            [
                62,
                4,
            ],
            [
                61,
                3,
            ],
            [
                62,
                10,
            ],
            [
                0,
                1,
            ],
            [
                62,
                21,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                1,
            ],
            [
                0,
                1,
            ],
            [
                62,
                25,
            ],
        ],
        'State Senate District': [
            [
                31,
                74,
            ],
            [
                0,
                5,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                6,
            ],
            [
                31,
                20,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                4,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                2,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                11,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                46,
            ],
            [
                0,
                1,
            ],
            [
                31,
                17,
            ],
            [
                0,
                1,
            ],
            [
                31,
                21,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'USDA County Type': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                18,
                74,
            ],
            [
                0,
                5,
            ],
            [
                18,
                4,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                4,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                6,
            ],
            [
                18,
                20,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                4,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                2,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                11,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                46,
            ],
            [
                0,
                1,
            ],
            [
                18,
                17,
            ],
            [
                0,
                1,
            ],
            [
                18,
                21,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                177,
                74,
            ],
            [
                0,
                5,
            ],
            [
                177,
                4,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                4,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                6,
            ],
            [
                177,
                20,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                4,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                2,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                11,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                46,
            ],
            [
                0,
                1,
            ],
            [
                177,
                4,
            ],
            [
                109,
                3,
            ],
            [
                170,
                3,
            ],
            [
                176,
                3,
            ],
            [
                177,
                4,
            ],
            [
                0,
                1,
            ],
            [
                177,
                5,
            ],
            [
                167,
                1,
            ],
            [
                171,
                1,
            ],
            [
                172,
                1,
            ],
            [
                165,
                1,
            ],
            [
                167,
                1,
            ],
            [
                177,
                11,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                1,
            ],
            [
                0,
                1,
            ],
            [
                177,
                25,
            ],
        ],
    },
    'American Samoa, USA': {
        'overall': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Guam, USA': {
        'overall': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Northern Mariana Islands, USA': {
        'overall': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'Puerto Rico, USA': {
        'overall': [
            [
                1,
                3,
            ],
            [
                1459,
                26,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                2,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                4,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                10,
            ],
            [
                0,
                5,
            ],
            [
                1462,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                3,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                5,
            ],
            [
                1463,
                3,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1462,
                6,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                2,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                3,
            ],
            [
                1462,
                1,
            ],
            [
                1463,
                3,
            ],
            [
                1459,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                12,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1462,
                25,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1463,
                4,
            ],
            [
                782,
                3,
            ],
            [
                1223,
                3,
            ],
            [
                1404,
                3,
            ],
            [
                1462,
                1,
            ],
            [
                1463,
                3,
            ],
            [
                0,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1398,
                1,
            ],
            [
                1412,
                1,
            ],
            [
                1416,
                1,
            ],
            [
                1369,
                1,
            ],
            [
                1379,
                1,
            ],
            [
                1463,
                7,
            ],
            [
                1,
                1,
            ],
            [
                1463,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1462,
                5,
            ],
            [
                1463,
                1,
            ],
            [
                1452,
                1,
            ],
            [
                1458,
                1,
            ],
            [
                1463,
                4,
            ],
            [
                1453,
                1,
            ],
            [
                1459,
                1,
            ],
            [
                1463,
                11,
            ],
        ],
        'CCD': [
            [
                0,
                3,
            ],
            [
                893,
                26,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                2,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                4,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                10,
            ],
            [
                0,
                5,
            ],
            [
                895,
                4,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                3,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                6,
            ],
            [
                896,
                3,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                895,
                6,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                2,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                3,
            ],
            [
                895,
                1,
            ],
            [
                896,
                3,
            ],
            [
                893,
                4,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                896,
                12,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                895,
                25,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                4,
            ],
            [
                0,
                1,
            ],
            [
                896,
                4,
            ],
            [
                411,
                3,
            ],
            [
                723,
                3,
            ],
            [
                852,
                3,
            ],
            [
                895,
                1,
            ],
            [
                896,
                3,
            ],
            [
                0,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                852,
                1,
            ],
            [
                858,
                1,
            ],
            [
                855,
                1,
            ],
            [
                827,
                1,
            ],
            [
                828,
                1,
            ],
            [
                896,
                7,
            ],
            [
                0,
                1,
            ],
            [
                896,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                1,
            ],
            [
                0,
                1,
            ],
            [
                895,
                5,
            ],
            [
                896,
                1,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                4,
            ],
            [
                894,
                1,
            ],
            [
                893,
                1,
            ],
            [
                896,
                11,
            ],
        ],
        'CSA': [
            [
                0,
                3,
            ],
            [
                3,
                71,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                20,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'City': [
            [
                0,
                3,
            ],
            [
                291,
                26,
            ],
            [
                292,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                2,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                4,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                10,
            ],
            [
                0,
                5,
            ],
            [
                292,
                4,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                4,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                6,
            ],
            [
                292,
                3,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                7,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                2,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                2,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                7,
            ],
            [
                291,
                4,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                12,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                26,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                4,
            ],
            [
                0,
                1,
            ],
            [
                292,
                4,
            ],
            [
                118,
                3,
            ],
            [
                229,
                3,
            ],
            [
                278,
                3,
            ],
            [
                292,
                4,
            ],
            [
                0,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                1,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                273,
                1,
            ],
            [
                280,
                1,
            ],
            [
                287,
                1,
            ],
            [
                269,
                1,
            ],
            [
                278,
                1,
            ],
            [
                292,
                7,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                1,
            ],
            [
                0,
                1,
            ],
            [
                292,
                6,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                4,
            ],
            [
                285,
                1,
            ],
            [
                291,
                1,
            ],
            [
                292,
                11,
            ],
        ],
        'County': [
            [
                0,
                3,
            ],
            [
                78,
                71,
            ],
            [
                0,
                5,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                6,
            ],
            [
                78,
                20,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                2,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                11,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                46,
            ],
            [
                0,
                1,
            ],
            [
                78,
                4,
            ],
            [
                72,
                3,
            ],
            [
                78,
                10,
            ],
            [
                0,
                1,
            ],
            [
                78,
                17,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                0,
                3,
            ],
            [
                1,
                71,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'MSA': [
            [
                0,
                3,
            ],
            [
                12,
                71,
            ],
            [
                0,
                5,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                6,
            ],
            [
                12,
                20,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                4,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                2,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                11,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                46,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                17,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                1,
            ],
            [
                0,
                1,
            ],
            [
                12,
                25,
            ],
        ],
        'Neighborhood': [
            [
                0,
                3,
            ],
            [
                18,
                71,
            ],
            [
                0,
                5,
            ],
            [
                18,
                4,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                4,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                6,
            ],
            [
                18,
                20,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                4,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                2,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                11,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                46,
            ],
            [
                0,
                1,
            ],
            [
                18,
                4,
            ],
            [
                17,
                3,
            ],
            [
                18,
                10,
            ],
            [
                0,
                1,
            ],
            [
                18,
                17,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                25,
            ],
        ],
        'School District': [
            [
                0,
                3,
            ],
            [
                1,
                71,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                3,
            ],
            [
                1,
                71,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                0,
                3,
            ],
            [
                26,
                71,
            ],
            [
                0,
                5,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                20,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                4,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                2,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                11,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                46,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                17,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                26,
                25,
            ],
        ],
        'Urban Center': [
            [
                0,
                3,
            ],
            [
                3,
                71,
            ],
            [
                0,
                5,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                4,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                2,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                11,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                46,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                17,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                25,
            ],
        ],
        'ZIP': [
            [
                0,
                3,
            ],
            [
                131,
                27,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                3,
            ],
            [
                130,
                1,
            ],
            [
                131,
                5,
            ],
            [
                130,
                1,
            ],
            [
                131,
                11,
            ],
            [
                0,
                5,
            ],
            [
                131,
                4,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                4,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                6,
            ],
            [
                131,
                3,
            ],
            [
                130,
                1,
            ],
            [
                131,
                8,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                2,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                11,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                12,
            ],
            [
                130,
                1,
            ],
            [
                131,
                27,
            ],
            [
                130,
                1,
            ],
            [
                131,
                5,
            ],
            [
                0,
                1,
            ],
            [
                131,
                4,
            ],
            [
                116,
                3,
            ],
            [
                127,
                3,
            ],
            [
                130,
                3,
            ],
            [
                131,
                4,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                129,
                1,
            ],
            [
                130,
                2,
            ],
            [
                129,
                2,
            ],
            [
                131,
                7,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                6,
            ],
            [
                129,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                4,
            ],
            [
                130,
                1,
            ],
            [
                131,
                12,
            ],
        ],
    },
    'US Virgin Islands, USA': {
        'overall': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
    },
    'District of Columbia, USA': {
        'overall': [
            [
                173,
                3,
            ],
            [
                169,
                26,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                2,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                4,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                10,
            ],
            [
                0,
                5,
            ],
            [
                171,
                4,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                3,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                5,
            ],
            [
                173,
                3,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                168,
                6,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                3,
            ],
            [
                168,
                1,
            ],
            [
                173,
                3,
            ],
            [
                169,
                4,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                171,
                1,
            ],
            [
                0,
                1,
            ],
            [
                173,
                12,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                170,
                25,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                4,
            ],
            [
                0,
                1,
            ],
            [
                173,
                4,
            ],
            [
                156,
                6,
            ],
            [
                166,
                3,
            ],
            [
                168,
                1,
            ],
            [
                173,
                3,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                3,
            ],
            [
                173,
                1,
            ],
            [
                169,
                2,
            ],
            [
                173,
                1,
            ],
            [
                171,
                5,
            ],
            [
                173,
                4,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                1,
            ],
            [
                0,
                1,
            ],
            [
                170,
                5,
            ],
            [
                171,
                1,
            ],
            [
                169,
                1,
            ],
            [
                164,
                1,
            ],
            [
                169,
                3,
            ],
            [
                173,
                1,
            ],
            [
                170,
                1,
            ],
            [
                169,
                1,
            ],
            [
                173,
                5,
            ],
            [
                168,
                6,
            ],
        ],
        'CCD': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'City': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'County': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Hospital Service Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial Circuit': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Judicial District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Neighborhood': [
            [
                131,
                3,
            ],
            [
                130,
                26,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                3,
            ],
            [
                130,
                1,
            ],
            [
                131,
                5,
            ],
            [
                130,
                1,
            ],
            [
                131,
                10,
            ],
            [
                0,
                5,
            ],
            [
                131,
                4,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                3,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                6,
            ],
            [
                131,
                4,
            ],
            [
                130,
                7,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                2,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                3,
            ],
            [
                130,
                1,
            ],
            [
                131,
                3,
            ],
            [
                130,
                4,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                13,
            ],
            [
                130,
                1,
            ],
            [
                131,
                27,
            ],
            [
                130,
                1,
            ],
            [
                131,
                4,
            ],
            [
                0,
                1,
            ],
            [
                131,
                4,
            ],
            [
                118,
                3,
            ],
            [
                119,
                3,
            ],
            [
                128,
                3,
            ],
            [
                130,
                1,
            ],
            [
                131,
                3,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                1,
            ],
            [
                131,
                2,
            ],
            [
                130,
                3,
            ],
            [
                131,
                1,
            ],
            [
                130,
                2,
            ],
            [
                131,
                11,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                1,
            ],
            [
                0,
                1,
            ],
            [
                131,
                5,
            ],
            [
                130,
                1,
            ],
            [
                131,
                1,
            ],
            [
                130,
                4,
            ],
            [
                131,
                2,
            ],
            [
                130,
                1,
            ],
            [
                131,
                5,
            ],
            [
                130,
                6,
            ],
        ],
        'School District': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Subnational Region': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Area': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                20,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'Urban Center': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        'ZIP': [
            [
                32,
                3,
            ],
            [
                29,
                26,
            ],
            [
                32,
                1,
            ],
            [
                29,
                4,
            ],
            [
                32,
                1,
            ],
            [
                29,
                4,
            ],
            [
                32,
                1,
            ],
            [
                29,
                4,
            ],
            [
                32,
                1,
            ],
            [
                29,
                4,
            ],
            [
                32,
                1,
            ],
            [
                29,
                4,
            ],
            [
                32,
                2,
            ],
            [
                29,
                2,
            ],
            [
                32,
                4,
            ],
            [
                29,
                2,
            ],
            [
                32,
                10,
            ],
            [
                0,
                5,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                6,
            ],
            [
                32,
                3,
            ],
            [
                29,
                2,
            ],
            [
                28,
                6,
            ],
            [
                32,
                1,
            ],
            [
                29,
                4,
            ],
            [
                32,
                1,
            ],
            [
                29,
                2,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                32,
                1,
            ],
            [
                29,
                2,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                3,
            ],
            [
                28,
                1,
            ],
            [
                32,
                3,
            ],
            [
                29,
                4,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                32,
                12,
            ],
            [
                29,
                27,
            ],
            [
                32,
                1,
            ],
            [
                29,
                2,
            ],
            [
                32,
                4,
            ],
            [
                0,
                1,
            ],
            [
                32,
                4,
            ],
            [
                28,
                3,
            ],
            [
                27,
                3,
            ],
            [
                28,
                4,
            ],
            [
                32,
                3,
            ],
            [
                0,
                1,
            ],
            [
                29,
                2,
            ],
            [
                32,
                1,
            ],
            [
                29,
                4,
            ],
            [
                32,
                1,
            ],
            [
                29,
                2,
            ],
            [
                32,
                1,
            ],
            [
                30,
                5,
            ],
            [
                32,
                4,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                5,
            ],
            [
                31,
                1,
            ],
            [
                28,
                1,
            ],
            [
                24,
                1,
            ],
            [
                29,
                3,
            ],
            [
                32,
                1,
            ],
            [
                29,
                2,
            ],
            [
                32,
                5,
            ],
            [
                28,
                6,
            ],
        ],
    },
    'Alberta, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                602,
                1,
            ],
            [
                0,
                12,
            ],
            [
                602,
                1,
            ],
            [
                0,
                6,
            ],
            [
                597,
                5,
            ],
            [
                0,
                4,
            ],
            [
                571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                571,
                1,
            ],
            [
                0,
                1,
            ],
            [
                571,
                1,
            ],
            [
                0,
                4,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                7,
                5,
            ],
            [
                602,
                2,
            ],
            [
                0,
                18,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                4,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                1,
            ],
            [
                481,
                1,
            ],
            [
                0,
                2,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                1,
            ],
            [
                562,
                1,
            ],
            [
                0,
                11,
            ],
            [
                442,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                1,
            ],
            [
                578,
                1,
            ],
            [
                0,
                46,
            ],
            [
                597,
                1,
            ],
            [
                0,
                17,
            ],
            [
                597,
                1,
            ],
            [
                0,
                21,
            ],
            [
                568,
                1,
            ],
            [
                0,
                1,
            ],
            [
                568,
                1,
            ],
            [
                0,
                1,
            ],
            [
                568,
                1,
            ],
            [
                0,
                1,
            ],
            [
                568,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                17,
                1,
            ],
            [
                0,
                12,
            ],
            [
                17,
                1,
            ],
            [
                0,
                6,
            ],
            [
                17,
                5,
            ],
            [
                0,
                4,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                4,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                5,
            ],
            [
                17,
                2,
            ],
            [
                0,
                18,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                4,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                2,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                11,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                46,
            ],
            [
                17,
                1,
            ],
            [
                0,
                17,
            ],
            [
                17,
                1,
            ],
            [
                0,
                21,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                1,
            ],
            [
                17,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                19,
                1,
            ],
            [
                0,
                12,
            ],
            [
                19,
                1,
            ],
            [
                0,
                6,
            ],
            [
                19,
                5,
            ],
            [
                0,
                4,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                4,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                5,
            ],
            [
                19,
                2,
            ],
            [
                0,
                18,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                4,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                2,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                11,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                46,
            ],
            [
                19,
                1,
            ],
            [
                0,
                17,
            ],
            [
                19,
                1,
            ],
            [
                0,
                21,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                1,
            ],
            [
                19,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                406,
                1,
            ],
            [
                0,
                12,
            ],
            [
                406,
                1,
            ],
            [
                0,
                6,
            ],
            [
                406,
                5,
            ],
            [
                0,
                4,
            ],
            [
                380,
                1,
            ],
            [
                0,
                1,
            ],
            [
                380,
                1,
            ],
            [
                0,
                1,
            ],
            [
                380,
                1,
            ],
            [
                0,
                4,
            ],
            [
                387,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                5,
            ],
            [
                406,
                2,
            ],
            [
                0,
                18,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                4,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                2,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                1,
            ],
            [
                371,
                1,
            ],
            [
                0,
                11,
            ],
            [
                251,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                1,
            ],
            [
                387,
                1,
            ],
            [
                0,
                46,
            ],
            [
                406,
                1,
            ],
            [
                0,
                17,
            ],
            [
                406,
                1,
            ],
            [
                0,
                21,
            ],
            [
                377,
                1,
            ],
            [
                0,
                1,
            ],
            [
                377,
                1,
            ],
            [
                0,
                1,
            ],
            [
                377,
                1,
            ],
            [
                0,
                1,
            ],
            [
                377,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                119,
                1,
            ],
            [
                0,
                12,
            ],
            [
                119,
                1,
            ],
            [
                0,
                6,
            ],
            [
                119,
                5,
            ],
            [
                0,
                4,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                4,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                5,
            ],
            [
                119,
                2,
            ],
            [
                0,
                18,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                4,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                2,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                11,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                46,
            ],
            [
                119,
                1,
            ],
            [
                0,
                17,
            ],
            [
                119,
                1,
            ],
            [
                0,
                21,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                1,
            ],
            [
                119,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                34,
                1,
            ],
            [
                0,
                12,
            ],
            [
                34,
                1,
            ],
            [
                0,
                6,
            ],
            [
                34,
                5,
            ],
            [
                0,
                4,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                4,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                5,
            ],
            [
                34,
                2,
            ],
            [
                0,
                18,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                4,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                2,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                11,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                46,
            ],
            [
                34,
                1,
            ],
            [
                0,
                17,
            ],
            [
                34,
                1,
            ],
            [
                0,
                21,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'British Columbia, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                855,
                1,
            ],
            [
                0,
                12,
            ],
            [
                855,
                1,
            ],
            [
                0,
                6,
            ],
            [
                849,
                5,
            ],
            [
                0,
                4,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                1,
            ],
            [
                740,
                1,
            ],
            [
                0,
                4,
            ],
            [
                742,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                7,
                5,
            ],
            [
                855,
                2,
            ],
            [
                0,
                18,
            ],
            [
                580,
                1,
            ],
            [
                0,
                1,
            ],
            [
                580,
                1,
            ],
            [
                0,
                1,
            ],
            [
                580,
                1,
            ],
            [
                0,
                4,
            ],
            [
                580,
                1,
            ],
            [
                0,
                1,
            ],
            [
                580,
                1,
            ],
            [
                0,
                1,
            ],
            [
                580,
                1,
            ],
            [
                0,
                2,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                1,
            ],
            [
                734,
                1,
            ],
            [
                0,
                11,
            ],
            [
                509,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                0,
                1,
            ],
            [
                742,
                1,
            ],
            [
                0,
                46,
            ],
            [
                849,
                1,
            ],
            [
                0,
                17,
            ],
            [
                849,
                1,
            ],
            [
                0,
                21,
            ],
            [
                735,
                1,
            ],
            [
                0,
                1,
            ],
            [
                735,
                1,
            ],
            [
                0,
                1,
            ],
            [
                735,
                1,
            ],
            [
                0,
                1,
            ],
            [
                735,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                28,
                1,
            ],
            [
                0,
                12,
            ],
            [
                28,
                1,
            ],
            [
                0,
                6,
            ],
            [
                28,
                5,
            ],
            [
                0,
                4,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                4,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                5,
            ],
            [
                28,
                2,
            ],
            [
                0,
                18,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                4,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                2,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                11,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                46,
            ],
            [
                28,
                1,
            ],
            [
                0,
                17,
            ],
            [
                28,
                1,
            ],
            [
                0,
                21,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                29,
                1,
            ],
            [
                0,
                12,
            ],
            [
                29,
                1,
            ],
            [
                0,
                6,
            ],
            [
                29,
                5,
            ],
            [
                0,
                4,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                4,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                5,
            ],
            [
                29,
                2,
            ],
            [
                0,
                18,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                4,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                2,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                11,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                46,
            ],
            [
                29,
                1,
            ],
            [
                0,
                17,
            ],
            [
                29,
                1,
            ],
            [
                0,
                21,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                641,
                1,
            ],
            [
                0,
                12,
            ],
            [
                641,
                1,
            ],
            [
                0,
                6,
            ],
            [
                641,
                5,
            ],
            [
                0,
                4,
            ],
            [
                532,
                1,
            ],
            [
                0,
                1,
            ],
            [
                532,
                1,
            ],
            [
                0,
                1,
            ],
            [
                532,
                1,
            ],
            [
                0,
                4,
            ],
            [
                534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                5,
            ],
            [
                641,
                2,
            ],
            [
                0,
                18,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                4,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                1,
            ],
            [
                373,
                1,
            ],
            [
                0,
                2,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                1,
            ],
            [
                526,
                1,
            ],
            [
                0,
                11,
            ],
            [
                302,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                1,
            ],
            [
                534,
                1,
            ],
            [
                0,
                46,
            ],
            [
                641,
                1,
            ],
            [
                0,
                17,
            ],
            [
                641,
                1,
            ],
            [
                0,
                21,
            ],
            [
                527,
                1,
            ],
            [
                0,
                1,
            ],
            [
                527,
                1,
            ],
            [
                0,
                1,
            ],
            [
                527,
                1,
            ],
            [
                0,
                1,
            ],
            [
                527,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                108,
                1,
            ],
            [
                0,
                12,
            ],
            [
                108,
                1,
            ],
            [
                0,
                6,
            ],
            [
                108,
                5,
            ],
            [
                0,
                4,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                4,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                5,
            ],
            [
                108,
                2,
            ],
            [
                0,
                18,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                4,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                2,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                11,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                46,
            ],
            [
                108,
                1,
            ],
            [
                0,
                17,
            ],
            [
                108,
                1,
            ],
            [
                0,
                21,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                1,
            ],
            [
                108,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                42,
                1,
            ],
            [
                0,
                12,
            ],
            [
                42,
                1,
            ],
            [
                0,
                6,
            ],
            [
                42,
                5,
            ],
            [
                0,
                4,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                4,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                5,
            ],
            [
                42,
                2,
            ],
            [
                0,
                18,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                4,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                2,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                11,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                46,
            ],
            [
                42,
                1,
            ],
            [
                0,
                17,
            ],
            [
                42,
                1,
            ],
            [
                0,
                21,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                1,
            ],
            [
                42,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Manitoba, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                322,
                1,
            ],
            [
                0,
                12,
            ],
            [
                322,
                1,
            ],
            [
                0,
                6,
            ],
            [
                319,
                5,
            ],
            [
                0,
                4,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                1,
            ],
            [
                311,
                1,
            ],
            [
                0,
                4,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                4,
                5,
            ],
            [
                322,
                2,
            ],
            [
                0,
                18,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                4,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                286,
                1,
            ],
            [
                0,
                2,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                1,
            ],
            [
                306,
                1,
            ],
            [
                0,
                11,
            ],
            [
                232,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                1,
            ],
            [
                312,
                1,
            ],
            [
                0,
                46,
            ],
            [
                319,
                1,
            ],
            [
                0,
                17,
            ],
            [
                319,
                1,
            ],
            [
                0,
                21,
            ],
            [
                308,
                1,
            ],
            [
                0,
                1,
            ],
            [
                308,
                1,
            ],
            [
                0,
                1,
            ],
            [
                308,
                1,
            ],
            [
                0,
                1,
            ],
            [
                308,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                5,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                5,
            ],
            [
                6,
                2,
            ],
            [
                0,
                18,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                2,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                11,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                46,
            ],
            [
                6,
                1,
            ],
            [
                0,
                17,
            ],
            [
                6,
                1,
            ],
            [
                0,
                21,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                23,
                1,
            ],
            [
                0,
                12,
            ],
            [
                23,
                1,
            ],
            [
                0,
                6,
            ],
            [
                23,
                5,
            ],
            [
                0,
                4,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                4,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                5,
            ],
            [
                23,
                2,
            ],
            [
                0,
                18,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                4,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                2,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                11,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                46,
            ],
            [
                23,
                1,
            ],
            [
                0,
                17,
            ],
            [
                23,
                1,
            ],
            [
                0,
                21,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                221,
                1,
            ],
            [
                0,
                12,
            ],
            [
                221,
                1,
            ],
            [
                0,
                6,
            ],
            [
                221,
                5,
            ],
            [
                0,
                4,
            ],
            [
                213,
                1,
            ],
            [
                0,
                1,
            ],
            [
                213,
                1,
            ],
            [
                0,
                1,
            ],
            [
                213,
                1,
            ],
            [
                0,
                4,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                5,
            ],
            [
                221,
                2,
            ],
            [
                0,
                18,
            ],
            [
                188,
                1,
            ],
            [
                0,
                1,
            ],
            [
                188,
                1,
            ],
            [
                0,
                1,
            ],
            [
                188,
                1,
            ],
            [
                0,
                4,
            ],
            [
                188,
                1,
            ],
            [
                0,
                1,
            ],
            [
                188,
                1,
            ],
            [
                0,
                1,
            ],
            [
                188,
                1,
            ],
            [
                0,
                2,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                1,
            ],
            [
                208,
                1,
            ],
            [
                0,
                11,
            ],
            [
                139,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                1,
            ],
            [
                214,
                1,
            ],
            [
                0,
                46,
            ],
            [
                221,
                1,
            ],
            [
                0,
                17,
            ],
            [
                221,
                1,
            ],
            [
                0,
                21,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                1,
            ],
            [
                210,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                54,
                1,
            ],
            [
                0,
                12,
            ],
            [
                54,
                1,
            ],
            [
                0,
                6,
            ],
            [
                54,
                5,
            ],
            [
                0,
                4,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                4,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                5,
            ],
            [
                54,
                2,
            ],
            [
                0,
                18,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                4,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                2,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                11,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                46,
            ],
            [
                54,
                1,
            ],
            [
                0,
                17,
            ],
            [
                54,
                1,
            ],
            [
                0,
                21,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                1,
            ],
            [
                54,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                5,
            ],
            [
                0,
                4,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                4,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                5,
            ],
            [
                14,
                2,
            ],
            [
                0,
                18,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                4,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                2,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                11,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                46,
            ],
            [
                14,
                1,
            ],
            [
                0,
                17,
            ],
            [
                14,
                1,
            ],
            [
                0,
                21,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'New Brunswick, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                329,
                1,
            ],
            [
                0,
                12,
            ],
            [
                329,
                1,
            ],
            [
                0,
                6,
            ],
            [
                328,
                5,
            ],
            [
                0,
                4,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                4,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                2,
                5,
            ],
            [
                329,
                2,
            ],
            [
                0,
                18,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                4,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                1,
            ],
            [
                313,
                1,
            ],
            [
                0,
                2,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                11,
            ],
            [
                301,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                1,
            ],
            [
                321,
                1,
            ],
            [
                0,
                46,
            ],
            [
                328,
                1,
            ],
            [
                0,
                17,
            ],
            [
                328,
                1,
            ],
            [
                0,
                21,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                1,
            ],
            [
                320,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                5,
            ],
            [
                0,
                4,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                4,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                5,
            ],
            [
                7,
                2,
            ],
            [
                0,
                18,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                4,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                2,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                11,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                46,
            ],
            [
                7,
                1,
            ],
            [
                0,
                17,
            ],
            [
                7,
                1,
            ],
            [
                0,
                21,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                15,
                1,
            ],
            [
                0,
                12,
            ],
            [
                15,
                1,
            ],
            [
                0,
                6,
            ],
            [
                15,
                5,
            ],
            [
                0,
                4,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                4,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                5,
            ],
            [
                15,
                2,
            ],
            [
                0,
                18,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                4,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                2,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                11,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                46,
            ],
            [
                15,
                1,
            ],
            [
                0,
                17,
            ],
            [
                15,
                1,
            ],
            [
                0,
                21,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                1,
            ],
            [
                15,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                264,
                1,
            ],
            [
                0,
                12,
            ],
            [
                264,
                1,
            ],
            [
                0,
                6,
            ],
            [
                264,
                5,
            ],
            [
                0,
                4,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                4,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                5,
            ],
            [
                264,
                2,
            ],
            [
                0,
                18,
            ],
            [
                249,
                1,
            ],
            [
                0,
                1,
            ],
            [
                249,
                1,
            ],
            [
                0,
                1,
            ],
            [
                249,
                1,
            ],
            [
                0,
                4,
            ],
            [
                249,
                1,
            ],
            [
                0,
                1,
            ],
            [
                249,
                1,
            ],
            [
                0,
                1,
            ],
            [
                249,
                1,
            ],
            [
                0,
                2,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                11,
            ],
            [
                238,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                1,
            ],
            [
                257,
                1,
            ],
            [
                0,
                46,
            ],
            [
                264,
                1,
            ],
            [
                0,
                17,
            ],
            [
                264,
                1,
            ],
            [
                0,
                21,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                1,
            ],
            [
                256,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                6,
            ],
            [
                31,
                5,
            ],
            [
                0,
                4,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                4,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                5,
            ],
            [
                31,
                2,
            ],
            [
                0,
                18,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                4,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                2,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                11,
            ],
            [
                30,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                46,
            ],
            [
                31,
                1,
            ],
            [
                0,
                17,
            ],
            [
                31,
                1,
            ],
            [
                0,
                21,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                1,
            ],
            [
                31,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                5,
            ],
            [
                0,
                4,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                4,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                5,
            ],
            [
                10,
                2,
            ],
            [
                0,
                18,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                4,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                2,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                11,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                46,
            ],
            [
                10,
                1,
            ],
            [
                0,
                17,
            ],
            [
                10,
                1,
            ],
            [
                0,
                21,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Newfoundland and Labrador, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                413,
                1,
            ],
            [
                0,
                12,
            ],
            [
                413,
                1,
            ],
            [
                0,
                6,
            ],
            [
                413,
                5,
            ],
            [
                0,
                4,
            ],
            [
                397,
                1,
            ],
            [
                0,
                1,
            ],
            [
                397,
                1,
            ],
            [
                0,
                1,
            ],
            [
                397,
                1,
            ],
            [
                0,
                4,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                1,
                5,
            ],
            [
                413,
                2,
            ],
            [
                0,
                18,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                4,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                1,
            ],
            [
                290,
                1,
            ],
            [
                0,
                2,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                11,
            ],
            [
                286,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                1,
            ],
            [
                404,
                1,
            ],
            [
                0,
                46,
            ],
            [
                413,
                1,
            ],
            [
                0,
                17,
            ],
            [
                413,
                1,
            ],
            [
                0,
                21,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                1,
            ],
            [
                388,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                5,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                5,
            ],
            [
                4,
                2,
            ],
            [
                0,
                18,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                2,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                11,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                46,
            ],
            [
                4,
                1,
            ],
            [
                0,
                17,
            ],
            [
                4,
                1,
            ],
            [
                0,
                21,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                5,
            ],
            [
                0,
                4,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                4,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                5,
            ],
            [
                11,
                2,
            ],
            [
                0,
                18,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                4,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                2,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                11,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                46,
            ],
            [
                11,
                1,
            ],
            [
                0,
                17,
            ],
            [
                11,
                1,
            ],
            [
                0,
                21,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                363,
                1,
            ],
            [
                0,
                12,
            ],
            [
                363,
                1,
            ],
            [
                0,
                6,
            ],
            [
                363,
                5,
            ],
            [
                0,
                4,
            ],
            [
                347,
                1,
            ],
            [
                0,
                1,
            ],
            [
                347,
                1,
            ],
            [
                0,
                1,
            ],
            [
                347,
                1,
            ],
            [
                0,
                4,
            ],
            [
                354,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                5,
            ],
            [
                363,
                2,
            ],
            [
                0,
                18,
            ],
            [
                240,
                1,
            ],
            [
                0,
                1,
            ],
            [
                240,
                1,
            ],
            [
                0,
                1,
            ],
            [
                240,
                1,
            ],
            [
                0,
                4,
            ],
            [
                240,
                1,
            ],
            [
                0,
                1,
            ],
            [
                240,
                1,
            ],
            [
                0,
                1,
            ],
            [
                240,
                1,
            ],
            [
                0,
                2,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                11,
            ],
            [
                237,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                1,
            ],
            [
                354,
                1,
            ],
            [
                0,
                46,
            ],
            [
                363,
                1,
            ],
            [
                0,
                17,
            ],
            [
                363,
                1,
            ],
            [
                0,
                21,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                1,
            ],
            [
                338,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                27,
                1,
            ],
            [
                0,
                12,
            ],
            [
                27,
                1,
            ],
            [
                0,
                6,
            ],
            [
                27,
                5,
            ],
            [
                0,
                4,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                4,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                5,
            ],
            [
                27,
                2,
            ],
            [
                0,
                18,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                4,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                2,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                11,
            ],
            [
                26,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                46,
            ],
            [
                27,
                1,
            ],
            [
                0,
                17,
            ],
            [
                27,
                1,
            ],
            [
                0,
                21,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                1,
            ],
            [
                27,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                7,
                1,
            ],
            [
                0,
                12,
            ],
            [
                7,
                1,
            ],
            [
                0,
                6,
            ],
            [
                7,
                5,
            ],
            [
                0,
                4,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                4,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                5,
            ],
            [
                7,
                2,
            ],
            [
                0,
                18,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                4,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                2,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                11,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                46,
            ],
            [
                7,
                1,
            ],
            [
                0,
                17,
            ],
            [
                7,
                1,
            ],
            [
                0,
                21,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                1,
            ],
            [
                7,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Northwest Territories, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                50,
                1,
            ],
            [
                0,
                12,
            ],
            [
                50,
                1,
            ],
            [
                0,
                6,
            ],
            [
                50,
                5,
            ],
            [
                0,
                4,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                4,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                1,
                5,
            ],
            [
                50,
                2,
            ],
            [
                0,
                18,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                4,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                35,
                1,
            ],
            [
                0,
                2,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                13,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                46,
            ],
            [
                50,
                1,
            ],
            [
                0,
                17,
            ],
            [
                50,
                1,
            ],
            [
                0,
                21,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                1,
            ],
            [
                46,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                5,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                5,
            ],
            [
                6,
                2,
            ],
            [
                0,
                18,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                2,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                13,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                46,
            ],
            [
                6,
                1,
            ],
            [
                0,
                17,
            ],
            [
                6,
                1,
            ],
            [
                0,
                21,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                5,
            ],
            [
                0,
                4,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                4,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                5,
            ],
            [
                37,
                2,
            ],
            [
                0,
                18,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                4,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                2,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                13,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                46,
            ],
            [
                37,
                1,
            ],
            [
                0,
                17,
            ],
            [
                37,
                1,
            ],
            [
                0,
                21,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                5,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                5,
            ],
            [
                4,
                2,
            ],
            [
                0,
                18,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                2,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                13,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                46,
            ],
            [
                4,
                1,
            ],
            [
                0,
                17,
            ],
            [
                4,
                1,
            ],
            [
                0,
                21,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Nova Scotia, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                164,
                1,
            ],
            [
                0,
                12,
            ],
            [
                164,
                1,
            ],
            [
                0,
                6,
            ],
            [
                164,
                5,
            ],
            [
                0,
                4,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                4,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                1,
                5,
            ],
            [
                164,
                2,
            ],
            [
                0,
                18,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                4,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                1,
            ],
            [
                149,
                1,
            ],
            [
                0,
                2,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                1,
            ],
            [
                155,
                1,
            ],
            [
                0,
                11,
            ],
            [
                138,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                46,
            ],
            [
                164,
                1,
            ],
            [
                0,
                17,
            ],
            [
                164,
                1,
            ],
            [
                0,
                21,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                156,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                5,
                1,
            ],
            [
                0,
                12,
            ],
            [
                5,
                1,
            ],
            [
                0,
                6,
            ],
            [
                5,
                5,
            ],
            [
                0,
                4,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                4,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                5,
            ],
            [
                5,
                2,
            ],
            [
                0,
                18,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                4,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                2,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                11,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                46,
            ],
            [
                5,
                1,
            ],
            [
                0,
                17,
            ],
            [
                5,
                1,
            ],
            [
                0,
                21,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                1,
            ],
            [
                5,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                6,
            ],
            [
                18,
                5,
            ],
            [
                0,
                4,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                4,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                5,
            ],
            [
                18,
                2,
            ],
            [
                0,
                18,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                4,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                2,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                11,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                46,
            ],
            [
                18,
                1,
            ],
            [
                0,
                17,
            ],
            [
                18,
                1,
            ],
            [
                0,
                21,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                92,
                1,
            ],
            [
                0,
                12,
            ],
            [
                92,
                1,
            ],
            [
                0,
                6,
            ],
            [
                92,
                5,
            ],
            [
                0,
                4,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                4,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                5,
            ],
            [
                92,
                2,
            ],
            [
                0,
                18,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                4,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                1,
            ],
            [
                77,
                1,
            ],
            [
                0,
                2,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                1,
            ],
            [
                83,
                1,
            ],
            [
                0,
                11,
            ],
            [
                68,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                46,
            ],
            [
                92,
                1,
            ],
            [
                0,
                17,
            ],
            [
                92,
                1,
            ],
            [
                0,
                21,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                84,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                5,
            ],
            [
                0,
                4,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                4,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                5,
            ],
            [
                37,
                2,
            ],
            [
                0,
                18,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                4,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                2,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                11,
            ],
            [
                35,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                46,
            ],
            [
                37,
                1,
            ],
            [
                0,
                17,
            ],
            [
                37,
                1,
            ],
            [
                0,
                21,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                1,
            ],
            [
                37,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                11,
                1,
            ],
            [
                0,
                12,
            ],
            [
                11,
                1,
            ],
            [
                0,
                6,
            ],
            [
                11,
                5,
            ],
            [
                0,
                4,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                4,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                5,
            ],
            [
                11,
                2,
            ],
            [
                0,
                18,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                4,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                2,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                11,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                46,
            ],
            [
                11,
                1,
            ],
            [
                0,
                17,
            ],
            [
                11,
                1,
            ],
            [
                0,
                21,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                1,
            ],
            [
                11,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Nunavut, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                5,
            ],
            [
                0,
                4,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                4,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                1,
                5,
            ],
            [
                37,
                2,
            ],
            [
                0,
                18,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                4,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                1,
            ],
            [
                34,
                1,
            ],
            [
                0,
                2,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                13,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                46,
            ],
            [
                37,
                1,
            ],
            [
                0,
                17,
            ],
            [
                37,
                1,
            ],
            [
                0,
                21,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                1,
            ],
            [
                36,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                5,
            ],
            [
                0,
                4,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                4,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                5,
            ],
            [
                3,
                2,
            ],
            [
                0,
                18,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                4,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                2,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                13,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                46,
            ],
            [
                3,
                1,
            ],
            [
                0,
                17,
            ],
            [
                3,
                1,
            ],
            [
                0,
                21,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                26,
                1,
            ],
            [
                0,
                12,
            ],
            [
                26,
                1,
            ],
            [
                0,
                6,
            ],
            [
                26,
                5,
            ],
            [
                0,
                4,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                4,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                5,
            ],
            [
                26,
                2,
            ],
            [
                0,
                18,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                4,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                2,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                13,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                46,
            ],
            [
                26,
                1,
            ],
            [
                0,
                17,
            ],
            [
                26,
                1,
            ],
            [
                0,
                21,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                1,
            ],
            [
                25,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                6,
                5,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                5,
            ],
            [
                6,
                2,
            ],
            [
                0,
                18,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                4,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                2,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                13,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                46,
            ],
            [
                6,
                1,
            ],
            [
                0,
                17,
            ],
            [
                6,
                1,
            ],
            [
                0,
                21,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                1,
            ],
            [
                6,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Ontario, Canada': {
        'overall': [
            [
                1,
                54,
            ],
            [
                1093,
                1,
            ],
            [
                1,
                12,
            ],
            [
                1093,
                1,
            ],
            [
                1,
                6,
            ],
            [
                1072,
                5,
            ],
            [
                1,
                4,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                4,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                24,
                5,
            ],
            [
                1093,
                2,
            ],
            [
                1,
                18,
            ],
            [
                996,
                1,
            ],
            [
                1,
                1,
            ],
            [
                996,
                1,
            ],
            [
                1,
                1,
            ],
            [
                996,
                1,
            ],
            [
                1,
                4,
            ],
            [
                996,
                1,
            ],
            [
                1,
                1,
            ],
            [
                996,
                1,
            ],
            [
                1,
                1,
            ],
            [
                996,
                1,
            ],
            [
                1,
                2,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1055,
                1,
            ],
            [
                1,
                11,
            ],
            [
                929,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                46,
            ],
            [
                1072,
                1,
            ],
            [
                1,
                17,
            ],
            [
                1072,
                1,
            ],
            [
                1,
                21,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                1,
            ],
            [
                1057,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                6,
                1,
            ],
            [
                0,
                12,
            ],
            [
                6,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                6,
                7,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        '200M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                35,
            ],
            [
                4,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                35,
            ],
            [
                2,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                1,
                54,
            ],
            [
                9,
                1,
            ],
            [
                1,
                12,
            ],
            [
                9,
                1,
            ],
            [
                1,
                35,
            ],
            [
                9,
                7,
            ],
            [
                1,
                209,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                43,
                1,
            ],
            [
                0,
                12,
            ],
            [
                43,
                1,
            ],
            [
                0,
                6,
            ],
            [
                43,
                5,
            ],
            [
                0,
                4,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                4,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                5,
            ],
            [
                43,
                2,
            ],
            [
                0,
                18,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                4,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                2,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                11,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                46,
            ],
            [
                43,
                1,
            ],
            [
                0,
                17,
            ],
            [
                43,
                1,
            ],
            [
                0,
                21,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                1,
            ],
            [
                43,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                49,
                1,
            ],
            [
                0,
                12,
            ],
            [
                49,
                1,
            ],
            [
                0,
                6,
            ],
            [
                49,
                5,
            ],
            [
                0,
                4,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                4,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                5,
            ],
            [
                49,
                2,
            ],
            [
                0,
                18,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                4,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                2,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                11,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                46,
            ],
            [
                49,
                1,
            ],
            [
                0,
                17,
            ],
            [
                49,
                1,
            ],
            [
                0,
                21,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                1,
            ],
            [
                49,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                559,
                1,
            ],
            [
                0,
                12,
            ],
            [
                559,
                1,
            ],
            [
                0,
                6,
            ],
            [
                559,
                5,
            ],
            [
                0,
                4,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                4,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                5,
            ],
            [
                559,
                2,
            ],
            [
                0,
                18,
            ],
            [
                483,
                1,
            ],
            [
                0,
                1,
            ],
            [
                483,
                1,
            ],
            [
                0,
                1,
            ],
            [
                483,
                1,
            ],
            [
                0,
                4,
            ],
            [
                483,
                1,
            ],
            [
                0,
                1,
            ],
            [
                483,
                1,
            ],
            [
                0,
                1,
            ],
            [
                483,
                1,
            ],
            [
                0,
                2,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                1,
            ],
            [
                542,
                1,
            ],
            [
                0,
                11,
            ],
            [
                420,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                46,
            ],
            [
                559,
                1,
            ],
            [
                0,
                17,
            ],
            [
                559,
                1,
            ],
            [
                0,
                21,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                1,
            ],
            [
                544,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                297,
                1,
            ],
            [
                0,
                12,
            ],
            [
                297,
                1,
            ],
            [
                0,
                6,
            ],
            [
                297,
                5,
            ],
            [
                0,
                4,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                4,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                5,
            ],
            [
                297,
                2,
            ],
            [
                0,
                18,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                4,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                2,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                11,
            ],
            [
                293,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                46,
            ],
            [
                297,
                1,
            ],
            [
                0,
                17,
            ],
            [
                297,
                1,
            ],
            [
                0,
                21,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                1,
            ],
            [
                297,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                121,
                1,
            ],
            [
                0,
                12,
            ],
            [
                121,
                1,
            ],
            [
                0,
                6,
            ],
            [
                121,
                5,
            ],
            [
                0,
                4,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                4,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                5,
            ],
            [
                121,
                2,
            ],
            [
                0,
                18,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                4,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                2,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                11,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                46,
            ],
            [
                121,
                1,
            ],
            [
                0,
                17,
            ],
            [
                121,
                1,
            ],
            [
                0,
                21,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                1,
            ],
            [
                121,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Prince Edward Island, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                112,
                1,
            ],
            [
                0,
                12,
            ],
            [
                112,
                1,
            ],
            [
                0,
                6,
            ],
            [
                112,
                5,
            ],
            [
                0,
                4,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                4,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                1,
                5,
            ],
            [
                112,
                2,
            ],
            [
                0,
                18,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                4,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                1,
            ],
            [
                85,
                1,
            ],
            [
                0,
                2,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                1,
            ],
            [
                110,
                1,
            ],
            [
                0,
                11,
            ],
            [
                84,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                46,
            ],
            [
                112,
                1,
            ],
            [
                0,
                17,
            ],
            [
                112,
                1,
            ],
            [
                0,
                21,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                1,
            ],
            [
                111,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                5,
            ],
            [
                0,
                4,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                4,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                5,
            ],
            [
                2,
                2,
            ],
            [
                0,
                18,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                4,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                2,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                11,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                46,
            ],
            [
                2,
                1,
            ],
            [
                0,
                17,
            ],
            [
                2,
                1,
            ],
            [
                0,
                21,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                3,
                5,
            ],
            [
                0,
                4,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                4,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                5,
            ],
            [
                3,
                2,
            ],
            [
                0,
                18,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                4,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                2,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                11,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                46,
            ],
            [
                3,
                1,
            ],
            [
                0,
                17,
            ],
            [
                3,
                1,
            ],
            [
                0,
                21,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                1,
            ],
            [
                3,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                98,
                1,
            ],
            [
                0,
                12,
            ],
            [
                98,
                1,
            ],
            [
                0,
                6,
            ],
            [
                98,
                5,
            ],
            [
                0,
                4,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                4,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                5,
            ],
            [
                98,
                2,
            ],
            [
                0,
                18,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                4,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                1,
            ],
            [
                71,
                1,
            ],
            [
                0,
                2,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                1,
            ],
            [
                96,
                1,
            ],
            [
                0,
                11,
            ],
            [
                70,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                46,
            ],
            [
                98,
                1,
            ],
            [
                0,
                17,
            ],
            [
                98,
                1,
            ],
            [
                0,
                21,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                1,
            ],
            [
                97,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                5,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                5,
            ],
            [
                4,
                2,
            ],
            [
                0,
                18,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                2,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                11,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                46,
            ],
            [
                4,
                1,
            ],
            [
                0,
                17,
            ],
            [
                4,
                1,
            ],
            [
                0,
                21,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                4,
                1,
            ],
            [
                0,
                12,
            ],
            [
                4,
                1,
            ],
            [
                0,
                6,
            ],
            [
                4,
                5,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                5,
            ],
            [
                4,
                2,
            ],
            [
                0,
                18,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                4,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                2,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                11,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                46,
            ],
            [
                4,
                1,
            ],
            [
                0,
                17,
            ],
            [
                4,
                1,
            ],
            [
                0,
                21,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                1,
            ],
            [
                4,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Quebec, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                1690,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1690,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1680,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1647,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1647,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1647,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                12,
                5,
            ],
            [
                1690,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1576,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1576,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1576,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1642,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1537,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1654,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1680,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1680,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1647,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1647,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1647,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1647,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        '100M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                35,
            ],
            [
                3,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                3,
                1,
            ],
            [
                0,
                12,
            ],
            [
                3,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                3,
                7,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                33,
                1,
            ],
            [
                0,
                12,
            ],
            [
                33,
                1,
            ],
            [
                0,
                6,
            ],
            [
                33,
                5,
            ],
            [
                0,
                4,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                4,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                5,
            ],
            [
                33,
                2,
            ],
            [
                0,
                18,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                4,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                2,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                11,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                46,
            ],
            [
                33,
                1,
            ],
            [
                0,
                17,
            ],
            [
                33,
                1,
            ],
            [
                0,
                21,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                1,
            ],
            [
                33,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                98,
                1,
            ],
            [
                0,
                12,
            ],
            [
                98,
                1,
            ],
            [
                0,
                6,
            ],
            [
                98,
                5,
            ],
            [
                0,
                4,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                4,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                5,
            ],
            [
                98,
                2,
            ],
            [
                0,
                18,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                4,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                2,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                11,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                46,
            ],
            [
                98,
                1,
            ],
            [
                0,
                17,
            ],
            [
                98,
                1,
            ],
            [
                0,
                21,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                1,
            ],
            [
                98,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                1194,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1194,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1194,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1161,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1161,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1161,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1194,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1090,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1090,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1090,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1156,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1060,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1168,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1194,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1194,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1161,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1161,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1161,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1161,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                275,
                1,
            ],
            [
                0,
                12,
            ],
            [
                275,
                1,
            ],
            [
                0,
                6,
            ],
            [
                275,
                5,
            ],
            [
                0,
                4,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                4,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                5,
            ],
            [
                275,
                2,
            ],
            [
                0,
                18,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                4,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                2,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                11,
            ],
            [
                266,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                46,
            ],
            [
                275,
                1,
            ],
            [
                0,
                17,
            ],
            [
                275,
                1,
            ],
            [
                0,
                21,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                1,
            ],
            [
                275,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                78,
                1,
            ],
            [
                0,
                12,
            ],
            [
                78,
                1,
            ],
            [
                0,
                6,
            ],
            [
                78,
                5,
            ],
            [
                0,
                4,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                4,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                5,
            ],
            [
                78,
                2,
            ],
            [
                0,
                18,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                4,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                2,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                11,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                46,
            ],
            [
                78,
                1,
            ],
            [
                0,
                17,
            ],
            [
                78,
                1,
            ],
            [
                0,
                21,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                1,
            ],
            [
                78,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Saskatchewan, Canada': {
        'overall': [
            [
                1,
                54,
            ],
            [
                1011,
                1,
            ],
            [
                1,
                12,
            ],
            [
                1011,
                1,
            ],
            [
                1,
                6,
            ],
            [
                1007,
                5,
            ],
            [
                1,
                4,
            ],
            [
                918,
                1,
            ],
            [
                1,
                1,
            ],
            [
                918,
                1,
            ],
            [
                1,
                1,
            ],
            [
                918,
                1,
            ],
            [
                1,
                4,
            ],
            [
                940,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                6,
                5,
            ],
            [
                1011,
                2,
            ],
            [
                1,
                18,
            ],
            [
                636,
                1,
            ],
            [
                1,
                1,
            ],
            [
                636,
                1,
            ],
            [
                1,
                1,
            ],
            [
                636,
                1,
            ],
            [
                1,
                4,
            ],
            [
                636,
                1,
            ],
            [
                1,
                1,
            ],
            [
                636,
                1,
            ],
            [
                1,
                1,
            ],
            [
                636,
                1,
            ],
            [
                1,
                2,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                1,
            ],
            [
                891,
                1,
            ],
            [
                1,
                11,
            ],
            [
                562,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                1,
                1,
            ],
            [
                940,
                1,
            ],
            [
                1,
                46,
            ],
            [
                1007,
                1,
            ],
            [
                1,
                17,
            ],
            [
                1007,
                1,
            ],
            [
                1,
                21,
            ],
            [
                898,
                1,
            ],
            [
                1,
                1,
            ],
            [
                898,
                1,
            ],
            [
                1,
                1,
            ],
            [
                898,
                1,
            ],
            [
                1,
                1,
            ],
            [
                898,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '100M Person Circle': [
            [
                1,
                74,
            ],
            [
                0,
                5,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                4,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                2,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                11,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                46,
            ],
            [
                0,
                1,
            ],
            [
                1,
                17,
            ],
            [
                0,
                1,
            ],
            [
                1,
                21,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                25,
            ],
        ],
        '10M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '20M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '50M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                35,
            ],
            [
                1,
                7,
            ],
            [
                0,
                209,
            ],
        ],
        '5M Person Circle': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                10,
                1,
            ],
            [
                0,
                12,
            ],
            [
                10,
                1,
            ],
            [
                0,
                6,
            ],
            [
                10,
                5,
            ],
            [
                0,
                4,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                4,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                5,
            ],
            [
                10,
                2,
            ],
            [
                0,
                18,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                4,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                2,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                11,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                46,
            ],
            [
                10,
                1,
            ],
            [
                0,
                17,
            ],
            [
                10,
                1,
            ],
            [
                0,
                21,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                1,
            ],
            [
                10,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                18,
                1,
            ],
            [
                0,
                12,
            ],
            [
                18,
                1,
            ],
            [
                0,
                6,
            ],
            [
                18,
                5,
            ],
            [
                0,
                4,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                4,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                5,
            ],
            [
                18,
                2,
            ],
            [
                0,
                18,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                4,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                2,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                11,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                46,
            ],
            [
                18,
                1,
            ],
            [
                0,
                17,
            ],
            [
                18,
                1,
            ],
            [
                0,
                21,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                1,
            ],
            [
                18,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                899,
                1,
            ],
            [
                0,
                12,
            ],
            [
                899,
                1,
            ],
            [
                0,
                6,
            ],
            [
                899,
                5,
            ],
            [
                0,
                4,
            ],
            [
                810,
                1,
            ],
            [
                0,
                1,
            ],
            [
                810,
                1,
            ],
            [
                0,
                1,
            ],
            [
                810,
                1,
            ],
            [
                0,
                4,
            ],
            [
                832,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                5,
            ],
            [
                899,
                2,
            ],
            [
                0,
                18,
            ],
            [
                528,
                1,
            ],
            [
                0,
                1,
            ],
            [
                528,
                1,
            ],
            [
                0,
                1,
            ],
            [
                528,
                1,
            ],
            [
                0,
                4,
            ],
            [
                528,
                1,
            ],
            [
                0,
                1,
            ],
            [
                528,
                1,
            ],
            [
                0,
                1,
            ],
            [
                528,
                1,
            ],
            [
                0,
                2,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                1,
            ],
            [
                783,
                1,
            ],
            [
                0,
                11,
            ],
            [
                455,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                1,
            ],
            [
                832,
                1,
            ],
            [
                0,
                46,
            ],
            [
                899,
                1,
            ],
            [
                0,
                17,
            ],
            [
                899,
                1,
            ],
            [
                0,
                21,
            ],
            [
                790,
                1,
            ],
            [
                0,
                1,
            ],
            [
                790,
                1,
            ],
            [
                0,
                1,
            ],
            [
                790,
                1,
            ],
            [
                0,
                1,
            ],
            [
                790,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                64,
                1,
            ],
            [
                0,
                12,
            ],
            [
                64,
                1,
            ],
            [
                0,
                6,
            ],
            [
                64,
                5,
            ],
            [
                0,
                4,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                4,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                5,
            ],
            [
                64,
                2,
            ],
            [
                0,
                18,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                4,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                2,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                11,
            ],
            [
                63,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                46,
            ],
            [
                64,
                1,
            ],
            [
                0,
                17,
            ],
            [
                64,
                1,
            ],
            [
                0,
                21,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                1,
            ],
            [
                64,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                14,
                1,
            ],
            [
                0,
                12,
            ],
            [
                14,
                1,
            ],
            [
                0,
                6,
            ],
            [
                14,
                5,
            ],
            [
                0,
                4,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                4,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                5,
            ],
            [
                14,
                2,
            ],
            [
                0,
                18,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                4,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                2,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                11,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                46,
            ],
            [
                14,
                1,
            ],
            [
                0,
                17,
            ],
            [
                14,
                1,
            ],
            [
                0,
                21,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                1,
            ],
            [
                14,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                11,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
    'Yukon, Canada': {
        'overall': [
            [
                0,
                54,
            ],
            [
                37,
                1,
            ],
            [
                0,
                12,
            ],
            [
                37,
                1,
            ],
            [
                0,
                6,
            ],
            [
                37,
                5,
            ],
            [
                0,
                4,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                4,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                1,
                5,
            ],
            [
                37,
                2,
            ],
            [
                0,
                18,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                4,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                2,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                13,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                1,
            ],
            [
                29,
                1,
            ],
            [
                0,
                46,
            ],
            [
                37,
                1,
            ],
            [
                0,
                17,
            ],
            [
                37,
                1,
            ],
            [
                0,
                21,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                1,
            ],
            [
                28,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA CMA': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Division': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Census Subdivision': [
            [
                0,
                54,
            ],
            [
                31,
                1,
            ],
            [
                0,
                12,
            ],
            [
                31,
                1,
            ],
            [
                0,
                6,
            ],
            [
                31,
                5,
            ],
            [
                0,
                4,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                4,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                5,
            ],
            [
                31,
                2,
            ],
            [
                0,
                18,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                4,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                1,
            ],
            [
                16,
                1,
            ],
            [
                0,
                2,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                13,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                1,
            ],
            [
                23,
                1,
            ],
            [
                0,
                46,
            ],
            [
                31,
                1,
            ],
            [
                0,
                17,
            ],
            [
                31,
                1,
            ],
            [
                0,
                21,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                1,
            ],
            [
                22,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Population Center': [
            [
                0,
                54,
            ],
            [
                2,
                1,
            ],
            [
                0,
                12,
            ],
            [
                2,
                1,
            ],
            [
                0,
                6,
            ],
            [
                2,
                5,
            ],
            [
                0,
                4,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                4,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                5,
            ],
            [
                2,
                2,
            ],
            [
                0,
                18,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                4,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                2,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                13,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                46,
            ],
            [
                2,
                1,
            ],
            [
                0,
                17,
            ],
            [
                2,
                1,
            ],
            [
                0,
                21,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                1,
            ],
            [
                2,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'CA Riding': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                5,
            ],
            [
                1,
                2,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
        'Subnational Region': [
            [
                0,
                54,
            ],
            [
                1,
                1,
            ],
            [
                0,
                12,
            ],
            [
                1,
                1,
            ],
            [
                0,
                6,
            ],
            [
                1,
                5,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                8,
            ],
            [
                0,
                18,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                4,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                2,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                13,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                46,
            ],
            [
                1,
                1,
            ],
            [
                0,
                17,
            ],
            [
                1,
                1,
            ],
            [
                0,
                21,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                1,
            ],
            [
                1,
                1,
            ],
            [
                0,
                25,
            ],
        ],
    },
}
export default value
